import { Axios } from "../../utils/axiosMiddleware";
import { message } from "antd";
export const ShareApi=async(inputValue,referralCode,referrer)=> {
    let response;
    try {
        const splitCode = inputValue.split('-');
        const referredById = splitCode[0] ;
        const referredId =   referrer;
        if (
          inputValue.trim() !== referralCode &&
          inputValue.trim().length === referredById?.length + referredId?.toString().length + 9
        ) {
          response = await Axios.patch(`user/referred/referredBy/${referredId}/${referredById}`);
        }
    } catch (e) {
      if (e.message === 'Network Error') {
        message.error(`${e.message}`);
      } else {
        message.error(`${e.response.data.message}`);
      }
    }

    return response;
  }
  

import React from 'react';
import { FormattedMessage } from 'react-intl'; 

const Title = (props) => {
  return (
    <p>
      <span
        style={{
          fontFamily: 'Poppins',
          fontWeight: 'normal',
          fontSize: 'medium',
        }}
      >
        <b>
          <FormattedMessage {...props} />
        </b>
      </span>
    </p>
  );
};

export default Title;

import { Modal } from "antd";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddBank from "../../assets/images/AddBank.svg";
import Wallet from "../../assets/images/editBank.svg";
import { setBankModal } from "../../actions/bankActions";
import { useNavigate } from "react-router";
import { injectIntl } from "react-intl";

const BankModal = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const BankModal = useSelector((state) => state?.bankInfo?.bankModal);
  const fullUser = useSelector((state) => state?.common?.fullUser);
  const userData = JSON.parse(localStorage.getItem("data"));
  const navigateTo = () => {
    if (userData?.user_type === "homeowner") {
      navigate("/bank-accounts");
    } else if (userData?.user_type === "contractor") {
      navigate("/payment-options");
    } else if (fullUser?.receivingAccountVerify && fullUser?.kyc) {
      navigate("/bank-info");
    } else if (fullUser?.receivingAccountVerify) {
      navigate("/w9-form");
    } else {
      navigate("/get-payments");
    }
    dispatch(setBankModal(false));
  };

  const handleCancel = useCallback(() => {
    dispatch(setBankModal(false));
  });
  const handleBankImageClick = useCallback(() => {
    navigateTo();
  });
  const handleWalletImageClick = () => {
    dispatch(setBankModal(false));
    navigate("/get-payments?path=debitcard");
  };
  return (
    <Modal
      open={BankModal}
      footer={null}
      destroyOnClose={true}
      onCancel={handleCancel}
    >
      <div className="d-flex flex-column">
        <span className="fs-3 fw-bold mb-3 text-center mt-3">
          {props.intl.formatMessage({ id: "Select" })}
        </span>
        <div className="d-flex justify-content-around align-items-center">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img
              src={AddBank}
              className="bank-modal-image"
              onClick={handleBankImageClick}
            />
            <span className="bank-modal-text">
              {props.intl.formatMessage({ id: "add_bank_info" })}
            </span>
          </div>
          {userData?.user_type === "tradesman" && (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                src={Wallet}
                className="bank-modal-image"
                onClick={handleWalletImageClick}
              />
              <span className="bank-modal-text">
                {props.intl.formatMessage({ id: "add_debit_card" })}
              </span>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default injectIntl(BankModal);

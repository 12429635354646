import { message } from "antd";
import { FormattedMessage } from "react-intl";
import STRINGS from "./UserTypes";
import { GetUserSkills } from "../api/contractsApi/getSkillsApi";

export const formatDate = (date) => {
  const originalDate = new Date(date);
  const year = originalDate.getFullYear();
  const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
  const day = originalDate.getDate().toString().padStart(2, "0");
  return `${month}/${day}/${year}`;
};
export const formatSocialNumber = (socialNumber) => {
  return socialNumber.toString().replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
};
export const formatPhoneNumber = (phone) => {
  return phone.toString().replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
};
export const formatTime = (date) => {
  const inputDate = new Date(date);

  const hours = inputDate.getHours().toString().padStart(2, "0");
  const minutes = inputDate.getMinutes().toString().padStart(2, "0");
  const seconds = inputDate.getSeconds().toString().padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
};

export const catchError = async (e) => {
  const error = e.response.data[0]
    ? JSON.stringify(e.response.data[0])
    : "No response from Banking Partner, Please try again later.";
  if (
    e.response.data[0] === "Address State is not in the list" ||
    e.response.data[0] === "Base Address is not Deliverable"
  ) {
    message.error("Address is not Deliverable, please provide another");
  } else {
    message.error(error);
  }
  if (e.message === "Network Error") {
    message.error(`${e.message}`);
  } else if (e.response.data.message) {
    message.error(`${e.response.data.message}`);
  }
};

export const truncateString = (str, limit) => {
  if (str.length > limit) {
    return str.substring(0, limit) + "...";
  } else {
    return str;
  }
};

export const SimpleCatchError = (e) => {
  if (e?.response?.data?.message === "Session Expired") {
    localStorage.clear();
    window.location.href = "/";
  } else if (e.message === "Network Error") {
    message.error(`${e.message}`);
  } else {
    message.error(`${e?.response?.data?.message}`);
  }
};

export const convertTime = (dateString) => {
  const dateObject = new Date(dateString);

  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return dateObject.toLocaleString("en-US", options);
};

export const getModalTitle = (userData, paymentType) => {
  if (userData?.user_type === "contractor" && paymentType === "send") {
    return <FormattedMessage id="receiver_account" />;
  } else if (
    userData?.user_type === "contractor" &&
    paymentType === "receive"
  ) {
    return <FormattedMessage id="sender_account" />;
  } else if (userData?.user_type === "homeowner") {
    return <FormattedMessage id="receiver_account" />;
  } else if (userData?.user_type === "tradesman") {
    return <FormattedMessage id="sender_account" />;
  }
};

export const getContractValues = ({
  navigate,
  verification,
  setVerificationModal,
  senderType,
  receiverType,
  receiverTypeId,
  skills,
}) => {
  if (!verification) {
    navigate("/payment-options");
    return;
  }

  const contractType =
    senderType === STRINGS.CONTRACTOR_TYPE &&
    receiverType === STRINGS.HOMEOWNER_TYPE
      ? "homeowner-reverse"
      : null;
  const contractPath =
    senderType === STRINGS.CONTRACTOR_TYPE &&
    receiverType === STRINGS.HOMEOWNER_TYPE
      ? "estimation"
      : "Chat";
  navigate("/create-contract", {
    state: {
      path: contractPath,
      skills: skills,
      tradesmanID: receiverTypeId,
      contractWithUser: receiverType,
      type: contractType,
    },
  });
};

export const extractString = (inputString) => {
  const parts = inputString.split("_");
  const address = parts[parts.length - 1].trim();
  return address;
};

export const extractLatLng = (inputString) => {
  const parts = inputString.split("_");
  const coordinates = {
    lat: parts[0],
    lng: parts[1],
  };
  return coordinates;
};

export const handleVideoChange = async (e) => {
  const selectedFile = e;
  async function checkVideoDuration(file) {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      const reader = new FileReader();

      reader.onload = function (e) {
        video.src = e?.target?.result;
        video.addEventListener("loadedmetadata", function () {
          resolve(video.duration);
        });
      };

      reader.readAsDataURL(file);
    });
  }

  const duration = await checkVideoDuration(selectedFile);

  if (duration > 60) {
    message.error(
      "Please select a video with a duration of 60 seconds or less."
    );
    e.target.value = "";
    return null;
  }
  selectedFile.duration = duration;

  return selectedFile;
};

export const getUserSkills = async (payload, dispatch, intl) => {
  await GetUserSkills(payload, dispatch, intl);
};

export const getContractedUser = (current_type, contract) => {
  let userType = "",
    ids = {};
  if (current_type === "contractor") {
    userType = contract?.homeownerId ? "homeowner" : "tradesman";
  } else if (current_type === "tradesman") {
    userType = contract?.homeownerId ? "homeowner" : "contractor";
  } else {
    userType = contract?.contractorId ? "contractor" : "tradesman";
  }
  ids["id"] = contract?.[userType]?.id;
  return { user_contracted_type: userType, ...ids };
};

export const checkAccountConfirmation = (
  currentUserType,
  contractedUserType,
  verification,
  setAccountInfo
) => {
  if (currentUserType === "contractor") {
    if (!verification) {
      setAccountInfo(true);
      return contractedUserType === "homeowner" ? "receive" : "sender";
    }
  } else if (currentUserType === "homeowner") {
    if (!verification) {
      setAccountInfo(true);
      return "sender";
    }
  } else if (currentUserType === "tradesman") {
    if (!verification) {
      setAccountInfo(true);
      return "receive";
    }
  }
  return null;
};

export const capitalizeTo = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useState } from "react";
export default function ImageViewModal({
  showModal,
  setShowModal,
  images,
  index,
}) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(index);
  const [number, setNumber] = useState(index + 1);
  const total = images?.length;
  const handlePrevImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : images?.length - 1
    );
  };

  const handleNextImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex < images?.length - 1 ? prevIndex + 1 : 0
    );
    setNumber((prevIndex) => (prevIndex < images?.length ? prevIndex + 1 : 1));
  };
  return (
    <Modal
      open={showModal}
      onCancel={() => setShowModal(false)}
      footer={false}
      className="image-view-modal"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ position: "relative" }}>
        {total > 1 && (
          <div
            className="d-flex justify-content-center align-items-center prev-image"
            onClick={handlePrevImage}
          >
            <ArrowLeftOutlined style={{ fontSize: "30px", color: "white" }} />
          </div>
        )}
        {total > 1 && (
          <div
            className="d-flex justify-content-center align-items-center next-image"
            onClick={handleNextImage}
          >
            <ArrowRightOutlined style={{ fontSize: "30px", color: "white" }} />
          </div>
        )}

        <div className="imageViewPic">
          <img
            src={`${process.env.REACT_APP_S3_BUCKET_URL}${images[selectedImageIndex]?.key}`}
            alt=""
            style={{ objectFit: "contain", width: "100%", height: "100%" }}
          />
        </div>
      </div>
      {total > 1 && (
        <div
          style={{ width: "100%", height: "30px" }}
          className="d-flex justify-content-center fs-3"
        >
          <span>
            {number}/{total}
          </span>
        </div>
      )}
    </Modal>
  );
}

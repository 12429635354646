import React from "react";
import { useNavigate } from "react-router";

import STRINGS from "../../utils/UserTypes";
import paperPlane from "../../assets/images/PaperPlane.svg";
import { injectIntl } from "react-intl";
import { getContractValues } from "../../utils/Helper";

const ShowContractIcon = ({
  intl,
  senderType,
  receiver_type,
  tradesmanSkills,
  userTypeData,
  setShowProfileCompletionModal,
  setVerificationModal,
  receiverTypeId,
  allowChat,
}) => {
  const navigate = useNavigate();
  const shouldShowContractIcon = (senderType, receiver_type) => {
    return (
      ((senderType === STRINGS.TRADESMAN_TYPE &&
        receiver_type === STRINGS.CONTRACTOR_TYPE) ||
        (senderType === STRINGS.CONTRACTOR_TYPE &&
          receiver_type === STRINGS.TRADESMAN_TYPE) ||
        (senderType === STRINGS.HOMEOWNER_TYPE &&
          receiver_type === STRINGS.CONTRACTOR_TYPE) ||
        (senderType === STRINGS.HOMEOWNER_TYPE &&
          receiver_type === STRINGS.TRADESMAN_TYPE) ||
        (senderType === STRINGS.CONTRACTOR_TYPE &&
          receiver_type === STRINGS.HOMEOWNER_TYPE)) &&
      allowChat !== false
    );
  };

  const getContractIconLabel = (senderType) => {
    if (senderType === STRINGS.HOMEOWNER_TYPE) {
      return intl.formatMessage({ id: "WORK_REQUEST" });
    } else if (
      (senderType === STRINGS.CONTRACTOR_TYPE &&
        receiver_type === STRINGS.TRADESMAN_TYPE) ||
      (senderType === STRINGS.TRADESMAN_TYPE &&
        receiver_type === STRINGS.CONTRACTOR_TYPE)
    ) {
      return intl.formatMessage({ id: "CONTRACT" });
    } else if (
      senderType === STRINGS.CONTRACTOR_TYPE &&
      receiver_type === STRINGS.HOMEOWNER_TYPE
    ) {
      return intl.formatMessage({ id: "ESTIMATES" });
    } else {
      return null;
    }
  };

  const handleContractIconPress = () => {
    if (!tradesmanSkills || tradesmanSkills.length < 1) {
      setShowProfileCompletionModal(true);
      return;
    }

    getContractValues({
      navigate,
      verification: userTypeData?.sendingAccountVerify,
      setVerificationModal,
      senderType,
      receiverType: receiver_type,
      receiverTypeId,
      skills: tradesmanSkills,
    });
  };

  if (!shouldShowContractIcon(senderType, receiver_type)) {
    return <div></div>;
  }

  return (
    <>
      <div
        className="d-flex me-3 mt-3"
        onClick={handleContractIconPress}
        style={{ cursor: "pointer" }}
      >
        <p
          className="me-2"
          style={{
            color: "#f77e0b",
          }}
        >
          {getContractIconLabel(senderType)}
        </p>
        <img
          src={paperPlane}
          style={{ width: "25px", height: "25px" }}
          alt={"Create Contract"}
        />
      </div>
    </>
  );
};

export default injectIntl(ShowContractIcon);

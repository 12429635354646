import { setW9formData } from "../../actions/W9formActions";
import { SimpleCatchError } from "../../utils/Helper";
import { Axios } from "../../utils/axiosMiddleware";
export const getFormData = async (token, dispatch) => {
  const currentDate = new Date().toISOString().slice(0, 10);

  try {
    const response = await Axios.get(
      `everee/w9-form?userId=${token?.user_id}&date=${currentDate}`
    );
    dispatch(setW9formData(response?.data));
  } catch (e) {
    dispatch(setW9formData({}));
    SimpleCatchError(e);
  }
};

import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
export default function Loader({ Style, loadingFontSize = 64 }) {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: loadingFontSize,
        color: "#F77E0B ",
      }}
      spin
    />
  );

  return (
    <div style={{ display: "flex", justifyContent: "center", ...Style }}>
      <Spin spinning={true} size="larger" indicator={antIcon} />
    </div>
  );
}

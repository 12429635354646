import { Axios } from "../../utils/axiosMiddleware";
import { SimpleCatchError } from "../../utils/Helper";
export const IntroVideoUpload = async (payload) => {
  try {
    const { formData, setUploadPercentage } = payload;
    await Axios.post("/user/profile", formData, {
      onUploadProgress: (progressEvent) => {
        let Percentage = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        setUploadPercentage(Percentage);
      },
    });
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const UpdateVideoUpload = async (formData, setUploadPercentage) => {
  try {
    await Axios.post("files/profile", formData, {
      onUploadProgress: (progressEvent) => {
        let Percentage = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        setUploadPercentage(Percentage);
      },
    });
  } catch (e) {
    SimpleCatchError(e);
  }
};

import React, { useEffect, useState } from "react";
import Subscription from "../../assets/images/subscription.svg";
import { FormattedMessage } from "react-intl";
import { Divider } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Disclaimer from "../BankInfo/Disclaimer";
import { subscriberTrial } from "../../api/Subscribers/subscriber";

export const SubscriptionOption = () => {
  const navigate = useNavigate();
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState("");
  const [alreadySubscribed, setAlreadySubscribed] = useState(false);
  const userData = JSON.parse(localStorage.getItem("data"));
  const fullUser = useSelector((state) => state?.common?.fullUser);
  const handleUserClick = () => {
    if (fullUser?.sendingAccountVerify && !fullUser?.subscribed) {
      navigate("/subscription");
    } else if (
      fullUser?.subscribed &&
      subscriptionType == "trial" &&
      !fullUser?.nextSubscription &&
      fullUser?.sendingAccountVerify
    ) {
      setAlreadySubscribed(true);
      return true;
    } else {
      setSubscriptionModal(true);
    }
  };
  const handleModalClick = () => {
    if (userData?.user_type === "homeowner") {
      navigate("/add-hom-account");
    } else {
      navigate("/payment-options");
    }
  };
  useEffect(() => {
    subType();
  }, []);
  const subType = async () => {
    const res = await subscriberTrial();
    setSubscriptionType(res);
  };
  return (
    <>
      {subscriptionType === "privilege" ||
      subscriptionType === "temporary_privilege" ? (
        ""
      ) : (
        <div
          className="d-flex flex-column"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
          onClick={handleUserClick}
        >
          <div className="d-flex align-items-center p-2">
            <img
              src={Subscription}
              alt=""
              style={{ width: "20px", height: "20px" }}
            />
            <span className="dropdown-text ms-3">
              <FormattedMessage id="subscription" />
            </span>
          </div>
          <Divider style={{ margin: "4px 0" }} />
        </div>
      )}
      <Disclaimer
        showModal={subscriptionModal}
        setShowModal={() => setSubscriptionModal(false)}
        body={
          fullUser?.subscribed &&
          !fullUser?.nextSubscription &&
          subscriptionType === "paid" ? (
            <FormattedMessage id="already_subscribe" />
          ) : fullUser?.subscribed &&
            fullUser?.nextSubscription &&
            subscriptionType === "paid" ? (
            <FormattedMessage id="already_subscribed" />
          ) : (
            <FormattedMessage id="add_sender_account" />
          )
        }
        confirmText={
          fullUser?.subscribed &&
          !fullUser?.nextSubscription &&
          subscriptionType === "paid" ? (
            ""
          ) : fullUser?.subscribed &&
            fullUser?.nextSubscription &&
            subscriptionType === "paid" ? (
            <FormattedMessage id="unsubscribe" />
          ) : (
            <FormattedMessage id="add" />
          )
        }
        cancelText={
          fullUser?.subscribed &&
          !fullUser?.nextSubscription &&
          subscriptionType === "paid" ? (
            <FormattedMessage id="ok" />
          ) : (
            <FormattedMessage id="cancel" />
          )
        }
        handleClick={() => {
          fullUser?.subscribed &&
          fullUser?.nextSubscription &&
          subscriptionType === "paid"
            ? navigate("/subscription")
            : handleModalClick();
        }}
        handleCancel={() => setSubscriptionModal(false)}
      />
      <Disclaimer
        showModal={alreadySubscribed}
        setShowModal={() => setAlreadySubscribed(false)}
        body={<FormattedMessage id="free_trail" />}
        confirmText={<FormattedMessage id="subscribe" />}
        cancelText={<FormattedMessage id="cancel" />}
        handleClick={handleModalClick}
        handleCancel={() => setAlreadySubscribed(false)}
      />
    </>
  );
};

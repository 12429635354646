import { Button, Modal } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";

const SubmitProofOfWorkModal = ({ visible, onClose }) => {
  const navigate = useNavigate();

  return (
    <Modal open={visible} footer={false} width={400} onCancel={onClose}>
      <div className="container-sm pt-2">
        <div className="text-center separator">
          <h3
            pb="5"
            className="click_sub_heading"
            style={{
              fontWeight: "600",
            }}
          >
            <FormattedMessage id="subheading.submit_proof_of_work" />
          </h3>
          <p
            style={{
              fontFamily: "Poppins",
              fontSize: "16px",
            }}
          >
            <FormattedMessage id="body.attach_pictures_to_get_paid" />
          </p>
        </div>
        <Button
          onClick={() => navigate(`/submit-review`, { state: { page: 0 } })}
          style={{
            color: "#F77E0B",
            width: "100%",
            fontFamily: "Poppins",
            fontWeight: "bold",
            border: "0px",
            marginVertical: "",
          }}
        >
          <FormattedMessage id="click.attach_pictures" />
        </Button>
      </div>
    </Modal>
  );
};

export default SubmitProofOfWorkModal;

import React from 'react'
import phoneGroup from "../../assets/images/PhoneGroup.png"
import AppStore from "../../assets/images/AppStore.png"
import GooglePlay from "../../assets/images/GooglePlay.png";
import { injectIntl } from 'react-intl';
const ClickAway = (props) => {
    return (
        <div>
            <div className="container-sm pt-5">
                <div className='text-center'>
                    <h1 pb="5" className='click_away_heading'>{props.intl.formatMessage({id: "clickAway.wearejust"})} <span style={{ color: "#F77E0B" }}><span style={{ borderBottom: "4px solid #F77E0B", marginBottom: "6px" }}>{props.intl.formatMessage({id: "clickAway.oneclick"})}</span> </span>{props.intl.formatMessage({id: "clickAway.away"})}</h1>
                    <p style={{ marginBottom: "86px" }} className="click_away_sub_heading">{props.intl.formatMessage({id: "clickAway.downloadGigmi"})}
                    </p>
                </div>
                <div className="row justify-content-center align-items-center g-2">

                    <div className="col" height="514px" width="100%">
                        <img src={phoneGroup} alt="Phone Group" width="100%" height="100%" />
                    </div>
                </div>
                <div className='text-center'>
                    <p style={{ fontSize: "26px", fontWeight: 400, color: "#000000",fontFamily:"Poppins" }}>{props.intl.formatMessage({id: "clickAway.joinus"})}</p>
                </div>
                <div className="d-flex justify-content-center" style={{ width: "100%", gap: "24px", display: "flex", justifyContent: "space-between" }}>



                    <a href='https://play.google.com/store/apps/details?id=com.gigmi' target="_blank" rel="noreferrer">
                        <img src={GooglePlay} alt="Google Play" style={{ width: "120px" }} />
                    </a>

                    <a href="https://apps.apple.com/us/app/gigmi-contractor-revolution/id1604625082" target="_blank" rel="noreferrer">
                        <img src={AppStore} alt="App Store" style={{ width: "120px" }} />
                    </a>

                </div>
            </div>
        </div>
    )
}

export default injectIntl( ClickAway);
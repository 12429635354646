import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { getContractStatus, getIDStatus, getSkill } from "./helper";
import { Divider } from "antd";
import { useNavigate } from "react-router";
import { WEATHER_API_KEY, bucket_base_url } from "../../constants";
import DocumentIcon from "../../assets/images/documentColored.svg";
import ImageViewModal from "../UserProfile/ImageViewModal";
import axios from "axios";
export const ContractDetailCard = ({
  contractDetails,
  contractState = false,
  flatAmountOnPress,
  amountTextStyle,
  contract_location,
  userData,
}) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [images, setImages] = useState();
  const [index, setIndex] = useState();
  const [weather, setWeather] = useState(null);

  const contractStatuses =
    contractDetails?.homeownerId && !contractDetails?.tradesmanId
      ? [
          "contractor_changed_requirements_homeowner",
          "homeowner_accepted_changes",
        ]
      : [
          "contractor_changed_requirements_gigster",
          "gigster_changed_requirements_homeowner",
          "gigster_accepted_changes",
        ];

  const showSecondaryStatus =
    !contractState &&
    contractStatuses?.includes(contractDetails?.secondary_status);

  const getFlatAmount = () => {
    let flatAmount;
    if (showSecondaryStatus) {
      flatAmount = parseInt(contractDetails?.changed_flat_amount);
    } else if (contractDetails?.updated_flat_amount) {
      flatAmount = parseInt(contractDetails?.updated_flat_amount);
    } else {
      flatAmount = parseInt(contractDetails?.flat_amount);
    }
    return flatAmount ? `$  ${flatAmount}` : "---";
  };

  const getJobDesc = (lineItem) => {
    if (showSecondaryStatus && !lineItem?.isCompleted) {
      return lineItem?.changed_job_desc;
    } else if (lineItem?.updated_job_desc) {
      return lineItem?.updated_job_desc;
    } else {
      return lineItem?.job_desc;
    }
  };

  const getFlatRate = (lineItem) => {
    if (showSecondaryStatus && !lineItem?.isCompleted) {
      return `$  ${lineItem?.changed_flat_rate}`;
    } else if (lineItem?.updated_flat_rate) {
      return `$  ${lineItem?.updated_flat_rate}`;
    } else {
      return `$  ${lineItem?.flat_rate}`;
    }
  };

  const getSetRate = (lineItem) => {
    if (showSecondaryStatus && !lineItem?.isCompleted) {
      return `$  ${lineItem?.changed_set_rate}`;
    } else if (lineItem?.updated_set_rate) {
      return `$  ${lineItem?.updated_set_rate}`;
    } else {
      return `$  ${lineItem?.set_rate}`;
    }
  };

  const subContractStatusColor = (item) => {
    if (["running", "pending_review"]?.includes(item?.status)) {
      return "rgba(140, 198, 63, 1)";
    } else if (item?.status === "receive") {
      return "#f77e0b";
    } else if (item?.status === "archived") {
      return "rgba(0, 0, 0, 1)";
    } else {
      return "rgba(255, 0, 0, 1)";
    }
  };

  const handleOpenGoogleMaps = () => {
    const latitude = contract_location?.latitude;
    const longitude = contract_location?.longitude;
    const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(googleMapsUrl, "_blank");
  };

  useEffect(() => {
    if (contract_location) {
      getWeather(contract_location?.latitude, contract_location?.longitude);
    }
    return () => {
      setWeather(null);
    };
  }, [contract_location]);

  const getWeather = async (lat, lon) => {
    const response = await axios.get(
      `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${WEATHER_API_KEY}&units=metric`
    );
    setWeather(response?.data);
  };

  return (
    <div className="date-div d-flex flex-column mt-5">
      <div className="d-flex justify-content-between align-items-center mt-2">
        <span className="fw-bold ">
          <FormattedMessage id="status" />:
        </span>
        <span className="status-div">
          {getContractStatus(
            showSecondaryStatus
              ? contractDetails?.secondary_status
              : contractDetails?.primary_status
              ? contractDetails?.primary_status
              : contractDetails?.status
          )}
        </span>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-2">
        <span className="fw-bold">{getIDStatus(contractDetails?.status)}:</span>
        <span>{contractDetails?.id}</span>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-2">
        <span className="fw-bold">
          {" "}
          <FormattedMessage id="skill" />:
        </span>
        <span>{getSkill(contractDetails)}</span>
      </div>
      <div
        onClick={getFlatAmount() !== "---" ? flatAmountOnPress : null}
        className="d-flex justify-content-between align-items-center mt-2"
      >
        <span
          style={
            (contractDetails?.contractor &&
              userData?.user_type === "homeowner") ||
            (contractDetails?.tradesman && userData?.user_type === "contractor")
              ? amountTextStyle
              : {}
          }
          className="fw-bold"
        >
          <FormattedMessage id="FLAT_AMOUNT" />:
        </span>
        {(contractDetails?.lineItemsSubcontract?.length > 0 ||
          contractDetails?.lineItem?.length > 0 ||
          (contractDetails?.lineItemsSubcontract?.length === 0 &&
            contractDetails?.lineItem?.length === 0)) && (
          <span>
            {getFlatAmount()}{" "}
            {getFlatAmount() !== "---" ? <FormattedMessage id="FIXED" /> : null}
          </span>
        )}
      </div>
      {weather && (
        <div
          style={{ alignItems: "center" }}
          className="d-flex justify-content-between  mt-2"
        >
          <span className="fw-bold">
            <FormattedMessage id="WEATHER_LOCATION" />:
          </span>
          <span
            style={{
              width: "50%",
              textAlign: "right",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
            className="d-flex justify-content-end"
            onClick={handleOpenGoogleMaps}
          >
            <img
              id="wicon"
              src={`https://openweathermap.org/img/wn/${weather?.weather[0]?.icon}.png`}
              alt="Weather icon"
            />
            <a style={{ marginRight: "10px" }}>
              {Math.round(weather?.main?.temp)}°C
            </a>
            |
            <a
              style={{
                marginRight: "10px",
                marginLeft: "10px",
                color: "black",
              }}
            >
              {weather?.weather[0]?.main}
            </a>
          </span>
        </div>
      )}
      <div className="d-flex justify-content-between  mt-2">
        <span className="fw-bold">
          <FormattedMessage id="LOCATION" />:
        </span>
        <span
          style={{
            width: "50%",
            color: "#f77e0b",
            textAlign: "right",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          className="d-flex justify-content-end"
          onClick={handleOpenGoogleMaps}
        >
          <a>{contract_location?.location_name}</a>
        </span>
      </div>
      {contractDetails?.lineItemsSubcontract?.length === 0 &&
      contractDetails?.lineItem?.length === 0 ? (
        <>
          <Divider />
          <div className="d-flex flex-column">
            <span className="fw-bold">
              <FormattedMessage id="DESCRIPTION" />:
            </span>
            <span style={{ wordBreak: "break-word" }}>
              {contractDetails?.job_desc}
            </span>
          </div>
        </>
      ) : null}
      {contractDetails?.lineItem?.length > 0 && (
        <>
          <Divider />
          <div style={{ justifyContent: "center" }}>
            {(contractDetails?.contractor &&
              (contractDetails?.tradesman || contractDetails?.homeowner) &&
              userData?.user_type === "contractor") ||
            (contractDetails?.tradesman &&
              (contractDetails?.contractor || contractDetails?.homeowner) &&
              userData?.user_type === "tradesman") ||
            ((contractDetails?.contractor || contractDetails?.tradesman) &&
              contractDetails?.homeowner &&
              userData?.user_type === "homeowner") ? (
              <>
                <span className="fw-bold">
                  <FormattedMessage id="LINE_ITEMS" />
                </span>
                {contractDetails?.lineItem?.map((item) => {
                  if (!item?.isDeleted) {
                    return (
                      <div
                        key={item?.id}
                        className="d-flex justify-content-between align-items-center mt-2"
                      >
                        <span>{getJobDesc(item)}</span>
                        <span>{getFlatRate(item)}</span>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </>
            ) : null}
          </div>
        </>
      )}
      {contractDetails?.lineItemsSubcontract?.length > 0 && (
        <div style={{ justifyContent: "center" }}>
          {contractDetails?.contractor &&
          contractDetails?.tradesman &&
          ["contractor", "tradesman"].includes(userData?.user_type) ? (
            <>
              <span>
                <FormattedMessage id="LINE_ITEMS" />
              </span>
              {contractDetails?.lineItemsSubcontract?.map((item) => {
                if (!item?.isDeleted || item?.deleted_at === null) {
                  return (
                    <div
                      key={item?.id}
                      className="d-flex justify-content-between mt-2"
                    >
                      <span>{getJobDesc(item)}</span>
                      <span>{getSetRate(item)}</span>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </>
          ) : null}
        </div>
      )}
      {contractDetails?.subContracts?.length > 0 &&
        userData?.user_type === "contractor" && (
          <>
            <span className="mt-3 fw-bold">
              <FormattedMessage id="SUB_CONTRACTS" />
            </span>
            <div className="d-flex flex-column">
              {contractDetails?.subContracts?.map((item) => (
                <div
                  key={item?.id}
                  onClick={() => navigate(`/contract-details?id=${item?.id}`)}
                  className="d-flex justify-content-between align-items-center mt-3"
                  style={{ cursor: "pointer" }}
                >
                  <div className="d-flex align-items-center">
                    <div
                      style={{
                        backgroundColor: `${subContractStatusColor(item)}`,
                        width: "15px",
                        height: "15px",
                        borderRadius: 50,
                      }}
                    ></div>
                    <div
                      className="avatar-container"
                      style={{ marginLeft: "20px" }}
                    >
                      <img
                        src={`${bucket_base_url}${item["tradesman"]?.profile_picture}`}
                        className="avatar-image"
                      />
                    </div>
                    <span style={{ marginLeft: "20px" }}>
                      {item["tradesman"]?.first_name +
                        " " +
                        item["tradesman"]?.last_name}
                    </span>
                  </div>
                  <span>
                    {item?.lineItemsSubcontract?.length} Item
                    {item?.lineItemsSubcontract?.length > 1 ? "s" : ""}
                  </span>
                </div>
              ))}
            </div>
          </>
        )}
      {contractDetails?.contractFiles?.length > 0 && <Divider />}
      {contractDetails?.contractFiles?.length > 0 ? (
        <div className="d-flex w-100 flex-wrap">
          {contractDetails?.contractFiles?.map((item, index) => {
            if (
              ["image/jpeg", "image/png"].includes(item?.type) ||
              item?.key?.includes(".jpeg")
            ) {
              return (
                <div className="contract-images mx-1" key={item?.id}>
                  <img
                    src={`${bucket_base_url}${item?.key}`}
                    onClick={() => {
                      setShowModal(true);
                      setIndex(index);
                      setImages(
                        contractDetails?.contractFiles.filter((element) =>
                          element?.type?.includes("image")
                        )
                      );
                    }}
                    className="w-100 h-100 rounded"
                    style={{ objectFit: "cover" }}
                    alt={"Contract Images"}
                  />
                </div>
              );
            } else if (
              item?.type === "application/pdf" ||
              item?.key?.includes(".pdf")
            ) {
              return (
                <div
                  key={item?.id}
                  className="d-flex contract-images rounded justify-content-center align-items-center mx-1"
                  style={{
                    border: "1px solid #f77e0b",
                    cursor: "pointer",
                  }}
                  onClick={() => window.open(bucket_base_url + item?.key)}
                >
                  <img
                    src={DocumentIcon}
                    style={{ height: "60px", width: "60px" }}
                    alt={"Document Icon"}
                  />
                </div>
              );
            }
          })}
        </div>
      ) : null}
      {showModal && (
        <ImageViewModal
          showModal={showModal}
          setShowModal={setShowModal}
          images={images}
          index={index}
        />
      )}
    </div>
  );
};

import { Button, Modal, Progress, message } from "antd";
import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { CloseOutlined } from "@ant-design/icons";
import "./style.css";
import VideoThumbnail from "react-video-thumbnail";
import Header from "../../components/Header/Header";
import { useSelector } from "react-redux";
import { UpdateVideoUpload } from "../../api/uploadVideos/UpoadProfileVideo";
import { isEmpty } from "lodash";
import { bucket_base_url } from "../../constants";
import { useLocation, useNavigate } from "react-router";
import { TextStoriesModal } from "../../components/Stories/TextStoriesModal";

const UploadProfileVideos = ({ ...props }) => {
  const { state } = useLocation();
  const { ProfileData } = state;
  const navigate = useNavigate();
  const [deletedVideoIds, setDeletedVideoIds] = useState([]);
  const userData = JSON.parse(localStorage.getItem("data"));
  const profileVideos =
    userData?.user_type === "tradesman"
      ? ProfileData?.tradesman?.tradesman_files
      : ProfileData?.contractor?.contractor_files;
  const videos = !isEmpty(profileVideos)
    ? profileVideos?.filter((item) => item?.type === "video")
    : [];
  const [data, setData] = useState(videos);
  const [thumbnail, setThumbnail] = useState(videos);
  const [thumbnailFiles, setThumbnailFiles] = useState([]);
  const [videoUrl, setVideoUrl] = useState();
  const [open, setOpen] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [percentageModal, setPercentageModal] = useState(false);
  const userTypeData = useSelector((state) => state?.common?.newUser?.data);

  async function dataUrlToFile(dataUrl, fileName) {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: "image/png" });
  }

  const handleUpload = async () => {
    if (data.length > 0) {
      let formData = new FormData();
      data?.map((element, index) => {
        if (!element.id) {
          formData.append(`files`, element?.file);
          formData.append("name", element?.file?.name);
          formData.append("textOverLay", element?.text ? element?.text : null);
        }
      });
      formData.append(
        "deletedFileId",
        deletedVideoIds ? JSON.stringify(deletedVideoIds) : JSON.stringify([])
      );
      formData.append("userRole", userData?.user_type);
      formData.append(
        userData?.user_type === "tradesman" ? "tradesmanId" : "contractorId",
        userTypeData?.id
      );
      formData.append("type", "video");
      setPercentageModal(true);
      await UpdateVideoUpload(formData, setUploadPercentage);
      setPercentageModal(false);
      navigate("/");
    } else {
      message.error(`${props.intl.formatMessage({ id: "add_video" })}`);
    }
  };
  const handleSubmit = (e) => {
    const file = e?.target?.files[0];

    if (file) {
      const fileSize = file.size;
      const maxSize = 5 * 1024 * 1024;

      if (fileSize > maxSize) {
        alert("File size exceeds 5 MB. Please choose a smaller file.");
        e.target.value = null;
        return;
      }

      const videoElement = document.createElement("video");
      videoElement.preload = "metadata";
      videoElement.onloadedmetadata = function () {
        window.URL.revokeObjectURL(videoElement.src);
        const duration = videoElement.duration;
        if (duration > 30) {
          message.error(
            `${props.intl.formatMessage({ id: "video_duration" })}`
          );
          e.target.value = null;
          return;
        }
        setData((prev) => [...prev, { file: file, text: null }]);
      };
      videoElement.src = URL.createObjectURL(file);
      setVideoUrl(videoElement.src);
      setOpen(true);
    }
    e.target.value = null;
  };
  const getVideoUrl = (index) => {
    if (data[index]?.file) {
      return URL.createObjectURL(data[index]?.file);
    }
    return null;
  };
  const handleClearClick = (index) => {
    let pics = [...data];
    let thumbnails = [...thumbnail];
    let thumbnailFilesArray = [...thumbnailFiles];
    thumbnailFilesArray.splice(index, 1);
    thumbnails.splice(index, 1);
    pics.splice(index, 1);
    setData(pics);
    setThumbnail(thumbnails);
    setThumbnailFiles(thumbnailFilesArray);
    if (data[index]?.key) {
      setDeletedVideoIds((prev) => [...prev, data[index]?.id]);
    }
  };
  const handleSubmitForm = async (url) => {
    setThumbnail((prev) => [...prev, url]);
    const fileName = "thumbnail.png";
    dataUrlToFile(url, fileName).then((file) => {
      setThumbnailFiles((prev) => [...prev, file]);
    });
  };
  return (
    <div>
      <div className="container d-flex flex-column align-items-center justify-content-center">
        <Header />
        <div className="d-flex justify-content-center align-items-center flex-column">
          <span className="upload-video-text text-center">
            <FormattedMessage id="upload_video" />
          </span>
          <span className="upload-video-sub-text">
            <FormattedMessage id="upload_video_sub_text" />
          </span>
        </div>
        <div className=" container-div border shadow">
          <div className="row p-1 w-100 d-flex justify-content-between">
            <div className="col-8 main-video-uploader">
              <div className="imageUploader">
                <input
                  id={`media`}
                  type="file"
                  accept="video/*"
                  onChange={handleSubmit}
                  className="imageUploader-input"
                />

                <div className="proofPreviewer border shadow">
                  {!data[0] && (
                    <label htmlFor={`media`}>
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          backgroundColor: "#F77E0B",
                          fontSize: "30px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        className="d-flex justify-content-center align-items-center rounded-circle"
                      >
                        <span className="mb-1">+</span>
                      </div>
                    </label>
                  )}
                </div>
                {data[0] && (
                  <>
                    {!data[0]?.key && (
                      <div style={{ display: "none" }}>
                        <VideoThumbnail
                          videoUrl={getVideoUrl(0)}
                          snapshotAtTime={1}
                          renderThumbnail={false}
                          width={0}
                          height={0}
                          cors={false}
                          thumbnailHandler={(url) => {
                            handleSubmitForm(url);
                          }}
                          style={{ display: "block" }}
                        />
                      </div>
                    )}
                    {thumbnail[0] && (
                      <div className="proofPreviewer">
                        <img
                          src={
                            thumbnail[0]?.key
                              ? `${bucket_base_url}${thumbnail[0]?.thumbnail}`
                              : thumbnail[0]
                          }
                          style={{
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    )}
                    <span
                      className="close-icon"
                      onClick={() => handleClearClick(0)}
                    >
                      <CloseOutlined />
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="col-4 sub-video-uploader-wrapper">
              <div className="col-12 sub-video-uploader ">
                <div className="imageUploader">
                  <input
                    id="media1"
                    type="file"
                    accept="video/*"
                    onChange={handleSubmit}
                    className="imageUpload-input"
                  />

                  <div className="proofPreviewer border shadow">
                    {!data[1] && (
                      <label htmlFor={`media1`}>
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: "#F77E0B",
                            fontSize: "20px",
                            color: "white",
                            cursor: "pointer",
                          }}
                          className="d-flex justify-content-center align-items-center rounded-circle"
                        >
                          <span
                            style={{ marginBottom: "2px", marginRight: "1px" }}
                          >
                            +
                          </span>
                        </div>
                      </label>
                    )}
                  </div>
                  {data[1] && (
                    <>
                      {!data[1]?.key && (
                        <div style={{ display: "none" }}>
                          <VideoThumbnail
                            videoUrl={getVideoUrl(1)}
                            snapshotAtTime={1}
                            renderThumbnail={false}
                            thumbnailHandler={(url) => handleSubmitForm(url)}
                          />
                        </div>
                      )}
                      {thumbnail[1] && (
                        <div className="proofPreviewer">
                          <img
                            src={
                              thumbnail[1]?.key
                                ? `${bucket_base_url}${thumbnail[1]?.thumbnail}`
                                : thumbnail[1]
                            }
                            style={{
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      )}
                      <span
                        className="close-icon"
                        onClick={() => handleClearClick(1)}
                      >
                        <CloseOutlined />
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className="col-12 sub-video-uploader">
                <div className="imageUploader">
                  <input
                    id="media1"
                    type="file"
                    accept="video/*"
                    onChange={handleSubmit}
                    className="imageUpload-input"
                  />

                  <div className="proofPreviewer border shadow">
                    {!data[2] && (
                      <label htmlFor={`media1`}>
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: "#F77E0B",
                            fontSize: "20px",
                            color: "white",
                            cursor: "pointer",
                          }}
                          className="d-flex justify-content-center align-items-center rounded-circle"
                        >
                          <span
                            style={{ marginBottom: "2px", marginRight: "1px" }}
                          >
                            +
                          </span>
                        </div>
                      </label>
                    )}
                  </div>
                  {data[2] && (
                    <>
                      {!data[2]?.key && (
                        <div style={{ display: "none" }}>
                          <VideoThumbnail
                            videoUrl={getVideoUrl(2)}
                            snapshotAtTime={1}
                            renderThumbnail={false}
                            thumbnailHandler={(url) => handleSubmitForm(url)}
                          />
                        </div>
                      )}
                      {thumbnail[2] && (
                        <div className="proofPreviewer">
                          <img
                            src={
                              thumbnail[2]?.key
                                ? `${bucket_base_url}${thumbnail[2]?.thumbnail}`
                                : thumbnail[2]
                            }
                            style={{
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      )}
                      <span
                        className="close-icon"
                        onClick={() => handleClearClick(2)}
                      >
                        <CloseOutlined />
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center w-100 p-1">
            <div className="col-4 sub-video-uploader-two ">
              <div className="imageUploader">
                <input
                  id="media1"
                  type="file"
                  accept="video/*"
                  onChange={handleSubmit}
                  className="imageUploader-input"
                />

                <div className="proofPreviewer border shadow">
                  {!data[3] && (
                    <label htmlFor={`media1`}>
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#F77E0B",
                          fontSize: "20px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        className="d-flex justify-content-center align-items-center rounded-circle"
                      >
                        <span style={{ marginBottom: "1px" }}>+</span>
                      </div>
                    </label>
                  )}
                </div>
                {data[3] && (
                  <>
                    {!data[3]?.key && (
                      <div style={{ display: "none" }}>
                        <VideoThumbnail
                          videoUrl={getVideoUrl(3)}
                          snapshotAtTime={1}
                          renderThumbnail={false}
                          thumbnailHandler={(url) => handleSubmitForm(url)}
                        />
                      </div>
                    )}
                    {thumbnail[3] && (
                      <div className="proofPreviewer">
                        <img
                          src={
                            thumbnail[3]?.key
                              ? `${bucket_base_url}${thumbnail[3]?.thumbnail}`
                              : thumbnail[3]
                          }
                          style={{
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    )}
                    <span
                      className="close-icon"
                      onClick={() => handleClearClick(3)}
                    >
                      <CloseOutlined />
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="col-4 sub-video-uploader-two ">
              <div className="imageUploader">
                <input
                  id="media1"
                  type="file"
                  accept="video/*"
                  onChange={handleSubmit}
                  className="imageUpload-input"
                />

                <div className="proofPreviewer border shadow">
                  {!data[4] && (
                    <label htmlFor={`media1`}>
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#F77E0B",
                          fontSize: "20px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        className="d-flex justify-content-center align-items-center rounded-circle"
                      >
                        <span style={{ marginBottom: "1px" }}>+</span>
                      </div>
                    </label>
                  )}
                </div>
                {data[4] && (
                  <>
                    {!data[4]?.key && (
                      <div style={{ display: "none" }}>
                        <VideoThumbnail
                          videoUrl={getVideoUrl(4)}
                          snapshotAtTime={1}
                          renderThumbnail={false}
                          thumbnailHandler={(url) => {
                            handleSubmitForm(url);
                          }}
                        />
                      </div>
                    )}
                    {thumbnail[4] && (
                      <div className="proofPreviewer">
                        <img
                          src={
                            thumbnail[4]?.key
                              ? `${bucket_base_url}${thumbnail[4]?.thumbnail}`
                              : thumbnail[4]
                          }
                          style={{
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    )}
                    <span
                      className="close-icon"
                      onClick={() => handleClearClick(4)}
                    >
                      <CloseOutlined />
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="col-4 sub-video-uploader-two ">
              <div className="imageUploader">
                <input
                  id="media1"
                  type="file"
                  accept="video/*"
                  onChange={handleSubmit}
                  className="imageUpload-input"
                />

                <div className="proofPreviewer border shadow">
                  {!data[5] && (
                    <label htmlFor={`media1`}>
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#F77E0B",
                          fontSize: "20px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        className="d-flex justify-content-center align-items-center rounded-circle"
                      >
                        <span style={{ marginBottom: "1px" }}>+</span>
                      </div>
                    </label>
                  )}
                </div>
                {data[5] && (
                  <>
                    {!data[5]?.key && (
                      <div style={{ display: "none" }}>
                        <VideoThumbnail
                          videoUrl={getVideoUrl(5)}
                          snapshotAtTime={1}
                          renderThumbnail={false}
                          thumbnailHandler={(url) => handleSubmitForm(url)}
                        />
                      </div>
                    )}
                    {thumbnail[5] && (
                      <div className="proofPreviewer">
                        <img
                          src={
                            thumbnail[5]?.key
                              ? `${bucket_base_url}${thumbnail[5]?.thumbnail}`
                              : thumbnail[5]
                          }
                          style={{
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    )}
                    <span
                      className="close-icon"
                      onClick={() => handleClearClick(5)}
                    >
                      <CloseOutlined />
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12 text-center">
                <Button
                  className=""
                  style={{
                    width: "99%",
                    height: "56px",
                    color: "#fff",
                    backgroundColor: "#F77E0B",
                    border: "2px solid #F77E0B",
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                  onClick={handleUpload}
                >
                  {props.intl.formatMessage({ id: "upload" })}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={percentageModal}
        maskClosable={false}
        closable={false}
        footer={false}
      >
        <div className="d-flex flex-column justify-content-center align-items-center mt-3">
          <Progress
            type="circle"
            percent={uploadPercentage}
            strokeColor="#F77E0B"
          />
          <div className="fw-bold fs-4 mt-5" style={{ fontFamily: "Poppins" }}>
            {props.intl.formatMessage({ id: "uploading" })}
          </div>
        </div>
      </Modal>
      <TextStoriesModal
        file={videoUrl}
        open={open}
        setOpen={setOpen}
        videoFile={data}
        setVideoFile={setData}
      />
    </div>
  );
};
export default injectIntl(UploadProfileVideos);

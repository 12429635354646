import React from "react";
import { FormattedMessage } from "react-intl";
import search from "../../assets/images/earch.svg";
import reviews from "../../assets/images/reviews.svg";
import reputation from "../../assets/images/reputation.svg";
import secure from "../../assets/images/secured.svg";
import contact from "../../assets/images/contact.svg";
import community from "../../assets/images/community.svg";

import "./style.css";
export const WelcomeToGigmi = () => {
  return (
    <div className="container" style={{ fontFamily: "Poppins" }}>
      <div className="d-flex justify-content-center mt-2">
        <h1 className="text-center">
          <span className="welcome-heading1 text-center">
            <FormattedMessage id="WelcomeTo" />
          </span>
          <span className="welcome-heading2 text-center">
            <FormattedMessage id="gigmi" />
          </span>
        </h1>
      </div>
      <div className="welcome-content mt-5">
        <FormattedMessage id="welcome_text" />
      </div>
      <div className="welcome-content1 d-flex justify-content-center  text-center">
        <FormattedMessage id="key_feature" />
      </div>
      <div className="row ">
        <div className="col-12 col-sm-12 col-md-6 col-lg-4 d-flex justify-content-center">
          <div className="welcome-card">
            <div className="d-flex justify-content-center align-items-center flex-column">
              <img src={search} className="mb-3" />
              <span className="welcome-card-heading">
                <FormattedMessage id="search_key" />
              </span>
            </div>
            <div className="card-back">
              <FormattedMessage id="search_text" />
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-4 d-flex justify-content-center">
          {" "}
          <div className="welcome-card">
            <div className="d-flex justify-content-center align-items-center flex-column">
              <img src={reviews} className="mb-3" />
              <span className="welcome-card-heading">
                <FormattedMessage id="reviews_key" />
              </span>
            </div>
            <div className="card-back">
              <FormattedMessage id="reviews_text" />
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-4 d-flex justify-content-center">
          {" "}
          <div className="welcome-card">
            {" "}
            <div className="d-flex justify-content-center align-items-center flex-column">
              <img src={reputation} className="mb-3" />
              <span className="welcome-card-heading">
                <FormattedMessage id="reputation" />
              </span>
            </div>
            <div className="card-back">
              <FormattedMessage id="reputation_text" />
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-4 d-flex justify-content-center">
          {" "}
          <div className="welcome-card">
            {" "}
            <div className="d-flex justify-content-center align-items-center flex-column">
              <img src={secure} className="mb-3" />
              <span className="welcome-card-heading">
                <FormattedMessage id="secure" />
              </span>
            </div>
            <div className="card-back">
              <FormattedMessage id="secure_text" />
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-12 col-md-6 col-lg-4 d-flex justify-content-center">
          {" "}
          <div className="welcome-card">
            {" "}
            <div className="d-flex justify-content-center align-items-center flex-column">
              <img src={contact} className="mb-3" />
              <span className="welcome-card-heading">
                <FormattedMessage id="contact" />
              </span>
            </div>
            <div className="card-back">
              <FormattedMessage id="contact_text" />
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-4 d-flex justify-content-center">
          {" "}
          <div className="welcome-card">
            {" "}
            <div className="d-flex justify-content-center align-items-center flex-column">
              <img src={community} className="mb-3" alt={"community"} />
              <span className="welcome-card-heading">
                <FormattedMessage id="community" />
              </span>
            </div>
            <div className="card-back">
              <FormattedMessage id="community_text" />
            </div>
          </div>
        </div>
      </div>
      <div className="lower_text mt-5">
        <FormattedMessage id="lower_text" />
      </div>
      <div className="upper_text">
        <FormattedMessage id="upper_text" />
      </div>
      <div className="upper_text_color d-flex justify-content-center mb-4">
        <FormattedMessage id="Gigmi_team" />
      </div>
    </div>
  );
};

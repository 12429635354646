import {
  setBankModal,
  setHomeownerModal,
  setPaymentType,
} from "../../actions/bankActions";

export const HandleSpanishClick = () => {
  const lang = localStorage.getItem("lang");
  if (!lang) {
    localStorage.setItem("lang", "true");
    window.location.href = "/";
  }
};

export const HandleEnglishClick = () => {
  const lang = localStorage.getItem("lang");
  if (lang) {
    localStorage.removeItem("lang");
    window.location.href = "/";
  }
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export const handleReload = (navigate) => {
  if (window.location.pathname === "/") {
    window.location.reload();
  } else {
    navigate("/");
  }
};
/**
 *
 * @param {cnFn} dispatch
 * @param {object} userTypeData
 * @returns boolean
 */
export const handleBankInfoVerification = (dispatch, userTypeData) => {
  const userData = JSON.parse(localStorage.getItem("data"));
  if (userData?.user_type !== "homeowner") {
    return true;
  } else if (
    userData?.user_type === "homeowner" &&
    userTypeData?.sendingAccountVerify
  ) {
    dispatch(setHomeownerModal(true));
  } else {
    dispatch(
      setPaymentType(userData?.user_type === "homeowner" ? "send" : "receive")
    );
    dispatch(setBankModal(true));
  }
};

import * as Actions from "../actions/ActionTypes";

const ContractReducer = (
  state = {
    contracts: {},
    contractDetails: {},
    contractListingType: "todo",
    userList: [],
    selectedUser: [],
  },
  action
) => {
  switch (action.type) {
    case Actions.SET_CONTRACT_LISTING:
      return Object.assign({}, state, {
        contracts: action?.data,
      });
    case Actions.SET_CONTRACT_DETAILS:
      return Object.assign({}, state, {
        contractDetails: action?.data,
      });
    case Actions.SET_CONTRACT_LISTING_TYPE:
      return Object.assign({}, state, {
        contractListingType: action?.data,
      });
    case Actions.AWARD_TO_MYSELF_REQUEST_ERRORS:
      return Object.assign({}, state, {
        awardToMyselfErrorUser: action?.data,
      });
    case Actions.SET_USER_LIST_DATA:
      return Object.assign({}, state, {
        userList: action?.data,
      });
    case Actions.SET_SELECTED_USER:
      return Object.assign({}, state, {
        selectedUser: action?.data,
      });

    default:
      return state;
  }
};

export default ContractReducer;

import React from "react";

const RedDot = ({ style }) => {
  return (
    <div
      style={{
        height: 12,
        width: 12,
        borderRadius: 6,
        backgroundColor: "red",
        marginBottom: "18px",
        boxShadow: "1px 2px 9px #F4AAB9",
        ...style,
      }}
    />
  );
};

export default RedDot;

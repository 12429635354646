import Header from "../Header/Header";
import { injectIntl } from "react-intl";
import StepsComponent from "./steps";
const ProgressStep = ({ intl, steps, children, current }) => {
  return (
    <>
      <Header />

      <div className="container ">
        <div className="mt-5 mb-3">
          {current === 0 ? (
            <StepsComponent
              title={intl.formatMessage({ id: steps[current].header })}
              desc={intl.formatMessage({ id: steps[current].description })}
            />
          ) : current === 1 ? (
            <StepsComponent
              title={intl.formatMessage({ id: steps[current].header })}
              desc={intl.formatMessage({ id: steps[current].description })}
              textProp={"text-center"}
            />
          ) : current === 2 ? (
            <StepsComponent
              title={intl.formatMessage({ id: steps[current].header })}
              desc={intl.formatMessage({ id: steps[current].description })}
              textProp={"text-center"}
            />
          ) : (
            <StepsComponent
              title={intl.formatMessage({ id: steps[current].header })}
              desc={intl.formatMessage({ id: steps[current].description })}
              textProp={"text-center"}
            />
          )}
        </div>
        {children}
      </div>
    </>
  );
};
export default injectIntl(ProgressStep);

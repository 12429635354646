import { message } from "antd";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import firebaseConfig from "../Firebase/Firebase";

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const getFCMToken = async () => {
  const permission = await Notification.requestPermission();

  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey:
        "BKf54fPlTtXYYcK0couG03moooQYQPe_z9a0ObiGLMIQDbqz4JPMLYks7ZoSDAXT0ZkKf6JnjwhzY1sEwkd9EPU",
    });
    return token;
  } else if (permission === "denied") {
    message.error(`Notification Permission: ${permission}`);
  } else {
    message.error(`Notification Permission: ${permission}`);
  }
};

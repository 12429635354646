import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import { GetUsersLocations } from "../../api/MapApis/Map";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import "./style.css";
import { useNavigate } from "react-router";
import profileIcon from "../../assets/images/profileIcon.png";
import { bucket_base_url } from "../../constants";
import locationIcon from "../../assets/images/locationIcon.svg";
import { FormattedMessage } from "react-intl";
import backIcon from "../../assets/images/backButton.svg";

const Marker = ({ type, user }) => {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("data"));
  return (
    <>
      {user?.id === userData?.user_id &&
        userData?.user_type === "contractor" && (
          <div className="map-marker">
            <div className="contractor-image">
              <img
                src={
                  user?.contractor?.profile_picture === null ||
                  user?.contractor?.profile_picture === ""
                    ? profileIcon
                    : `${bucket_base_url}${user?.contractor?.profile_picture}`
                }
                style={{
                  cursor: "pointer",
                  objectFit: "cover",
                  borderRadius: "50%",
                  width: "95%",
                  height: "95%",
                }}
                onClick={() => {
                  navigate(`/user-profile`);
                }}
              />
            </div>
          </div>
        )}
      {user?.id === userData?.user_id &&
        userData?.user_type === "tradesman" && (
          <div className="map-marker">
            <div className="gigster-image">
              <img
                src={
                  user?.tradesman?.profile_picture === null ||
                  user?.tradesman?.profile_picture === ""
                    ? profileIcon
                    : `${bucket_base_url}${user?.tradesman?.profile_picture}`
                }
                style={{
                  cursor: "pointer",
                  objectFit: "cover",
                  borderRadius: "50%",
                  width: "95%",
                  height: "95%",
                }}
                onClick={() => {
                  navigate(
                    `/trads-profile?user-id=${user?.id}&id=${user?.tradesman?.id}&type=${type}`
                  );
                }}
              />
            </div>
          </div>
        )}

      {userData?.user_id !== user?.id &&
      type === "contractor" &&
      user?.contractor ? (
        <div className="map-marker">
          <div className="contractor-image">
            <img
              src={
                user?.contractor?.profile_picture === null ||
                user?.contractor?.profile_picture === ""
                  ? profileIcon
                  : `${bucket_base_url}${user?.contractor?.profile_picture}`
              }
              style={{
                cursor: "pointer",
                objectFit: "cover",
                borderRadius: "50%",
                width: "95%",
                height: "95%",
              }}
              onClick={() => {
                navigate(
                  `/trads-profile?user-id=${user?.id}&id=${user?.contractor?.id}&type=${type}`
                );
              }}
            />
          </div>
          <div className="contractor-tag">Contractor</div>
          <div className="contractor-triangle"></div>
        </div>
      ) : userData?.user_id !== user?.id &&
        type === "tradesman" &&
        user?.tradesman ? (
        <div className="map-marker">
          <div className="gigster-image">
            <img
              src={
                user?.tradesman?.profile_picture === null ||
                user?.tradesman?.profile_picture === ""
                  ? profileIcon
                  : `${bucket_base_url}${user?.tradesman?.profile_picture}`
              }
              style={{
                cursor: "pointer",
                objectFit: "cover",
                borderRadius: "50%",
                width: "95%",
                height: "95%",
              }}
              onClick={() => {
                navigate(
                  `/trads-profile?user-id=${user?.id}&id=${user?.tradesman?.id}&type=${type}`
                );
              }}
            />
          </div>
          <div className="gigster-tag">Gigster</div>
          <div className="gigster-triangle"></div>
        </div>
      ) : null}
    </>
  );
};
export const Map = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [coordinates, setCoordinates] = useState();
  const [zoom, setZoom] = useState(11);
  const mapRef = useRef();
  useEffect(() => {
    GetUsersLocations(dispatch);
    handleGetLocation();
  }, []);
  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        setCoordinates({
          lat,
          lng,
        });
        setZoom(12);
        if (mapRef.current) {
          mapRef.current.panTo({ lat: lat, lng: lng });
          mapRef.current.setZoom(18);
        }
      });
    } else {
      message.error("Unable to get Location.");
    }
  };
  const { usersLocations } = useSelector((state) => state?.mapData);
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      {coordinates && (
        <GoogleMapReact
          defaultCenter={coordinates}
          defaultZoom={zoom}
          center={coordinates}
          onChange={({ coordinates }) => {
            if (coordinates) {
              setCoordinates({ lat: coordinates.lat, lng: coordinates.lng });
            }
            if (zoom) {
              setZoom(zoom);
            }
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map }) => (mapRef.current = map)}
        >
          {!isEmpty(usersLocations) &&
            usersLocations?.map((user, index) => {
              return (
                <Marker
                  lat={user?.latitude}
                  lng={user?.longitude}
                  type={user?.current_type}
                  id={user?.id}
                  user={user}
                />
              );
            })}
        </GoogleMapReact>
      )}
      <div className="current-location" onClick={handleGetLocation}>
        <img src={locationIcon} />
      </div>
      <div className="button-map">
        <img
          src={backIcon}
          alt=""
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer" }}
        />
        <span className=" fs-4 m-1">
          <FormattedMessage id="back" />
        </span>
      </div>
    </div>
  );
};

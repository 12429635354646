import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import "./BankInfo.css";
import Header from "../../components/Header/Header";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Divider, Modal } from "antd";
import AddBank from "../../assets/images/AddBank.svg";
import Wallet from "../../assets/images/editBank.svg";
import { BackButton } from "../../components/General/BackButton";
import receiverIcon from "../../assets/images/payment1.svg";
import senderIcon from "../../assets/images/Payment.svg";
import CompleteProfileModal from "../../components/UpdateProfile/CompleteProfileModal";
import checkMark from "../../assets/images/checkmark.png";
const PaymentOptions = (props) => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state?.common?.newUser?.data);
  const isAuthenticated = JSON.parse(localStorage.getItem("data"));
  const [showModal, setShowModal] = useState(true);
  const [bankModal, setBankModal] = useState(false);
  const [contractorModal, setContractorModal] = useState(false);
  const [paymentType, setPaymenType] = useState("");
  const fullUser = useSelector((state) => state?.common?.fullUser);
  const navigateTo = () => {
    if (paymentType === "send") {
      navigate("/add-hom-account");
    } else if (paymentType === "receive") {
      navigate("/get-payments");
    }
    setBankModal(false);
  };
  const handleReceivingAccount = () => {
    if (fullUser?.receivingAccountVerify) {
      setContractorModal(true);
    } else {
      setBankModal(true);
    }
    setPaymenType("receive");
  };
  return (
    <>
      <BackButton />
      <div className="container paymentscreen">
        <Header />
        <div className="paymentscreen-inner">
          <div className="d-flex justify-content-center align-items-center getting-payment-heading shadow border">
            <span>
              <FormattedMessage id="payments" />
            </span>
          </div>
          <div
            onClick={handleReceivingAccount}
            className="payment-option d-flex justify-content-between"
          >
            <div>
              <img src={receiverIcon} style={{ marginRight: "20px" }} />
              <FormattedMessage id="receiving_accounts" />
            </div>
            <div>
              {fullUser?.receivingAccountVerify && (
                <img src={checkMark} className="checkmark" />
              )}
            </div>
          </div>
          <Divider />
          <div
            onClick={() => {
              // if (fullUser?.sendingAccountVerify) {
              setContractorModal(true);
              // } else {
              // setBankModal(true);
              // }

              setPaymenType("send");
            }}
            className="payment-option d-flex justify-content-between"
          >
            <div>
              <img src={senderIcon} style={{ marginRight: "12px" }} />
              <FormattedMessage id="send_payment_accounts" />
            </div>

            <div>
              {fullUser?.sendingAccountVerify && (
                <img src={checkMark} className="checkmark" />
              )}
            </div>
          </div>
          <Divider />
        </div>
        <Modal
          open={bankModal}
          footer={null}
          onCancel={() => setBankModal(false)}
        >
          <div className="d-flex justify-content-around align-items-center">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <span className="fs-3 fw-bold mb-3">
                {props.intl.formatMessage({ id: "Select" })}
              </span>
              <img
                src={AddBank}
                className="bank-modal-image"
                onClick={() => navigateTo()}
              />
              <span className="bank-modal-text">
                {props.intl.formatMessage({ id: "add_bank_info" })}
              </span>
            </div>
          </div>
        </Modal>
        <Modal
          open={contractorModal}
          onCancel={() => setContractorModal(false)}
          footer={null}
        >
          <div className="d-flex justify-content-around align-items-center">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <span className="fs-3 fw-bold mb-3">
                {props.intl.formatMessage({ id: "select" })}
              </span>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column w-100 justify-content-center align-items-center">
                  <img
                    src={Wallet}
                    className="bank-modal-image"
                    onClick={() => {
                      setContractorModal(false);
                      if (paymentType === "receive") {
                        navigate("/get-payments?path=Edit");
                      } else {
                        navigate("/bank-accounts");
                      }
                    }}
                  />
                  <span className="bank-modal-text  text-center">
                    {fullUser?.sendingAccountVerify
                      ? props.intl.formatMessage({ id: "edit_bank_account" })
                      : props.intl.formatMessage({ id: "add_bank_account" })}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      {isAuthenticated && currentUser?.health < 100 && (
        <CompleteProfileModal
          showModal={showModal}
          setShowModal={setShowModal}
          currentUser={currentUser}
          closeable={false}
        />
      )}
    </>
  );
};
export default injectIntl(PaymentOptions);

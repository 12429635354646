import { setConnectionList } from "../../actions/ChatActions";
import { SimpleCatchError } from "../../utils/Helper";
import { Axios } from "../../utils/axiosMiddleware";

export const getConnections = async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("data"));
    const response = await Axios.get(
      `interactions/connections/${token?.id}/${token?.user_type}`
    );
    const GetConnectionUsersIds = {
      contractor_ids: [],
      tradesman_ids: [],
    };

    response?.data?.map((item) => {
      if (token?.user_type === "contractor") {
        if (item?.senderContractorId === token?.id) {
          item?.receiverContractorId
            ? GetConnectionUsersIds.contractor_ids?.push(
                item?.receiverContractorId
              )
            : GetConnectionUsersIds.tradesman_ids?.push(
                item?.receiverTradesmanId
              );
        } else if (item?.receiverContractorId === token?.id) {
          item?.senderContractorId
            ? GetConnectionUsersIds.contractor_ids.push(
                item?.senderContractorId
              )
            : GetConnectionUsersIds?.tradesman_ids?.push(
                item?.senderTradesmanId
              );
        }
      }
    });
    fetchUsersDataConnects(GetConnectionUsersIds, dispatch);
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const fetchUsersDataConnects = async (
  GetConnectionUsersIds,
  dispatch
) => {
  try {
    const token = JSON.parse(localStorage.getItem("data"));
    const response = await Axios.post(
      "user/getConnectionFiles",
      GetConnectionUsersIds
    );
    const ConnectionUsers = response?.data;
    let SortedUsers = [];
    if (ConnectionUsers) {
      ConnectionUsers.forEach((element) => {
        if (
          GetConnectionUsersIds?.contractor_ids?.includes(
            element?.contractor?.id
          )
        ) {
          SortedUsers.push({
            viewed_user_type_id: element?.contractor?.id,
            viewed_user_type: "contractor",
            current_user_type: token?.user_type,
            current_user_type_id: token?.id,
            name: element?.first_name + " " + element?.last_name,
            profile_picture: element?.tradesman?.profile_picture,
          });
        }
        if (
          GetConnectionUsersIds?.tradesman_ids?.includes(element?.tradesman?.id)
        ) {
          SortedUsers.push({
            viewed_user_type_id: element?.tradesman?.id,
            viewed_user_type: "tradesman",
            name: element?.first_name + " " + element?.last_name,
            current_user_type: token?.user_type,
            current_user_type_id: token?.id,
            profile_picture: element?.tradesman?.profile_picture,
          });
        }
      });
    }
    SortedUsers = SortedUsers.filter(
      (item) => item.viewed_user_type !== item.current_user_type
    );
    dispatch(setConnectionList(SortedUsers));
  } catch (e) {
    SimpleCatchError(e);
  }
};

import { bucket_base_url } from "../../constants";
import { SimpleCatchError } from "../../utils/Helper";
import { Axios } from "../../utils/axiosMiddleware";

export const GenerateInvoiceRequest = async (payload, path) => {
  try {
    const response = await Axios.get(
      `contracts/download/invoice/pdf/${payload}`
    );
    const url = `${bucket_base_url}${response?.data}`;
    if (path) {
      return url;
    } else {
      window.open(url);
    }
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const deleteContractSaga = async (payload) => {
  try {
    await Axios.delete(`contracts/${payload}`);
    return true;
  } catch (e) {
    SimpleCatchError(e);
  }
};

import { injectIntl } from "react-intl";
const StepsComponent = ({ intl, title, desc, textProp = "" }) => {
  return (
    <>
      <div
        className={`d-flex flex-column w-100 align-items-center justify-content-between ${textProp}`}
      >
        <span
          style={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: "400",
          }}
        >
          {intl.formatMessage({ id: title })}
        </span>
        <span
          style={{
            fontFamily: "Poppins",
            fontSize: "13px",
            fontWeight: "400",
          }}
        >
          {intl.formatMessage({ id: desc })}
        </span>
      </div>
    </>
  );
};
export default injectIntl(StepsComponent);

import React, { useEffect } from "react";
import { Carousel } from "antd";
import { getBannerImageData } from "../../views/Home/Service";
import { useDispatch, useSelector } from "react-redux";
import { bucket_base_url } from "../../constants";
import { isEmpty } from "lodash";
const BannerCards = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getBannerImageData(dispatch);
  }, []);
  const bannerArray = useSelector(
    (state) => state?.common?.bannerImageList?.data
  );
  return (
    <div className="container mt-2">
      <Carousel autoplay={true} delay={1000} className="banner-height">
        {!isEmpty(bannerArray) &&
          bannerArray?.length > 0 &&
          bannerArray?.map((data) => {
            return (
              <a href={data.link} target="_blank" rel="noreferrer">
                <div className="d-flex justify-content-center align-items-center banner-height">
                  <img
                    src={`${bucket_base_url}${data?.file?.key}`}
                    alt=""
                    className='img-fluid"'
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "20px",
                    }}
                  />
                </div>
              </a>
            );
          })}
      </Carousel>
    </div>
  );
};
export default BannerCards;

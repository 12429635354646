import { SimpleCatchError } from "../../utils/Helper";
import { Axios } from "../../utils/axiosMiddleware";

export const reInitializePayment = async (payload, navigate) => {
  try {
    await Axios.post(`payment/re-initialize`, payload);
    navigate("/contract-lists");
  } catch (e) {
    SimpleCatchError(e);
  }
};

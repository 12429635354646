import { setChatDetailMobile } from "../../actions/ChatActions";
import { leaveConversation } from "../../sockets/ChatSockets";
import { loadConversation } from "../../api/chatApi/loadConversation";

export const navigateOnNotification = (
  notificationType,
  navigate,
  data,
  chatConversation,
  dispatch
) => {
  if (notificationType === "contract") {
    navigate(`/contract-details?id=${data?.RequiredData?.contractId}`);
  } else if (notificationType === "chat") {
    getConversation(
      data?.sender,
      chatConversation,
      dispatch,
      navigate,
      data?.notificationForm
    );
  } else if (notificationType === "profile") {
    const { id, userId } = data[data?.notificationFrom + "Sender"];
    navigate(
      `/trads-profile?user-id=${userId}&id=${id}&type=${data?.notificationFrom}`
    );
  } else if (notificationType === "reffered") {
    navigate("/share-app");
  } else if (notificationType === "wallet") {
    navigate("/wallet");
  } else {
    return null;
  }
};
const getConversation = async (
  user,
  chatConversation,
  dispatch,
  navigate,
  notificationFrom
) => {
  let item = {
    oponent: {
      first_name: user?.first_name,
      id: user?.id,
      last_name: user?.last_name,
      profile_picture: user?.profile_picture,
      userId: user?.userId,
    },
    oponentType:
      notificationFrom === "tradesman"
        ? "Gigster"
        : notificationFrom === "contractor"
        ? "Contractor"
        : "Homeowner",
  };
  leaveConversation(chatConversation?.id);
  dispatch(setChatDetailMobile(true));
  const userData = JSON.parse(localStorage.getItem("data"));
  const payload = {
    senderID: userData?.id,
    receiverID: user?.id,
    senderType: userData?.user_type,
    receiverType: notificationFrom,
    take: 10,
    skip: 0,
  };
  await loadConversation(payload, item, dispatch);
  navigate("/chat");
};

import { FormattedMessage } from "react-intl";
import { formatContractLogTime, formatDate, logTime } from "./helper";
import { Divider } from "antd";
import React from "react";

export const ViewTotalTime = ({ firstDate, secondData }) => {
  let totalTime = " - - : - -";

  if (firstDate && secondData) {
    const first = new Date(firstDate);
    const second = new Date(secondData);

    const differenceInMilliseconds = second - first;
    const differenceInSeconds = differenceInMilliseconds / 1000;
    const differenceInMinutes = Math.floor(differenceInSeconds / 60);
    const hours = Math.floor(differenceInMinutes / 60);
    const minutes = differenceInMinutes % 60;

    totalTime = `${hours} hr ${minutes} m`;
  }

  return <span>{totalTime}</span>;
};
const LogItem = ({ checkInTime, checkOutTime }) => {
  return (
    <div className="date-div d-flex flex-column mt-4">
      <div className="d-flex justify-content-between">
        <span>{<FormattedMessage id="CHECK_IN_SMALL" />}</span>
        <span>{logTime(checkInTime)}</span>
      </div>
      <div className="d-flex justify-content-between">
        <span>{<FormattedMessage id="CHECK_OUT_SMALL" />}</span>
        <span>{checkOutTime ? logTime(checkOutTime) : "- - : - -"}</span>
      </div>
      <Divider />
      <div className="d-flex justify-content-between">
        <span>
          <FormattedMessage id="TOTAL_TIME" />
        </span>
        <ViewTotalTime firstDate={checkInTime} secondData={checkOutTime} />
      </div>
    </div>
  );
};

export const ContractLogs = ({ contractLogs }) => {
  let totalTime = 0;

  const logs =
    contractLogs?.length > 0
      ? contractLogs?.reduce((open, val) => {
          const firstDate = val?.check_in_time?.split("T")[0];
          const secondDate = val?.check_out_time?.split("T")[0];
          !open[firstDate] && !open[secondDate]
            ? ((open[firstDate] = []), open[firstDate]?.push(val))
            : open[firstDate]?.push(val);
          return open;
        }, {})
      : [];

  const sortedLogs = Object.keys(logs).map((date) => {
    return {
      date,
      logs: logs[date],
    };
  });

  for (let i = 0; i < contractLogs?.length; i++) {
    if (
      contractLogs[i]?.check_in_time !== null &&
      contractLogs[i]?.check_out_time !== null
    ) {
      const firstDate = formatContractLogTime(contractLogs[i]?.check_in_time);
      const secondData = formatContractLogTime(contractLogs[i]?.check_out_time);
      const differenceInMilliseconds =
        new Date(secondData) - new Date(firstDate);
      const difference = differenceInMilliseconds / (1000 * 60);
      totalTime = totalTime + difference;
    }
  }
  totalTime = Math.floor(totalTime / 60) + ":" + (totalTime % 60);
  return sortedLogs?.length > 0 ? (
    <div className="mb-5">
      <div className="date-div mt-5 d-flex flex-column align-items-center justify-content-center p-5">
        <span className="fw-bold">
          <FormattedMessage id="LOG_DETAILS_TEXT" />
        </span>
        <div className="d-flex mt-3 justify-content-around w-100">
          <span>
            <FormattedMessage id="TOTAL_WORKING_HOURS_SPENT" />
          </span>
          <span>
            {totalTime.split(":")[0] + " hr "}
            {totalTime.split(":")[1] + " m"}
          </span>
        </div>
      </div>
      {sortedLogs?.map((item, index) => (
        <div key={index}>
          <span className="d-flex justify-content-center mt-5 fw-bold">
            {formatDate(item?.date)}
          </span>
          {item?.logs?.map((atem, innerIndex) => (
            <LogItem
              key={innerIndex}
              checkInTime={atem?.check_in_time}
              checkOutTime={atem?.check_out_time}
            />
          ))}
        </div>
      ))}
    </div>
  ) : (
    <span className="date-div mt-5 mb-5 d-flex flex-column align-items-center justify-content-center p-5">
      <FormattedMessage id="NO_LOGS_FOUND" />
    </span>
  );
};

import { setSelectedProfile } from "../actions/CommonActions";
import { SimpleCatchError } from "../utils/Helper";
import { Axios } from "../utils/axiosMiddleware";

export const getProfile = async (dispatch, user_id, id, user_type) => {
  try {
    const response = await Axios.get(
      `user/GetFullUser/${user_id}/${id}/${user_type}`
    );
    dispatch(setSelectedProfile(response?.data[0]));
  } catch (e) {
    SimpleCatchError(e);
  }
};

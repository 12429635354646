import { Input, Modal, message } from "antd";
import React, { useState } from "react";
import reportIcon from "../../assets/images/Report.svg";
import { FormattedMessage } from "react-intl";
import { reportProfile } from "../../api/ReportProfileApi/reportProfile";
import { useNavigate } from "react-router";
import Loader from "../GeneralComponents";

export const ReportProfile = ({
  setReportModal,
  reportModal,
  userType,
  profile,
}) => {
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async () => {
    if (reason?.length > 0 && reason?.length < 250) {
      setReportModal(false);

      const finalData = {
        reported_id:
          userType === "tradesman"
            ? profile?.tradesman?.id
            : profile?.contractor?.id,
        reported_type: userType,
        reason: reason,
      };
      setReportModal(false);
      setLoading(true);
      await reportProfile(finalData, navigate);
      setLoading(false);
    } else {
      message.error("reason should be less then 250 words");
    }
  };
  return (
    <>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}

      <Modal
        open={reportModal}
        onCancel={() => setReportModal(false)}
        footer={null}
      >
        <div className="mt-3">
          <div
            className="w-100 mb-4 d-flex justify-content-center fs-4"
            style={{ fontFamily: "Poppins" }}
          >
            <img
              src={reportIcon}
              style={{ width: "35px", marginRight: "5px" }}
            />
            <FormattedMessage id="report_profile" />!
          </div>
          <div className="reason-text mb-2">
            <span>
              <FormattedMessage id="reason_text" />
            </span>
          </div>

          <Input.TextArea
            rows={5}
            placeholder="Enter Report Reason"
            onChange={(e) => setReason(e?.target?.value)}
          />
          <div>
            <span className="contract-button" onClick={handleSubmit}>
              <FormattedMessage id="submit" />
            </span>
          </div>
        </div>
        '
      </Modal>
    </>
  );
};

import { Axios } from "../../utils/axiosMiddleware";
import { SimpleCatchError } from "../../utils/Helper";

export const deleteUser = async () => {
  const token = JSON.parse(localStorage.getItem("data"));
  try {
    const body = {
      user_id: token.user_id,
      user_type: token?.user_type,
      type_id: token?.id,
    };
    await Axios.post("contracts/account/delete", body);
    localStorage.clear();
    window.location.href = "/";
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const disableUser = async () => {
  const token = JSON.parse(localStorage.getItem("data"));
  try {
    const body = {
      user_id: token.user_id,
      user_type: token?.user_type,
      type_id: token?.id,
    };
    await Axios.post("contracts/account/disable", body);
    localStorage.clear();
    window.location.href = "/";
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const changeUsername = async (body, navigate) => {
  try {
    await Axios.post("user/send-otp/change-username", body);
    navigate("/otp-verification", { state: { username: body?.username } });
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const otpVerify = async (
  body,
  fullUser,
  navigate,
  setShowModal,
  usernameType
) => {
  try {
    await Axios.patch(`user/change-username/${fullUser?.id}`, body);
    if (
      usernameType === "email" &&
      (fullUser?.sendingAccountVerify || fullUser?.receivingAccountVerify)
    ) {
      setShowModal(true);
    } else {
      navigate("/");
    }
  } catch (e) {
    SimpleCatchError(e);
  }
};

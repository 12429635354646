import {
  setContractsList,
  setUserListData,
} from "../../actions/ContractActions";
import { SimpleCatchError } from "../../utils/Helper";
import { Axios } from "../../utils/axiosMiddleware";

export const getContractsRequest = async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("data"));
    const response = await Axios.get(
      `contracts/${token?.id}/${token?.user_type}`
    );
    const workRequestContracts = [];
    const toDoContracts = [];
    const inProgressContracts = [];
    const archivedContracts = [];
    response?.data?.forEach((item) => {
      ["open", "send", "pending_approval", "assigned"].includes(item?.status)
        ? toDoContracts.push(item)
        : ["work_request_sent", "estimation_given", "contract_given"].includes(
            item?.status
          )
        ? workRequestContracts.push(item)
        : [
            "pending_review",
            "submit_to_homeowner",
            "running",
            "receive",
            "direct_pay_to_contractor",
            "direct_pay_to_tradesman",
            "direct_pay_to_homeowner",
          ].includes(item?.status)
        ? inProgressContracts.push(item)
        : ["archived", "cancel", "disputed", "reject", "not_assigned"].includes(
            item?.status
          )
        ? archivedContracts.push(item)
        : null;
    });
    dispatch(
      setContractsList({
        contracts: {
          workRequestContracts: workRequestContracts,
          toDoContracts: toDoContracts,
          inProgressContracts: inProgressContracts,
          archivedContracts: archivedContracts,
        },
        id: token?.id,
        type: token?.user_type,
      })
    );
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const GetUserListRequest = async (payload, dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("data"));
    const { userSearchType, searchText } = payload;
    const response = await Axios.get(
      `/user/list/${token?.user_id}/${userSearchType}?search=${searchText}`
    );

    const userList = response?.data?.map((user) => {
      return {
        viewed_user_type_id: user?.[userSearchType]?.id,
        viewed_user_type: userSearchType,
        name:
          user?.[userSearchType]?.first_name +
          " " +
          user?.[userSearchType]?.last_name,
        current_user_type: token?.user_type,
        current_user_type_id: token?.id,
        profile_picture: user?.[userSearchType]?.profile_picture,
        username: user?.username,
      };
    });

    dispatch(setUserListData(userList));
  } catch (e) {
    SimpleCatchError(e);
  }
};

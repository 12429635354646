import React from "react";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();
  return `${month} ${day}, ${year}`;
};

const GetDate = ({ chatData, index, firstMessage }) => {
  const currentDate = formatDate(chatData[index]?.created_at);
  const previousDate =
    index < chatData.length - 1
      ? formatDate(chatData[index + 1]?.created_at)
      : null;
  if (firstMessage) {
    return (
      <div className="w-100 d-flex justify-content-center m-3">
        <span>{currentDate}</span>
      </div>
    );
  } else if (currentDate !== previousDate && previousDate !== null) {
    return (
      <div className="w-100 d-flex justify-content-center m-3">
        <span>{previousDate}</span>
      </div>
    );
  }
  return null;
};

export default GetDate;

import { bucket_base_url } from "../../constants";
import { SimpleCatchError } from "../../utils/Helper";
import { Axios } from "../../utils/axiosMiddleware";

export const GenerateReceiptRequest = async (payload, path) => {
  try {
    const response = await Axios.get(
      `contracts/download/contract/pdf/${payload}`
    );
    const url = `${bucket_base_url}${response?.data}`;
    if (path) {
      return url;
    } else {
      window.open(url);
    }
  } catch (e) {
    SimpleCatchError(e);
  }
};

import { Button, Form, Modal, Input, message } from "antd";
import { useState } from "react";
import { injectIntl } from "react-intl";
function AddSkillModal({
  modalShow,
  setModalShow,
  skillArray,
  selectedSkills,
  setSkillArray,
  ...props
}) {
  const [newSkill, setNewSkill] = useState("");

  const handleSkillAdd = () => {
    const flag = skillArray.some((obj) => obj.label == newSkill);
    if (!flag) {
      if (newSkill !== "") {
        setSkillArray([...skillArray, { label: newSkill, isChecked: true }]);
        selectedSkills.push(newSkill);
        setModalShow(false);
      } else {
        message.error(`${props.intl.formatMessage({ id: "enter_new_skill" })}`);
      }
    } else {
      message.error(`${props.intl.formatMessage({ id: "already_skills" })}`);
    }
  };
  return (
    <Modal
      open={modalShow}
      onCancel={() => setModalShow(false)}
      maskClosable={true}
      footer={[
        <Button
          style={{
            width: "100%",
            backgroundColor: "#F77E0B",
            height: "56px",
            color: "white",
            marginTop: "0px",
            fontWeight: "bold",
            fontSize: "20px",
            fontFamily: "Poppins",
          }}
          onClick={handleSkillAdd}
        >
          {props.intl.formatMessage({ id: "add" })}
        </Button>,
      ]}
      className="p-5"
    >
      <span className="email-text fw-bolder">
        {props.intl.formatMessage({ id: "addNewSkill" })}
      </span>
      <Form.Item
        name="firstname"
        rules={[
          {
            required: true,
            message: `${props.intl.formatMessage({ id: "enter_new_skill" })}`,
          },
          {
            pattern: new RegExp(/^[A-Za-z\s]+$/),
            message: `${props.intl.formatMessage({ id: "not_accept_number" })}`,
          },
        ]}
      >
        <Input
          placeholder={props.intl.formatMessage({ id: "enter_new_skill" })}
          maxLength={25}
          style={{
            height: "56px",
            backgroundColor: "#E6E7EB",
            marginTop: "10px",
          }}
          onChange={(e) => setNewSkill(e.target.value)}
        />
      </Form.Item>
    </Modal>
  );
}
export default injectIntl(AddSkillModal);

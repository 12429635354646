import React from "react";
import home from "../../assets/images/homewhite.svg";
import gigmi from "../../assets/images/Gigmiwhite.svg";
import chat from "../../assets/images/chatwhite.svg";
import location from "../../assets/images/locationwhite.svg";
import contract from "../../assets/images/contractwhite.svg";
import "./style.css";
import { Tooltip } from "antd";
import { useNavigate } from "react-router";
import RedDot from "../General/RedDot";
import { useDispatch, useSelector } from "react-redux";
import { setUnsubscribedUser } from "../../actions/CommonActions";
import { ConsoleSqlOutlined } from "@ant-design/icons";
export const SideTabNavigator = ({ fullUser }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = JSON.parse(localStorage.getItem("data"));
  const redDot = useSelector(
    (state) => state?.RedDotReducer?.unSeenNotification
  );
  const isAnyStatusUnseen =
    redDot?.contract_status &&
    (redDot?.contract_status?.isEstimateSeen ||
      redDot?.contract_status?.isToDoSeen ||
      redDot?.contract_status?.isRunningSeen ||
      redDot?.contract_status?.isArchivedSeen);

  const handleNavClick = (value) => {
    if (token?.accessToken && fullUser?.subscribed) {
      navigate(`/${value}`);
    } else if (value === "") {
      navigate("/");
    } else if (token?.accessToken && fullUser?.subscribed === false) {
      dispatch(setUnsubscribedUser(true));
    } else {
      navigate(`/welcome`);
    }
  };
  return (
    <div className="navigator">
      <div className="nav-icon">
        <Tooltip title="home">
          <div onClick={() => handleNavClick("")}>
            <img src={home} />
          </div>
        </Tooltip>
      </div>
      {token?.user_type !== "homeowner" && (
        <div className="nav-icon">
          <Tooltip title="Feed" onClick={() => handleNavClick("feeds")}>
            <div className="red-dot-style">
              <img src={gigmi} />
              {!redDot?.isOpenContractSeen && <RedDot />}
            </div>
          </Tooltip>
        </div>
      )}
      <div className="nav-icon">
        <Tooltip title="Map" onClick={() => handleNavClick("gigmi-maps")}>
          <div>
            <img src={location} />
          </div>
        </Tooltip>
      </div>
      <div className="nav-icon">
        <Tooltip title="Contract">
          <div
            className="red-dot-style"
            onClick={() => handleNavClick("contract-lists")}
          >
            <img src={contract} />
            {isAnyStatusUnseen && <RedDot />}
          </div>
        </Tooltip>
      </div>
      <div className="nav-icon">
        <Tooltip title="Chat">
          <div className="red-dot-style" onClick={() => handleNavClick("chat")}>
            <img src={chat} />
            {redDot?.isMessageSeen && <RedDot />}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

import React from "react";
import backIcon from "../../assets/images/backButton.svg";
import { useNavigate } from "react-router";
import { FormattedMessage } from "react-intl";
import "./general.css";
export const BackButton = ({
  position = "absolute",
  top = "100px",
  left = "40px",
  navigateTo = -1,
}) => {
  const navigate = useNavigate();
  return (
    <div>
      <div
        style={{
          position: position,
          top: top,
          left: left,
          fontFamily: "Poppins",
        }}
        className="d-flex justify-content-center align-items-center"
      >
        <img
          src={backIcon}
          alt=""
          onClick={() => navigate(navigateTo)}
          style={{ cursor: "pointer" }}
          className="shadow-lg border rounded"
        />
        <span className="text-black fs-4 m-1">
          <FormattedMessage id="back" />
        </span>
      </div>
    </div>
  );
};

import { setChatConversation } from "../actions/ChatActions";
import { getOpponentType } from "../components/service";
import { socket } from "./SocketConnection";

export const joinConversation = (chatConversation) => {
  socket?.emit("joinConversation", {
    room_id: chatConversation?.id,
  });
};
export const leaveConversation = (RoomID) => {
  if (socket) {
    socket.off("joinConversation");
    socket.off("receiveMessage");
    socket.off("sendMessageReturn");
    socket.emit("leaveConversation", { room: RoomID });
    socket.off("leaveConversation");
  }
};

export const sendChatMediaMsgReturn = (conversation, dispatch) => {
  socket.on("receiveMessage", (data) => {
    if (
      [data?.message?.conversationId, data?.conversationId].includes(
        conversation?.id
      )
    ) {
      const contractorSenderId =
        data?.contractorSenderId || data?.message?.contractorSenderId || null;
      const homeownerSenderId =
        data?.homeownerSenderId || data?.message?.homeownerSenderId || null;
      const tradesmanSenderId =
        data?.tradesmanSenderId || data?.message?.tradesmanSenderId || null;
      const messageReceieved = {
        id:
          data?.type === "text" ? data?.message?.id : data?.message?.messageId,
        message:
          data?.type === "text"
            ? data?.message?.message
            : data?.message?.type == "work_request"
            ? data?.message?.message
            : data?.message.type,
        message_file_key: data?.message?.key,
        conversationId: conversation?.id,
        tradesmanSenderId: tradesmanSenderId ? Number(tradesmanSenderId) : null,
        contractorSenderId: contractorSenderId
          ? Number(contractorSenderId)
          : null,
        homeownerSenderId: homeownerSenderId ? Number(homeownerSenderId) : null,
        created_at: data?.message?.created_at,
        updated_at: data?.message?.updated_at,
        contract: null,
        type: data?.type,
        presence: "socket",
      };
      conversation?.data?.push(messageReceieved);
      dispatch(setChatConversation(conversation));
    }
  });
};

const messageToSend = (message, userData, type = "text", id = undefined) => ({
  id,
  message: message?.trim(),
  tradesmanSenderId: userData?.user_type === "tradesman" ? userData?.id : null,
  contractorSenderId:
    userData?.user_type === "contractor" ? userData?.id : null,
  homeownerSenderId: userData?.user_type === "homeowner" ? userData?.id : null,
  contract: null,
  type: type,
  user_type: userData?.user_type,
  created_at: new Date(),
  updated_at: new Date(),
});

export const sendChatMsg = async (
  userData,
  chatConversation,
  msg,
  dispatch
) => {
  if (socket?.connected) {
    socket.emit(
      "sendMessage",
      {
        message: msg?.trim(),
        sender: userData?.id,
        receiver: chatConversation?.opponentData?.oponent?.id,
        room: chatConversation?.id,
        type: "text",
        user_type: userData?.user_type,
        receiver_type: getOpponentType(chatConversation),
      },
      (callback) => {
        console.log("callback", callback);
      }
    );
    let data = messageToSend(msg, userData);
    chatConversation?.data?.push(data);
    dispatch(setChatConversation(chatConversation));
    return true;
  } else {
    return false;
  }
};

export const sendChatMessageViaContract = (messageData) => {
  socket.emit("sendMessage", messageData);
};

import { setInvoices } from "../../actions/InvoicesActions";
import { SimpleCatchError } from "../../utils/Helper";
import { Axios } from "../../utils/axiosMiddleware";

export const getInvoices = async (
  dispatch,
  payload,
  tradesmanId,
  homeownerId
) => {
  try {
    const token = JSON.parse(localStorage.getItem("data"));
    const response = await Axios.get(
      `contracts/view/invoice/list?search=${payload}&homeownerId=${homeownerId}&contractorId=${token?.id}&tradesmanId=${tradesmanId}`
    );
    dispatch(setInvoices(response?.data));
  } catch (e) {
    SimpleCatchError(e);
  }
};

import React from "react";
import { FormattedMessage } from "react-intl";

const ContractToggleButton = ({
  contractDetail,
  userData,
  contractState,
  setContractState,
}) => {
  const shouldRenderToggleButton =
    (contractDetail?.contractorId &&
      contractDetail?.homeownerId &&
      contractDetail?.updated_assigned_line_item?.length > 0 &&
      (userData?.user_type === "homeowner" ||
        userData?.user_type === "contractor")) ||
    (contractDetail?.contractorId &&
      contractDetail?.tradesmanId &&
      contractDetail?.homeownerId &&
      contractDetail?.updated_line_item?.length > 0 &&
      (userData?.user_type === "contractor" ||
        userData?.user_type === "tradesman")) ||
    (!contractDetail?.contractorId &&
      contractDetail?.tradesmanId &&
      contractDetail?.homeownerId &&
      contractDetail?.updated_line_item?.length > 0 &&
      (userData?.user_type === "homeowner" ||
        userData?.user_type === "tradesman"));

  if (!shouldRenderToggleButton) {
    return null;
  }

  return (
    <div>
      <div onClick={() => setContractState(!contractState)}>
        <span>
          {contractState ? (
            <FormattedMessage id="UPDATED" />
          ) : (
            <FormattedMessage id="ORIGINAL" />
          )}
        </span>
      </div>
    </div>
  );
};

export default ContractToggleButton;

import {
  setAllowChat,
  setAllowChatModal,
  setChatConversation,
  setGetNewChatMsgs,
} from "../../actions/ChatActions";
import { SimpleCatchError } from "../../utils/Helper";
import { Axios } from "../../utils/axiosMiddleware";
import { chatListing } from "./getChatListing";

export const loadConversation = async (
  payload,
  item,
  dispatch,
  fromPagination
) => {
  try {
    const token = JSON.parse(localStorage.getItem("data"));
    const {
      senderID,
      receiverID,
      senderType,
      receiverType,
      take,
      skip,
      chatOldMsgs,
    } = payload;
    const response = await Axios.post(
      `message-files/conversation/${senderID}/${receiverID}/${senderType}/${receiverType}/${take}/${skip}`
    );
    const messages = response?.data?.messages?.map((message) => {
      return { ...message, message_file_key: message?.message_file?.[0]?.key };
    });
    if (skip === 0) {
      const allowed = response?.data?.allowed;
      const messagesLength = response?.data?.messages.length;
      if (
        (messagesLength === 0 && allowed === null) ||
        (messagesLength > 0 && allowed === true)
      ) {
        dispatch(setAllowChat(true));
        dispatch(setAllowChatModal(false));
      } else if (messagesLength > 0 && allowed === null) {
        if (
          (token?.user_type === "tradesman" &&
            response?.data?.messages[0]?.tradesmanSenderId === token?.id) ||
          (token?.user_type === "contractor" &&
            response?.data?.messages[0]?.contractorSenderId === token?.id) ||
          (token?.user_type === "homeowner" &&
            response?.data?.messages[0]?.homeownerSenderId === token?.id)
        ) {
          dispatch(setAllowChat(true));
          dispatch(setAllowChatModal(false));
        } else {
          dispatch(setAllowChat(true));
          dispatch(setAllowChatModal(true));
        }
      } else if (messagesLength > 0 && allowed === false) {
        dispatch(setAllowChat(false));
        dispatch(setAllowChatModal(false));
      }
    }

    dispatch(
      setChatConversation({
        id: response?.data?.id,
        data: fromPagination ? messages.concat(chatOldMsgs) : messages,
        opponentData: item,
        skip,
      })
    );
    await chatListing(20, 0, dispatch);
    setTimeout(() => {
      dispatch(setGetNewChatMsgs(false));
    }, 3000);
  } catch (e) {
    dispatch(setGetNewChatMsgs(false));
    SimpleCatchError(e);
  }
};

export const ChatSeenRequest = async (payload) => {
  try {
    const { receiverID, roomID, receiver_type } = payload;
    if (receiverID && roomID && receiver_type) {
      await Axios.post(`message/${receiverID}/${roomID}/${receiver_type}`);
    }
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const AllowChatRequest = async (payload) => {
  try {
    const { body, messageBody, chatConversation, dispatch } = payload;
    await Axios.patch(`conversation`, body);
    await loadConversation(messageBody, chatConversation, dispatch);
  } catch (e) {
    SimpleCatchError(e);
  }
};


import { CloseOutlined } from '@ant-design/icons';
import axios from 'axios';

import { injectIntl } from 'react-intl';
import React, { useState } from 'react';
import { base_url } from '../../constants';
import Loader from '../GeneralComponents';

 const ProfileImageUpload1 = (props) => {
const[loading,setLoading]=useState(false);
const handleSubmit = async (event) => {
  const accessToken = JSON.parse(localStorage.getItem('data'));

  const file = event.target.files[0];
  event.target.value=''

  const formData=new FormData();
    formData.append('file',file);
    formData.append('type','story/image');
    formData.append('name',file.name);
  if(accessToken.user_type === 'contractor'){
    formData.append("contractorId",accessToken.id)
    }
  
  else if(accessToken.user_type === 'tradesman'){
    formData.append("tradesmanId",accessToken.id)

  }
  else if(accessToken.user_type === 'homeowner'){
    formData.append("homeownerId",accessToken.id)

  }


  try {
    setLoading(true);
      const config = {
        headers: { 
          Authorization: `Bearer ${accessToken?.accessToken}`,
          "ngrok-skip-browser-warning": true,
          'content-type': 'multipart/form-data' 
        },
      };
       const response=await axios.post(`${base_url}files/uploadPicture`, formData, config)
        props.setProfilePic(response?.data)
        setLoading(false)
  } catch (error) {
    console.error (error);
  }
};

  const handleClearClick = () => {
    props.setProfilePic('');
  };



  return (
    <div className='imageUpload w-100 h-100 '>
      <input
        id='profile'
        type="file"
        accept="image/*"
        onChange={handleSubmit}
        className="imageUpload-input"
      />

      <div
        className='preview1 shadow border'
      >
        {!props.profilePic && !loading &&
          <label htmlFor="profile" className='d-flex flex-column align-items-center'>
            <div style={{width:"30px",height:"30px",backgroundColor:"#F77E0B",fontSize:"30px",fontWeight:"bold",color:"white",cursor:'pointer' }} className='d-flex justify-content-center align-items-center rounded-circle' ><span className='mb-1'>+</span></div>
            <span style={{fontFamily:"Poppins",marginTop:"10px"}}>{props.intl.formatMessage({id: "uploadPics"})}</span>
          </label>
        }

      </div>
      {loading ?(<div  className='preview1 border'><Loader/></div>):props.profilePic &&
              <div
              className='preview1 border'
              >
                <img
                src={`${process.env.REACT_APP_S3_BUCKET_URL}${props.profilePic}`}
                className=' w-100 h-100 rounded'
                style={{objectFit: 'cover'}}

                />
      
        <span className="close-icon" onClick={handleClearClick}>
          <CloseOutlined />
        </span>
        </div>
      }
    </div>
  );
    };

export default injectIntl(ProfileImageUpload1);


import * as Actions from "../actions/ActionTypes";
const FeedsReducer = (
  state = {
    FeedsListing: [],
    feedCount: 0,
    commentListing: [],
    likeListing: [],
  },
  action
) => {
  switch (action.type) {
    case Actions.SET_FEEDS_LISTING:
      return Object.assign({}, state, {
        FeedsListing: action?.data,
      });
    case Actions.TOTAL_FEEDS_LENGTH:
      return Object.assign({}, state, {
        feedCount: action?.data,
      });

    case Actions.SET_COMMENTS_LISTING:
      return Object.assign({}, state, {
        commentListing: action?.data,
      });
    case Actions.SET_LIKES_LISTING:
      return Object.assign({}, state, {
        likeListing: action?.data,
      });
    default:
      return state;
  }
};

export default FeedsReducer;

import { Axios, formAxios } from "../../utils/axiosMiddleware";
import { catchError, SimpleCatchError } from "../../utils/Helper";

export const CustomerCreation = async (body, path) => {
  try {
    if (path === "Edit") {
      await formAxios.patch(`gigwage/sending-account`, body);
    } else {
      await formAxios.post(`gigwage/sending-account`, body);
    }
    window.location.href = "/";
  } catch (e) {
    await catchError(e);
  }
};
export const BankTokenCreationAeroPay = async (body) => {
  try {
    let response = await formAxios.post(`aeropay/create-user`, body);
    if (response?.data?.token) {
      return response?.data?.token;
    }
  } catch (e) {
    await catchError(e);
  }
};

export const getUserBankDetailsAeroPay = async (id) => {
  try {
    let response = await formAxios.get(`aeropay/${id}`);
    const aeroPayUser = response?.data?.user;
    if (aeroPayUser?.firstName) {
      const body = {
        userId: id,
        first_name: aeroPayUser?.firstName,
        last_name: aeroPayUser?.lastName,
        email: aeroPayUser?.email,
        phone_number: aeroPayUser?.phone,
      };
      let token = await BankTokenCreationAeroPay(body);
      if (token) {
        return token;
      }
    }
  } catch (e) {
    await catchError(e);
  }
};

export const aeroPayAccountSuccess = async (body) => {
  try {
    let response = await formAxios.post(
      `aeropay/link-account-from-aggregator`,
      body
    );
  } catch (e) {
    await catchError(e);
  }
};

export const bankTokenCreationEveree = async (body, type) => {
  try {
    let response =
      type == "edit"
        ? await formAxios.patch(`everee/${body?.userId}`, body)
        : await formAxios.post(`everee/create-user`, body);
    if (response?.data) {
      return response?.data;
    }
  } catch (e) {
    await catchError(e);
  }
};

export const getUserBankDetailsEveree = async (id) => {
  try {
    let response = await Axios.get("everee");
    const everreePay = response?.data;
    if (everreePay) {
      return everreePay;
    }
  } catch (e) {
    await catchError(e);
  }
};

export const AddDebitCard = async (body) => {
  try {
    await Axios.post("gigwage/receiving-account/debit-card", body);
  } catch (e) {
    SimpleCatchError(e);
  }
};

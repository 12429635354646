import * as ActionTypes from "./ActionTypes";

export const getBannerImages = () => ({
  type: ActionTypes.GET_BANNER_IMAGES,
});

export const getBannerImagesSuccess = (bannerList) => ({
  type: ActionTypes.GET_BANNER_IMAGES_SUCCESS,
  bannerList,
});

export const getBannerImagesError = (error) => ({
  type: ActionTypes.GET_BANNER_IMAGES_ERROR,
  error,
});

export const getProfileVideos = () => ({
  type: ActionTypes.GET_PROFILE_VIDEOS,
});

export const getProfileVideosSuccess = (videoList) => ({
  type: ActionTypes.GET_PROFILE_VIDEOS_SUCCESS,
  videoList,
});

export const getProfileVideosError = (error) => ({
  type: ActionTypes.GET_PROFILE_VIDEOS_ERRORS,
  error,
});

export const getUser = () => ({
  type: ActionTypes.GET_USER_DATA,
});

export const getUserSuccess = (userData) => ({
  type: ActionTypes.GET_USER_DATA_SUCCESS,
  userData,
});

export const getUserError = (error) => ({
  type: ActionTypes.GET_USER_DATA_ERRORS,
  error,
});

export const setFromSignUpTrue = () => {
  return {
    type: ActionTypes.SET_FROM_SIGN_UP_TRUE,
  };
};
export const setFromSignUpFalse = () => {
  return {
    type: ActionTypes.SET_FROM_SIGN_UP_FALSE,
  };
};

export const setSelectedProfile = (user) => {
  return {
    type: ActionTypes.SET_SELECTED_PROFILE,
    user,
  };
};
export const delSelectedProfile = () => ({
  type: ActionTypes.DELETE_SELECTED_PROFILE,
});

export const setVideosUrl = (url) => {
  return {
    type: ActionTypes.SET_VIDEOS_URL,
    url,
  };
};
export const setAllSkills = (skills) => {
  return {
    type: ActionTypes.SET_ALL_SKILLS,
    skills,
  };
};
export const setFullUser = (user) => {
  return {
    type: ActionTypes.SET_FULL_USER_DATA,
    user,
  };
};
export const setLogoUrl = (url) => {
  return {
    type: ActionTypes.SET_LOGO_URL,
    url,
  };
};

export const setProfileUrl = (profileUrl) => {
  return {
    type: ActionTypes.SET_PROFILE_URL,
    profileUrl,
  };
};
export const setReviewList = (reviews) => {
  return {
    type: ActionTypes.SET_REVIEW_LIST,
    reviews,
  };
};

export const setSkillList = (skill) => {
  return {
    type: ActionTypes.SET_SKILLS_LIST,
    skill,
  };
};
export const setUserSkill = (userSkill) => {
  return {
    type: ActionTypes.SET_USER_SKILLS_LIST,
    userSkill,
  };
};

export const getImagesList = (image, index) => {
  return {
    type: ActionTypes.SET_IMAGES_DATA_LIST,
    image,
    index,
  };
};

export const setStoriesList = (stories) => {
  return {
    type: ActionTypes.SET_STORIES_LIST,
    stories,
  };
};
export const setStoryModal = (stories) => {
  return {
    type: ActionTypes.SET_STORIES_MODAL,
    stories,
  };
};
export const setYoutubeModal = (stories) => {
  return {
    type: ActionTypes.SET_YOUTUBE_MODAL,
    stories,
  };
};
export const setStoryIndex = (stories) => {
  return {
    type: ActionTypes.SET_STORIES_INDEX,
    stories,
  };
};

export const setSearchedProfilesList = (profile) => {
  return {
    type: ActionTypes.SEARCHED_PROFILES_LIST,
    profile,
  };
};

export const setCurrentUser = (newUser) => {
  return {
    type: ActionTypes.SET_CURRENT_USER,
    newUser,
  };
};

export const setLikedUsersList = (likedUser) => {
  return {
    type: ActionTypes.LIKED_USER_LIST,
    likedUser,
  };
};

export const setUnsubscribedUser = (data) => {
  return {
    type: ActionTypes.SET_UNSUBSCRIBED_USER,
    data,
  };
};

export const setOtpVerificationModal = (data) => {
  return {
    type: ActionTypes.SET_OTP_VERIFICATION_MODAL,
    data,
  };
};

import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import LocationIcon from "../../assets/images/Location.png";
import PhoneIcon from "../../assets/images/Phone.png";
import EmailIcon from "../../assets/images/Email.png";
import { useNavigate } from "react-router-dom";
import backIcon from "../../assets/images/backButton.svg";
import { injectIntl } from "react-intl";
import { supportApi } from "../../api/supportApi/supportApi";
import Loader from "../../components/GeneralComponents";
import { FooterTextButton } from "../../components/FooterTextButton";
const SupportPage = (props) => {
  const navigate = useNavigate();

  const userType = useSelector((state) => state?.common?.fullUser?.data);

  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    subject: "",
    Message: "",
  });

  useEffect(() => {
    if (userType) {
      setFullName(
        userType?.length > 0 && userType[0]?.first_name
          ? userType[0]?.first_name
          : "" + " " + (userType?.length > 0 && userType[0]?.last_name)
          ? userType[0]?.last_name
          : ""
      );
      setEmail(
        userType?.length > 0 && userType[0]?.username?.includes("@")
          ? userType[0]?.username
          : ""
      );
    }
  }, [userType]);

  const form = useRef();
  const sendMessageHandler = async (e) => {
    e.preventDefault();

    const newErrors = {};

    if (fullName.trim() === "") {
      newErrors.name = `${props.intl.formatMessage({ id: "name_required" })}`;
    }
    if (!/^[A-Za-z\s]+$/.test(fullName)) {
      newErrors.name = `${props.intl.formatMessage({ id: "name_required" })}`;
    }

    if (email.trim() === "") {
      newErrors.email = `${props.intl.formatMessage({ id: "email_required" })}`;
    }
    if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|io|org|net|edu|gov|mil|co|ac|biz|info|me|name|tv|us|ca|uk|co\.uk)$/.test(
        email
      )
    ) {
      newErrors.email = `${props.intl.formatMessage({ id: "valid_email" })}`;
    }

    if (subject.trim() === "") {
      newErrors.subject = `${props.intl.formatMessage({
        id: "subject_required",
      })}`;
    }

    if (message.trim() === "") {
      newErrors.Message = `${props.intl.formatMessage({
        id: "message_required",
      })}`;
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }

    setLoading(true);
    await supportApi(
      {
        name: fullName.trim(),
        email: email.trim(),
        reason: message.trim(),
      },
      navigate,
      props
    );
    setLoading(false);
  };

  return (
    <>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}
      <div>
        <div
          className="container-md"
          style={{
            marginTop: "50px",
            marginBottom: "50px",
            fontFamily: "Poppins",
          }}
        >
          <div
            className="mt-4 w-100"
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          >
            <img
              className="shadow-lg border rounded "
              src={backIcon}
              alt={backIcon}
            />
            <span className="ms-2">
              {props.intl.formatMessage({ id: "back" })}
            </span>
          </div>

          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6">
              <p className="help_heading">
                {props.intl.formatMessage({ id: "getin_touch" })}
              </p>
              <p className="help_sub_heading">
                {props.intl.formatMessage({ id: "we_are_here" })}
              </p>
              <form ref={form} onSubmit={sendMessageHandler}>
                <input
                  value={fullName}
                  type="text"
                  id="full_name"
                  name="user_full_name"
                  placeholder={props.intl.formatMessage({ id: "full_name" })}
                  className="form-control form-control-lg"
                  onChange={(e) => setFullName(e.target.value)}
                />
                <label class="form-label" for="full_name"></label>
                {errors?.name && (
                  <span style={{ color: "red" }}>{errors?.name}</span>
                )}
                <input
                  // type="email"
                  id="email_address"
                  value={email}
                  name="user_email_address"
                  placeholder={props.intl.formatMessage({ id: "email" })}
                  className="form-control form-control-lg"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label class="form-label" for="email_address"></label>
                {errors?.email && (
                  <span style={{ color: "red" }}>{errors?.email}</span>
                )}

                <input
                  type="text"
                  id="subject"
                  name="user_subject"
                  placeholder={props.intl.formatMessage({ id: "subject" })}
                  className="form-control form-control-lg"
                  onChange={(e) => setSubject(e.target.value)}
                />
                <label class="form-label" for="subject"></label>
                {errors?.subject && (
                  <span style={{ color: "red" }}>{errors?.subject}</span>
                )}

                <textarea
                  type="text"
                  id="message"
                  name="user_message"
                  placeholder={props.intl.formatMessage({ id: "message" })}
                  rows={5}
                  cols={5}
                  className="form-control form-control-lg"
                  onChange={(e) => setMessage(e.target.value)}
                />
                <label class="form-label" for="message"></label>
                {errors?.Message && (
                  <span style={{ color: "red" }}>{errors?.Message}</span>
                )}

                <input
                  type="submit"
                  value={props.intl.formatMessage({ id: "send_message" })}
                  style={{
                    backgroundColor: "#F77E0B",
                    width: "100%",
                    border: "none",
                    color: "white",
                    borderRadius: "8px",
                    padding: "16px 52px",
                    fontSize: "16px",
                    fontWeight: "600",
                    marginTop: "8px",
                  }}
                />
              </form>
            </div>
            <div
              className="col-sm-6 col-md-6 col-lg-6"
              style={{ paddingTop: "5rem" }}
            >
              <p className="aaccessibility_paaragraph">
                {props.intl.formatMessage({ id: "for_choosing" })}
              </p>
              <FooterTextButton
                text={props.intl.formatMessage({ id: "gigmi_address" })}
                icon={<img src={LocationIcon} alt="Location" />}
              />
              <FooterTextButton
                icon={<img src={PhoneIcon} alt="Phone" />}
                text={"+1(970)744-4412"}
              />
              <FooterTextButton
                icon={<img src={EmailIcon} alt="Email" />}
                text={"support@gigmiapp.com"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default injectIntl(SupportPage);

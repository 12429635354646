import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import { FormattedMessage } from "react-intl";
import backIcon from "../../assets/images/backButton.svg";
import { GetHowToUseApp } from "../../api/HowToUse";
import { useDispatch, useSelector } from "react-redux";
import playIcon from "../../assets/images/playvideo.png";
import { isEmpty } from "lodash";
import "./style.css";
import { bucket_base_url } from "../../constants";
import { useNavigate } from "react-router";
export const HowToUseApp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    GetHowToUseApp(dispatch);
  }, []);
  const { videoList } = useSelector((state) => state?.howTo);
  return (
    <div className="container">
      <Header />
      <div className="paymentscreen-inner mb-5">
        <div className="d-flex justify-content-between align-items-center ">
          <div className="d-flex align-items-center">
            <img
              src={backIcon}
              alt=""
              onClick={() => navigate(-1)}
              style={{ cursor: "pointer" }}
              className="shadow-lg border rounded"
            />
            <span className="text-black fs-4 m-1">
              <FormattedMessage id="back" />
            </span>
          </div>
          <div>
            <span className="text-black fw-bold fs-4 m-1">
              <FormattedMessage id="How_to" />
            </span>
          </div>
          <div></div>
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column">
          {!isEmpty(videoList) &&
            videoList?.map((video) => {
              return (
                <div
                  className="how-to-card"
                  key={video?.id}
                  onClick={() => window.open(`${video?.link}`)}
                >
                  <div className="h-75" style={{ position: "relative" }}>
                    <img className="play-icon" src={playIcon} />
                    <img
                      src={`${bucket_base_url}${video?.file?.key}`}
                      className="w-100 h-100 video-img"
                    />
                  </div>
                  <div className="p-2 fw-bold">
                    <span className="title-video">{video?.title}</span>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

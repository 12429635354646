
import React from 'react';
import { FormattedMessage } from 'react-intl'; 

const Paragraph = (props) => {
  return (
    <div>
      <p></p>
      <p>
        <span
          style={{
            fontFamily: 'Poppins',
            fontWeight: 'normal',
            fontSize: 'medium',
          }}
        >
            <FormattedMessage {...props} />
        </span>
      </p>
    </div>
  );
};

export default Paragraph;

import { Button, DatePicker, Form, Input, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Header from "../../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/GeneralComponents";
import { GetAdminSkills } from "../../api/contractsApi/getSkillsApi";
import { GenerateGigmiCharges } from "../../api/contractsApi/contractDetails";
import AddSkillModal from "../../components/Contracts/AddSkillModal";
import Disclaimer from "../../components/BankInfo/Disclaimer";
import usStates from "../../assets/States";
import { usCities } from "../../assets/Cities";
import { createInvoiceOrReceipt } from "../../api/invoicesApi/createInvoiceOrReceiptApi";
import { GenerateReceiptRequest } from "../../api/contractsApi/generateReceipt";
import { GenerateInvoiceRequest } from "../../api/contractsApi/GenerateInvoice";
import { useNavigate } from "react-router";
import { getProfile } from "../../api/GetProfile";

const CreateInvoiceAndReceipt = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const searchParams = new URLSearchParams(window.location.search);
  const user_id = searchParams.get("user_id");
  const id = searchParams.get("id");
  const user_type = searchParams.get("type");
  const path = searchParams.get("path");
  const [loading, setLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem("data"));
  const userTypeData = useSelector((state) => state?.common?.newUser?.data);
  const receiverUserData = useSelector((state) => state?.common?.profile);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [isBalanceAvailable, setIsBalanceAvailable] = useState(false);
  const [skillOptions, setSkillOptions] = useState([]);
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [userType, setUserType] = useState(user_type);
  const [totalAmount, setTotalAmount] = useState("0");
  const navigate = useNavigate();

  const firstName =
    userType == "homeowner"
      ? receiverUserData?.homeowner?.first_name
      : userType == "tradesman"
      ? receiverUserData?.tradesman?.first_name
      : "";
  const lastName =
    userType == "homeowner"
      ? receiverUserData?.homeowner?.last_name
      : userType == "tradesman"
      ? receiverUserData?.tradesman?.last_name
      : "";
  const receiverCity =
    userType == "homeowner"
      ? receiverUserData?.city
      : userType == "tradesman"
      ? receiverUserData?.city
      : "";
  const receiverState =
    userType == "homeowner"
      ? receiverUserData?.state
      : userType == "tradesman"
      ? receiverUserData?.state
      : "";

  const getSkills = async () => {
    const res = await GetAdminSkills();
    setSkillOptions(res);
    if (userData?.user_type !== "tradesman") {
      const body = {
        homeOwnerId:
          userData?.user_type === "contractor" ? null : userTypeData?.id,
        contractorId:
          userData?.user_type === "contractor" ? userTypeData?.id : null,
      };
      await GenerateGigmiCharges(body, dispatch);
    }
  };
  useEffect(() => {
    getSkills();
    if (path) {
      getProfile(dispatch, user_id, id, user_type);
    }
  }, [userType]);

  const handleSubmit = async (values) => {
    setLoading(true);

    const finalData = {
      invoice_user: [
        {
          first_name: values?.firstName,
          last_name: values?.lastName,
          city: values?.city,
          state: values?.state,
          user_type: values?.userType,
        },
      ],
      contractorId: userTypeData?.id,
      homeownerId: user_type == "homeowner" ? id : null,
      tradesmanId: user_type == "tradesman" ? id : null,
      custom_skill: values.skill,
      line_item:
        userType == "homeowner"
          ? values.lineItems?.map((item) => {
              return { job_desc: item?.lineItem, flat_rate: item?.flatRate };
            })
          : [],
      job_desc: userType == "tradesman" ? values?.jobdesc : null,
      flat_amount:
        userType == "tradesman"
          ? parseInt(values?.flatAmount)
          : parseInt(totalAmount),
      start_date: values.sDate,
      end_date: values.eDate,
    };
    const data = await createInvoiceOrReceipt(finalData);
    if (userType == "homeowner") {
      await GenerateInvoiceRequest(`contractor/homeowner/${data?.id}`);
    } else {
      await GenerateReceiptRequest(data?.id);
    }
    setLoading(false);
    navigate(-1);
  };
  const disabledDate = (current) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return current && current < currentDate;
  };

  const handleState = (e) => {
    setCity("");
    form.setFieldValue({ city: city });
    form.resetFields(["city"]);
    setState(e);
  };

  const Cities = [];
  usCities.map((city) => {
    if (city.state === state) {
      Cities.push({
        label: city.city,
        value: city.city,
      });
    }
  });

  const onValuesChange = (_, values) => {
    if (values?.lineItems?.length > 0) {
      const { lineItems } = values;
      const newTotalPrice = lineItems.reduce(
        (acc, item) => acc + Number(item.flatRate || 0),
        0
      );
      setTotalAmount(newTotalPrice.toString());
      form.setFieldsValue({
        totalAmount: newTotalPrice.toString(),
      });
    } else if (values?.lineItems?.length === 0) {
      form.setFieldsValue({
        totalAmount: "0",
      });
    }
  };

  return (
    <>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}

      <div className="container paymentscreen">
        <Header />
        <div className="paymentscreen-inner">
          <div className="d-flex justify-content-center align-items-center getting-payment-heading text-center">
            {/* <BackButton position="relative" navigateTo={-1} /> */}

            <span>
              <FormattedMessage id="CREATE" />{" "}
              <FormattedMessage
                id={
                  path
                    ? user_type === "tradesman"
                      ? "RECEIPTS"
                      : "INVOICES"
                    : "INVOICES_RECEIPTS"
                }
              />
            </span>
          </div>
          <Form
            onFinish={handleSubmit}
            onValuesChange={onValuesChange}
            form={form}
            layout="vertical"
            initialValues={{
              firstName: path ? firstName : "",
              lastName: path ? lastName : "",
              skill: "",
              totalAmount: totalAmount,
              sDate: "",
              eDate: "",
              upload: "",
              city: path ? receiverCity : "",
              state: path ? receiverState : "",
              userType: userType,
              lineItems: "",
              flatAmount: "",
              jobdesc: "",
            }}
            style={{ fontFamily: "Poppins" }}
          >
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <span className="email-text">
                  {props.intl.formatMessage({ id: "first_name" })}
                </span>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "first_name_required",
                      })}`,
                    },
                    {
                      pattern: new RegExp(/^[A-Za-z\s]+$/),
                      message: `${props.intl.formatMessage({
                        id: "not_accept_number",
                      })}`,
                    },
                  ]}
                >
                  <Input
                    placeholder={props.intl.formatMessage({ id: "first_name" })}
                    style={{
                      height: "56px",
                      backgroundColor: "#E6E7EB",
                      marginTop: "8px",
                    }}
                    type="text"
                    disabled={!!path}
                    required
                    // onChange={(e) => setFirstName(e.target.value)}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <span className="email-text">
                  {props.intl.formatMessage({ id: "last_name" })}
                </span>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "last_name_required",
                      })}`,
                    },
                    {
                      pattern: new RegExp(/^[A-Za-z\s]+$/),
                      message: `${props.intl.formatMessage({
                        id: "not_accept_number",
                      })}`,
                    },
                  ]}
                >
                  <Input
                    placeholder={props.intl.formatMessage({ id: "last_name" })}
                    disabled={!!path}
                    style={{
                      height: "56px",
                      backgroundColor: "#E6E7EB",
                      marginTop: "8px",
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="row ">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <span className="email-text">
                  {props.intl.formatMessage({ id: "state" })}
                </span>
                <Form.Item
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "state_required",
                      })}`,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    disabled={!!path}
                    style={{
                      width: "100%",
                    }}
                    placeholder={props.intl.formatMessage({ id: "state" })}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={usStates}
                    onSelect={(e) => handleState(e)}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <span className="email-text">
                  {props.intl.formatMessage({ id: "city" })}
                </span>
                <Form.Item
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "city_required",
                      })}`,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    disabled={!!path}
                    style={{
                      width: "100%",
                    }}
                    placeholder={props.intl.formatMessage({ id: "state" })}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={Cities}
                    onSelect={(e) => setCity(e)}
                  />
                </Form.Item>
              </div>
            </div>
            <div>
              <Form.Item
                name="skill"
                rules={[
                  {
                    required: true,
                    message: "Please select a skill",
                  },
                ]}
              >
                <div className="row w-100">
                  <span className="col-6">
                    <FormattedMessage id="skill" />{" "}
                  </span>
                  <div className="col-6 d-flex justify-content-end">
                    <span
                      style={{
                        color: "#F77E0B",
                        fontFamily: "Poppins",
                        cursor: "pointer",
                      }}
                      onClick={() => setModalShow(true)}
                    >
                      {props.intl.formatMessage({ id: "addNewSkill" })}
                    </span>
                  </div>
                </div>
                <Select
                  placeholder="Select a skill"
                  onChange={(value) => form.setFieldsValue({ skill: value })}
                >
                  {skillOptions.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                name="userType"
                rules={[
                  {
                    required: true,
                    message: "Please select a user type",
                  },
                ]}
              >
                <div className="row w-100">
                  <span className="col-6">
                    <FormattedMessage id="USER_TYPE" />{" "}
                  </span>
                </div>
                <Select
                  defaultValue={userType}
                  disabled={!!path}
                  placeholder="Select  user type..."
                  onChange={(value) => {
                    form.setFieldsValue({ userType: value });
                    setUserType(value);
                  }}
                >
                  {[
                    { label: "gigster", value: "tradesman" },
                    { label: "homeowner", value: "homeowner" },
                  ].map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label.toUpperCase()}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div>
              {userType !== "homeowner" ? (
                <Form.Item
                  name="jobdesc"
                  label="Job Description"
                  rules={[
                    {
                      required: true,
                      message: "required",
                    },
                  ]}
                >
                  <Input.TextArea
                    placeholder="Job Description"
                    type="text"
                    className="input-field"
                    rows={4}
                    style={{ padding: 20, resize: "none" }}
                  />
                </Form.Item>
              ) : (
                <Form.List name="lineItems">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <div key={key} className="row">
                          <div className="col-8">
                            <Form.Item
                              {...restField}
                              name={[name, "lineItem"]}
                              label="Line Item"
                              key={[key + "lineItem"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing Line Item",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Add new line item"
                                className="input-field"
                              />
                            </Form.Item>
                          </div>
                          <div className="col-3">
                            <Form.Item
                              {...restField}
                              name={[name, "flatRate"]}
                              key={[key + "flatRate"]}
                              label="Flat Rate"
                              rules={[
                                {
                                  required: true,
                                  message: "Missing flat rate",
                                },
                              ]}
                            >
                              <Input
                                type="number"
                                placeholder="Flat Rate"
                                className="input-field"
                              />
                            </Form.Item>
                          </div>
                          <div className="col-1 d-flex justify-content-center align-items-center">
                            <MinusCircleOutlined
                              style={{ height: 110, fontSize: 25 }}
                              onClick={() => remove(name)}
                            />
                          </div>
                        </div>
                      ))}
                      <Button className="input-field mb-3" onClick={add} block>
                        <span style={{ color: "black" }}>
                          <PlusOutlined style={{ paddingRight: "10px" }} />
                          Add Line Items
                        </span>
                      </Button>
                    </>
                  )}
                </Form.List>
              )}
            </div>

            {userType !== "homeowner" ? (
              <Form.Item
                name="flatAmount"
                label="Flat Rate"
                rules={[
                  {
                    required: true,
                    message: "required",
                  },
                ]}
              >
                <Input
                  placeholder="Flat Rate"
                  type="number"
                  className="input-field"
                  prefix={"$"}
                />
              </Form.Item>
            ) : (
              <Form.Item
                name="totalAmount"
                label="Total Amount"
                rules={[
                  {
                    required: true,
                    message: "required",
                  },
                ]}
              >
                <Input
                  placeholder="Amount"
                  type="number"
                  className="input-field"
                  disabled={true}
                  prefix="$"
                  value={totalAmount}
                />
              </Form.Item>
            )}

            <div className="row">
              <div className="col-6">
                <Form.Item
                  name="sDate"
                  label="Start Date"
                  rules={[
                    {
                      required: true,
                      message: "required",
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={disabledDate}
                    className="input-field"
                  />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  name="eDate"
                  label="End Date"
                  rules={[
                    {
                      required: true,
                      message: "required",
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={disabledDate}
                    className="input-field"
                  />
                </Form.Item>
              </div>
            </div>

            <div className="d-flex justify-content-end">
              <Form.Item>
                <Button
                  type="submit"
                  htmlType="submit"
                  className="submit-button"
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
        <div>
          <AddSkillModal
            modalShow={modalShow}
            setModalShow={setModalShow}
            skillArray={skillOptions}
            selectedSkills={selectedSkills}
            setSkillArray={setSkillOptions}
            setSelectedSkills={setSelectedSkills}
          />
        </div>

        <Disclaimer
          showModal={isBalanceAvailable}
          setShowModal={() => setIsBalanceAvailable(false)}
          body={props.intl.formatMessage({
            id: "WALLET_DOES_NOT_HAVE_SUFFICIENT_BALANCE",
          })}
          handleClick={() => {
            setIsBalanceAvailable(false);
          }}
          handleCancel={() => {
            setIsBalanceAvailable(false);
          }}
        />
      </div>
    </>
  );
};
export default injectIntl(CreateInvoiceAndReceipt);

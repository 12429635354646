import * as Actions from "../actions/ActionTypes";
const ChatReducer = (
  state = {
    chatList: [],
    searchedChatList: [],
    connections: [],
    conversation: {},
    allowChat: null,
    allowChatModal: false,
    conversationList: [],
    chatDetailsMobile: false,
    isMobile: false,
    newChatMsgs: false,
  },
  action
) => {
  switch (action.type) {
    case Actions.SET_CHAT_ARRAY:
      return Object.assign({}, state, {
        chatList: action?.data,
      });
    case Actions.SET_SEARCHED_CHAT_ARRAY:
      return Object.assign({}, state, {
        searchedChatList: action?.data,
      });
    case Actions.SET_CONNECTIONS_ARRAY:
      return Object.assign({}, state, {
        connections: action?.data,
      });
    case Actions.SET_CHAT_CONVERSATION:
      return Object.assign({}, state, {
        conversation: action?.data,
        conversationList: action?.data.data,
      });
    case Actions.SET_ALLOW_CHAT:
      return Object.assign({}, state, {
        allowChat: action?.data,
      });
    case Actions.SET_CHAT_DETAIL_MOBILE:
      return Object.assign({}, state, {
        chatDetailsMobile: action?.data,
      });
    case Actions.SET_ALLOW_CHAT_MODAL:
      return Object.assign({}, state, {
        allowChatModal: action?.data,
      });
    case Actions.IS_MOBILE:
      return Object.assign({}, state, {
        isMobile: action?.data,
      });
    case Actions.GET_NEW_CHAT_MSGS:
      return Object.assign({}, state, {
        newChatMsgs: action?.data,
      });

    default:
      return state;
  }
};

export default ChatReducer;

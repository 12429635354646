import React from "react";
import { setContractListingType } from "../../actions/ContractActions";
import { useDispatch } from "react-redux";
import RedDot from "../../components/General/RedDot";
import { socket } from "../../sockets/SocketConnection";

const ContractTabButton = ({
  title,
  setActiveMenu,
  setFilterValue,
  socketSeen,
  selected,
  tab,
  showRedDot,
  userData,
}) => {
  const dispatch = useDispatch();
  const setRedDotSocket = (seen) => {
    socket.emit("red_dot", {
      seen: seen,
      user_id: userData?.id,
      user_type: userData?.user_type,
    });
  };
  return (
    <div
      onClick={() => {
        setActiveMenu(tab);
        dispatch(setContractListingType(tab));
        setFilterValue("all");
        setRedDotSocket(socketSeen);
      }}
      className={selected ? "selected-tab-style" : "un-selected-tab-style"}
    >
      <span>{title}</span>
      {showRedDot && (
        <RedDot style={{ marginLeft: "4px", marginBottom: "4px" }} />
      )}
    </div>
  );
};

export default ContractTabButton;

import * as Actions from "../actions/ActionTypes";
const SkillsReducer = (
  state = {
    userSkills: [],
  },
  action
) => {
  if (action.type == Actions.SET_USER_SKILLS_AD) {
    return Object.assign({}, state, {
      userSkills: action.skills,
    });
  } else {
    return state;
  }
};

export default SkillsReducer;

import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useState } from "react";
import ReactPlayer from "react-player";
import Loader from "../GeneralComponents";
export default function VideoPlayModal({
  showModal,
  setShowModal,
  images,
  index,
}) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(index);
  const [number, setNumber] = useState(index + 1);
  const total = images?.length;
  const handlePrevImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : total - 1
    );
    setNumber((prevNumber) => (prevNumber > 1 ? prevNumber - 1 : total));
  };
  const handleNextImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex < total - 1 ? prevIndex + 1 : 0
    );
    setNumber((prevNumber) => (prevNumber < total ? prevNumber + 1 : 1));
  };
  const [loading, setLoading] = useState(false);
  return (
    <Modal
      open={showModal}
      onCancel={() => setShowModal(false)}
      footer={false}
      className="image-view-modal"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      destroyOnClose={true}
    >
      <div style={{ position: "relative" }}>
        {total > 1 && (
          <div className="d-flex justify-content-center align-items-center prev-image">
            <ArrowLeftOutlined
              style={{
                fontSize: "30px",
                color: "white",
                cursor: "pointer",
                zIndex: 1000,
              }}
              onClick={handlePrevImage}
            />
          </div>
        )}{" "}
        {total > 1 && (
          <div
            className="d-flex justify-content-center align-items-center next-image"
            onClick={handleNextImage}
            style={{ zIndex: 1000 }}
          >
            <ArrowRightOutlined style={{ fontSize: "30px", color: "white" }} />
          </div>
        )}
        <div className="video-card-profile" style={{ position: "relative" }}>
          {loading && (
            <div className="video-loader">
              <Loader />
            </div>
          )}
          <ReactPlayer
            config={{
              file: {
                forceVideo: true,
              },
            }}
            width="100%"
            height="100%"
            muted={false}
            url={`${process.env.REACT_APP_S3_BUCKET_URL}${images[selectedImageIndex]?.key}`}
            playing={true}
            onBuffer={() => setLoading(true)}
            onBufferEnd={() => setLoading(false)}
            onEnded={handleNextImage}
          />
        </div>
      </div>
      {total > 1 && (
        <div
          style={{ width: "100%", height: "30px" }}
          className="d-flex justify-content-center fs-3"
        >
          <span>
            {number}/{total}
          </span>
        </div>
      )}
    </Modal>
  );
}

import React from 'react'
import { injectIntl } from 'react-intl'
import "./style.css"
const EarningSpendingInfoCard = ({amount,type,...props}) => {
  return (
    <div className='amount-card'>
        <span className='fw-bold text-white text-center fs-4'>{type === 'contractor' || type === 'homeowner'
            ? `${props.intl.formatMessage({id: "total_spendings"})}`
            : `${props.intl.formatMessage({id: "total_earnings"})}`}</span>
        <span className='fw-normal text-white mt-4 text-center fs-2'>${amount}/-</span>
    </div>
  )
}
export default injectIntl(EarningSpendingInfoCard);

import { SimpleCatchError } from "../../utils/Helper";
import { Axios } from "../../utils/axiosMiddleware";
import { message } from "antd";
import { setUserSkills } from "../../actions/SkillsActions";

export const GetAdminSkills = async () => {
  try {
    const response = await Axios.get("/skills");
    const SkillsObjects = [],
      renderSkills = response?.data;
    for (const item of renderSkills) {
      SkillsObjects.push({
        label: item?.skill?.toUpperCase(),
        value: item?.skill?.toUpperCase(),
      });
    }
    return SkillsObjects;
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const GetUserSkills = async (payload, dispatch, intl) => {
  try {
    const { receiverID, receiver_type } = payload;
    if (receiverID && receiver_type) {
      const response = await Axios.get(
        `/tradesman-skills/users/${receiverID}/${receiver_type}`
      );
      const SkillsObjects = [],
        renderSkills = response?.data;

      for (const item of renderSkills) {
        SkillsObjects.push({
          label: item?.skill?.toUpperCase() || item?.user_skill?.toUpperCase(),
          value: item?.skill?.toUpperCase() || item?.user_skill?.toUpperCase(),
        });
      }

      dispatch(setUserSkills(SkillsObjects));
    } else {
      message.error(`${intl.formatMessage({ id: "SOMETHING_WENT_WRONG" })}`);
    }
  } catch (e) {
    SimpleCatchError(e);
  }
};

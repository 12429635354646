import { Modal } from 'antd'
import React from 'react'
import { injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import Wallet from "../../assets/images/editBank.svg";
import { setGigsterModal } from '../../actions/bankActions';
import { useNavigate } from 'react-router-dom';

const GigsterModal = (props) => {
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const gigsterModal=useSelector((state)=>state?.bankInfo?.gigsterModal)
    const handleCancel=()=>{
      dispatch(setGigsterModal(false));
    }
    const handleWalletImageClick=()=>{
      dispatch(setGigsterModal(false));
      navigate('/bank-accounts?path=add');
    }
  return (
    <Modal
        open={gigsterModal}
        footer={null}
        destroyOnClose={true}
        onCancel={handleCancel}
    >
        
        <div className="d-flex justify-content-around align-items-center">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <span className='fs-3 fw-bold mb-3'>{props.intl.formatMessage({id:"select_gigster"})}</span>
            <img
              src={Wallet}
              className="bank-modal-image"
              onClick={handleWalletImageClick}
            />
            <span className="bank-modal-text">{props.intl.formatMessage({ id: "add_more_account" })}</span>
          </div>
      </div>

    </Modal>
  )
}
export default injectIntl(GigsterModal)
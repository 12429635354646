const usCitiesWithCode = [
  {city: 'Alexander City', state: 'AL'},
  {city: 'Andalusia', state: 'AL'},
  {city: 'Anniston', state: 'AL'},
  {city: 'Athens', state: 'AL'},
  {city: 'Atmore', state: 'AL'},
  {city: 'Auburn', state: 'AL'},
  {city: 'Bessemer', state: 'AL'},
  {city: 'Birmingham', state: 'AL'},
  {city: 'Chickasaw', state: 'AL'},
  {city: 'Clanton', state: 'AL'},
  {city: 'Cullman', state: 'AL'},
  {city: 'Decatur', state: 'AL'},
  {city: 'Demopolis', state: 'AL'},
  {city: 'Dothan', state: 'AL'},
  {city: 'Enterprise', state: 'AL'},
  {city: 'Eufaula', state: 'AL'},
  {city: 'Florence', state: 'AL'},
  {city: 'Fort Payne', state: 'AL'},
  {city: 'Gadsden', state: 'AL'},
  {city: 'Greenville', state: 'AL'},
  {city: 'Guntersville', state: 'AL'},
  {city: 'Huntsville', state: 'AL'},
  {city: 'Jasper', state: 'AL'},
  {city: 'Marion', state: 'AL'},
  {city: 'Mobile', state: 'AL'},
  {city: 'Montgomery', state: 'AL'},
  {city: 'Opelika', state: 'AL'},
  {city: 'Ozark', state: 'AL'},
  {city: 'Phenix City', state: 'AL'},
  {city: 'Prichard', state: 'AL'},
  {city: 'Scottsboro', state: 'AL'},
  {city: 'Selma', state: 'AL'},
  {city: 'Sheffield', state: 'AL'},
  {city: 'Sylacauga', state: 'AL'},
  {city: 'Talladega', state: 'AL'},
  {city: 'Troy', state: 'AL'},
  {city: 'Tuscaloosa', state: 'AL'},
  {city: 'Tuscumbia', state: 'AL'},
  {city: 'Tuskegee', state: 'AL'},
  {city: 'Anchorage', state: 'AK'},
  {city: 'Cordova', state: 'AK'},
  {city: 'Fairbanks', state: 'AK'},
  {city: 'Haines', state: 'AK'},
  {city: 'Homer', state: 'AK'},
  {city: 'Juneau', state: 'AK'},
  {city: 'Ketchikan', state: 'AK'},
  {city: 'Kodiak', state: 'AK'},
  {city: 'Kotzebue', state: 'AK'},
  {city: 'Nome', state: 'AK'},
  {city: 'Palmer', state: 'AK'},
  {city: 'Seward', state: 'AK'},
  {city: 'Sitka', state: 'AK'},
  {city: 'Skagway', state: 'AK'},
  {city: 'Valdez', state: 'AK'},
  {city: 'Ajo', state: 'AZ'},
  {city: 'Avondale', state: 'AZ'},
  {city: 'Bisbee', state: 'AZ'},
  {city: 'Casa Grande', state: 'AZ'},
  {city: 'Chandler', state: 'AZ'},
  {city: 'Clifton', state: 'AZ'},
  {city: 'Douglas', state: 'AZ'},
  {city: 'Flagstaff', state: 'AZ'},
  {city: 'Florence', state: 'AZ'},
  {city: 'Gila Bend', state: 'AZ'},
  {city: 'Glendale', state: 'AZ'},
  {city: 'Globe', state: 'AZ'},
  {city: 'Kingman', state: 'AZ'},
  {city: 'Lake Havasu City', state: 'AZ'},
  {city: 'Mesa', state: 'AZ'},
  {city: 'Nogales', state: 'AZ'},
  {city: 'Oraibi', state: 'AZ'},
  {city: 'Phoenix', state: 'AZ'},
  {city: 'Prescott', state: 'AZ'},
  {city: 'Scottsdale', state: 'AZ'},
  {city: 'Sierra Vista', state: 'AZ'},
  {city: 'Tempe', state: 'AZ'},
  {city: 'Tombstone', state: 'AZ'},
  {city: 'Tucson', state: 'AZ'},
  {city: 'Walpi', state: 'AZ'},
  {city: 'Window Rock', state: 'AZ'},
  {city: 'Winslow', state: 'AZ'},
  {city: 'Yuma', state: 'AZ'},
  {city: 'Arkadelphia', state: 'AR'},
  {city: 'AR Post', state: 'AR'},
  {city: 'Batesville', state: 'AR'},
  {city: 'Benton', state: 'AR'},
  {city: 'Blytheville', state: 'AR'},
  {city: 'Camden', state: 'AR'},
  {city: 'Conway', state: 'AR'},
  {city: 'Crossett', state: 'AR'},
  {city: 'El Dorado', state: 'AR'},
  {city: 'Fayetteville', state: 'AR'},
  {city: 'Forrest City', state: 'AR'},
  {city: 'Fort Smith', state: 'AR'},
  {city: 'Harrison', state: 'AR'},
  {city: 'Helena', state: 'AR'},
  {city: 'Hope', state: 'AR'},
  {city: 'Hot Springs', state: 'AR'},
  {city: 'Jacksonville', state: 'AR'},
  {city: 'Jonesboro', state: 'AR'},
  {city: 'Little Rock', state: 'AR'},
  {city: 'Magnolia', state: 'AR'},
  {city: 'Morrilton', state: 'AR'},
  {city: 'Newport', state: 'AR'},
  {city: 'North Little Rock', state: 'AR'},
  {city: 'Osceola', state: 'AR'},
  {city: 'Pine Bluff', state: 'AR'},
  {city: 'Rogers', state: 'AR'},
  {city: 'Searcy', state: 'AR'},
  {city: 'Stuttgart', state: 'AR'},
  {city: 'Van Buren', state: 'AR'},
  {city: 'West Memphis', state: 'AR'},
  {city: 'Alameda', state: 'CA'},
  {city: 'Alhambra', state: 'CA'},
  {city: 'Anaheim', state: 'CA'},
  {city: 'Antioch', state: 'CA'},
  {city: 'Arcadia', state: 'CA'},
  {city: 'Bakersfield', state: 'CA'},
  {city: 'Barstow', state: 'CA'},
  {city: 'Belmont', state: 'CA'},
  {city: 'Berkeley', state: 'CA'},
  {city: 'Beverly Hills', state: 'CA'},
  {city: 'Brea', state: 'CA'},
  {city: 'Buena Park', state: 'CA'},
  {city: 'Burbank', state: 'CA'},
  {city: 'Calexico', state: 'CA'},
  {city: 'Calistoga', state: 'CA'},
  {city: 'Carlsbad', state: 'CA'},
  {city: 'Carmel', state: 'CA'},
  {city: 'Chico', state: 'CA'},
  {city: 'Chula Vista', state: 'CA'},
  {city: 'Claremont', state: 'CA'},
  {city: 'Compton', state: 'CA'},
  {city: 'Concord', state: 'CA'},
  {city: 'Corona', state: 'CA'},
  {city: 'Coronado', state: 'CA'},
  {city: 'Costa Mesa', state: 'CA'},
  {city: 'Culver City', state: 'CA'},
  {city: 'Daly City', state: 'CA'},
  {city: 'Davis', state: 'CA'},
  {city: 'Downey', state: 'CA'},
  {city: 'El Centro', state: 'CA'},
  {city: 'El Cerrito', state: 'CA'},
  {city: 'El Monte', state: 'CA'},
  {city: 'Escondido', state: 'CA'},
  {city: 'Eureka', state: 'CA'},
  {city: 'Fairfield', state: 'CA'},
  {city: 'Fontana', state: 'CA'},
  {city: 'Fremont', state: 'CA'},
  {city: 'Fresno', state: 'CA'},
  {city: 'Fullerton', state: 'CA'},
  {city: 'Garden Grove', state: 'CA'},
  {city: 'Glendale', state: 'CA'},
  {city: 'Hayward', state: 'CA'},
  {city: 'Hollywood', state: 'CA'},
  {city: 'Huntington Beach', state: 'CA'},
  {city: 'Indio', state: 'CA'},
  {city: 'Inglewood', state: 'CA'},
  {city: 'Irvine', state: 'CA'},
  {city: 'La Habra', state: 'CA'},
  {city: 'Laguna Beach', state: 'CA'},
  {city: 'Lancaster', state: 'CA'},
  {city: 'Livermore', state: 'CA'},
  {city: 'Lodi', state: 'CA'},
  {city: 'Lompoc', state: 'CA'},
  {city: 'Long Beach', state: 'CA'},
  {city: 'Los Angeles', state: 'CA'},
  {city: 'Malibu', state: 'CA'},
  {city: 'Martinez', state: 'CA'},
  {city: 'Marysville', state: 'CA'},
  {city: 'Menlo Park', state: 'CA'},
  {city: 'Merced', state: 'CA'},
  {city: 'Modesto', state: 'CA'},
  {city: 'Monterey', state: 'CA'},
  {city: 'Mountain View', state: 'CA'},
  {city: 'Napa', state: 'CA'},
  {city: 'Needles', state: 'CA'},
  {city: 'Newport Beach', state: 'CA'},
  {city: 'Norwalk', state: 'CA'},
  {city: 'Novato', state: 'CA'},
  {city: 'Oakland', state: 'CA'},
  {city: 'Oceanside', state: 'CA'},
  {city: 'Ojai', state: 'CA'},
  {city: 'Ontario', state: 'CA'},
  {city: 'Orange', state: 'CA'},
  {city: 'Oroville', state: 'CA'},
  {city: 'Oxnard', state: 'CA'},
  {city: 'Pacific Grove', state: 'CA'},
  {city: 'Palm Springs', state: 'CA'},
  {city: 'Palmdale', state: 'CA'},
  {city: 'Palo Alto', state: 'CA'},
  {city: 'Pasadena', state: 'CA'},
  {city: 'Petaluma', state: 'CA'},
  {city: 'Pomona', state: 'CA'},
  {city: 'Port Hueneme', state: 'CA'},
  {city: 'Rancho Cucamonga', state: 'CA'},
  {city: 'Red Bluff', state: 'CA'},
  {city: 'Redding', state: 'CA'},
  {city: 'Redlands', state: 'CA'},
  {city: 'Redondo Beach', state: 'CA'},
  {city: 'Redwood City', state: 'CA'},
  {city: 'Richmond', state: 'CA'},
  {city: 'Riverside', state: 'CA'},
  {city: 'Roseville', state: 'CA'},
  {city: 'Sacramento', state: 'CA'},
  {city: 'Salinas', state: 'CA'},
  {city: 'San Bernardino', state: 'CA'},
  {city: 'San Clemente', state: 'CA'},
  {city: 'San Diego', state: 'CA'},
  {city: 'San Fernando', state: 'CA'},
  {city: 'San Francisco', state: 'CA'},
  {city: 'San Gabriel', state: 'CA'},
  {city: 'San Jose', state: 'CA'},
  {city: 'San Juan Capistrano', state: 'CA'},
  {city: 'San Leandro', state: 'CA'},
  {city: 'San Luis Obispo', state: 'CA'},
  {city: 'San Marino', state: 'CA'},
  {city: 'San Mateo', state: 'CA'},
  {city: 'San Pedro', state: 'CA'},
  {city: 'San Rafael', state: 'CA'},
  {city: 'San Simeon', state: 'CA'},
  {city: 'Santa Ana', state: 'CA'},
  {city: 'Santa Barbara', state: 'CA'},
  {city: 'Santa Clara', state: 'CA'},
  {city: 'Santa Clarita', state: 'CA'},
  {city: 'Santa Cruz', state: 'CA'},
  {city: 'Santa Monica', state: 'CA'},
  {city: 'Santa Rosa', state: 'CA'},
  {city: 'Sausalito', state: 'CA'},
  {city: 'Simi Valley', state: 'CA'},
  {city: 'Sonoma', state: 'CA'},
  {city: 'South San Francisco', state: 'CA'},
  {city: 'Stockton', state: 'CA'},
  {city: 'Sunnyvale', state: 'CA'},
  {city: 'Susanville', state: 'CA'},
  {city: 'Thousand Oaks', state: 'CA'},
  {city: 'Torrance', state: 'CA'},
  {city: 'Turlock', state: 'CA'},
  {city: 'Ukiah', state: 'CA'},
  {city: 'Vallejo', state: 'CA'},
  {city: 'Ventura', state: 'CA'},
  {city: 'Victorville', state: 'CA'},
  {city: 'Visalia', state: 'CA'},
  {city: 'Walnut Creek', state: 'CA'},
  {city: 'Watts', state: 'CA'},
  {city: 'West Covina', state: 'CA'},
  {city: 'Whittier', state: 'CA'},
  {city: 'Woodland', state: 'CA'},
  {city: 'Yorba Linda', state: 'CA'},
  {city: 'Yuba City', state: 'CA'},
  {city: 'Alamosa', state: 'CO'},
  {city: 'Aspen', state: 'CO'},
  {city: 'Aurora', state: 'CO'},
  {city: 'Boulder', state: 'CO'},
  {city: 'Breckenridge', state: 'CO'},
  {city: 'Brighton', state: 'CO'},
  {city: 'Canon City', state: 'CO'},
  {city: 'Central City', state: 'CO'},
  {city: 'Climax', state: 'CO'},
  {city: 'CO Springs', state: 'CO'},
  {city: 'Cortez', state: 'CO'},
  {city: 'Cripple Creek', state: 'CO'},
  {city: 'Denver', state: 'CO'},
  {city: 'Durango', state: 'CO'},
  {city: 'Englewood', state: 'CO'},
  {city: 'Estes Park', state: 'CO'},
  {city: 'Fort Collins', state: 'CO'},
  {city: 'Fort Morgan', state: 'CO'},
  {city: 'Georgetown', state: 'CO'},
  {city: 'Glenwood Springs', state: 'CO'},
  {city: 'Golden', state: 'CO'},
  {city: 'Grand Junction', state: 'CO'},
  {city: 'Greeley', state: 'CO'},
  {city: 'Gunnison', state: 'CO'},
  {city: 'La Junta', state: 'CO'},
  {city: 'Leadville', state: 'CO'},
  {city: 'Littleton', state: 'CO'},
  {city: 'Longmont', state: 'CO'},
  {city: 'Loveland', state: 'CO'},
  {city: 'Montrose', state: 'CO'},
  {city: 'Ouray', state: 'CO'},
  {city: 'Pagosa Springs', state: 'CO'},
  {city: 'Parker', state: 'CO'},
  {city: 'Pueblo', state: 'CO'},
  {city: 'Silverton', state: 'CO'},
  {city: 'Steamboat Springs', state: 'CO'},
  {city: 'Sterling', state: 'CO'},
  {city: 'Telluride', state: 'CO'},
  {city: 'Trinidad', state: 'CO'},
  {city: 'Vail', state: 'CO'},
  {city: 'Walsenburg', state: 'CO'},
  {city: 'Westminster', state: 'CO'},
  {city: 'Ansonia', state: 'CT'},
  {city: 'Berlin', state: 'CT'},
  {city: 'Bloomfield', state: 'CT'},
  {city: 'Branford', state: 'CT'},
  {city: 'Bridgeport', state: 'CT'},
  {city: 'Bristol', state: 'CT'},
  {city: 'Coventry', state: 'CT'},
  {city: 'Danbury', state: 'CT'},
  {city: 'Darien', state: 'CT'},
  {city: 'Derby', state: 'CT'},
  {city: 'East Hartford', state: 'CT'},
  {city: 'East Haven', state: 'CT'},
  {city: 'Enfield', state: 'CT'},
  {city: 'Fairfield', state: 'CT'},
  {city: 'Farmington', state: 'CT'},
  {city: 'Greenwich', state: 'CT'},
  {city: 'Groton', state: 'CT'},
  {city: 'Guilford', state: 'CT'},
  {city: 'Hamden', state: 'CT'},
  {city: 'Hartford', state: 'CT'},
  {city: 'Lebanon', state: 'CT'},
  {city: 'Litchfield', state: 'CT'},
  {city: 'Manchester', state: 'CT'},
  {city: 'Mansfield', state: 'CT'},
  {city: 'Meriden', state: 'CT'},
  {city: 'Middletown', state: 'CT'},
  {city: 'Milford', state: 'CT'},
  {city: 'Mystic', state: 'CT'},
  {city: 'Naugatuck', state: 'CT'},
  {city: 'New Britain', state: 'CT'},
  {city: 'New Haven', state: 'CT'},
  {city: 'New London', state: 'CT'},
  {city: 'North Haven', state: 'CT'},
  {city: 'Norwalk', state: 'CT'},
  {city: 'Norwich', state: 'CT'},
  {city: 'Old Saybrook', state: 'CT'},
  {city: 'Orange', state: 'CT'},
  {city: 'Seymour', state: 'CT'},
  {city: 'Shelton', state: 'CT'},
  {city: 'Simsbury', state: 'CT'},
  {city: 'Southington', state: 'CT'},
  {city: 'Stamford', state: 'CT'},
  {city: 'Stonington', state: 'CT'},
  {city: 'Stratford', state: 'CT'},
  {city: 'Torrington', state: 'CT'},
  {city: 'Wallingford', state: 'CT'},
  {city: 'Waterbury', state: 'CT'},
  {city: 'Waterford', state: 'CT'},
  {city: 'Watertown', state: 'CT'},
  {city: 'West Hartford', state: 'CT'},
  {city: 'West Haven', state: 'CT'},
  {city: 'Westport', state: 'CT'},
  {city: 'Wethersfield', state: 'CT'},
  {city: 'Willimantic', state: 'CT'},
  {city: 'Windham', state: 'CT'},
  {city: 'Windsor', state: 'CT'},
  {city: 'Windsor Locks', state: 'CT'},
  {city: 'Winsted', state: 'CT'},
  {city: 'Dover', state: 'DE'},
  {city: 'Lewes', state: 'DE'},
  {city: 'Milford', state: 'DE'},
  {city: 'New Castle', state: 'DE'},
  {city: 'Newark', state: 'DE'},
  {city: 'Smyrna', state: 'DE'},
  {city: 'Wilmington', state: 'DE'},
  {city: 'Apalachicola', state: 'FL'},
  {city: 'Bartow', state: 'FL'},
  {city: 'Belle Glade', state: 'FL'},
  {city: 'Boca Raton', state: 'FL'},
  {city: 'Bradenton', state: 'FL'},
  {city: 'Cape Coral', state: 'FL'},
  {city: 'Clearwater', state: 'FL'},
  {city: 'Cocoa Beach', state: 'FL'},
  {city: 'Cocoa-Rockledge', state: 'FL'},
  {city: 'Coral Gables', state: 'FL'},
  {city: 'Daytona Beach', state: 'FL'},
  {city: 'De Land', state: 'FL'},
  {city: 'Deerfield Beach', state: 'FL'},
  {city: 'Delray Beach', state: 'FL'},
  {city: 'Fernandina Beach', state: 'FL'},
  {city: 'Fort Lauderdale', state: 'FL'},
  {city: 'Fort Myers', state: 'FL'},
  {city: 'Fort Pierce', state: 'FL'},
  {city: 'Fort Walton Beach', state: 'FL'},
  {city: 'Gainesville', state: 'FL'},
  {city: 'Hallandale Beach', state: 'FL'},
  {city: 'Hialeah', state: 'FL'},
  {city: 'Hollywood', state: 'FL'},
  {city: 'Homestead', state: 'FL'},
  {city: 'Jacksonville', state: 'FL'},
  {city: 'Key West', state: 'FL'},
  {city: 'Lake City', state: 'FL'},
  {city: 'Lake Wales', state: 'FL'},
  {city: 'Lakeland', state: 'FL'},
  {city: 'Largo', state: 'FL'},
  {city: 'Melbourne', state: 'FL'},
  {city: 'Miami', state: 'FL'},
  {city: 'Miami Beach', state: 'FL'},
  {city: 'Naples', state: 'FL'},
  {city: 'New Smyrna Beach', state: 'FL'},
  {city: 'Ocala', state: 'FL'},
  {city: 'Orlando', state: 'FL'},
  {city: 'Ormond Beach', state: 'FL'},
  {city: 'Palatka', state: 'FL'},
  {city: 'Palm Bay', state: 'FL'},
  {city: 'Palm Beach', state: 'FL'},
  {city: 'Panama City', state: 'FL'},
  {city: 'Pensacola', state: 'FL'},
  {city: 'Pompano Beach', state: 'FL'},
  {city: 'Saint Augustine', state: 'FL'},
  {city: 'Saint Petersburg', state: 'FL'},
  {city: 'Sanford', state: 'FL'},
  {city: 'Sarasota', state: 'FL'},
  {city: 'Sebring', state: 'FL'},
  {city: 'Tallahassee', state: 'FL'},
  {city: 'Tampa', state: 'FL'},
  {city: 'Tarpon Springs', state: 'FL'},
  {city: 'Titusville', state: 'FL'},
  {city: 'Venice', state: 'FL'},
  {city: 'West Palm Beach', state: 'FL'},
  {city: 'White Springs', state: 'FL'},
  {city: 'Winter Haven', state: 'FL'},
  {city: 'Winter Park', state: 'FL'},
  {city: 'Albany', state: 'GA'},
  {city: 'Americus', state: 'GA'},
  {city: 'Andersonville', state: 'GA'},
  {city: 'Athens', state: 'GA'},
  {city: 'Atlanta', state: 'GA'},
  {city: 'Augusta', state: 'GA'},
  {city: 'Bainbridge', state: 'GA'},
  {city: 'Blairsville', state: 'GA'},
  {city: 'Brunswick', state: 'GA'},
  {city: 'Calhoun', state: 'GA'},
  {city: 'Carrollton', state: 'GA'},
  {city: 'Columbus', state: 'GA'},
  {city: 'Dahlonega', state: 'GA'},
  {city: 'Dalton', state: 'GA'},
  {city: 'Darien', state: 'GA'},
  {city: 'Decatur', state: 'GA'},
  {city: 'Douglas', state: 'GA'},
  {city: 'East Point', state: 'GA'},
  {city: 'Fitzgerald', state: 'GA'},
  {city: 'Fort Valley', state: 'GA'},
  {city: 'Gainesville', state: 'GA'},
  {city: 'La Grange', state: 'GA'},
  {city: 'Lafayette', state: 'GA'},
  {city: 'Macon', state: 'GA'},
  {city: 'Marietta', state: 'GA'},
  {city: 'Milledgeville', state: 'GA'},
  {city: 'Plains', state: 'GA'},
  {city: 'Rome', state: 'GA'},
  {city: 'Savannah', state: 'GA'},
  {city: 'Toccoa', state: 'GA'},
  {city: 'Valdosta', state: 'GA'},
  {city: 'Warm Springs', state: 'GA'},
  {city: 'Warner Robins', state: 'GA'},
  {city: 'WA', state: 'GA'},
  {city: 'Waycross', state: 'GA'},
  {city: 'Hanalei', state: 'HI'},
  {city: 'Hilo', state: 'HI'},
  {city: 'Honaunau', state: 'HI'},
  {city: 'Honolulu', state: 'HI'},
  {city: 'Kahului', state: 'HI'},
  {city: 'Kaneohe', state: 'HI'},
  {city: 'Kapaa', state: 'HI'},
  {city: 'Kawaihae', state: 'HI'},
  {city: 'Lahaina', state: 'HI'},
  {city: 'Laie', state: 'HI'},
  {city: 'Wahiawa', state: 'HI'},
  {city: 'Wailuku', state: 'HI'},
  {city: 'Waimea', state: 'HI'},
  {city: 'Blackfoot', state: 'ID'},
  {city: 'Boise', state: 'ID'},
  {city: 'Bonners Ferry', state: 'ID'},
  {city: 'Caldwell', state: 'ID'},
  {city: "Coeur d'Alene", state: 'ID'},
  {city: 'ID City', state: 'ID'},
  {city: 'ID Falls', state: 'ID'},
  {city: 'Kellogg', state: 'ID'},
  {city: 'Lewiston', state: 'ID'},
  {city: 'Moscow', state: 'ID'},
  {city: 'Nampa', state: 'ID'},
  {city: 'Pocatello', state: 'ID'},
  {city: 'Priest River', state: 'ID'},
  {city: 'Rexburg', state: 'ID'},
  {city: 'Sun Valley', state: 'ID'},
  {city: 'Twin Falls', state: 'ID'},
  {city: 'Alton', state: 'IL'},
  {city: 'Arlington Heights', state: 'IL'},
  {city: 'Arthur', state: 'IL'},
  {city: 'Aurora', state: 'IL'},
  {city: 'Belleville', state: 'IL'},
  {city: 'Belvidere', state: 'IL'},
  {city: 'Bloomington', state: 'IL'},
  {city: 'Brookfield', state: 'IL'},
  {city: 'Cahokia', state: 'IL'},
  {city: 'Cairo', state: 'IL'},
  {city: 'Calumet City', state: 'IL'},
  {city: 'Canton', state: 'IL'},
  {city: 'Carbondale', state: 'IL'},
  {city: 'Carlinville', state: 'IL'},
  {city: 'Carthage', state: 'IL'},
  {city: 'Centralia', state: 'IL'},
  {city: 'Champaign', state: 'IL'},
  {city: 'Charleston', state: 'IL'},
  {city: 'Chester', state: 'IL'},
  {city: 'Chicago', state: 'IL'},
  {city: 'Chicago Heights', state: 'IL'},
  {city: 'Cicero', state: 'IL'},
  {city: 'Collinsville', state: 'IL'},
  {city: 'Danville', state: 'IL'},
  {city: 'Decatur', state: 'IL'},
  {city: 'DeKalb', state: 'IL'},
  {city: 'Des Plaines', state: 'IL'},
  {city: 'Dixon', state: 'IL'},
  {city: 'East Moline', state: 'IL'},
  {city: 'East Saint Louis', state: 'IL'},
  {city: 'Effingham', state: 'IL'},
  {city: 'Elgin', state: 'IL'},
  {city: 'Elmhurst', state: 'IL'},
  {city: 'Evanston', state: 'IL'},
  {city: 'Freeport', state: 'IL'},
  {city: 'Galena', state: 'IL'},
  {city: 'Galesburg', state: 'IL'},
  {city: 'Glen Ellyn', state: 'IL'},
  {city: 'Glenview', state: 'IL'},
  {city: 'Granite City', state: 'IL'},
  {city: 'Harrisburg', state: 'IL'},
  {city: 'Herrin', state: 'IL'},
  {city: 'Highland Park', state: 'IL'},
  {city: 'Jacksonville', state: 'IL'},
  {city: 'Joliet', state: 'IL'},
  {city: 'Kankakee', state: 'IL'},
  {city: 'Kaskaskia', state: 'IL'},
  {city: 'Kewanee', state: 'IL'},
  {city: 'La Salle', state: 'IL'},
  {city: 'Lake Forest', state: 'IL'},
  {city: 'Libertyville', state: 'IL'},
  {city: 'Lincoln', state: 'IL'},
  {city: 'Lisle', state: 'IL'},
  {city: 'Lombard', state: 'IL'},
  {city: 'Macomb', state: 'IL'},
  {city: 'Mattoon', state: 'IL'},
  {city: 'Moline', state: 'IL'},
  {city: 'Monmouth', state: 'IL'},
  {city: 'Mount Vernon', state: 'IL'},
  {city: 'Mundelein', state: 'IL'},
  {city: 'Naperville', state: 'IL'},
  {city: 'Nauvoo', state: 'IL'},
  {city: 'Normal', state: 'IL'},
  {city: 'North Chicago', state: 'IL'},
  {city: 'Oak Park', state: 'IL'},
  {city: 'OR', state: 'IL'},
  {city: 'Ottawa', state: 'IL'},
  {city: 'Palatine', state: 'IL'},
  {city: 'Park Forest', state: 'IL'},
  {city: 'Park Ridge', state: 'IL'},
  {city: 'Pekin', state: 'IL'},
  {city: 'Peoria', state: 'IL'},
  {city: 'Petersburg', state: 'IL'},
  {city: 'Pontiac', state: 'IL'},
  {city: 'Quincy', state: 'IL'},
  {city: 'Rantoul', state: 'IL'},
  {city: 'River Forest', state: 'IL'},
  {city: 'Rock Island', state: 'IL'},
  {city: 'Rockford', state: 'IL'},
  {city: 'Salem', state: 'IL'},
  {city: 'Shawneetown', state: 'IL'},
  {city: 'Skokie', state: 'IL'},
  {city: 'South Holland', state: 'IL'},
  {city: 'Springfield', state: 'IL'},
  {city: 'Streator', state: 'IL'},
  {city: 'Summit', state: 'IL'},
  {city: 'Urbana', state: 'IL'},
  {city: 'Vandalia', state: 'IL'},
  {city: 'Virden', state: 'IL'},
  {city: 'Waukegan', state: 'IL'},
  {city: 'Wheaton', state: 'IL'},
  {city: 'Wilmette', state: 'IL'},
  {city: 'Winnetka', state: 'IL'},
  {city: 'Wood River', state: 'IL'},
  {city: 'Zion', state: 'IL'},
  {city: 'Anderson', state: 'IN'},
  {city: 'Bedford', state: 'IN'},
  {city: 'Bloomington', state: 'IN'},
  {city: 'Columbus', state: 'IN'},
  {city: 'Connersville', state: 'IN'},
  {city: 'Corydon', state: 'IN'},
  {city: 'Crawfordsville', state: 'IN'},
  {city: 'East Chicago', state: 'IN'},
  {city: 'Elkhart', state: 'IN'},
  {city: 'Elwood', state: 'IN'},
  {city: 'Evansville', state: 'IN'},
  {city: 'Fort Wayne', state: 'IN'},
  {city: 'French Lick', state: 'IN'},
  {city: 'Gary', state: 'IN'},
  {city: 'Geneva', state: 'IN'},
  {city: 'Goshen', state: 'IN'},
  {city: 'Greenfield', state: 'IN'},
  {city: 'Hammond', state: 'IN'},
  {city: 'Hobart', state: 'IN'},
  {city: 'Huntington', state: 'IN'},
  {city: 'INpolis', state: 'IN'},
  {city: 'Jeffersonville', state: 'IN'},
  {city: 'Kokomo', state: 'IN'},
  {city: 'Lafayette', state: 'IN'},
  {city: 'Madison', state: 'IN'},
  {city: 'Marion', state: 'IN'},
  {city: 'MI City', state: 'IN'},
  {city: 'Mishawaka', state: 'IN'},
  {city: 'Muncie', state: 'IN'},
  {city: 'Nappanee', state: 'IN'},
  {city: 'Nashville', state: 'IN'},
  {city: 'New Albany', state: 'IN'},
  {city: 'New Castle', state: 'IN'},
  {city: 'New Harmony', state: 'IN'},
  {city: 'Peru', state: 'IN'},
  {city: 'Plymouth', state: 'IN'},
  {city: 'Richmond', state: 'IN'},
  {city: 'Santa Claus', state: 'IN'},
  {city: 'Shelbyville', state: 'IN'},
  {city: 'South Bend', state: 'IN'},
  {city: 'Terre Haute', state: 'IN'},
  {city: 'Valparaiso', state: 'IN'},
  {city: 'Vincennes', state: 'IN'},
  {city: 'Wabash', state: 'IN'},
  {city: 'West Lafayette', state: 'IN'},
  {city: 'Amana Colonies', state: 'IA'},
  {city: 'Ames', state: 'IA'},
  {city: 'Boone', state: 'IA'},
  {city: 'Burlington', state: 'IA'},
  {city: 'Cedar Falls', state: 'IA'},
  {city: 'Cedar Rapids', state: 'IA'},
  {city: 'Charles City', state: 'IA'},
  {city: 'Cherokee', state: 'IA'},
  {city: 'Clinton', state: 'IA'},
  {city: 'Council Bluffs', state: 'IA'},
  {city: 'Davenport', state: 'IA'},
  {city: 'Des Moines', state: 'IA'},
  {city: 'Dubuque', state: 'IA'},
  {city: 'Estherville', state: 'IA'},
  {city: 'Fairfield', state: 'IA'},
  {city: 'Fort Dodge', state: 'IA'},
  {city: 'Grinnell', state: 'IA'},
  {city: 'Indianola', state: 'IA'},
  {city: 'IA City', state: 'IA'},
  {city: 'Keokuk', state: 'IA'},
  {city: 'Mason City', state: 'IA'},
  {city: 'Mount Pleasant', state: 'IA'},
  {city: 'Muscatine', state: 'IA'},
  {city: 'Newton', state: 'IA'},
  {city: 'Oskaloosa', state: 'IA'},
  {city: 'Ottumwa', state: 'IA'},
  {city: 'Sioux City', state: 'IA'},
  {city: 'Waterloo', state: 'IA'},
  {city: 'Webster City', state: 'IA'},
  {city: 'West Des Moines', state: 'IA'},
  {city: 'Abilene', state: 'KS'},
  {city: 'AR City', state: 'KS'},
  {city: 'Atchison', state: 'KS'},
  {city: 'Chanute', state: 'KS'},
  {city: 'Coffeyville', state: 'KS'},
  {city: 'Council Grove', state: 'KS'},
  {city: 'Dodge City', state: 'KS'},
  {city: 'Emporia', state: 'KS'},
  {city: 'Fort Scott', state: 'KS'},
  {city: 'Garden City', state: 'KS'},
  {city: 'Great Bend', state: 'KS'},
  {city: 'Hays', state: 'KS'},
  {city: 'Hutchinson', state: 'KS'},
  {city: 'Independence', state: 'KS'},
  {city: 'Junction City', state: 'KS'},
  {city: 'KS City', state: 'KS'},
  {city: 'Lawrence', state: 'KS'},
  {city: 'Leavenworth', state: 'KS'},
  {city: 'Liberal', state: 'KS'},
  {city: 'Manhattan', state: 'KS'},
  {city: 'McPherson', state: 'KS'},
  {city: 'Medicine Lodge', state: 'KS'},
  {city: 'Newton', state: 'KS'},
  {city: 'Olathe', state: 'KS'},
  {city: 'Osawatomie', state: 'KS'},
  {city: 'Ottawa', state: 'KS'},
  {city: 'Overland Park', state: 'KS'},
  {city: 'Pittsburg', state: 'KS'},
  {city: 'Salina', state: 'KS'},
  {city: 'Shawnee', state: 'KS'},
  {city: 'Smith Center', state: 'KS'},
  {city: 'Topeka', state: 'KS'},
  {city: 'Wichita', state: 'KS'},
  {city: 'Ashland', state: 'KY'},
  {city: 'Barbourville', state: 'KY'},
  {city: 'Bardstown', state: 'KY'},
  {city: 'Berea', state: 'KY'},
  {city: 'Boonesborough', state: 'KY'},
  {city: 'Bowling Green', state: 'KY'},
  {city: 'Campbellsville', state: 'KY'},
  {city: 'Covington', state: 'KY'},
  {city: 'Danville', state: 'KY'},
  {city: 'Elizabethtown', state: 'KY'},
  {city: 'Frankfort', state: 'KY'},
  {city: 'Harlan', state: 'KY'},
  {city: 'Harrodsburg', state: 'KY'},
  {city: 'Hazard', state: 'KY'},
  {city: 'Henderson', state: 'KY'},
  {city: 'Hodgenville', state: 'KY'},
  {city: 'Hopkinsville', state: 'KY'},
  {city: 'Lexington', state: 'KY'},
  {city: 'Louisville', state: 'KY'},
  {city: 'Mayfield', state: 'KY'},
  {city: 'Maysville', state: 'KY'},
  {city: 'Middlesboro', state: 'KY'},
  {city: 'Newport', state: 'KY'},
  {city: 'Owensboro', state: 'KY'},
  {city: 'Paducah', state: 'KY'},
  {city: 'Paris', state: 'KY'},
  {city: 'Richmond', state: 'KY'},
  {city: 'Abbeville', state: 'LA'},
  {city: 'Alexandria', state: 'LA'},
  {city: 'Bastrop', state: 'LA'},
  {city: 'Baton Rouge', state: 'LA'},
  {city: 'Bogalusa', state: 'LA'},
  {city: 'Bossier City', state: 'LA'},
  {city: 'Gretna', state: 'LA'},
  {city: 'Houma', state: 'LA'},
  {city: 'Lafayette', state: 'LA'},
  {city: 'Lake Charles', state: 'LA'},
  {city: 'Monroe', state: 'LA'},
  {city: 'Morgan City', state: 'LA'},
  {city: 'Natchitoches', state: 'LA'},
  {city: 'New Iberia', state: 'LA'},
  {city: 'New Orleans', state: 'LA'},
  {city: 'Opelousas', state: 'LA'},
  {city: 'Ruston', state: 'LA'},
  {city: 'Saint Martinville', state: 'LA'},
  {city: 'Shreveport', state: 'LA'},
  {city: 'Thibodaux', state: 'LA'},
  {city: 'Auburn', state: 'ME'},
  {city: 'Augusta', state: 'ME'},
  {city: 'Bangor', state: 'ME'},
  {city: 'Bar Harbor', state: 'ME'},
  {city: 'Bath', state: 'ME'},
  {city: 'Belfast', state: 'ME'},
  {city: 'Biddeford', state: 'ME'},
  {city: 'Boothbay Harbor', state: 'ME'},
  {city: 'Brunswick', state: 'ME'},
  {city: 'Calais', state: 'ME'},
  {city: 'Caribou', state: 'ME'},
  {city: 'Castine', state: 'ME'},
  {city: 'Eastport', state: 'ME'},
  {city: 'Ellsworth', state: 'ME'},
  {city: 'Farmington', state: 'ME'},
  {city: 'Fort Kent', state: 'ME'},
  {city: 'Gardiner', state: 'ME'},
  {city: 'Houlton', state: 'ME'},
  {city: 'Kennebunkport', state: 'ME'},
  {city: 'Kittery', state: 'ME'},
  {city: 'Lewiston', state: 'ME'},
  {city: 'Lubec', state: 'ME'},
  {city: 'Machias', state: 'ME'},
  {city: 'Orono', state: 'ME'},
  {city: 'Portland', state: 'ME'},
  {city: 'Presque Isle', state: 'ME'},
  {city: 'Rockland', state: 'ME'},
  {city: 'Rumford', state: 'ME'},
  {city: 'Saco', state: 'ME'},
  {city: 'Scarborough', state: 'ME'},
  {city: 'Waterville', state: 'ME'},
  {city: 'York', state: 'ME'},
  {city: 'Aberdeen', state: 'MD'},
  {city: 'Annapolis', state: 'MD'},
  {city: 'Baltimore', state: 'MD'},
  {city: 'Bethesda-Chevy Chase', state: 'MD'},
  {city: 'Bowie', state: 'MD'},
  {city: 'Cambridge', state: 'MD'},
  {city: 'Catonsville', state: 'MD'},
  {city: 'College Park', state: 'MD'},
  {city: 'Columbia', state: 'MD'},
  {city: 'Cumberland', state: 'MD'},
  {city: 'Easton', state: 'MD'},
  {city: 'Elkton', state: 'MD'},
  {city: 'Emmitsburg', state: 'MD'},
  {city: 'Frederick', state: 'MD'},
  {city: 'Greenbelt', state: 'MD'},
  {city: 'Hagerstown', state: 'MD'},
  {city: 'Hyattsville', state: 'MD'},
  {city: 'Laurel', state: 'MD'},
  {city: 'Oakland', state: 'MD'},
  {city: 'Ocean City', state: 'MD'},
  {city: 'Rockville', state: 'MD'},
  {city: 'Saint Marys City', state: 'MD'},
  {city: 'Salisbury', state: 'MD'},
  {city: 'Silver Spring', state: 'MD'},
  {city: 'Takoma Park', state: 'MD'},
  {city: 'Towson', state: 'MD'},
  {city: 'Westminster', state: 'MD'},
  {city: 'Abington', state: 'MA'},
  {city: 'Adams', state: 'MA'},
  {city: 'Amesbury', state: 'MA'},
  {city: 'Amherst', state: 'MA'},
  {city: 'Andover', state: 'MA'},
  {city: 'Arlington', state: 'MA'},
  {city: 'Athol', state: 'MA'},
  {city: 'Attleboro', state: 'MA'},
  {city: 'Barnstable', state: 'MA'},
  {city: 'Bedford', state: 'MA'},
  {city: 'Beverly', state: 'MA'},
  {city: 'Boston', state: 'MA'},
  {city: 'Bourne', state: 'MA'},
  {city: 'Braintree', state: 'MA'},
  {city: 'Brockton', state: 'MA'},
  {city: 'Brookline', state: 'MA'},
  {city: 'Cambridge', state: 'MA'},
  {city: 'Canton', state: 'MA'},
  {city: 'Charlestown', state: 'MA'},
  {city: 'Chelmsford', state: 'MA'},
  {city: 'Chelsea', state: 'MA'},
  {city: 'Chicopee', state: 'MA'},
  {city: 'Clinton', state: 'MA'},
  {city: 'Cohasset', state: 'MA'},
  {city: 'Concord', state: 'MA'},
  {city: 'Danvers', state: 'MA'},
  {city: 'Dartmouth', state: 'MA'},
  {city: 'Dedham', state: 'MA'},
  {city: 'Dennis', state: 'MA'},
  {city: 'Duxbury', state: 'MA'},
  {city: 'Eastham', state: 'MA'},
  {city: 'Edgartown', state: 'MA'},
  {city: 'Everett', state: 'MA'},
  {city: 'Fairhaven', state: 'MA'},
  {city: 'Fall River', state: 'MA'},
  {city: 'Falmouth', state: 'MA'},
  {city: 'Fitchburg', state: 'MA'},
  {city: 'Framingham', state: 'MA'},
  {city: 'Gloucester', state: 'MA'},
  {city: 'Great Barrington', state: 'MA'},
  {city: 'Greenfield', state: 'MA'},
  {city: 'Groton', state: 'MA'},
  {city: 'Harwich', state: 'MA'},
  {city: 'Haverhill', state: 'MA'},
  {city: 'Hingham', state: 'MA'},
  {city: 'Holyoke', state: 'MA'},
  {city: 'Hyannis', state: 'MA'},
  {city: 'Ipswich', state: 'MA'},
  {city: 'Lawrence', state: 'MA'},
  {city: 'Lenox', state: 'MA'},
  {city: 'Leominster', state: 'MA'},
  {city: 'Lexington', state: 'MA'},
  {city: 'Lowell', state: 'MA'},
  {city: 'Ludlow', state: 'MA'},
  {city: 'Lynn', state: 'MA'},
  {city: 'Malden', state: 'MA'},
  {city: 'Marblehead', state: 'MA'},
  {city: 'Marlborough', state: 'MA'},
  {city: 'Medford', state: 'MA'},
  {city: 'Milton', state: 'MA'},
  {city: 'Nahant', state: 'MA'},
  {city: 'Natick', state: 'MA'},
  {city: 'New Bedford', state: 'MA'},
  {city: 'Newburyport', state: 'MA'},
  {city: 'Newton', state: 'MA'},
  {city: 'North Adams', state: 'MA'},
  {city: 'Northampton', state: 'MA'},
  {city: 'Norton', state: 'MA'},
  {city: 'Norwood', state: 'MA'},
  {city: 'Peabody', state: 'MA'},
  {city: 'Pittsfield', state: 'MA'},
  {city: 'Plymouth', state: 'MA'},
  {city: 'Provincetown', state: 'MA'},
  {city: 'Quincy', state: 'MA'},
  {city: 'Randolph', state: 'MA'},
  {city: 'Revere', state: 'MA'},
  {city: 'Salem', state: 'MA'},
  {city: 'Sandwich', state: 'MA'},
  {city: 'Saugus', state: 'MA'},
  {city: 'Somerville', state: 'MA'},
  {city: 'South Hadley', state: 'MA'},
  {city: 'Springfield', state: 'MA'},
  {city: 'Stockbridge', state: 'MA'},
  {city: 'Stoughton', state: 'MA'},
  {city: 'Sturbridge', state: 'MA'},
  {city: 'Sudbury', state: 'MA'},
  {city: 'Taunton', state: 'MA'},
  {city: 'Tewksbury', state: 'MA'},
  {city: 'Truro', state: 'MA'},
  {city: 'Watertown', state: 'MA'},
  {city: 'Webster', state: 'MA'},
  {city: 'Wellesley', state: 'MA'},
  {city: 'Wellfleet', state: 'MA'},
  {city: 'West Bridgewater', state: 'MA'},
  {city: 'West Springfield', state: 'MA'},
  {city: 'Westfield', state: 'MA'},
  {city: 'Weymouth', state: 'MA'},
  {city: 'Whitman', state: 'MA'},
  {city: 'Williamstown', state: 'MA'},
  {city: 'Woburn', state: 'MA'},
  {city: 'Woods Hole', state: 'MA'},
  {city: 'Worcester', state: 'MA'},
  {city: 'Adrian', state: 'MI'},
  {city: 'Alma', state: 'MI'},
  {city: 'Ann Arbor', state: 'MI'},
  {city: 'Battle Creek', state: 'MI'},
  {city: 'Bay City', state: 'MI'},
  {city: 'Benton Harbor', state: 'MI'},
  {city: 'Bloomfield Hills', state: 'MI'},
  {city: 'Cadillac', state: 'MI'},
  {city: 'Charlevoix', state: 'MI'},
  {city: 'Cheboygan', state: 'MI'},
  {city: 'Dearborn', state: 'MI'},
  {city: 'Detroit', state: 'MI'},
  {city: 'East Lansing', state: 'MI'},
  {city: 'Eastpointe', state: 'MI'},
  {city: 'Ecorse', state: 'MI'},
  {city: 'Escanaba', state: 'MI'},
  {city: 'Flint', state: 'MI'},
  {city: 'Grand Haven', state: 'MI'},
  {city: 'Grand Rapids', state: 'MI'},
  {city: 'Grayling', state: 'MI'},
  {city: 'Grosse Pointe', state: 'MI'},
  {city: 'Hancock', state: 'MI'},
  {city: 'Highland Park', state: 'MI'},
  {city: 'Holland', state: 'MI'},
  {city: 'Houghton', state: 'MI'},
  {city: 'Interlochen', state: 'MI'},
  {city: 'Iron Mountain', state: 'MI'},
  {city: 'Ironwood', state: 'MI'},
  {city: 'Ishpeming', state: 'MI'},
  {city: 'Jackson', state: 'MI'},
  {city: 'Kalamazoo', state: 'MI'},
  {city: 'Lansing', state: 'MI'},
  {city: 'Livonia', state: 'MI'},
  {city: 'Ludington', state: 'MI'},
  {city: 'Mackinaw City', state: 'MI'},
  {city: 'Manistee', state: 'MI'},
  {city: 'Marquette', state: 'MI'},
  {city: 'Menominee', state: 'MI'},
  {city: 'Midland', state: 'MI'},
  {city: 'Monroe', state: 'MI'},
  {city: 'Mount Clemens', state: 'MI'},
  {city: 'Mount Pleasant', state: 'MI'},
  {city: 'Muskegon', state: 'MI'},
  {city: 'Niles', state: 'MI'},
  {city: 'Petoskey', state: 'MI'},
  {city: 'Pontiac', state: 'MI'},
  {city: 'Port Huron', state: 'MI'},
  {city: 'Royal Oak', state: 'MI'},
  {city: 'Saginaw', state: 'MI'},
  {city: 'Saint Ignace', state: 'MI'},
  {city: 'Saint Joseph', state: 'MI'},
  {city: 'Sault Sainte Marie', state: 'MI'},
  {city: 'Traverse City', state: 'MI'},
  {city: 'Trenton', state: 'MI'},
  {city: 'Warren', state: 'MI'},
  {city: 'Wyandotte', state: 'MI'},
  {city: 'Ypsilanti', state: 'MI'},
  {city: 'Albert Lea', state: 'MN'},
  {city: 'Alexandria', state: 'MN'},
  {city: 'Austin', state: 'MN'},
  {city: 'Bemidji', state: 'MN'},
  {city: 'Bloomington', state: 'MN'},
  {city: 'Brainerd', state: 'MN'},
  {city: 'Crookston', state: 'MN'},
  {city: 'Duluth', state: 'MN'},
  {city: 'Ely', state: 'MN'},
  {city: 'Eveleth', state: 'MN'},
  {city: 'Faribault', state: 'MN'},
  {city: 'Fergus Falls', state: 'MN'},
  {city: 'Hastings', state: 'MN'},
  {city: 'Hibbing', state: 'MN'},
  {city: 'International Falls', state: 'MN'},
  {city: 'Little Falls', state: 'MN'},
  {city: 'Mankato', state: 'MN'},
  {city: 'Minneapolis', state: 'MN'},
  {city: 'Moorhead', state: 'MN'},
  {city: 'New Ulm', state: 'MN'},
  {city: 'Northfield', state: 'MN'},
  {city: 'Owatonna', state: 'MN'},
  {city: 'Pipestone', state: 'MN'},
  {city: 'Red Wing', state: 'MN'},
  {city: 'Rochester', state: 'MN'},
  {city: 'Saint Cloud', state: 'MN'},
  {city: 'Saint Paul', state: 'MN'},
  {city: 'Sauk Centre', state: 'MN'},
  {city: 'South Saint Paul', state: 'MN'},
  {city: 'Stillwater', state: 'MN'},
  {city: 'VA', state: 'MN'},
  {city: 'Willmar', state: 'MN'},
  {city: 'Winona', state: 'MN'},
  {city: 'Bay Saint Louis', state: 'MS'},
  {city: 'Biloxi', state: 'MS'},
  {city: 'Canton', state: 'MS'},
  {city: 'Clarksdale', state: 'MS'},
  {city: 'Columbia', state: 'MS'},
  {city: 'Columbus', state: 'MS'},
  {city: 'Corinth', state: 'MS'},
  {city: 'Greenville', state: 'MS'},
  {city: 'Greenwood', state: 'MS'},
  {city: 'Grenada', state: 'MS'},
  {city: 'Gulfport', state: 'MS'},
  {city: 'Hattiesburg', state: 'MS'},
  {city: 'Holly Springs', state: 'MS'},
  {city: 'Jackson', state: 'MS'},
  {city: 'Laurel', state: 'MS'},
  {city: 'Meridian', state: 'MS'},
  {city: 'Natchez', state: 'MS'},
  {city: 'Ocean Springs', state: 'MS'},
  {city: 'Oxford', state: 'MS'},
  {city: 'Pascagoula', state: 'MS'},
  {city: 'Pass Christian', state: 'MS'},
  {city: 'Philadelphia', state: 'MS'},
  {city: 'Port Gibson', state: 'MS'},
  {city: 'Starkville', state: 'MS'},
  {city: 'Tupelo', state: 'MS'},
  {city: 'Vicksburg', state: 'MS'},
  {city: 'West Point', state: 'MS'},
  {city: 'Yazoo City', state: 'MS'},
  {city: 'Boonville', state: 'MO'},
  {city: 'Branson', state: 'MO'},
  {city: 'Cape Girardeau', state: 'MO'},
  {city: 'Carthage', state: 'MO'},
  {city: 'Chillicothe', state: 'MO'},
  {city: 'Clayton', state: 'MO'},
  {city: 'Columbia', state: 'MO'},
  {city: 'Excelsior Springs', state: 'MO'},
  {city: 'Ferguson', state: 'MO'},
  {city: 'Florissant', state: 'MO'},
  {city: 'Fulton', state: 'MO'},
  {city: 'Hannibal', state: 'MO'},
  {city: 'Independence', state: 'MO'},
  {city: 'Jefferson City', state: 'MO'},
  {city: 'Joplin', state: 'MO'},
  {city: 'KS City', state: 'MO'},
  {city: 'Kirksville', state: 'MO'},
  {city: 'Lamar', state: 'MO'},
  {city: 'Lebanon', state: 'MO'},
  {city: 'Lexington', state: 'MO'},
  {city: 'Maryville', state: 'MO'},
  {city: 'Mexico', state: 'MO'},
  {city: 'Monett', state: 'MO'},
  {city: 'Neosho', state: 'MO'},
  {city: 'New Madrid', state: 'MO'},
  {city: 'Rolla', state: 'MO'},
  {city: 'Saint Charles', state: 'MO'},
  {city: 'Saint Joseph', state: 'MO'},
  {city: 'Saint Louis', state: 'MO'},
  {city: 'Sainte Genevieve', state: 'MO'},
  {city: 'Salem', state: 'MO'},
  {city: 'Sedalia', state: 'MO'},
  {city: 'Springfield', state: 'MO'},
  {city: 'Warrensburg', state: 'MO'},
  {city: 'West Plains', state: 'MO'},
  {city: 'Anaconda', state: 'MT'},
  {city: 'Billings', state: 'MT'},
  {city: 'Bozeman', state: 'MT'},
  {city: 'Butte', state: 'MT'},
  {city: 'Dillon', state: 'MT'},
  {city: 'Fort Benton', state: 'MT'},
  {city: 'Glendive', state: 'MT'},
  {city: 'Great Falls', state: 'MT'},
  {city: 'Havre', state: 'MT'},
  {city: 'Helena', state: 'MT'},
  {city: 'Kalispell', state: 'MT'},
  {city: 'Lewistown', state: 'MT'},
  {city: 'Livingston', state: 'MT'},
  {city: 'Miles City', state: 'MT'},
  {city: 'Missoula', state: 'MT'},
  {city: 'VA City', state: 'MT'},
  {city: 'Beatrice', state: 'NE'},
  {city: 'Bellevue', state: 'NE'},
  {city: 'Boys Town', state: 'NE'},
  {city: 'Chadron', state: 'NE'},
  {city: 'Columbus', state: 'NE'},
  {city: 'Fremont', state: 'NE'},
  {city: 'Grand Island', state: 'NE'},
  {city: 'Hastings', state: 'NE'},
  {city: 'Kearney', state: 'NE'},
  {city: 'Lincoln', state: 'NE'},
  {city: 'McCook', state: 'NE'},
  {city: 'Minden', state: 'NE'},
  {city: 'NE City', state: 'NE'},
  {city: 'Norfolk', state: 'NE'},
  {city: 'North Platte', state: 'NE'},
  {city: 'Omaha', state: 'NE'},
  {city: 'Plattsmouth', state: 'NE'},
  {city: 'Red Cloud', state: 'NE'},
  {city: 'Sidney', state: 'NE'},
  {city: 'Boulder City', state: 'NV'},
  {city: 'Carson City', state: 'NV'},
  {city: 'Elko', state: 'NV'},
  {city: 'Ely', state: 'NV'},
  {city: 'Fallon', state: 'NV'},
  {city: 'Genoa', state: 'NV'},
  {city: 'Goldfield', state: 'NV'},
  {city: 'Henderson', state: 'NV'},
  {city: 'Las Vegas', state: 'NV'},
  {city: 'North Las Vegas', state: 'NV'},
  {city: 'Reno', state: 'NV'},
  {city: 'Sparks', state: 'NV'},
  {city: 'VA City', state: 'NV'},
  {city: 'Winnemucca', state: 'NV'},
  {city: 'Berlin', state: 'NH'},
  {city: 'Claremont', state: 'NH'},
  {city: 'Concord', state: 'NH'},
  {city: 'Derry', state: 'NH'},
  {city: 'Dover', state: 'NH'},
  {city: 'Durham', state: 'NH'},
  {city: 'Exeter', state: 'NH'},
  {city: 'Franklin', state: 'NH'},
  {city: 'Hanover', state: 'NH'},
  {city: 'Hillsborough', state: 'NH'},
  {city: 'Keene', state: 'NH'},
  {city: 'Laconia', state: 'NH'},
  {city: 'Lebanon', state: 'NH'},
  {city: 'Manchester', state: 'NH'},
  {city: 'Nashua', state: 'NH'},
  {city: 'Peterborough', state: 'NH'},
  {city: 'Plymouth', state: 'NH'},
  {city: 'Portsmouth', state: 'NH'},
  {city: 'Rochester', state: 'NH'},
  {city: 'Salem', state: 'NH'},
  {city: 'Somersworth', state: 'NH'},
  {city: 'Asbury Park', state: 'NJ'},
  {city: 'Atlantic City', state: 'NJ'},
  {city: 'Bayonne', state: 'NJ'},
  {city: 'Bloomfield', state: 'NJ'},
  {city: 'Bordentown', state: 'NJ'},
  {city: 'Bound Brook', state: 'NJ'},
  {city: 'Bridgeton', state: 'NJ'},
  {city: 'Burlington', state: 'NJ'},
  {city: 'Caldwell', state: 'NJ'},
  {city: 'Camden', state: 'NJ'},
  {city: 'Cape May', state: 'NJ'},
  {city: 'Clifton', state: 'NJ'},
  {city: 'Cranford', state: 'NJ'},
  {city: 'East Orange', state: 'NJ'},
  {city: 'Edison', state: 'NJ'},
  {city: 'Elizabeth', state: 'NJ'},
  {city: 'Englewood', state: 'NJ'},
  {city: 'Fort Lee', state: 'NJ'},
  {city: 'Glassboro', state: 'NJ'},
  {city: 'Hackensack', state: 'NJ'},
  {city: 'Haddonfield', state: 'NJ'},
  {city: 'Hoboken', state: 'NJ'},
  {city: 'Irvington', state: 'NJ'},
  {city: 'Jersey City', state: 'NJ'},
  {city: 'Lakehurst', state: 'NJ'},
  {city: 'Lakewood', state: 'NJ'},
  {city: 'Long Beach', state: 'NJ'},
  {city: 'Long Branch', state: 'NJ'},
  {city: 'Madison', state: 'NJ'},
  {city: 'Menlo Park', state: 'NJ'},
  {city: 'Millburn', state: 'NJ'},
  {city: 'Millville', state: 'NJ'},
  {city: 'Montclair', state: 'NJ'},
  {city: 'Morristown', state: 'NJ'},
  {city: 'Mount Holly', state: 'NJ'},
  {city: 'New Brunswick', state: 'NJ'},
  {city: 'New Milford', state: 'NJ'},
  {city: 'Newark', state: 'NJ'},
  {city: 'Ocean City', state: 'NJ'},
  {city: 'Orange', state: 'NJ'},
  {city: 'Parsippany-Troy Hills', state: 'NJ'},
  {city: 'Passaic', state: 'NJ'},
  {city: 'Paterson', state: 'NJ'},
  {city: 'Perth Amboy', state: 'NJ'},
  {city: 'Plainfield', state: 'NJ'},
  {city: 'Princeton', state: 'NJ'},
  {city: 'Ridgewood', state: 'NJ'},
  {city: 'Roselle', state: 'NJ'},
  {city: 'Rutherford', state: 'NJ'},
  {city: 'Salem', state: 'NJ'},
  {city: 'Somerville', state: 'NJ'},
  {city: 'South Orange Village', state: 'NJ'},
  {city: 'Totowa', state: 'NJ'},
  {city: 'Trenton', state: 'NJ'},
  {city: 'Union', state: 'NJ'},
  {city: 'Union City', state: 'NJ'},
  {city: 'Vineland', state: 'NJ'},
  {city: 'Wayne', state: 'NJ'},
  {city: 'Weehawken', state: 'NJ'},
  {city: 'West NY', state: 'NJ'},
  {city: 'West Orange', state: 'NJ'},
  {city: 'Willingboro', state: 'NJ'},
  {city: 'Woodbridge', state: 'NJ'},
  {city: 'Acoma', state: 'NM'},
  {city: 'Espanola', state: 'NM'},
  {city: 'Questa', state: 'NM'},
  {city: 'Red River', state: 'NM'},
  {city: 'Alamogordo', state: 'NM'},
  {city: 'Albuquerque', state: 'NM'},
  {city: 'Artesia', state: 'NM'},
  {city: 'Belen', state: 'NM'},
  {city: 'Carlsbad', state: 'NM'},
  {city: 'Clovis', state: 'NM'},
  {city: 'Deming', state: 'NM'},
  {city: 'Farmington', state: 'NM'},
  {city: 'Gallup', state: 'NM'},
  {city: 'Grants', state: 'NM'},
  {city: 'Hobbs', state: 'NM'},
  {city: 'Las Cruces', state: 'NM'},
  {city: 'Las Vegas', state: 'NM'},
  {city: 'Los Alamos', state: 'NM'},
  {city: 'Lovington', state: 'NM'},
  {city: 'Portales', state: 'NM'},
  {city: 'Raton', state: 'NM'},
  {city: 'Roswell', state: 'NM'},
  {city: 'Santa Fe', state: 'NM'},
  {city: 'Shiprock', state: 'NM'},
  {city: 'Silver City', state: 'NM'},
  {city: 'Socorro', state: 'NM'},
  {city: 'Taos', state: 'NM'},
  {city: 'Truth or Consequences', state: 'NM'},
  {city: 'Tucumcari', state: 'NM'},
  {city: 'Albany', state: 'NY'},
  {city: 'Amsterdam', state: 'NY'},
  {city: 'Auburn', state: 'NY'},
  {city: 'Babylon', state: 'NY'},
  {city: 'Batavia', state: 'NY'},
  {city: 'Beacon', state: 'NY'},
  {city: 'Bedford', state: 'NY'},
  {city: 'Binghamton', state: 'NY'},
  {city: 'Bronx', state: 'NY'},
  {city: 'Brooklyn', state: 'NY'},
  {city: 'Buffalo', state: 'NY'},
  {city: 'Chautauqua', state: 'NY'},
  {city: 'Cheektowaga', state: 'NY'},
  {city: 'Clinton', state: 'NY'},
  {city: 'Cohoes', state: 'NY'},
  {city: 'Coney Island', state: 'NY'},
  {city: 'Cooperstown', state: 'NY'},
  {city: 'Corning', state: 'NY'},
  {city: 'Cortland', state: 'NY'},
  {city: 'Crown Point', state: 'NY'},
  {city: 'Dunkirk', state: 'NY'},
  {city: 'East Aurora', state: 'NY'},
  {city: 'East Hampton', state: 'NY'},
  {city: 'Eastchester', state: 'NY'},
  {city: 'Elmira', state: 'NY'},
  {city: 'Flushing', state: 'NY'},
  {city: 'Forest Hills', state: 'NY'},
  {city: 'Fredonia', state: 'NY'},
  {city: 'Garden City', state: 'NY'},
  {city: 'Geneva', state: 'NY'},
  {city: 'Glens Falls', state: 'NY'},
  {city: 'Gloversville', state: 'NY'},
  {city: 'Great Neck', state: 'NY'},
  {city: 'Hammondsport', state: 'NY'},
  {city: 'Harlem', state: 'NY'},
  {city: 'Hempstead', state: 'NY'},
  {city: 'Herkimer', state: 'NY'},
  {city: 'Hudson', state: 'NY'},
  {city: 'Huntington', state: 'NY'},
  {city: 'Hyde Park', state: 'NY'},
  {city: 'Ilion', state: 'NY'},
  {city: 'Ithaca', state: 'NY'},
  {city: 'Jamestown', state: 'NY'},
  {city: 'Johnstown', state: 'NY'},
  {city: 'Kingston', state: 'NY'},
  {city: 'Lackawanna', state: 'NY'},
  {city: 'Lake Placid', state: 'NY'},
  {city: 'Levittown', state: 'NY'},
  {city: 'Lockport', state: 'NY'},
  {city: 'Mamaroneck', state: 'NY'},
  {city: 'Manhattan', state: 'NY'},
  {city: 'Massena', state: 'NY'},
  {city: 'Middletown', state: 'NY'},
  {city: 'Mineola', state: 'NY'},
  {city: 'Mount Vernon', state: 'NY'},
  {city: 'New Paltz', state: 'NY'},
  {city: 'New Rochelle', state: 'NY'},
  {city: 'New Windsor', state: 'NY'},
  {city: 'NY City', state: 'NY'},
  {city: 'Newburgh', state: 'NY'},
  {city: 'Niagara Falls', state: 'NY'},
  {city: 'North Hempstead', state: 'NY'},
  {city: 'Nyack', state: 'NY'},
  {city: 'Ogdensburg', state: 'NY'},
  {city: 'Olean', state: 'NY'},
  {city: 'Oneida', state: 'NY'},
  {city: 'Oneonta', state: 'NY'},
  {city: 'Ossining', state: 'NY'},
  {city: 'Oswego', state: 'NY'},
  {city: 'Oyster Bay', state: 'NY'},
  {city: 'Palmyra', state: 'NY'},
  {city: 'Peekskill', state: 'NY'},
  {city: 'Plattsburgh', state: 'NY'},
  {city: 'Port WA', state: 'NY'},
  {city: 'Potsdam', state: 'NY'},
  {city: 'Poughkeepsie', state: 'NY'},
  {city: 'Queens', state: 'NY'},
  {city: 'Rensselaer', state: 'NY'},
  {city: 'Rochester', state: 'NY'},
  {city: 'Rome', state: 'NY'},
  {city: 'Rotterdam', state: 'NY'},
  {city: 'Rye', state: 'NY'},
  {city: 'Sag Harbor', state: 'NY'},
  {city: 'Saranac Lake', state: 'NY'},
  {city: 'Saratoga Springs', state: 'NY'},
  {city: 'Scarsdale', state: 'NY'},
  {city: 'Schenectady', state: 'NY'},
  {city: 'Seneca Falls', state: 'NY'},
  {city: 'Southampton', state: 'NY'},
  {city: 'Staten Island', state: 'NY'},
  {city: 'Stony Brook', state: 'NY'},
  {city: 'Stony Point', state: 'NY'},
  {city: 'Syracuse', state: 'NY'},
  {city: 'Tarrytown', state: 'NY'},
  {city: 'Ticonderoga', state: 'NY'},
  {city: 'Tonawanda', state: 'NY'},
  {city: 'Troy', state: 'NY'},
  {city: 'Utica', state: 'NY'},
  {city: 'Watertown', state: 'NY'},
  {city: 'Watervliet', state: 'NY'},
  {city: 'Watkins Glen', state: 'NY'},
  {city: 'West Seneca', state: 'NY'},
  {city: 'White Plains', state: 'NY'},
  {city: 'Woodstock', state: 'NY'},
  {city: 'Yonkers', state: 'NY'},
  {city: 'Asheboro', state: 'NC'},
  {city: 'Asheville', state: 'NC'},
  {city: 'Bath', state: 'NC'},
  {city: 'Beaufort', state: 'NC'},
  {city: 'Boone', state: 'NC'},
  {city: 'Burlington', state: 'NC'},
  {city: 'Chapel Hill', state: 'NC'},
  {city: 'Charlotte', state: 'NC'},
  {city: 'Concord', state: 'NC'},
  {city: 'Durham', state: 'NC'},
  {city: 'Edenton', state: 'NC'},
  {city: 'Elizabeth City', state: 'NC'},
  {city: 'Fayetteville', state: 'NC'},
  {city: 'Gastonia', state: 'NC'},
  {city: 'Goldsboro', state: 'NC'},
  {city: 'Greensboro', state: 'NC'},
  {city: 'Greenville', state: 'NC'},
  {city: 'Halifax', state: 'NC'},
  {city: 'Henderson', state: 'NC'},
  {city: 'Hickory', state: 'NC'},
  {city: 'High Point', state: 'NC'},
  {city: 'Hillsborough', state: 'NC'},
  {city: 'Jacksonville', state: 'NC'},
  {city: 'Kinston', state: 'NC'},
  {city: 'Kitty Hawk', state: 'NC'},
  {city: 'Lumberton', state: 'NC'},
  {city: 'Morehead City', state: 'NC'},
  {city: 'Morganton', state: 'NC'},
  {city: 'Nags Head', state: 'NC'},
  {city: 'New Bern', state: 'NC'},
  {city: 'Pinehurst', state: 'NC'},
  {city: 'Raleigh', state: 'NC'},
  {city: 'Rocky Mount', state: 'NC'},
  {city: 'Salisbury', state: 'NC'},
  {city: 'Shelby', state: 'NC'},
  {city: 'WA', state: 'NC'},
  {city: 'Wilmington', state: 'NC'},
  {city: 'Wilson', state: 'NC'},
  {city: 'Winston-Salem', state: 'NC'},
  {city: 'Bismarck', state: 'ND'},
  {city: 'Devils Lake', state: 'ND'},
  {city: 'Dickinson', state: 'ND'},
  {city: 'Fargo', state: 'ND'},
  {city: 'Grand Forks', state: 'ND'},
  {city: 'Jamestown', state: 'ND'},
  {city: 'Mandan', state: 'ND'},
  {city: 'Minot', state: 'ND'},
  {city: 'Rugby', state: 'ND'},
  {city: 'Valley City', state: 'ND'},
  {city: 'Wahpeton', state: 'ND'},
  {city: 'Williston', state: 'ND'},
  {city: 'Akron', state: 'OH'},
  {city: 'Alliance', state: 'OH'},
  {city: 'Ashtabula', state: 'OH'},
  {city: 'Athens', state: 'OH'},
  {city: 'Barberton', state: 'OH'},
  {city: 'Bedford', state: 'OH'},
  {city: 'Bellefontaine', state: 'OH'},
  {city: 'Bowling Green', state: 'OH'},
  {city: 'Canton', state: 'OH'},
  {city: 'Chillicothe', state: 'OH'},
  {city: 'Cincinnati', state: 'OH'},
  {city: 'Cleveland', state: 'OH'},
  {city: 'Cleveland Heights', state: 'OH'},
  {city: 'Columbus', state: 'OH'},
  {city: 'Conneaut', state: 'OH'},
  {city: 'Cuyahoga Falls', state: 'OH'},
  {city: 'Dayton', state: 'OH'},
  {city: 'Defiance', state: 'OH'},
  {city: 'DE', state: 'OH'},
  {city: 'East Cleveland', state: 'OH'},
  {city: 'East Liverpool', state: 'OH'},
  {city: 'Elyria', state: 'OH'},
  {city: 'Euclid', state: 'OH'},
  {city: 'Findlay', state: 'OH'},
  {city: 'Gallipolis', state: 'OH'},
  {city: 'Greenville', state: 'OH'},
  {city: 'Hamilton', state: 'OH'},
  {city: 'Kent', state: 'OH'},
  {city: 'Kettering', state: 'OH'},
  {city: 'Lakewood', state: 'OH'},
  {city: 'Lancaster', state: 'OH'},
  {city: 'Lima', state: 'OH'},
  {city: 'Lorain', state: 'OH'},
  {city: 'Mansfield', state: 'OH'},
  {city: 'Marietta', state: 'OH'},
  {city: 'Marion', state: 'OH'},
  {city: 'Martins Ferry', state: 'OH'},
  {city: 'Massillon', state: 'OH'},
  {city: 'Mentor', state: 'OH'},
  {city: 'Middletown', state: 'OH'},
  {city: 'Milan', state: 'OH'},
  {city: 'Mount Vernon', state: 'OH'},
  {city: 'New Philadelphia', state: 'OH'},
  {city: 'Newark', state: 'OH'},
  {city: 'Niles', state: 'OH'},
  {city: 'North College Hill', state: 'OH'},
  {city: 'Norwalk', state: 'OH'},
  {city: 'Oberlin', state: 'OH'},
  {city: 'Painesville', state: 'OH'},
  {city: 'Parma', state: 'OH'},
  {city: 'Piqua', state: 'OH'},
  {city: 'Portsmouth', state: 'OH'},
  {city: 'Put-in-Bay', state: 'OH'},
  {city: 'Salem', state: 'OH'},
  {city: 'Sandusky', state: 'OH'},
  {city: 'Shaker Heights', state: 'OH'},
  {city: 'Springfield', state: 'OH'},
  {city: 'Steubenville', state: 'OH'},
  {city: 'Tiffin', state: 'OH'},
  {city: 'Toledo', state: 'OH'},
  {city: 'Urbana', state: 'OH'},
  {city: 'Warren', state: 'OH'},
  {city: 'Wooster', state: 'OH'},
  {city: 'Worthington', state: 'OH'},
  {city: 'Xenia', state: 'OH'},
  {city: 'Yellow Springs', state: 'OH'},
  {city: 'Youngstown', state: 'OH'},
  {city: 'Zanesville', state: 'OH'},
  {city: 'Ada', state: 'OK'},
  {city: 'Altus', state: 'OK'},
  {city: 'Alva', state: 'OK'},
  {city: 'Anadarko', state: 'OK'},
  {city: 'Ardmore', state: 'OK'},
  {city: 'Bartlesville', state: 'OK'},
  {city: 'Bethany', state: 'OK'},
  {city: 'Chickasha', state: 'OK'},
  {city: 'Claremore', state: 'OK'},
  {city: 'Clinton', state: 'OK'},
  {city: 'Cushing', state: 'OK'},
  {city: 'Duncan', state: 'OK'},
  {city: 'Durant', state: 'OK'},
  {city: 'Edmond', state: 'OK'},
  {city: 'El Reno', state: 'OK'},
  {city: 'Elk City', state: 'OK'},
  {city: 'Enid', state: 'OK'},
  {city: 'Eufaula', state: 'OK'},
  {city: 'Frederick', state: 'OK'},
  {city: 'Guthrie', state: 'OK'},
  {city: 'Guymon', state: 'OK'},
  {city: 'Hobart', state: 'OK'},
  {city: 'Holdenville', state: 'OK'},
  {city: 'Hugo', state: 'OK'},
  {city: 'Lawton', state: 'OK'},
  {city: 'McAlester', state: 'OK'},
  {city: 'Miami', state: 'OK'},
  {city: 'Midwest City', state: 'OK'},
  {city: 'Moore', state: 'OK'},
  {city: 'Muskogee', state: 'OK'},
  {city: 'Norman', state: 'OK'},
  {city: 'OK City', state: 'OK'},
  {city: 'Okmulgee', state: 'OK'},
  {city: 'Pauls Valley', state: 'OK'},
  {city: 'Pawhuska', state: 'OK'},
  {city: 'Perry', state: 'OK'},
  {city: 'Ponca City', state: 'OK'},
  {city: 'Pryor', state: 'OK'},
  {city: 'Sallisaw', state: 'OK'},
  {city: 'Sand Springs', state: 'OK'},
  {city: 'Sapulpa', state: 'OK'},
  {city: 'Seminole', state: 'OK'},
  {city: 'Shawnee', state: 'OK'},
  {city: 'Stillwater', state: 'OK'},
  {city: 'Tahlequah', state: 'OK'},
  {city: 'The Village', state: 'OK'},
  {city: 'Tulsa', state: 'OK'},
  {city: 'Vinita', state: 'OK'},
  {city: 'Wewoka', state: 'OK'},
  {city: 'Woodward', state: 'OK'},
  {city: 'Albany', state: 'OR'},
  {city: 'Ashland', state: 'OR'},
  {city: 'Astoria', state: 'OR'},
  {city: 'Baker City', state: 'OR'},
  {city: 'Beaverton', state: 'OR'},
  {city: 'Bend', state: 'OR'},
  {city: 'Brookings', state: 'OR'},
  {city: 'Burns', state: 'OR'},
  {city: 'Coos Bay', state: 'OR'},
  {city: 'Corvallis', state: 'OR'},
  {city: 'Eugene', state: 'OR'},
  {city: 'Grants Pass', state: 'OR'},
  {city: 'Hillsboro', state: 'OR'},
  {city: 'Hood River', state: 'OR'},
  {city: 'Jacksonville', state: 'OR'},
  {city: 'John Day', state: 'OR'},
  {city: 'Klamath Falls', state: 'OR'},
  {city: 'La Grande', state: 'OR'},
  {city: 'Lake Oswego', state: 'OR'},
  {city: 'Lakeview', state: 'OR'},
  {city: 'McMinnville', state: 'OR'},
  {city: 'Medford', state: 'OR'},
  {city: 'Newberg', state: 'OR'},
  {city: 'Newport', state: 'OR'},
  {city: 'Ontario', state: 'OR'},
  {city: 'OR City', state: 'OR'},
  {city: 'Pendleton', state: 'OR'},
  {city: 'Port Orford', state: 'OR'},
  {city: 'Portland', state: 'OR'},
  {city: 'Prineville', state: 'OR'},
  {city: 'Redmond', state: 'OR'},
  {city: 'Reedsport', state: 'OR'},
  {city: 'Roseburg', state: 'OR'},
  {city: 'Salem', state: 'OR'},
  {city: 'Seaside', state: 'OR'},
  {city: 'Springfield', state: 'OR'},
  {city: 'The Dalles', state: 'OR'},
  {city: 'Tillamook', state: 'OR'},
  {city: 'Abington', state: 'PA'},
  {city: 'Aliquippa', state: 'PA'},
  {city: 'Allentown', state: 'PA'},
  {city: 'Altoona', state: 'PA'},
  {city: 'Ambridge', state: 'PA'},
  {city: 'Bedford', state: 'PA'},
  {city: 'Bethlehem', state: 'PA'},
  {city: 'Bloomsburg', state: 'PA'},
  {city: 'Bradford', state: 'PA'},
  {city: 'Bristol', state: 'PA'},
  {city: 'Carbondale', state: 'PA'},
  {city: 'Carlisle', state: 'PA'},
  {city: 'Chambersburg', state: 'PA'},
  {city: 'Chester', state: 'PA'},
  {city: 'Columbia', state: 'PA'},
  {city: 'Easton', state: 'PA'},
  {city: 'Erie', state: 'PA'},
  {city: 'Franklin', state: 'PA'},
  {city: 'Germantown', state: 'PA'},
  {city: 'Gettysburg', state: 'PA'},
  {city: 'Greensburg', state: 'PA'},
  {city: 'Hanover', state: 'PA'},
  {city: 'Harmony', state: 'PA'},
  {city: 'Harrisburg', state: 'PA'},
  {city: 'Hazleton', state: 'PA'},
  {city: 'Hershey', state: 'PA'},
  {city: 'Homestead', state: 'PA'},
  {city: 'Honesdale', state: 'PA'},
  {city: 'IN', state: 'PA'},
  {city: 'Jeannette', state: 'PA'},
  {city: 'Jim Thorpe', state: 'PA'},
  {city: 'Johnstown', state: 'PA'},
  {city: 'Lancaster', state: 'PA'},
  {city: 'Lebanon', state: 'PA'},
  {city: 'Levittown', state: 'PA'},
  {city: 'Lewistown', state: 'PA'},
  {city: 'Lock Haven', state: 'PA'},
  {city: 'Lower Southampton', state: 'PA'},
  {city: 'McKeesport', state: 'PA'},
  {city: 'Meadville', state: 'PA'},
  {city: 'Middletown', state: 'PA'},
  {city: 'Monroeville', state: 'PA'},
  {city: 'Nanticoke', state: 'PA'},
  {city: 'New Castle', state: 'PA'},
  {city: 'New Hope', state: 'PA'},
  {city: 'New Kensington', state: 'PA'},
  {city: 'Norristown', state: 'PA'},
  {city: 'Oil City', state: 'PA'},
  {city: 'Philadelphia', state: 'PA'},
  {city: 'Phoenixville', state: 'PA'},
  {city: 'Pittsburgh', state: 'PA'},
  {city: 'Pottstown', state: 'PA'},
  {city: 'Pottsville', state: 'PA'},
  {city: 'Reading', state: 'PA'},
  {city: 'Scranton', state: 'PA'},
  {city: 'Shamokin', state: 'PA'},
  {city: 'Sharon', state: 'PA'},
  {city: 'State College', state: 'PA'},
  {city: 'Stroudsburg', state: 'PA'},
  {city: 'Sunbury', state: 'PA'},
  {city: 'Swarthmore', state: 'PA'},
  {city: 'Tamaqua', state: 'PA'},
  {city: 'Titusville', state: 'PA'},
  {city: 'Uniontown', state: 'PA'},
  {city: 'Warren', state: 'PA'},
  {city: 'WA', state: 'PA'},
  {city: 'West Chester', state: 'PA'},
  {city: 'Wilkes-Barre', state: 'PA'},
  {city: 'Williamsport', state: 'PA'},
  {city: 'York', state: 'PA'},
  {city: 'Barrington', state: 'RI'},
  {city: 'Bristol', state: 'RI'},
  {city: 'Central Falls', state: 'RI'},
  {city: 'Cranston', state: 'RI'},
  {city: 'East Greenwich', state: 'RI'},
  {city: 'East Providence', state: 'RI'},
  {city: 'Kingston', state: 'RI'},
  {city: 'Middletown', state: 'RI'},
  {city: 'Narragansett', state: 'RI'},
  {city: 'Newport', state: 'RI'},
  {city: 'North Kingstown', state: 'RI'},
  {city: 'Pawtucket', state: 'RI'},
  {city: 'Portsmouth', state: 'RI'},
  {city: 'Providence', state: 'RI'},
  {city: 'South Kingstown', state: 'RI'},
  {city: 'Tiverton', state: 'RI'},
  {city: 'Warren', state: 'RI'},
  {city: 'Warwick', state: 'RI'},
  {city: 'Westerly', state: 'RI'},
  {city: 'Wickford', state: 'RI'},
  {city: 'Woonsocket', state: 'RI'},
  {city: 'Abbeville', state: 'SC'},
  {city: 'Aiken', state: 'SC'},
  {city: 'Anderson', state: 'SC'},
  {city: 'Beaufort', state: 'SC'},
  {city: 'Camden', state: 'SC'},
  {city: 'Charleston', state: 'SC'},
  {city: 'Columbia', state: 'SC'},
  {city: 'Darlington', state: 'SC'},
  {city: 'Florence', state: 'SC'},
  {city: 'Gaffney', state: 'SC'},
  {city: 'Georgetown', state: 'SC'},
  {city: 'Greenville', state: 'SC'},
  {city: 'Greenwood', state: 'SC'},
  {city: 'Hartsville', state: 'SC'},
  {city: 'Lancaster', state: 'SC'},
  {city: 'Mount Pleasant', state: 'SC'},
  {city: 'Myrtle Beach', state: 'SC'},
  {city: 'Orangeburg', state: 'SC'},
  {city: 'Rock Hill', state: 'SC'},
  {city: 'Spartanburg', state: 'SC'},
  {city: 'Sumter', state: 'SC'},
  {city: 'Union', state: 'SC'},
  {city: 'Aberdeen', state: 'SD'},
  {city: 'Belle Fourche', state: 'SD'},
  {city: 'Brookings', state: 'SD'},
  {city: 'Canton', state: 'SD'},
  {city: 'Custer', state: 'SD'},
  {city: 'De Smet', state: 'SD'},
  {city: 'Deadwood', state: 'SD'},
  {city: 'Hot Springs', state: 'SD'},
  {city: 'Huron', state: 'SD'},
  {city: 'Lead', state: 'SD'},
  {city: 'Madison', state: 'SD'},
  {city: 'Milbank', state: 'SD'},
  {city: 'Mitchell', state: 'SD'},
  {city: 'Mobridge', state: 'SD'},
  {city: 'Pierre', state: 'SD'},
  {city: 'Rapid City', state: 'SD'},
  {city: 'Sioux Falls', state: 'SD'},
  {city: 'Spearfish', state: 'SD'},
  {city: 'Sturgis', state: 'SD'},
  {city: 'Vermillion', state: 'SD'},
  {city: 'Watertown', state: 'SD'},
  {city: 'Yankton', state: 'SD'},
  {city: 'Alcoa', state: 'TN'},
  {city: 'Athens', state: 'TN'},
  {city: 'Chattanooga', state: 'TN'},
  {city: 'Clarksville', state: 'TN'},
  {city: 'Cleveland', state: 'TN'},
  {city: 'Columbia', state: 'TN'},
  {city: 'Cookeville', state: 'TN'},
  {city: 'Dayton', state: 'TN'},
  {city: 'Elizabethton', state: 'TN'},
  {city: 'Franklin', state: 'TN'},
  {city: 'Gallatin', state: 'TN'},
  {city: 'Gatlinburg', state: 'TN'},
  {city: 'Greeneville', state: 'TN'},
  {city: 'Jackson', state: 'TN'},
  {city: 'Johnson City', state: 'TN'},
  {city: 'Jonesborough', state: 'TN'},
  {city: 'Kingsport', state: 'TN'},
  {city: 'Knoxville', state: 'TN'},
  {city: 'Lebanon', state: 'TN'},
  {city: 'Maryville', state: 'TN'},
  {city: 'Memphis', state: 'TN'},
  {city: 'Morristown', state: 'TN'},
  {city: 'Murfreesboro', state: 'TN'},
  {city: 'Nashville', state: 'TN'},
  {city: 'Norris', state: 'TN'},
  {city: 'Oak Ridge', state: 'TN'},
  {city: 'Shelbyville', state: 'TN'},
  {city: 'Tullahoma', state: 'TN'},
  {city: 'Abilene', state: 'TX'},
  {city: 'Alpine', state: 'TX'},
  {city: 'Amarillo', state: 'TX'},
  {city: 'Arlington', state: 'TX'},
  {city: 'Austin', state: 'TX'},
  {city: 'Baytown', state: 'TX'},
  {city: 'Beaumont', state: 'TX'},
  {city: 'Big Spring', state: 'TX'},
  {city: 'Borger', state: 'TX'},
  {city: 'Brownsville', state: 'TX'},
  {city: 'Bryan', state: 'TX'},
  {city: 'Canyon', state: 'TX'},
  {city: 'Cleburne', state: 'TX'},
  {city: 'College Station', state: 'TX'},
  {city: 'Corpus Christi', state: 'TX'},
  {city: 'Crystal City', state: 'TX'},
  {city: 'Dallas', state: 'TX'},
  {city: 'Del Rio', state: 'TX'},
  {city: 'Denison', state: 'TX'},
  {city: 'Denton', state: 'TX'},
  {city: 'Eagle Pass', state: 'TX'},
  {city: 'Edinburg', state: 'TX'},
  {city: 'El Paso', state: 'TX'},
  {city: 'Fort Worth', state: 'TX'},
  {city: 'Freeport', state: 'TX'},
  {city: 'Galveston', state: 'TX'},
  {city: 'Garland', state: 'TX'},
  {city: 'Goliad', state: 'TX'},
  {city: 'Greenville', state: 'TX'},
  {city: 'Harlingen', state: 'TX'},
  {city: 'Houston', state: 'TX'},
  {city: 'Huntsville', state: 'TX'},
  {city: 'Irving', state: 'TX'},
  {city: 'Johnson City', state: 'TX'},
  {city: 'Kilgore', state: 'TX'},
  {city: 'Killeen', state: 'TX'},
  {city: 'Kingsville', state: 'TX'},
  {city: 'Laredo', state: 'TX'},
  {city: 'Longview', state: 'TX'},
  {city: 'Lubbock', state: 'TX'},
  {city: 'Lufkin', state: 'TX'},
  {city: 'Marshall', state: 'TX'},
  {city: 'McAllen', state: 'TX'},
  {city: 'McKinney', state: 'TX'},
  {city: 'Mesquite', state: 'TX'},
  {city: 'Midland', state: 'TX'},
  {city: 'Mission', state: 'TX'},
  {city: 'Nacogdoches', state: 'TX'},
  {city: 'New Braunfels', state: 'TX'},
  {city: 'Odessa', state: 'TX'},
  {city: 'Orange', state: 'TX'},
  {city: 'Pampa', state: 'TX'},
  {city: 'Paris', state: 'TX'},
  {city: 'Pasadena', state: 'TX'},
  {city: 'Pecos', state: 'TX'},
  {city: 'Pharr', state: 'TX'},
  {city: 'Plainview', state: 'TX'},
  {city: 'Plano', state: 'TX'},
  {city: 'Port Arthur', state: 'TX'},
  {city: 'Port Lavaca', state: 'TX'},
  {city: 'Richardson', state: 'TX'},
  {city: 'San Angelo', state: 'TX'},
  {city: 'San Antonio', state: 'TX'},
  {city: 'San Felipe', state: 'TX'},
  {city: 'San Marcos', state: 'TX'},
  {city: 'Sherman', state: 'TX'},
  {city: 'Sweetwater', state: 'TX'},
  {city: 'Temple', state: 'TX'},
  {city: 'Texarkana', state: 'TX'},
  {city: 'TX City', state: 'TX'},
  {city: 'Tyler', state: 'TX'},
  {city: 'Uvalde', state: 'TX'},
  {city: 'Victoria', state: 'TX'},
  {city: 'Waco', state: 'TX'},
  {city: 'Weatherford', state: 'TX'},
  {city: 'Wichita Falls', state: 'TX'},
  {city: 'Ysleta', state: 'TX'},
  {city: 'Alta', state: 'UT'},
  {city: 'American Fork', state: 'UT'},
  {city: 'Bountiful', state: 'UT'},
  {city: 'Brigham City', state: 'UT'},
  {city: 'Cedar City', state: 'UT'},
  {city: 'Clearfield', state: 'UT'},
  {city: 'Delta', state: 'UT'},
  {city: 'Fillmore', state: 'UT'},
  {city: 'Green River', state: 'UT'},
  {city: 'Heber City', state: 'UT'},
  {city: 'Kanab', state: 'UT'},
  {city: 'Layton', state: 'UT'},
  {city: 'Lehi', state: 'UT'},
  {city: 'Logan', state: 'UT'},
  {city: 'Manti', state: 'UT'},
  {city: 'Moab', state: 'UT'},
  {city: 'Monticello', state: 'UT'},
  {city: 'Murray', state: 'UT'},
  {city: 'Nephi', state: 'UT'},
  {city: 'Ogden', state: 'UT'},
  {city: 'Orderville', state: 'UT'},
  {city: 'Orem', state: 'UT'},
  {city: 'Panguitch', state: 'UT'},
  {city: 'Park City', state: 'UT'},
  {city: 'Payson', state: 'UT'},
  {city: 'Price', state: 'UT'},
  {city: 'Provo', state: 'UT'},
  {city: 'Saint George', state: 'UT'},
  {city: 'Salt Lake City', state: 'UT'},
  {city: 'Spanish Fork', state: 'UT'},
  {city: 'Springville', state: 'UT'},
  {city: 'Tooele', state: 'UT'},
  {city: 'Vernal', state: 'UT'},
  {city: 'Barre', state: 'VT'},
  {city: 'Bellows Falls', state: 'VT'},
  {city: 'Bennington', state: 'VT'},
  {city: 'Brattleboro', state: 'VT'},
  {city: 'Burlington', state: 'VT'},
  {city: 'Essex', state: 'VT'},
  {city: 'Manchester', state: 'VT'},
  {city: 'Middlebury', state: 'VT'},
  {city: 'Montpelier', state: 'VT'},
  {city: 'Newport', state: 'VT'},
  {city: 'Plymouth', state: 'VT'},
  {city: 'Rutland', state: 'VT'},
  {city: 'Saint Albans', state: 'VT'},
  {city: 'Saint Johnsbury', state: 'VT'},
  {city: 'Sharon', state: 'VT'},
  {city: 'Winooski', state: 'VT'},
  {city: 'Abingdon', state: 'VA'},
  {city: 'Alexandria', state: 'VA'},
  {city: 'Bristol', state: 'VA'},
  {city: 'Charlottesville', state: 'VA'},
  {city: 'Chesapeake', state: 'VA'},
  {city: 'Danville', state: 'VA'},
  {city: 'Fairfax', state: 'VA'},
  {city: 'Falls Church', state: 'VA'},
  {city: 'Fredericksburg', state: 'VA'},
  {city: 'Hampton', state: 'VA'},
  {city: 'Hanover', state: 'VA'},
  {city: 'Hopewell', state: 'VA'},
  {city: 'Lexington', state: 'VA'},
  {city: 'Lynchburg', state: 'VA'},
  {city: 'Manassas', state: 'VA'},
  {city: 'Martinsville', state: 'VA'},
  {city: 'New Market', state: 'VA'},
  {city: 'Newport News', state: 'VA'},
  {city: 'Norfolk', state: 'VA'},
  {city: 'Petersburg', state: 'VA'},
  {city: 'Portsmouth', state: 'VA'},
  {city: 'Reston', state: 'VA'},
  {city: 'Richmond', state: 'VA'},
  {city: 'Roanoke', state: 'VA'},
  {city: 'Staunton', state: 'VA'},
  {city: 'Suffolk', state: 'VA'},
  {city: 'VA Beach', state: 'VA'},
  {city: 'Waynesboro', state: 'VA'},
  {city: 'Williamsburg', state: 'VA'},
  {city: 'Winchester', state: 'VA'},
  {city: 'Aberdeen', state: 'WA'},
  {city: 'Anacortes', state: 'WA'},
  {city: 'Auburn', state: 'WA'},
  {city: 'Bellevue', state: 'WA'},
  {city: 'Bellingham', state: 'WA'},
  {city: 'Bremerton', state: 'WA'},
  {city: 'Centralia', state: 'WA'},
  {city: 'Coulee Dam', state: 'WA'},
  {city: 'Coupeville', state: 'WA'},
  {city: 'Ellensburg', state: 'WA'},
  {city: 'Ephrata', state: 'WA'},
  {city: 'Everett', state: 'WA'},
  {city: 'Hoquiam', state: 'WA'},
  {city: 'Kelso', state: 'WA'},
  {city: 'Kennewick', state: 'WA'},
  {city: 'Longview', state: 'WA'},
  {city: 'Moses Lake', state: 'WA'},
  {city: 'Oak Harbor', state: 'WA'},
  {city: 'Olympia', state: 'WA'},
  {city: 'Pasco', state: 'WA'},
  {city: 'Point Roberts', state: 'WA'},
  {city: 'Port Angeles', state: 'WA'},
  {city: 'Pullman', state: 'WA'},
  {city: 'Puyallup', state: 'WA'},
  {city: 'Redmond', state: 'WA'},
  {city: 'Renton', state: 'WA'},
  {city: 'Richland', state: 'WA'},
  {city: 'Seattle', state: 'WA'},
  {city: 'Spokane', state: 'WA'},
  {city: 'Tacoma', state: 'WA'},
  {city: 'Vancouver', state: 'WA'},
  {city: 'Walla Walla', state: 'WA'},
  {city: 'Wenatchee', state: 'WA'},
  {city: 'Yakima', state: 'WA'},
  {city: 'Bath', state: 'WV'},
  {city: 'Beckley', state: 'WV'},
  {city: 'Bluefield', state: 'WV'},
  {city: 'Buckhannon', state: 'WV'},
  {city: 'Charles Town', state: 'WV'},
  {city: 'Charleston', state: 'WV'},
  {city: 'Clarksburg', state: 'WV'},
  {city: 'Elkins', state: 'WV'},
  {city: 'Fairmont', state: 'WV'},
  {city: 'Grafton', state: 'WV'},
  {city: 'Harpers Ferry', state: 'WV'},
  {city: 'Hillsboro', state: 'WV'},
  {city: 'Hinton', state: 'WV'},
  {city: 'Huntington', state: 'WV'},
  {city: 'Keyser', state: 'WV'},
  {city: 'Lewisburg', state: 'WV'},
  {city: 'Logan', state: 'WV'},
  {city: 'Martinsburg', state: 'WV'},
  {city: 'Morgantown', state: 'WV'},
  {city: 'Moundsville', state: 'WV'},
  {city: 'New Martinsville', state: 'WV'},
  {city: 'Parkersburg', state: 'WV'},
  {city: 'Philippi', state: 'WV'},
  {city: 'Point Pleasant', state: 'WV'},
  {city: 'Princeton', state: 'WV'},
  {city: 'Romney', state: 'WV'},
  {city: 'Shepherdstown', state: 'WV'},
  {city: 'South Charleston', state: 'WV'},
  {city: 'Summersville', state: 'WV'},
  {city: 'Weirton', state: 'WV'},
  {city: 'Welch', state: 'WV'},
  {city: 'Wellsburg', state: 'WV'},
  {city: 'Weston', state: 'WV'},
  {city: 'Wheeling', state: 'WV'},
  {city: 'White Sulphur Springs', state: 'WV'},
  {city: 'Williamson', state: 'WV'},
  {city: 'Appleton', state: 'WI'},
  {city: 'Ashland', state: 'WI'},
  {city: 'Baraboo', state: 'WI'},
  {city: 'Belmont', state: 'WI'},
  {city: 'Beloit', state: 'WI'},
  {city: 'Eau Claire', state: 'WI'},
  {city: 'Fond du Lac', state: 'WI'},
  {city: 'Green Bay', state: 'WI'},
  {city: 'Hayward', state: 'WI'},
  {city: 'Janesville', state: 'WI'},
  {city: 'Kenosha', state: 'WI'},
  {city: 'La Crosse', state: 'WI'},
  {city: 'Lake Geneva', state: 'WI'},
  {city: 'Madison', state: 'WI'},
  {city: 'Manitowoc', state: 'WI'},
  {city: 'Marinette', state: 'WI'},
  {city: 'Menasha', state: 'WI'},
  {city: 'Milwaukee', state: 'WI'},
  {city: 'Neenah', state: 'WI'},
  {city: 'New Glarus', state: 'WI'},
  {city: 'Oconto', state: 'WI'},
  {city: 'Oshkosh', state: 'WI'},
  {city: 'Peshtigo', state: 'WI'},
  {city: 'Portage', state: 'WI'},
  {city: 'Prairie du Chien', state: 'WI'},
  {city: 'Racine', state: 'WI'},
  {city: 'Rhinelander', state: 'WI'},
  {city: 'Ripon', state: 'WI'},
  {city: 'Sheboygan', state: 'WI'},
  {city: 'Spring Green', state: 'WI'},
  {city: 'Stevens Point', state: 'WI'},
  {city: 'Sturgeon Bay', state: 'WI'},
  {city: 'Superior', state: 'WI'},
  {city: 'Waukesha', state: 'WI'},
  {city: 'Wausau', state: 'WI'},
  {city: 'Wauwatosa', state: 'WI'},
  {city: 'West Allis', state: 'WI'},
  {city: 'West Bend', state: 'WI'},
  {city: 'WI Dells', state: 'WI'},
  {city: 'Buffalo', state: 'WY'},
  {city: 'Casper', state: 'WY'},
  {city: 'Cheyenne', state: 'WY'},
  {city: 'Cody', state: 'WY'},
  {city: 'Douglas', state: 'WY'},
  {city: 'Evanston', state: 'WY'},
  {city: 'Gillette', state: 'WY'},
  {city: 'Green River', state: 'WY'},
  {city: 'Jackson', state: 'WY'},
  {city: 'Lander', state: 'WY'},
  {city: 'Laramie', state: 'WY'},
  {city: 'Newcastle', state: 'WY'},
  {city: 'Powell', state: 'WY'},
  {city: 'Rawlins', state: 'WY'},
  {city: 'Riverton', state: 'WY'},
  {city: 'Rock Springs', state: 'WY'},
  {city: 'Sheridan', state: 'WY'},
  {city: 'Ten Sleep', state: 'WY'},
  {city: 'Thermopolis', state: 'WY'},
  {city: 'Torrington', state: 'WY'},
  {city: 'Worland', state: 'WY'},
];

export default usCitiesWithCode;

import {message} from "antd"
import { setReviewList } from "../../actions/CommonActions";
import axios from "axios"
import { base_url } from "../../constants";
export const getUserReviews = async (dispatch,id,type) => {
  const accessToken = JSON.parse(localStorage.getItem('data'));

    try {

      const reviews = await axios.get(`${base_url}reviews/${id}/${type}`, {
        headers: {
          Authorization: `Bearer ${accessToken?.accessToken}`,
        },
      });
      
        dispatch(setReviewList(reviews?.data));
      
    } catch (err) {
      message.error(err);
    }
  };
  
import React from "react";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import STRINGS from "../../utils/UserTypes";
import { GenerateReceiptRequest } from "../../api/contractsApi/generateReceipt";
import { ContractDetailButton } from "./helper";
import { CancelContractButton } from "./ContractDetailsMainButton";
import { reInitializePayment } from "../../api/contractsApi/reinitializePayment";
import { useSelector } from "react-redux";

export const ContractButtons = ({
  userData,
  contractDetail,
  setCancelModal,
  setDisputeModal,
  setModalVisible,
  setAwardToSelfModal,
  reviews,
}) => {
  const navigate = useNavigate();
  const fullUser = useSelector((state) => state?.common?.fullUser);

  const showResendEstimationButton =
    userData.user_type === "contractor" &&
    contractDetail?.status === "estimation_given" &&
    contractDetail?.homeownerId &&
    contractDetail?.contractorId &&
    contractDetail?.send_via;

  const handleResendEstimationOnPress = () => {
    // dispatch(
    //   resendEstimationRequest({
    //     userName: contractDetail?.homeowner?.user?.username || null,
    //     contractId: contractDetail?.id || null,
    //   })
    // );
  };
  const handleGenerateReceipt = async () => {
    await GenerateReceiptRequest(contractDetail?.id);
  };

  const getSubContractsCount = () => {
    const len = contractDetail["lineItem"]?.filter(
      (element) => !element.subContractId
    ).length;
    return len > 0;
  };

  const isDateBefore = (date1, date2) => {
    return date1.getTime() < date2.getTime();
  };

  const cancelFromHomeowner = () => {
    const contractStartDate =
      contractDetail?.dates_record?.[contractDetail?.dates_record?.length - 1]
        .start_date;
    const startDate = new Date(contractStartDate);
    return (
      (["work_request_sent", "receive"].includes(contractDetail?.status) ||
        (["assigned", "send"].includes(contractDetail?.status) &&
          !isDateBefore(startDate, new Date()))) &&
      userData?.user_type === STRINGS.HOMEOWNER_TYPE
    );
  };
  //
  const cancelFromContractor = () => {
    return (
      ["send", "receive"].includes(contractDetail?.status) &&
      userData?.user_type === STRINGS.CONTRACTOR_TYPE &&
      !contractDetail?.homeownerId
    );
  };

  const cancelMode = cancelFromHomeowner() || cancelFromContractor();

  return (
    <div className="mb-5">
      {contractDetail?.status === "pending_review" &&
        userData?.user_type === reviews?.[0]?.user_contracted_type &&
        !["waiting", "blocked"].includes(contractDetail?.primary_status) && (
          <div className="d-flex mt-5">
            <Button
              onClick={() => setDisputeModal(true)}
              className="w-100 fw-bold mx-1"
              style={{
                height: "56px",
                color: "#f77e0b",
                backgroundColor: "#ffffff",
                border: "1px solid #f77e0b",
              }}
            >
              <FormattedMessage id="DISPUTED" />
            </Button>
            <Button
              onClick={() => navigate(`/submit-review`, { state: { page: 1 } })}
              className="w-100 fw-bold mx-1"
              style={{
                height: "56px",
                color: "white",
                backgroundColor: "#f77e0b",
                border: "1px solid #f77e0b",
              }}
            >
              <FormattedMessage id="ACCPET_WORK" />
            </Button>
          </div>
        )}

      {(userData?.user_type === "tradesman" &&
        contractDetail?.status === "running" &&
        ![
          "contractor_changed_requirements_homeowner",
          "contractor_changed_requirements_gigster",
          "gigster_changed_requirements_homeowner",
          "homeowner_accepted_changes",
        ].includes(contractDetail?.secondary_status)) ||
      (userData?.user_type === "contractor" &&
        contractDetail?.status === "submit_to_homeowner" &&
        !["waiting", "blocked"].includes(contractDetail?.primary_status)) ? (
        <div className="mt-5">
          <Button
            onClick={() => setModalVisible(true)}
            className="w-100 fw-bold contractButtonText1"
          >
            <FormattedMessage id="SUB_WORK" />
          </Button>
        </div>
      ) : null}

      {contractDetail?.status === "work_request_sent" &&
      (userData?.user_type === "tradesman" ||
        userData?.user_type === "contractor") ? (
        <div className="mt-5">
          <Button
            onClick={() => {
              if (fullUser?.receivingAccountVerify) {
                navigate(`/create-contract`, {
                  state: {
                    contractDetails: contractDetail,
                    path: "estimation",
                  },
                });
              } else {
                navigate(`/payment-options`);
              }
            }}
            className="w-100 fw-bold contractButtonText1"
          >
            <FormattedMessage id="GIVE_ESTIMATION" />
          </Button>
        </div>
      ) : null}

      {showResendEstimationButton && (
        <div>
          <ContractDetailButton
            title={
              contractDetail?.send_via === "email" ? (
                <FormattedMessage id="RESEND_ESTIMATION_EMAIL" />
              ) : (
                <FormattedMessage id="RESEND_ESTIMATION_SMS" />
              )
            }
            onClick={handleResendEstimationOnPress}
          />
        </div>
      )}

      {cancelMode && (
        <div className="mt-5">
          <CancelContractButton
            contractDetail={contractDetail}
            visible={cancelMode}
            width={"100%"}
            setCancelModal={setCancelModal}
          />
        </div>
      )}

      {!["waiting", "blocked"].includes(contractDetail?.primary_status) &&
        ["assigned", "send", "receive", "running", "pending_review"].includes(
          contractDetail?.status
        ) &&
        getSubContractsCount() &&
        userData?.user_type === "contractor" &&
        contractDetail?.secondary_status !==
          "contractor_changed_requirements_homeowner" && (
          <div className="date-div mb-5">
            <Button
              className="w-100 fw-bold mx-1 acceptContractBtn"
              onClick={() => {
                setAwardToSelfModal(true);
              }}
            >
              <FormattedMessage id="Do It Myself" />
            </Button>
            <Button
              className="w-100 fw-bold mx-1 acceptContractBtn"
              onClick={() => {
                navigate(`/create-contract`, {
                  state: { contractDetails: contractDetail, path: "pricing" },
                });
              }}
            >
              <FormattedMessage id="AWARD_TO_GIGSTER" />
            </Button>
          </div>
        )}

      {userData?.user_type === STRINGS.CONTRACTOR_TYPE &&
      contractDetail?.status === "disputed" ? (
        <div className="mt-5">
          <Button
            onClick={() => navigate(`/submit-review`, { state: { page: 1 } })}
            className="w-100 fw-bold contractButtonText1"
          >
            <FormattedMessage id="APPROVED" />
          </Button>
        </div>
      ) : null}
      {contractDetail?.primary_status === "blocked" ? (
        <div>
          <Button
            className="w-100 fw-bold contractButtonText1"
            onClick={() => {
              const payload = {
                contractId: contractDetail?.id,
              };
              reInitializePayment(payload, navigate);
            }}
          >
            <FormattedMessage id="re-initialize" />
          </Button>
        </div>
      ) : null}

      {!["waiting", "blocked"].includes(contractDetail?.primary_status) &&
        contractDetail?.status === "archived" &&
        ((userData?.user_type === "homeowner" &&
          !contractDetail?.parentContractId) ||
          (userData?.user_type === "contractor" &&
            !contractDetail?.homeownerId)) && (
          <div className="w-100">
            <Button
              className="w-100 fw-bold contractButtonText2"
              onClick={handleGenerateReceipt}
            >
              <FormattedMessage id="GENE_RECIPT" />
            </Button>
          </div>
        )}
    </div>
  );
};

import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { FormattedMessage } from "react-intl";
import { Stories } from "../../components/Stories/Stories";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { bucket_base_url } from "../../constants";
import profileIcon from "../../assets/images/profileIcon.png";
import FeedsCard from "../../components/Feeds/FeedsCard";
import { GetAllPosts } from "../../api/Feeds/GetAllPostRequest";
import { isEmpty } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../components/GeneralComponents";
import { useNavigate } from "react-router";
export const FeedsListing = () => {
  const [refresh, setRefresh] = useState(false);
  const userTypeData = useSelector((state) => state?.common?.newUser?.data);
  const userData = JSON.parse(localStorage.getItem("data"));
  const dispatch = useDispatch();
  const FeedsList = useSelector((state) => state?.feeds?.FeedsListing);
  const feedCount = useSelector((state) => state?.feeds?.feedCount);
  const navigate = useNavigate();
  const AllPostRequest = async () => {
    const body = {
      skip: FeedsList?.length,
      take: 10,
      tradsmanId: userData?.user_type === "tradesman" ? userData?.id : null,
    };
    await GetAllPosts(body, FeedsList, dispatch);
  };

  useEffect(() => {
    AllPostRequest();
  }, []);

  const fetchData = async () => {
    if (FeedsList?.length >= 10) {
      await AllPostRequest();
    }
  };
  return (
    <div className="container paymentscreen">
      <Header />
      <div className="paymentscreen-inner mb-5">
        <Stories />
        <div className="feed-divider"></div>
        {userData?.user_type !== "tradesman" && (
          <div className="d-flex align-items-center justify-content-around shadow  border-rounded">
            <div className="avatar-container">
              <img
                src={
                  userTypeData?.profile_picture === null ||
                  userTypeData?.profile_picture === ""
                    ? `${profileIcon}`
                    : `${bucket_base_url}${userTypeData?.profile_picture}`
                }
                className="avatar-image"
              />
            </div>
            <div
              className="create-post-button"
              onClick={() =>
                navigate("/create-post", {
                  state: {
                    path: "post",
                  },
                })
              }
            >
              <FormattedMessage id="whats_in_your_mind" />
            </div>
          </div>
        )}
        <div className="feed-divider"></div>
        <InfiniteScroll
          dataLength={FeedsList?.length}
          next={fetchData}
          hasMore={feedCount > FeedsList?.length ? true : false}
          scrollThreshold="500px"
          loader={
            <div>
              <Loader />
            </div>
          }
          endMessage={
            <div
              style={{
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "20px",
                color: "grey",
              }}
            >
              <p>No More Post</p>
            </div>
          }
        >
          {!isEmpty(FeedsList) &&
            FeedsList.map((post, index) => {
              return (
                <div key={post?.id} className="mt-5">
                  <FeedsCard
                    ProfilePic={post?.contractor?.profile_picture}
                    TimeAgo={post?.created_at}
                    firstName={post?.contractor?.user?.first_name}
                    lastName={post?.contractor?.user?.last_name}
                    postText={post?.desc}
                    postImage={post?.postFile[0]?.key}
                    flatRate={post?.flat_rate}
                    skill={post?.skill}
                    intestedCount={post?.interestedCount}
                    commentsCount={post?.commentsCount}
                    item={post}
                    index={index}
                    refresh={() => setRefresh(!refresh)}
                  />
                </div>
              );
            })}
        </InfiniteScroll>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from 'react'
import "./style.css"
import { FormattedMessage, injectIntl } from 'react-intl';
import EarningSpendingInfoCard from '../../components/EarningSpendings/EarningSpendingInfoCard';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/Header/Header';
import { TransactionsDetails } from '../../api/EarnSpendApi/TotalAmount';
import TransactionDetailsCard from '../../components/EarningSpendings/TransactionDetailsCard';
import Loader from '../../components/GeneralComponents';
import { formatDate } from '../../utils/Helper';
const EarningSpendingInfo = (props) => {
    const [loading,setLoading] = useState(false);
    const userData=JSON.parse(localStorage.getItem('data'));
    const userTypeData=useSelector((state)=>state?.common?.newUser?.data);
    const transactions=useSelector((state)=>state?.earnSpend?.transactionsDetail);
    const totalAmount =
    userData?.user_type === 'contractor' ||
    userData?.user_type === 'homeowner'
      ? userTypeData?.spending
      : userTypeData?.earnings;
    const dispatch=useDispatch();
useEffect(()=>{
    getTransactions();
},[]);
    const getTransactions=async()=>{
        setLoading(true);
       await TransactionsDetails(dispatch);
       setLoading(false);
    }
  return (
    <>
          {loading &&
        <div className='search-loader'>
          <Loader />
        </div>
      }

    <div className='container paymentscreen mb-5'>
        <Header/>
        <div className='paymentscreen-inner'>
        <EarningSpendingInfoCard
            amount= {totalAmount}
            type={userData?.user_type}
          />
          <div className='d-flex justify-content-center mt-4 fw-bold fs-6'>
            <span> {userData?.current_type === "contractor"
              ? <FormattedMessage id="transaction_details"/>
              : <FormattedMessage id="transactions_completed"/>}
</span>
          </div>
          <div>

          {transactions?.length > 0 ?(
            transactions?.map(item => {
              return (
                <>
                  <span className='d-flex justify-content-center mt-3'>
                    {formatDate(item?.date)}
                  </span>
                  {item?.transactions?.map(atem => {
                     return ( 
                      <TransactionDetailsCard
                        transactionID={atem?.id}
                        amount={atem?.paid_amount}
                        date={atem?.transaction_date}
                        contractID={atem?.contractId}
                        type={userData?.user_type}
                        typeName={
                          atem[userData?.user_type]?.first_name +
                          ' ' +
                          atem[userData?.user_type]?.last_name
                        }
                      />
                     );
                   })} 
                </>
              );
            })
          )
           : (
            <div className='d-flex justify-content-center mt-5'>
            <span  >
              <FormattedMessage id="no_transactions"/>
            </span>
            </div>
          )}
 
          </div>

        </div>
    </div>
    </>
  )
}

export default injectIntl(EarningSpendingInfo);

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bucket_base_url } from "../../constants";
import profileIcon from "../../assets/images/profileIcon.png";
import "./style.css";
import {
  setChatConversation,
  setChatDetailMobile,
} from "../../actions/ChatActions";
import BackArrow from "../BackArrow";
import { useNavigate } from "react-router";

export const ChatHeader = ({ rightIcon }) => {
  const { isMobile, conversation } = useSelector((state) => state?.chat);
  const dispatch = useDispatch();
  let ChatUser = conversation?.opponentData?.oponent;
  const navigate = useNavigate();
  const userType =
    conversation?.opponentData?.oponentType === "Gigster" ||
    conversation?.opponentData?.oponentType === "tradesman"
      ? "tradesman"
      : conversation?.opponentData?.oponentType === "Contractor" ||
        conversation?.opponentData?.oponentType === "contractor"
      ? "contractor"
      : conversation?.opponentData?.oponentType === "Homeowner"
      ? "homeowner"
      : null;
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      className="chat-body-header border shadow"
    >
      {isMobile && (
        <div
          onClick={() =>
            dispatch(
              setChatDetailMobile(false),
              dispatch(setChatConversation({}))
            )
          }
        >
          <BackArrow />
        </div>
      )}

      <div
        onClick={() =>
          navigate(
            `/trads-profile?user-id=${ChatUser?.userId}&id=${ChatUser?.id}&type=${userType}`
          )
        }
        className="d-flex align-items-center"
      >
        <div className="avatar-container m-2">
          <img
            src={
              ChatUser?.profile_picture === null ||
              ChatUser?.profile_picture === ""
                ? `${profileIcon}`
                : `${bucket_base_url}${ChatUser?.profile_picture}`
            }
            className="avatar-image"
          />
        </div>
        <div className="title-name">
          <span>{ChatUser?.first_name + " " + ChatUser?.last_name}</span>
          <div
            className="type-tag mt-1"
            style={{
              backgroundColor:
                conversation?.opponentData?.oponentType === "Gigster" ||
                conversation?.opponentData?.oponentType === "tradesman"
                  ? "rgb(138, 187, 42)"
                  : conversation?.opponentData?.oponentType === "Homeowner"
                  ? "#5E7AA7"
                  : "#F77E0B",
              marginLeft: 0,
            }}
          >
            {conversation?.opponentData?.oponentType}
          </div>
        </div>
      </div>
      {rightIcon}
    </div>
  );
};

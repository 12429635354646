import axios from "axios";
import firebaseConfig from "./Firebase";
import { dynamic_Link_key } from "../constants";

firebase.initializeApp(firebaseConfig);

const generateDynamicLink = async (id, type) => {
  const link = `https://gigmiapp.com/`;

  const domainPrefix = `https://gigmi.page.link`;
  const androidPackageName = `com.gigmi`;
  const iOSBundleId = `com.gigmi`;
  const headers = {
    "Content-Type": "application/json",
  };

  const body = {
    dynamicLinkInfo: {
      domainUriPrefix: domainPrefix,
      link: link,
      androidInfo: {
        androidPackageName: androidPackageName,
      },
      iosInfo: {
        iosBundleId: iOSBundleId,
      },
    },
  };

  try {
    const response = await axios.post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${dynamic_Link_key}`,
      body,
      {
        headers: headers,
      }
    );
    return response?.data?.shortLink;
  } catch (error) {}
};

export default generateDynamicLink;

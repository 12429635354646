import React from 'react';
import { FormattedMessage } from 'react-intl';

const InformationListItem = ( props) => {
  return (
    <>
      <li>
        <span
          style={{
            fontFamily: "Poppins",
            fontWeight: "normal",
            fontSize: "medium",
          }}
        >
          <FormattedMessage
            {...props}
          />
        </span>
    </li>
    <p></p>
    </>
  
  );
};

export default InformationListItem;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import FeedsCard from "../../components/Feeds/FeedsCard";
import Header from "../../components/Header/Header";
import {
  commentPost,
  getPostComments,
  getPostLikes,
} from "../../api/Feeds/comment";
import { useDispatch, useSelector } from "react-redux";
import { Button, message } from "antd";
import { FormattedMessage } from "react-intl";
import { BackButton } from "../../components/General/BackButton";

export const PostDetails = () => {
  const { state } = useLocation();
  const { data, index } = state;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [comment, setComment] = useState("");
  const { commentListing, likeListing } = useSelector((state) => state?.feeds);
  const userData = JSON.parse(localStorage.getItem("data"));
  const userTypeData = useSelector((state) => state?.common?.newUser?.data);
  const commentCount = commentListing?.length;
  useEffect(() => {
    getPostComments(data?.id, dispatch);
    getPostLikes(data?.id, dispatch);
  }, []);
  const handleCommentPress = async () => {
    if (comment === "") {
      message.error(`Enter comment before submit`);
      return;
    }
    const postId = data?.id;
    const body = {
      postId,
      comment,
      contractorId:
        userData?.user_type === "tradesman" ? null : userTypeData?.id,
      tradesmanId:
        userData?.user_type === "tradesman" ? userTypeData?.id : null,
      index,
    };
    await commentPost(body, commentListing, dispatch);
    setComment("");
  };

  return (
    <div className="container">
      <Header />
      <div className="paymentscreen-inner">
        <div className="d-flex justify-content-start">
          {" "}
          <BackButton
            position="relative"
            top="0px"
            left="0px"
            navigateTo={-1}
          />
        </div>
        <FeedsCard
          ProfilePic={data?.contractor?.profile_picture}
          TimeAgo={data?.created_at}
          firstName={data?.contractor?.user?.first_name}
          lastName={data?.contractor?.user?.last_name}
          postText={data?.desc}
          postImage={data?.postFile[0]?.key}
          flatRate={data?.flat_rate}
          skill={data?.skill}
          intestedCount={data?.interestedCount}
          commentsCount={data?.commentsCount}
          item={data}
          index={index}
          detail={true}
          comments={commentListing}
          likes={likeListing}
          setComment={setComment}
          handleCommentPress={handleCommentPress}
          commentdetailsCount={commentCount}
          commentValue={comment}
        />
        {data?.contractor?.id === userData?.id &&
          userData?.user_type === "contractor" && (
            <Button
              className="w-100 fw-bold mx-1 mt-4"
              style={{
                color: "white",
                border: "2px solid #F77e0b",
                backgroundColor: "#f77e0b",
                height: "56px",
                width: "100px",
                marginBottom: "50px",
              }}
              onClick={() =>
                navigate("/send-offer", {
                  state: {
                    data: data,
                    likeListing: likeListing,
                  },
                })
              }
            >
              {<FormattedMessage id="send_offer" />}
            </Button>
          )}
      </div>
    </div>
  );
};

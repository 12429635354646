import * as Actions from "../actions/ActionTypes";
const W9Reducer = (
  state = {
    formData: {},
  },
  action
) => {
  if (action.type == Actions.SET_W9_FORM_DATA) {
    return Object.assign({}, state, {
      formData: action?.data,
    });
  } else {
    return state;
  }
};

export default W9Reducer;

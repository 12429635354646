import React from "react";
import "./style.css";
import { FormattedMessage, injectIntl } from "react-intl";
const BankAccountCard = ({ item, setConfirmModal, setSelectedId }) => {
  const handleClick = () => {
    if (item?.isSelected === "1") {
      return;
    } else {
      setSelectedId(item?.bankAccountId);
      setConfirmModal(true);
    }
  };

  return (
    <div
      className="bank-account-card"
      style={
        item?.isSelected === "1"
          ? { border: "2px solid #f77e0b" }
          : { border: "2px solid black" }
      }
      onClick={handleClick}
    >
      <div className="d-flex justify-content-between">
        <span>
          <FormattedMessage id="Name" />
        </span>
        <span>{item?.name}</span>
      </div>
      <div className="d-flex justify-content-between">
        <span>
          <FormattedMessage id="status" />
        </span>
        <span>{item?.status}</span>
      </div>
      <div className="d-flex justify-content-between">
        <span>
          <FormattedMessage id="bank_name" />
        </span>
        <span>{item?.bankName}</span>
      </div>
      <div className="d-flex justify-content-between">
        <span>
          <FormattedMessage id="account_number" />
        </span>
        <span>************{item?.accountLast4}</span>
      </div>
    </div>
  );
};
export default injectIntl(BankAccountCard);

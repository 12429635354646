import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Header from "../../components/Header/Header";
import { Button, Form, Input, Select } from "antd";
import { useSelector } from "react-redux";
import { PaymentScreenThreeApi } from "../../api/bankApi/PaymentScreenThree";
import Loader from "../../components/GeneralComponents";
import { BackButton } from "../../components/General/BackButton";

const paymentScreenThree = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const handleSubmit = async (values) => {
    const userData = JSON.parse(localStorage.getItem("data"));
    const body = {
      account_number: values?.accountNumber,
      routing_number: values?.routingNumber,
      name: values?.accountName,
      account_type: values?.accountType,
      userId: userData?.user_id,
    };
    console.log("body", values);
    return;
    setLoading(true);
    await PaymentScreenThreeApi(body);
    setLoading(false);
  };
  const PaymentAccountType = [
    { label: "Checking", value: "checking" },
    { label: "Savings", value: "savings" },
  ];
  return (
    <>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}
      <BackButton />
      <div className="container paymentscreen">
        <Header />
        <div className="paymentscreen-inner">
          <div className="d-flex justify-content-center align-items-center getting-payment-heading shadow border">
            <span className="text-center">
              <FormattedMessage id="add_bank_info" />
            </span>
          </div>
          <Form
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            initialValues={{
              accountName: "",
              accountNumber: "",
              confirmAccountNumber: "",
              routingNumber: "",
              confirmRoutingNumber: "",
              accountType: "",
            }}
          >
            <Form.Item
              name="bankName"
              label={props.intl.formatMessage({ id: "bank_name" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
              ]}
            >
              <Input
                placeholder={props.intl.formatMessage({ id: "bank_name" })}
                className="input-field"
              />
            </Form.Item>
            <Form.Item
              name="accountName"
              label={props.intl.formatMessage({ id: "account_name" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
              ]}
            >
              <Input
                placeholder={props.intl.formatMessage({ id: "account_name" })}
                className="input-field"
              />
            </Form.Item>

            <Form.Item
              name="accountType"
              label={props.intl.formatMessage({ id: "account_type" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
              ]}
            >
              <Select
                placeholder={props.intl.formatMessage({ id: "account_type" })}
                options={PaymentAccountType}
              />
            </Form.Item>

            <Form.Item
              name="accountNumber"
              label={props.intl.formatMessage({ id: "account_number" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
                {
                  pattern: new RegExp(/^[0-9]*(?:\.[0-9]*)?$/),
                  message: `${props.intl.formatMessage({
                    id: "only_numeric",
                  })}`,
                },
              ]}
            >
              <Input
                placeholder={props.intl.formatMessage({ id: "account_number" })}
                className="input-field"
              />
            </Form.Item>

            <Form.Item
              name="confirmAccountNumber"
              label={props.intl.formatMessage({ id: "confirm_account_number" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
                {
                  pattern: new RegExp(/^[0-9]*(?:\.[0-9]*)?$/),
                  message: `${props.intl.formatMessage({
                    id: "only_numeric",
                  })}`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("accountNumber") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      `${props.intl.formatMessage({
                        id: "account_do_not_match",
                      })}`
                    );
                  },
                }),
              ]}
            >
              <Input
                className="input-field"
                placeholder={props.intl.formatMessage({
                  id: "confirm_account_number",
                })}
              />
            </Form.Item>
            <Form.Item
              name="routingNumber"
              label={props.intl.formatMessage({ id: "routing_number" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
                {
                  pattern: new RegExp(/^[0-9]*(?:\.[0-9]*)?$/),
                  message: `${props.intl.formatMessage({
                    id: "only_numeric",
                  })}`,
                },
              ]}
            >
              <Input
                placeholder={props.intl.formatMessage({ id: "routing_number" })}
                maxLength={9}
                className="input-field"
              />
            </Form.Item>
            <Form.Item
              name="confirmRoutingNumber"
              label={props.intl.formatMessage({ id: "confirm_routing_number" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
                {
                  pattern: new RegExp(/^[0-9]*(?:\.[0-9]*)?$/),
                  message: `${props.intl.formatMessage({
                    id: "only_numeric",
                  })}`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("routingNumber") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      `${props.intl.formatMessage({
                        id: "routing_number_do_not_match",
                      })}`
                    );
                  },
                }),
              ]}
            >
              <Input
                className="input-field"
                maxLength={9}
                placeholder={props.intl.formatMessage({
                  id: "confirm_routing_number",
                })}
              />
            </Form.Item>

            <Form.Item
              name="taxPayer"
              label={props.intl.formatMessage({ id: "tax_payer" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
                {
                  pattern: new RegExp(/^[0-9]*(?:\.[0-9]*)?$/),
                  message: `${props.intl.formatMessage({
                    id: "only_numeric",
                  })}`,
                },
              ]}
            >
              <Input
                placeholder={props.intl.formatMessage({ id: "tax_payer" })}
                maxLength={9}
                className="input-field"
              />
            </Form.Item>

            <div className="d-flex justify-content-end">
              <Form.Item>
                <Button
                  type="submit"
                  htmlType="submit"
                  className="submit-button"
                >
                  <FormattedMessage id="submit" />
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
export default injectIntl(paymentScreenThree);

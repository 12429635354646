import { Divider, Modal } from "antd";
import React from "react";
import AppLogo from "../../assets/images/AppLogo.png";
import { FormattedMessage } from "react-intl";

const ContractAmountDetailsModal = ({
  visible,
  title,
  contractId,
  gigmiCharges,
  flatAmount,
  cancelAction,
  confirmAction,
  cancelText,
  confirmText,
}) => {
  return (
    <Modal open={visible} footer={null} onCancel={cancelAction}>
      <div className="d-flex flex-column" style={{ fontFamily: "Poppins" }}>
        <div className="w-100 d-flex justify-content-center mt-2">
          <img src={AppLogo} />
        </div>
        <span
          className="d-flex justify-content-center mt-3 mb-3 fs-5 fw-bold "
          style={{ color: "#f77e0b" }}
        >
          {title}
        </span>
        <div>
          {contractId && (
            <div className="d-flex justify-content-between mt-2">
              <span>
                <FormattedMessage id="CONTRACT_NO" />
              </span>
              <span>{contractId}</span>
            </div>
          )}
          <div className="d-flex justify-content-between mt-2">
            <span>
              <FormattedMessage id="CONTRACT_AMOUNT" />
            </span>
            <span>$ {flatAmount}</span>
          </div>
          {gigmiCharges ? (
            <div className="d-flex justify-content-between mt-2">
              <span>
                <FormattedMessage id="TRANSACTION_FEE" />
              </span>
              <span>$ {gigmiCharges ? gigmiCharges : 0}</span>
            </div>
          ) : null}
          <div className="d-flex justify-content-between mt-2">
            <span className="fw-bold">
              <FormattedMessage id="TOT_AMOUNT" />
            </span>
            <span className="fw-bold">
              ${" "}
              {(parseFloat(gigmiCharges ?? 0) + parseFloat(flatAmount)).toFixed(
                2
              )}
            </span>
          </div>
          <div className="d-flex justify-content-center mt-5 w-100 fw-bold">
            <span>
              ${" "}
              {(parseFloat(gigmiCharges ?? 0) + parseFloat(flatAmount)).toFixed(
                2
              )}
            </span>
          </div>
        </div>
        <Divider />
        {(cancelText || confirmText) && <div />}
        <div className="d-flex justify-content-around fw-bold">
          {cancelText ? (
            <>
              <div
                onClick={cancelAction}
                style={{ cursor: "pointer", color: "#f77e0b" }}
              >
                <span>{cancelText}</span>
              </div>
            </>
          ) : null}
          {confirmText ? (
            <div
              onClick={confirmAction}
              style={{ cursor: "pointer", color: "#f77e0b" }}
            >
              <span>{confirmText}</span>
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default ContractAmountDetailsModal;

import { message } from "antd";
import { Axios } from "../../utils/axiosMiddleware";
import { GetContractDetails } from "./contractDetails";
import { SimpleCatchError } from "../../utils/Helper";

export const CheckInCheckOutRequest = async (finalData, msg, dispatch) => {
  try {
    if (msg === "checkIn") {
      await Axios.post("contract-logs", finalData);
      await GetContractDetails(finalData?.contract_id, dispatch);
      message.success("Check In Successfully");
    } else if (msg === "checkOut") {
      await Axios.patch("contract-logs", finalData);
      await GetContractDetails(finalData?.contract_id, dispatch);
      await message.success("Checkout Successfully");
    }
  } catch (e) {
    SimpleCatchError(e);
  }
};

import React from "react";
import { GenerateInvoice } from "./helper";
import { ContractEditButton } from "./ContractEditButton";
import { useNavigate } from "react-router";

export const RightIconGenerator = ({ contractDetail, userData }) => {
  const navigate = useNavigate();

  const isHomeownerWorkRequestSent =
    userData?.user_type === "homeowner" &&
    contractDetail?.status === "work_request_sent";

  const isContractorOrTradesmanOnEstimationGiven =
    (userData?.user_type === "contractor" ||
      userData?.user_type === "tradesman") &&
    contractDetail?.status === "estimation_given";

  const isContractAssignedOrRunning =
    ["assigned", "send", "receive", "running"].includes(
      contractDetail?.status
    ) &&
    userData?.user_type === "contractor" &&
    contractDetail?.homeownerId &&
    contractDetail?.lineItem?.filter((element) => element?.isChanged).length ===
      0;

  const isContractSendOrRunning =
    userData?.user_type === "contractor" &&
    contractDetail?.tradesmanId &&
    contractDetail?.parentContractId &&
    ["send", "receive", "running"].includes(contractDetail?.status) &&
    (contractDetail?.lineItemsSubcontract?.filter(
      (element) => element?.isChanged
    ).length > 0 ||
      contractDetail?.status === "send");

  const isTradesmanRunningOrReceiveWithoutContractor =
    userData?.user_type === "tradesman" &&
    ["receive", "running"].includes(contractDetail?.status) &&
    !contractDetail?.contractorId;

  if (
    !contractDetail?.secondary_status &&
    (isHomeownerWorkRequestSent ||
      isContractorOrTradesmanOnEstimationGiven ||
      isContractAssignedOrRunning ||
      isContractSendOrRunning ||
      isTradesmanRunningOrReceiveWithoutContractor)
  ) {
    return (
      <ContractEditButton
        contractDetails={contractDetail}
        userData={userData}
        navigate={navigate}
      />
    );
  } else if (
    contractDetail?.status === "archived" ||
    contractDetail?.status === "pending_review"
  ) {
    return GenerateInvoice(contractDetail, userData);
  } else {
    return <></>;
  }
};

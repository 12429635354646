import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import BankAccountCard from "../../components/BankInfo/BankAccountCard";
import {
  deActivateAccount,
  getAccountList,
  getSdkOtp,
} from "../../api/bankApi/BankAccountList";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header/Header";
import { isEmpty } from "lodash";
import "./BankInfo.css";
import Loader from "../../components/GeneralComponents";
import edit from "../../assets/images/Edit.png";
import { BackButton } from "../../components/General/BackButton";
import { useNavigate } from "react-router";
import { Modal } from "antd";
import { openWidget } from "aerosync-web-sdk";
import { aeroPayAccountSuccess } from "../../api/bankApi/AddBankAccount";

const BankAccountList = (props) => {
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const userTypeData = useSelector((state) => state?.common?.fullUser);
  const bankAccountList = useSelector(
    (state) => state?.bankInfo?.bankAccountList
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      if (userTypeData?.id) {
        const list = await getAccountList(userTypeData?.id, dispatch);
        if (list?.length === 0) {
          const token = await getSdkOtp(userTypeData?.id);
          if (token) {
            openAerosyncWidget(token);
          }
        }
      }
      setLoading(false);
    };
    getData();
  }, [userTypeData?.id]);

  const handleEditAccount = async () => {
    setLoading(true);
    const token = await getSdkOtp(userTypeData?.id);
    if (token) {
      openAerosyncWidget(token);
    }
    setLoading(false);
  };

  const openAerosyncWidget = (accessToken) => {
    let token = accessToken;
    let deeplink = "https://gigmi.page.link/d6o5"; // Unique URL that points to the specific page within the mobile app
    let consumerId = userTypeData?.id; // Unique ID of the consumer
    let widgetRef = openWidget({
      id: "widget",
      iframeTitle: "Connect",
      environment: "staging", // staging, production
      token: token,
      style: {
        width: "375px",
        height: "688px",
        bgColor: "#000000",
        opacity: 0.7,
      },
      deeplink: deeplink,
      consumerId: consumerId,
      onEvent: function () {},
      onLoad: function () {},
      onError: function () {},
      onSuccess: function (event) {
        aeroPayAccountSuccess({
          userId: userTypeData?.id,
          user_id: event.user_id,
          user_password: event.user_password,
        });
      },
      onClose: function () {
        navigate("/");
      },
    });
    widgetRef.launch();
  };

  const confirmAction = async () => {
    setConfirmModal(false);
    setLoading(true);
    const body = {
      bankAccountId: selectedId,
    };
    await deActivateAccount(userTypeData?.id, body);
    setLoading(false);
    navigate("/");
  };

  return (
    <>
      <div id="widget"></div>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}
      <BackButton />
      <div className="container paymentscreen">
        <Header />
        <div className="paymentscreen-inner">
          <div className="d-flex">
            <div className="banklist-heading d-flex justify-content-end">
              <FormattedMessage id="bank_accounts" />
            </div>
            <div className="add-button d-flex justify-content-end mr-1 cursor-pointer">
              <img src={edit} onClick={handleEditAccount} />
            </div>
          </div>
          {!isEmpty(bankAccountList) &&
            bankAccountList.map((item) => {
              console.log(item);
              return (
                <BankAccountCard
                  key={item?.id}
                  item={item}
                  setConfirmModal={setConfirmModal}
                  setSelectedId={setSelectedId}
                />
              );
            })}
        </div>
      </div>
      <Modal
        open={confirmModal}
        onCancel={() => setConfirmModal(false)}
        footer={null}
        style={{ height: "800px" }}
      >
        <div>
          <div className="disclaimer1 text-center mt-3">
            <FormattedMessage id="confirm_default" />
          </div>
          <div className="d-flex justify-content-center mt-5">
            <button
              style={{
                backgroundColor: "#F77E0B",
                color: "white",
                fontSize: "17px",
                fontFamily: "Poppins",
                border: "none",
                borderRadius: "3px",
                zIndex: 1000,
              }}
              onClick={confirmAction}
            >
              <FormattedMessage id="make_default" />
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default injectIntl(BankAccountList);

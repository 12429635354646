import "./style.css";
import { useSelector } from "react-redux";
import { useState } from "react";
import lodash from "lodash";
import { injectIntl } from "react-intl";
import ProgressStep from "../../components/ProgressStep.js";
import WorkReview from "./WorkReview";
import ProofofWork from "./ProofofWork";
import { useLocation } from "react-router";

const steps = [
  {
    content: 1,
    header: "header.attach_proof_of_work",
    description: " ",
  },
  {
    content: 2,
    header: "header.submit_review",
    description: " ",
  },
];

const SubmitReview = () => {
  const { state } = useLocation();

  const contractDetail = useSelector(
    (state) => state?.contracts?.contractDetails
  );

  const data = useSelector((state) => state?.common?.fullUser);

  const [proofImage, setProofImage] = useState([]);
  const [current, setCurrent] = useState(parseInt(state?.page) || 0);

  const contentStyle = {
    marginTop: "50px",
  };

  return (
    <ProgressStep steps={steps} current={current}>
      <div className="row d-flex justify-content-center">
        <div className="col-12 col-sm-12 col-md-10 col-lg-8">
          <div style={contentStyle}>
            {data && current === 0 ? (
              <ProofofWork
                current={current}
                setCurrent={setCurrent}
                data={proofImage}
                setData={setProofImage}
              />
            ) : (
              <WorkReview
                current={current}
                data={proofImage}
                contract={contractDetail}
              />
            )}
          </div>
          <div
            style={{
              marginTop: 24,
            }}
          ></div>
        </div>
      </div>
    </ProgressStep>
  );
};

export default injectIntl(SubmitReview);

import React from "react";
import "./style.css";
import { Input } from "antd";
export const ChatInput = ({
  label = "",
  name = "",
  type = "textArea",
  className = "",
  isRequired = true,
  disabled = false,
  placeholder = "",
  value,
  onChange = () => {},
  onPressEnter = () => {},
}) => {
  const { TextArea } = Input;
  return (
    <div className="input-class">
      <TextArea
        value={value}
        type={type}
        id={name}
        className={`input-input shadow-lg ${className}`}
        disabled={disabled}
        placeholder={placeholder}
        required={isRequired}
        onChange={onChange}
        onPressEnter={onPressEnter}
        style={{
          resize: "none",
          maxHeight: "50px",
          width: "100%",
          overflow: "auto",
        }}
        autoSize={true}
      />
    </div>
  );
};

import React, { useEffect, useState } from "react";
import Loader from "../../components/GeneralComponents";
import Header from "../../components/Header/Header";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router";
import { getFormData } from "../../api/w9formApi/getForm";
import { useDispatch, useSelector } from "react-redux";
import { BackButton } from "../../components/General/BackButton";

export const W9from = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const path = queryParams.get("path");
  const userData = JSON.parse(localStorage.getItem("data"));
  const [loading, setLoading] = useState();
  const formData = useSelector((state) => state?.formData?.formData);
  useEffect(() => {
    getFormDetails();
  }, [path]);
  const getFormDetails = async () => {
    setLoading(true);
    await getFormData(userData, dispatch);
    setLoading(false);
  };
  return (
    <>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}
      <BackButton />
      <div className="container paymentscreen">
        <Header />
        <div className="paymentscreen-inner">
          <div className="d-flex justify-content-center align-items-center getting-payment-heading shadow border">
            <span>
              <FormattedMessage id="w9-form" />
            </span>
          </div>
          {(userData?.user_type === "tradesman" ||
            userData?.user_type === "contractor") &&
          path === "receive" ? (
            <div className="transaction-card">
              <div className="d-flex justify-content-between mt-2">
                <span className="size">
                  <FormattedMessage id="first_name" />
                </span>
                <span className="size">{formData?.first_name}</span>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <span className="size">
                  <FormattedMessage id="last_name" />
                </span>
                <span className="size">{formData?.last_name}</span>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <span className="size">
                  <FormattedMessage id="email" />
                </span>
                <span className="size">{formData?.email}</span>
              </div>
              <div className="divider"></div>
              <div className="d-flex justify-content-between mt-2">
                <span className="size">
                  <FormattedMessage id="social_security" />
                </span>
                <span className="size">{formData?.social_security}</span>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <span className="size">
                  <FormattedMessage id="phone_number" />
                </span>
                <span className="size">{formData?.phone_number}</span>
              </div>
              <div className="divider"></div>
              <div className="d-flex justify-content-between mt-2">
                <span className="size">
                  <FormattedMessage id="birth_date" />
                </span>
                <span className="size">{formData?.birthdate}</span>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <span className="size">
                  <FormattedMessage id="tax_classification" />
                </span>
                <span className="size">{formData?.tax_classification}</span>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <span className="size">
                  <FormattedMessage id="city" />
                </span>
                <span className="size">{formData?.city}</span>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <span className="size">
                  <FormattedMessage id="zip" />
                </span>
                <span className="size">{formData?.zip}</span>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <span className="size">
                  <FormattedMessage id="state" />
                </span>
                <span className="size">{formData?.state}</span>
              </div>
            </div>
          ) : (
            <div className="transaction-card">
              <div className="d-flex justify-content-between mt-2">
                <span className="size">
                  <FormattedMessage id="company_regis_date" />
                </span>
                <span className="size">
                  {formData?.Company_Registration_Date}
                </span>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <span className="size">
                  <FormattedMessage id="company_w_name" />
                </span>
                <span className="size">{formData?.Company_Name}</span>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <span className="size">
                  <FormattedMessage id="company_email" />
                </span>
                <span className="size">{formData?.Email}</span>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <span className="size">
                  <FormattedMessage id="address" />
                </span>
                <span className="size">{formData?.Address}</span>
              </div>

              <div className="divider"></div>
              <div className="d-flex justify-content-between mt-2">
                <span className="size">
                  <FormattedMessage id="Ein_text" />
                </span>
                <span className="size">EIN</span>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <span className="size">
                  <FormattedMessage id="phone_number" />
                </span>
                <span className="size">{formData?.Company_Phone}</span>
              </div>
              <div className="divider"></div>
              <div className="d-flex justify-content-between mt-2">
                <span className="size">
                  <FormattedMessage id="city" />
                </span>
                <span className="size">{formData?.City}</span>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <span className="size">
                  <FormattedMessage id="zip" />
                </span>
                <span className="size">{formData?.Zip}</span>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <span className="size">
                  <FormattedMessage id="state" />
                </span>
                <span className="size">{formData?.State}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

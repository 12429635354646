import { CloseOutlined, SearchOutlined, MenuOutlined } from "@ant-design/icons";
import { Button, Divider, Input, message, Modal, Progress } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppStore from "../../assets/images/AppStore.png";
import GooglePlay from "../../assets/images/GooglePlay.png";
import {
  setSearchedProfilesList,
  setUnsubscribedUser,
} from "../../actions/CommonActions";
import AppLogo from "../../assets/images/AppLogo.png";
import contractor from "../../assets/images/conractor.png";
import homeowner from "../../assets/images/home.png";
import power from "../../assets/images/power-off.svg";
import profile from "../../assets/images/profile.svg";
import profileIcon from "../../assets/images/profileIcon.png";
import register from "../../assets/images/register.png";
import Tradesman from "../../assets/images/tradesman.png";
import update from "../../assets/images/update.svg";
import { base_url } from "../../constants";
import { getCurrentUserDetails, getFullUser } from "../../views/Home/Service";
import Loader from "../GeneralComponents";
import Filter from "./Filter";
import "./Header.css";
import { SwitchNewUser, SwitchUser } from "./service";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  capitalizeFirstLetter,
  handleBankInfoVerification,
  handleReload,
} from "./Helper";
import GigsterModal from "./GigsterModal";
import BankModal from "./BankModal";
import bankImage from "../../assets/images/bankInfo.svg";
import spendingImage from "../../assets/images/spendings.svg";
import walletImage from "../../assets/images/wallet.svg";
import settingsImage from "../../assets/images/gigmiSettings.svg";
import shareImage from "../../assets/images/gigmiShare.svg";
import receipt from "../../assets/images/receipt.svg";
import howtouse from "../../assets/images/howto.svg";
import HomeownerModal from "./HomeownerModal";
import { Form } from "../W9Form/Form";
import { EstimateContract } from "./EstimateContract";
import { NotificationDropDown } from "../Notifications/NotificationDropDown";
import { SubscriptionOption } from "./SubscriptionOption";
const Header = (props) => {
  const [searchText, setSearchText] = useState();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mobileSearch, setMobileSearch] = useState(false);
  const dispatch = useDispatch();
  const fullUser = useSelector((state) => state?.common?.fullUser);
  const currentUser = useSelector((state) => state?.common?.newUser?.data);
  const accessToken = JSON.parse(localStorage.getItem("data"));

  useEffect(() => {
    if (accessToken) {
      getFullUser(dispatch);
      getCurrentUserDetails(dispatch);
    }
  }, [accessToken?.user_type]);
  const navigate = useNavigate();
  const lang = JSON.parse(localStorage.getItem("lang"));
  const isAuthenticated = JSON.parse(localStorage.getItem("data"));
  const handleSearch = async () => {
    const accessToken = JSON.parse(localStorage.getItem("data"));
    try {
      setMobileSearch(false);
      props.setSearchLoading(true);
      const getUserStories = await axios.get(
        `${base_url}user/search/${searchText?.trim()}/${
          accessToken?.user_type
        }/${accessToken?.user_id}`,

        {
          headers: {
            "ngrok-skip-browser-warning": true,
          },
        }
      );
      if (getUserStories?.error) {
        props.setSearchLoading(false);
        if (lang) {
          message.error("No se encontró ningún perfil", 3);
        } else {
          message.error("Not Found Any Profile", 3);
        }
      } else {
        if (getUserStories?.data == 0) {
          if (lang) {
            message.error("No se encontró ningún perfil", 3);
          } else {
            message.error("Not Found Any Profile", 3);
          }
        }
        dispatch(setSearchedProfilesList(getUserStories?.data));
        props.setSearchLoading(false);
      }
    } catch (e) {
      message.error(e);
    }
    props.setSearchLoading(false);
  };
  const handleIconClick = () => {
    setSearchText(null);
    dispatch(setSearchedProfilesList([]));
  };
  const handleSwitch = async (type) => {
    setLoading(true);
    if (fullUser?.availabilityRecord.includes(`${type}`)) {
      await SwitchUser(type);
    } else {
      await SwitchNewUser(type);
    }
    setLoading(false);
    setShowModal(false);
  };
  const handleBankInfoClick = () => {
    handleBankInfoVerification(dispatch, fullUser);
    if (isAuthenticated?.user_type !== "homeowner") {
      navigate("/payment-options");
    }
  };

  return (
    <div className="container">
      <div className="row" style={{ height: "80px" }}>
        <div className="col-2 d-flex justify-content-start align-items-center">
          <img
            src={AppLogo}
            alt="logo"
            height={40}
            style={{ cursor: "pointer" }}
            onClick={() => handleReload(navigate)}
          />
        </div>
        <div className="col-4 d-flex justify-content-center align-items-center">
          <div className="d-none d-sm-none d-md-flex justify-content-center ms-3 store-icons">
            <a
              href="https://play.google.com/store/apps/details?id=com.gigmi"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={GooglePlay}
                alt="Google Play"
                style={{ width: "100px" }}
              />
            </a>
            <a
              href="https://apps.apple.com/us/app/gigmi-contractor-revolution/id1604625082"
              target="_blank"
              rel="noreferrer"
            >
              <img src={AppStore} alt="App Store" style={{ width: "100px" }} />
            </a>
          </div>
        </div>
        <div className=" col-6 d-flex justify-content-end align-items-center">
          <div className="d-flex justify-content-end">
            <div className="d-sm-none d-none d-md-flex ">
              <Input
                className="search-input"
                type="text"
                onPressEnter={handleSearch}
                onSubmit={handleSearch}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder={props.intl.formatMessage({ id: "header.search" })}
                prefix={<SearchOutlined />}
                suffix={
                  searchText ? (
                    <CloseOutlined onClick={handleIconClick} />
                  ) : (
                    <div style={{ width: "14px" }}></div>
                  )
                }
              />
              <Button
                className="search-button"
                disabled={searchText ? false : true}
                onClick={handleSearch}
              >
                {props.intl.formatMessage({ id: "header.search" })}
              </Button>
            </div>
            <div className="d-flex d-sm-flex d-md-none mt-2 ">
              <SearchOutlined
                style={{ fontSize: "30px", cursor: "pointer" }}
                onClick={() => setMobileSearch(true)}
              />
            </div>
            <div
              className="border rounded d-flex align-items-center justify-content-center"
              style={{ width: "58px", height: "48px", marginLeft: "10px" }}
            >
              <Filter
                searchLoading={props.searchLoading}
                setSearchLoading={props.setSearchLoading}
              />
            </div>
            {isAuthenticated && fullUser?.subscribed && (
              <NotificationDropDown />
            )}
          </div>
          <div
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
          >
            <div>
              {isAuthenticated ? (
                <div
                  className="border rounded-circle d-flex justify-content-center align-items-center"
                  style={{ width: "48px", height: "48px", marginLeft: "10px" }}
                >
                  {fullUser && accessToken.user_type === "contractor" && (
                    <img
                      src={
                        fullUser?.contractor?.profile_picture === null ||
                        fullUser?.contractor?.profile_picture === ""
                          ? profileIcon
                          : `${process.env.REACT_APP_S3_BUCKET_URL}${fullUser?.contractor?.profile_picture}`
                      }
                      className="img-fluid rounded-circle"
                    />
                  )}
                  {fullUser && accessToken.user_type === "tradesman" && (
                    <img
                      src={
                        fullUser?.tradesman?.profile_picture === null ||
                        fullUser?.tradesman?.profile_picture === ""
                          ? profileIcon
                          : `${process.env.REACT_APP_S3_BUCKET_URL}${fullUser?.tradesman?.profile_picture}`
                      }
                      className="img-fluid rounded-circle"
                    />
                  )}
                  {fullUser && accessToken.user_type === "homeowner" && (
                    <img
                      src={
                        currentUser?.profile_picture === null ||
                        currentUser?.profile_picture === ""
                          ? profileIcon
                          : `${process.env.REACT_APP_S3_BUCKET_URL}${currentUser?.profile_picture}`
                      }
                      className="img-fluid rounded-circle"
                    />
                  )}
                </div>
              ) : (
                <div className="ms-2">
                  <MenuOutlined
                    style={{ color: "#F77E0B", fontSize: "45px" }}
                  />
                </div>
              )}
            </div>
          </div>

          <div
            className="offcanvas offcanvas-end canvasWidth "
            tabIndex="-1"
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
          >
            <div className="offcanvas-header">
              <div className="w-100 d-flex justify-content-center mt-2">
                <img src={AppLogo} />
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              {!isAuthenticated && (
                <div>
                  <div
                    className="d-flex flex-column"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    onClick={() => navigate("/login")}
                  >
                    <div className="p-2">
                      <img src={power} alt="" />
                      <span className="dropdown-text ms-3">
                        {" "}
                        {props.intl.formatMessage({ id: "header.login" })}
                      </span>
                    </div>
                    <Divider style={{ margin: "4px 0" }} />
                  </div>
                  <div
                    className="d-flex flex-column"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    onClick={() => navigate("/profile-selection")}
                  >
                    <div className="d-flex p-2">
                      <div style={{ width: "25px", height: "25px" }}>
                        <img src={register} alt="" className="img-fluid" />
                      </div>
                      <span
                        className="dropdown-text"
                        style={{ marginLeft: "6px" }}
                      >
                        {props.intl.formatMessage({ id: "header.register" })}
                      </span>
                    </div>
                    <Divider style={{ margin: "4px 0" }} />
                  </div>
                  <div
                    className="d-flex flex-column"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    onClick={() => navigate(`/how-use-app`)}
                  >
                    <div className="d-flex align-items-center p-2">
                      <img
                        src={howtouse}
                        alt=""
                        style={{ width: "20px", height: "20px" }}
                      />
                      <span className="dropdown-text ms-3">
                        <FormattedMessage id="How_to" />
                      </span>
                    </div>
                    <Divider style={{ margin: "4px 0" }} />
                  </div>
                </div>
              )}
              {isAuthenticated && (
                <div className="d-flex flex-column">
                  <div
                    style={{ position: "relative", height: "150px" }}
                    className="d-flex justify-content-center"
                  >
                    <Progress
                      type="circle"
                      percent={currentUser?.health}
                      strokeColor="#F77E0B"
                      showInfo={false}
                      style={{ position: "absolute" }}
                    />
                    <div
                      style={{
                        width: "105px",
                        height: "105px",
                        marginTop: "7px",
                      }}
                    >
                      {fullUser && accessToken.user_type === "contractor" && (
                        <img
                          src={
                            fullUser?.contractor?.profile_picture === null ||
                            fullUser?.contractor?.profile_picture === ""
                              ? profileIcon
                              : `${process.env.REACT_APP_S3_BUCKET_URL}${fullUser?.contractor?.profile_picture}`
                          }
                          className="w-100 h-100 rounded-circle"
                          style={{ objectFit: "cover" }}
                        />
                      )}
                      {fullUser && accessToken.user_type === "tradesman" && (
                        <img
                          src={
                            fullUser?.tradesman?.profile_picture === null ||
                            fullUser?.tradesman?.profile_picture === ""
                              ? profileIcon
                              : `${process.env.REACT_APP_S3_BUCKET_URL}${fullUser?.tradesman?.profile_picture}`
                          }
                          className="w-100 h-100 rounded-circle"
                          style={{ objectFit: "cover" }}
                        />
                      )}
                      {fullUser && accessToken.user_type === "homeowner" && (
                        <img
                          src={
                            fullUser?.homeowner?.profile_picture === null ||
                            fullUser?.homeowner?.profile_picture === ""
                              ? profileIcon
                              : `${process.env.REACT_APP_S3_BUCKET_URL}${fullUser?.homeowner?.profile_picture}`
                          }
                          className=" rounded-circle w-100 h-100"
                          style={{ objectFit: "cover" }}
                        />
                      )}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        marginTop: "80px",
                        marginLeft: "180px",
                      }}
                    >
                      <span className="health-show">
                        {currentUser?.health}%
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <span className="username-text">
                      {currentUser?.first_name} {currentUser?.last_name}
                    </span>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div
                      className="type-banner mt-4 "
                      style={
                        accessToken.user_type === "tradesman"
                          ? { backgroundColor: "#8ABB2A" }
                          : { backgroundColor: "#F77E0B" }
                      }
                    >
                      <span>
                        {accessToken.user_type === "tradesman"
                          ? "Gigster"
                          : capitalizeFirstLetter(accessToken.user_type)}
                      </span>
                    </div>
                  </div>
                  <div
                    className="d-flex flex-column"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    onClick={() => setShowModal(true)}
                  >
                    <div className="d-flex align-items-center pt-2 pb-2">
                      <span className="dropdown-text">
                        {" "}
                        {props.intl.formatMessage({ id: "header.switchUser" })}
                      </span>
                    </div>
                    <Divider style={{ margin: "4px 0" }} />
                  </div>

                  <div
                    className="d-flex flex-column"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    onClick={() => navigate("/user-profile")}
                  >
                    <div className="d-flex align-items-center p-2">
                      <img
                        src={profile}
                        alt=""
                        style={{ width: "20px", height: "20px" }}
                      />
                      <span className="dropdown-text ms-3">
                        {" "}
                        {props.intl.formatMessage({ id: "header.profile" })}
                      </span>
                    </div>
                    <Divider style={{ margin: "4px 0" }} />
                  </div>
                  <div
                    className="d-flex flex-column"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    onClick={() => navigate("/update-user")}
                  >
                    <div className="d-flex align-items-center p-2">
                      <img
                        src={update}
                        alt=""
                        style={{ width: "20px", height: "20px" }}
                      />
                      <span className="dropdown-text ms-3">
                        {" "}
                        {props.intl.formatMessage({
                          id: "header.updateProfile",
                        })}
                      </span>
                    </div>
                    <Divider style={{ margin: "4px 0" }} />
                  </div>
                  <div
                    className="d-flex flex-column"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    onClick={() => navigate("/spendings-earnings")}
                  >
                    <div className="d-flex align-items-center p-2">
                      <img
                        src={spendingImage}
                        alt=""
                        style={{ width: "20px", height: "20px" }}
                      />
                      <span className="dropdown-text ms-3">
                        {isAuthenticated?.user_type === "contractor" ||
                        isAuthenticated?.user_type === "homeowner"
                          ? `${props.intl.formatMessage({ id: "spendings" })}`
                          : `${props.intl.formatMessage({ id: "earnings" })}`}
                      </span>
                    </div>
                    <Divider style={{ margin: "4px 0" }} />
                  </div>
                  {(isAuthenticated?.user_type === "contractor" ||
                    isAuthenticated?.user_type === "homeowner") && (
                    <div
                      className="d-flex flex-column"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                      onClick={() => navigate("/wallet")}
                    >
                      <div className="d-flex align-items-center p-2">
                        <img
                          src={walletImage}
                          alt=""
                          style={{ width: "20px", height: "20px" }}
                        />
                        <span className="dropdown-text ms-3">
                          {props.intl.formatMessage({ id: "wallet" })}
                        </span>
                      </div>
                      <Divider style={{ margin: "4px 0" }} />
                    </div>
                  )}
                  <div
                    className="d-flex flex-column"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    onClick={handleBankInfoClick}
                  >
                    <div className="d-flex align-items-center p-2">
                      <img
                        src={bankImage}
                        alt=""
                        style={{ width: "20px", height: "20px" }}
                      />
                      <span className="dropdown-text ms-3">
                        {" "}
                        {props.intl.formatMessage({ id: "bank_info" })}
                      </span>
                    </div>
                    <Divider style={{ margin: "4px 0" }} />
                  </div>
                  {isAuthenticated &&
                    isAuthenticated?.user_type === "contractor" && (
                      <div
                        className="d-flex flex-column"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                        onClick={() => {
                          if (fullUser?.subscribed) {
                            navigate(`/generate-invoices-or-receipts`);
                          } else {
                            dispatch(setUnsubscribedUser(true));
                          }
                        }}
                      >
                        <div className="d-flex align-items-center p-2">
                          <img
                            src={receipt}
                            alt=""
                            style={{ width: "20px", height: "20px" }}
                          />
                          <span className="dropdown-text ms-3">
                            {props.intl.formatMessage({
                              id: "SEND_INVOICES_RECEIPTS",
                            })}
                          </span>
                        </div>
                        <Divider style={{ margin: "4px 0" }} />
                      </div>
                    )}
                  <div
                    className="d-flex flex-column"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    onClick={() => {
                      if (fullUser?.subscribed) {
                        navigate(`/share-app`);
                      } else {
                        dispatch(setUnsubscribedUser(true));
                      }
                    }}
                  >
                    <div className="d-flex align-items-center p-2">
                      <img
                        src={shareImage}
                        alt=""
                        style={{ width: "20px", height: "20px" }}
                      />
                      <span className="dropdown-text ms-3">
                        <FormattedMessage id="header.shareApp" />
                      </span>
                    </div>
                    <Divider style={{ margin: "4px 0" }} />
                  </div>
                  <Form />
                  <EstimateContract fullUser={fullUser} />
                  <SubscriptionOption />
                  <div
                    className="d-flex flex-column"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    onClick={() => navigate("/settings")}
                  >
                    <div className="d-flex align-items-center p-2">
                      <img
                        src={settingsImage}
                        alt=""
                        style={{ width: "20px", height: "20px" }}
                      />
                      <span className="dropdown-text ms-3">
                        <FormattedMessage id="settings" />
                      </span>
                    </div>
                    <Divider style={{ margin: "4px 0" }} />
                  </div>
                </div>
              )}
              <div
                style={{ width: "100%", height: "100px" }}
                className="d-flex justify-content-center align-items-center"
              ></div>
            </div>
            <Modal
              open={showModal}
              onCancel={() => setShowModal(false)}
              footer={false}
              width={400}
            >
              <div className="w-100 d-flex justify-content-center mt-3">
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                  }}
                >
                  {props.intl.formatMessage({ id: "header.switchUser" })}{" "}
                </span>
              </div>
              <div className="row mt-3">
                {isAuthenticated &&
                  isAuthenticated.user_type === "contractor" &&
                  (loading ? (
                    <div>
                      <Loader />
                    </div>
                  ) : (
                    <>
                      <div className="col-6 d-flex flex-column align-items-center justify-content-center">
                        <img
                          src={homeowner}
                          onClick={() => handleSwitch("homeowner")}
                        />
                        <spa
                          style={{ fontSize: "14px", fontFamily: "Poppins" }}
                        >
                          {props.intl.formatMessage({ id: "homeowner" })}
                        </spa>
                      </div>
                      <div className="col-6 d-flex flex-column align-items-center justify-content-center">
                        <img
                          src={Tradesman}
                          onClick={() => handleSwitch("tradesman")}
                        />
                        <span
                          style={{ fontSize: "14px", fontFamily: "Poppins" }}
                        >
                          {props.intl.formatMessage({ id: "gigster" })}
                        </span>
                      </div>
                    </>
                  ))}
                {isAuthenticated &&
                  isAuthenticated.user_type === "tradesman" &&
                  (loading ? (
                    <div>
                      <Loader />
                    </div>
                  ) : (
                    <>
                      <div className="col-6 d-flex flex-column align-items-center justify-content-center">
                        <img
                          src={homeowner}
                          onClick={() => handleSwitch("homeowner")}
                        />
                        <spa
                          style={{ fontSize: "14px", fontFamily: "Poppins" }}
                        >
                          {props.intl.formatMessage({ id: "homeowner" })}
                        </spa>
                      </div>
                      <div className="col-6 d-flex flex-column align-items-center justify-content-center">
                        <img
                          src={contractor}
                          onClick={() => handleSwitch("contractor")}
                        />
                        <span
                          style={{ fontSize: "14px", fontFamily: "Poppins" }}
                        >
                          {props.intl.formatMessage({ id: "contractor" })}
                        </span>
                      </div>
                    </>
                  ))}
                {isAuthenticated &&
                  isAuthenticated.user_type === "homeowner" &&
                  (loading ? (
                    <div>
                      <Loader />
                    </div>
                  ) : (
                    <>
                      <div className="col-6 d-flex flex-column align-items-center justify-content-center">
                        <img
                          src={contractor}
                          onClick={() => handleSwitch("contractor")}
                        />
                        <span
                          style={{ fontSize: "14px", fontFamily: "Poppins" }}
                        >
                          {props.intl.formatMessage({ id: "contractor" })}
                        </span>
                      </div>
                      <div className="col-6 d-flex flex-column align-items-center justify-content-center">
                        <img
                          src={Tradesman}
                          onClick={() => handleSwitch("tradesman")}
                        />
                        <span
                          style={{ fontSize: "14px", fontFamily: "Poppins" }}
                        >
                          {props.intl.formatMessage({ id: "gigster" })}
                        </span>
                      </div>
                    </>
                  ))}
              </div>
            </Modal>
          </div>
        </div>
      </div>
      {mobileSearch && (
        <Modal
          open={mobileSearch}
          onCancel={() => setMobileSearch(false)}
          footer={false}
          width={300}
          closable={false}
        >
          <div>
            <Input
              type="text"
              className="search-input"
              onPressEnter={handleSearch}
              onSubmit={handleSearch}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder={props.intl.formatMessage({ id: "header.search" })}
              prefix={<SearchOutlined />}
              suffix={
                searchText ? (
                  <CloseOutlined onClick={handleIconClick} />
                ) : (
                  <div style={{ width: "14px" }}></div>
                )
              }
            />
            <div className="d-flex justify-content-end mt-3">
              <Button className="search-button" onClick={handleSearch}>
                {props.intl.formatMessage({ id: "header.search" })}
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <GigsterModal />
      <BankModal />
      <HomeownerModal />
    </div>
  );
};
export default injectIntl(Header);

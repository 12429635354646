import axios from "axios";
import {
  getUserError,
  getUserSuccess,
  setSelectedProfile,
} from "../actions/CommonActions";
import { base_url } from "../constants";

export const getOpponentType = (chatConversation) => {
  switch (chatConversation.opponentData?.oponentType) {
    case "Gigster":
      return "tradesman";
    case "Contractor":
      return "contractor";
    default:
      return "homeowner";
  }
};

const Getbanner = async (userType) => {
  await axios
    .get(`${base_url}banners/5/15/${userType}`)
    .then((response) => {})
    .catch((err) => {});
};

export const getSelectedUser = async (dispatch, id) => {
  const accessToken = JSON.parse(localStorage.getItem("data"));

  try {
    const selectedProfile = await axios.get(`${base_url}user/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken?.accessToken}`,
      },
    });
    if (selectedProfile?.error) throw selectedProfile.error;
    else {
      dispatch(setSelectedProfile(selectedProfile));
    }
  } catch (err) {}
};

export const getUserDetailsData = async (dispatch) => {
  const accessToken = JSON.parse(localStorage.getItem("data"));
  try {
    const getUserData = await axios.get(
      `${base_url}user/GetFullUser/${accessToken?.user_id}/${accessToken?.id}/${accessToken?.user_type}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken?.accessToken}`,
        },
      }
    );
    if (getUserData?.error) {
      dispatch(getUserError(getUserData?.error));
    } else {
      dispatch(getUserSuccess(getUserData));
    }
  } catch (e) {
    //    else if(accessToken?.user_type==='contractor'){
    //     const getUserData = await axios.get(
    //         `${base_url}contractor/findByUserId/${accessToken?.user_id}`,{
    //         headers: {
    //             Authorization: `Bearer ${accessToken?.accessToken}`,
    //           },
    //         }
    //       );
    //       if(getUserData?.error){
    //         dispatch(getUserError(getUserData?.error));
    //       }
    //       else{
    //         dispatch(getUserSuccess(getUserData));
    //       }

    //    }
    //    else if(accessToken?.user_type==='homeowner'){
    //     const getUserData = await axios.get(
    //         `${base_url}home-owner/findByUserId/${accessToken?.user_id}`,{
    //         headers: {
    //             Authorization: `Bearer ${accessToken?.accessToken}`,
    //           },
    //         }
    //       );
    //       if(getUserData?.error){
    //         dispatch(getUserError(getUserData?.error));
    //       }
    //       else{
    //         dispatch(getUserSuccess(getUserData));
    //       }

    //    }

    dispatch(getUserError(e));
  }
};

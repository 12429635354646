import { SimpleCatchError } from "../../utils/Helper";
import { Axios } from "../../utils/axiosMiddleware";

export const reportProfile=async(finalData,navigate)=> {
    try {
      const token = JSON.parse(localStorage.getItem('data'));
      await Axios.post(
        `/reported-profiles`,
        {...finalData, user_id: token?.id, user_type: token?.user_type});
         navigate('/');
    } catch (e) {
        SimpleCatchError(e);
    }
  }
  
import React, { useEffect, useState } from "react";
import crown from "../../assets/images/crown.svg";
import tick from "../../assets/images/tick.svg";
import { Axios } from "../../utils/axiosMiddleware";
import { FormattedMessage } from "react-intl";
import { Button } from "antd";
import Disclaimer from "../BankInfo/Disclaimer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { isEmpty } from "lodash";
import { subscriberTrial } from "../../api/Subscribers/subscriber";

export const SubscriptionCard = () => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState();
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState("");
  const [alreadySubscribed, setAlreadySubscribed] = useState(false);
  const userData = JSON.parse(localStorage.getItem("data"));
  const userTypeData = useSelector((state) => state?.common?.newUser?.data);
  const fullUser = useSelector((state) => state?.common?.fullUser);
  const handleUserClick = () => {
    if (fullUser?.sendingAccountVerify && !fullUser?.subscribed) {
      navigate("/subscription");
    } else if (
      fullUser?.subscribed &&
      subscriptionType == "trial" &&
      !fullUser?.nextSubscription &&
      fullUser?.sendingAccountVerify
    ) {
      setAlreadySubscribed(true);
      return true;
    } else {
      setSubscriptionModal(true);
    }
  };
  useEffect(() => {
    if (userData) {
      getAmount();
      subType();
    }
  }, []);
  const subType = async () => {
    const res = await subscriberTrial();
    setSubscriptionType(res);
  };
  const getAmount = async () => {
    const response = await Axios.get(`subscriptions`, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: `Bearer ${userData?.accessToken}`,
      },
    });
    setAmount(response?.data);
  };
  const handleModalClick = () => {
    if (userData?.user_type === "homeowner") {
      navigate("/add-hom-account");
    } else {
      navigate("/payment-options");
    }
  };

  return (
    <>
      {subscriptionType === "privilege" ||
      subscriptionType === "temporary_privilege" ? (
        ""
      ) : (
        <>
          <div className="w-100 d-flex justify-content-center align-items-center mt-5">
            <span className="subscription-heading">
              <FormattedMessage id="subscription" />
            </span>
            <span className="subscription-heading2">
              <FormattedMessage id="plan" />
            </span>
          </div>
          <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <div className="subscription-card d-flex flex-column  align-items-center">
              <div>
                <img src={crown} />
              </div>
              <div
                className="fw-bold fs-4 mt-3"
                style={{ fontFamily: "Poppins" }}
              >
                {userData ? (
                  `$${amount?.amount}/month`
                ) : (
                  <FormattedMessage id="free_trail_month" />
                )}
              </div>
              <div className="sub-divider"></div>
              <div>
                <div className="sub-item d-flex justify-content-center align-items-center">
                  <img src={tick} className="me-2" />
                  <span>
                    <FormattedMessage id="view_profile" />
                  </span>
                </div>
                <div className="sub-item d-flex justify-content-center align-items-center">
                  <img src={tick} className="me-2" />
                  <span>
                    <FormattedMessage id="like" />
                  </span>
                </div>
                <div className="sub-item d-flex justify-content-center align-items-center">
                  <img src={tick} className="me-2" />
                  <span>
                    <FormattedMessage id="share" />
                  </span>
                </div>
                <div className="sub-item d-flex justify-content-center align-items-center">
                  <img src={tick} className="me-2" />
                  <span>
                    <FormattedMessage id="contract" />
                  </span>
                </div>
                <div className="sub-item d-flex justify-content-center align-items-center">
                  <img src={tick} className="me-2" />
                  <span>
                    <FormattedMessage id="chat" />
                  </span>
                </div>
              </div>
              {userData ? (
                <Button
                  onClick={handleUserClick}
                  className="sub-button mt-5 fs-4 fw-bold"
                >
                  {subscriptionType === "trial" ? (
                    <FormattedMessage id="using_free_trail" />
                  ) : subscriptionType === "paid" && fullUser?.subscribed ? (
                    <FormattedMessage id="subscribed" />
                  ) : (
                    <FormattedMessage id="subscribe" />
                  )}
                </Button>
              ) : (
                <Button
                  onClick={() => navigate("/welcome")}
                  className="sub-button mt-5 fs-4 fw-bold"
                >
                  <FormattedMessage id="register" />
                </Button>
              )}
            </div>
          </div>
        </>
      )}
      <Disclaimer
        showModal={subscriptionModal}
        setShowModal={() => setSubscriptionModal(false)}
        body={
          fullUser?.subscribed &&
          !fullUser?.nextSubscription &&
          subscriptionType === "paid" ? (
            <FormattedMessage id="already_subscribe" />
          ) : fullUser?.subscribed &&
            fullUser?.nextSubscription &&
            subscriptionType === "paid" ? (
            <FormattedMessage id="already_subscribed" />
          ) : (
            <FormattedMessage id="add_sender_account" />
          )
        }
        confirmText={
          fullUser?.subscribed &&
          !fullUser?.nextSubscription &&
          subscriptionType === "paid" ? (
            ""
          ) : fullUser?.subscribed &&
            fullUser?.nextSubscription &&
            subscriptionType === "paid" ? (
            <FormattedMessage id="unsubscribe" />
          ) : (
            <FormattedMessage id="add" />
          )
        }
        cancelText={
          fullUser?.subscribed &&
          !fullUser?.nextSubscription &&
          subscriptionType === "paid" ? (
            <FormattedMessage id="ok" />
          ) : (
            <FormattedMessage id="cancel" />
          )
        }
        handleClick={() => {
          fullUser?.subscribed &&
          fullUser?.nextSubscription &&
          subscriptionType === "paid"
            ? navigate("/subscription")
            : handleModalClick();
        }}
        handleCancel={() => setSubscriptionModal(false)}
      />
      <Disclaimer
        showModal={alreadySubscribed}
        setShowModal={() => setAlreadySubscribed(false)}
        body={<FormattedMessage id="free_trail" />}
        confirmText={<FormattedMessage id="subscribe" />}
        cancelText={<FormattedMessage id="cancel" />}
        handleClick={() => navigate("/subscription")}
        handleCancel={() => setAlreadySubscribed(false)}
      />
    </>
  );
};

import { Input, message } from "antd";
import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Loader from "../GeneralComponents";
import { injectIntl } from "react-intl";

const LocationSearch = ({
  address,
  setAddress,
  coordinates,
  setCoordinates,
  setCity,
  ...props
}) => {
  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setCoordinates(latLng);
      setAddress(selectedAddress);
      if (setCity) {
        let city = results[0].address_components.filter(
          (x) => x.types.filter((t) => t == "locality").length > 0
        )[0];
        if (city?.long_name) {
          setCity(city?.long_name);
        }
      }
    } catch (error) {
      message.error("Error fetching location details", error);
    }
  };

  return (
    <div>
      <PlacesAutocomplete
        value={address}
        onChange={(value) => setAddress(value)}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Input
              {...getInputProps({
                placeholder: `${props.intl.formatMessage({
                  id: "type_location",
                })}`,
                className: "location-search-input",
              })}
              className="input-field"
            />
            <div className="autocomplete-dropdown-container">
              {loading && (
                <div
                  style={{
                    width: "100%",
                    height: "100px",
                    backgroundColor: "#E6E7EB",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loader />
                </div>
              )}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                    style={{
                      width: "100%",
                      height: "auto",
                      backgroundColor: "#E6E7EB",
                      padding: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          fontFamily: "Poppins",
                        }}
                      >
                        {suggestion.description}
                      </span>
                    </div>
                    <div style={{ borderTop: "1px solid grey" }}></div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default injectIntl(LocationSearch);

import { useState } from "react";
import FirstPage from "./FirstPage";
import FourthPage from "./FourthPage";
import SecondPage from "./SecondPage";
import ThirdPage from "./ThirdPage";
import { useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import ProgressStep from "../ProgressStep.js";
import { Steps } from "antd";
const steps = [
  {
    content: 1,
    header: "header.updateProfile",
    description: "pleaseUpdate",
  },
  {
    content: 2,
    header: "skillsYouWant",
    description: "multipleSkills",
  },
  {
    content: 3,
    header: "introduce",
    description: "writeSomething",
  },
  {
    content: 4,
    header: "body.complete",
    description: "addPics",
  },
];
const UpdateProfile = ({ intl }) => {
  const data = useSelector((state) => state?.common?.fullUser);
  const accessToken = JSON.parse(localStorage.getItem("data"));

  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    company: "",
    phonenumber: "",
    state: "",
    city: "",
    location: "",
    company_logo: "",
  });
  const [skills, setSkills] = useState([]);
  const [introText, setIntroText] = useState("");

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const contentStyle = {
    marginTop: "50px",
  };
  return (
    <ProgressStep steps={steps} current={current}>
      <div className="row d-flex justify-content-center">
        <div className="col-12 col-sm-12 col-md-10 col-lg-8">
          {data &&
            (data.current_type === "contractor" ||
              data.current_type === "tradesman") && (
              <Steps current={current} items={items} responsive={false} />
            )}
          <div style={contentStyle}>
            {data &&
              accessToken.user_type === "contractor" &&
              (current === 0 ? (
                <FirstPage
                  loading={loading}
                  setLoading={setLoading}
                  current={current}
                  setCurrent={setCurrent}
                />
              ) : current === 1 ? (
                <SecondPage
                  loading={loading}
                  setLoading={setLoading}
                  current={current}
                  setCurrent={setCurrent}
                />
              ) : current === 2 ? (
                <ThirdPage
                  introText={introText}
                  setIntroText={setIntroText}
                  current={current}
                  setCurrent={setCurrent}
                />
              ) : (
                <FourthPage current={current} setCurrent={setCurrent} />
              ))}
            {data &&
              accessToken.user_type === "tradesman" &&
              (current === 0 ? (
                <FirstPage
                  formData={formData}
                  setFormData={setFormData}
                  current={current}
                  setCurrent={setCurrent}
                />
              ) : current === 1 ? (
                <SecondPage
                  skills={skills}
                  setSkills={setSkills}
                  current={current}
                  setCurrent={setCurrent}
                />
              ) : current === 2 ? (
                <ThirdPage
                  introText={introText}
                  setIntroText={setIntroText}
                  current={current}
                  setCurrent={setCurrent}
                />
              ) : (
                <FourthPage current={current} setCurrent={setCurrent} />
              ))}

            {data && accessToken?.user_type === "homeowner" && (
              <FirstPage
                formData={formData}
                setFormData={setFormData}
                current={current}
                setCurrent={setCurrent}
              />
            )}
          </div>
          <div
            style={{
              marginTop: 24,
            }}
          ></div>
        </div>
      </div>
    </ProgressStep>
  );
};
export default injectIntl(UpdateProfile);

import STRINGS from "../../utils/UserTypes";
import Edit from "../../assets/images/Edit.png";
import React from "react";
import { useSelector } from "react-redux";

export const ContractEditButton = ({ userData, contractDetails, navigate }) => {
  const skills = useSelector((state) => state?.skills?.userSkills);

  return (
    <div style={{ flexDirection: "row" }}>
      <div
        style={{ marginRight: 12 }}
        onClick={() => {
          const isContractorOrTradesmanOnEstimationGiven =
            (userData?.user_type === STRINGS.CONTRACTOR_TYPE ||
              userData?.user_type === STRINGS.TRADESMAN_TYPE) &&
            contractDetails?.status === "estimation_given";

          const isContractAssignedOrRunning =
            ["assigned", "send", "receive", "running"].includes(
              contractDetails?.status
            ) &&
            userData?.user_type === STRINGS.CONTRACTOR_TYPE &&
            contractDetails?.homeownerId;

          const isContractSendOrRunning =
            userData?.user_type === STRINGS.CONTRACTOR_TYPE &&
            contractDetails?.tradesmanId &&
            contractDetails?.parentContractId &&
            ["send", "receive", "running"].includes(contractDetails?.status);

          const isTradesmanRunningOrReceiveWithoutContractor =
            userData?.user_type === STRINGS.TRADESMAN_TYPE &&
            ["receive", "running"].includes(contractDetails?.status) &&
            !contractDetails?.contractorId;

          if (
            isContractorOrTradesmanOnEstimationGiven ||
            isContractAssignedOrRunning ||
            isTradesmanRunningOrReceiveWithoutContractor
          ) {
            const jobLineItems = contractDetails?.lineItem
              ?.map((element) => {
                if (!element?.isCompleted) {
                  return {
                    id: element?.id,
                    lineItem: element?.updated_job_desc
                      ? element?.updated_job_desc
                      : element?.job_desc,
                    flatRate: (element?.updated_flat_rate
                      ? parseInt(element?.updated_flat_rate)
                      : parseInt(element?.flat_rate) || 0
                    ).toString(),
                    subContractId: element?.subContractId,
                    contractId: element?.contractId,
                    notRemovable: element?.subContract?.status === "running",
                    isChecked: true,
                    isChanged: false,
                    isDeleted: false,
                  };
                }
              })
              .filter((element) => !!element);

            navigate("/create-contract", {
              state: {
                contractDetails,
                path: isContractAssignedOrRunning ? "assigned" : "edit",
                screen: isContractAssignedOrRunning
                  ? "editAssignedEstimation"
                  : "editEstimation",
                secondaryPath: "",
                updatedTask: "updated",
                jobLineItems: jobLineItems,
              },
            });
          } else if (isContractSendOrRunning) {
            const jobLineItems = contractDetails?.lineItemsSubcontract?.map(
              (element) => ({
                id: element?.id,
                job_desc: element?.updated_job_desc
                  ? element?.updated_job_desc
                  : element?.pre_updated_job_desc
                  ? element?.pre_updated_job_desc
                  : element?.job_desc,
                flat_rate: (element?.updated_set_rate
                  ? parseInt(element?.updated_set_rate)
                  : element?.pre_updated_set_rate
                  ? parseInt(element?.pre_updated_set_rate)
                  : parseInt(element?.set_rate) || 0
                ).toString(),
                flatRate: (element?.updated_set_rate
                  ? parseInt(element?.updated_set_rate)
                  : element?.pre_updated_set_rate
                  ? parseInt(element?.pre_updated_set_rate)
                  : parseInt(element?.set_rate) || 0
                ).toString(),
                subContractId: element?.subContractId,
                contractId: element?.contractId,
                isChecked: true,
                isSelected: true,
                isChanged: false,
                isDeleted: element?.isDeleted,
              })
            );

            navigate("/create-contract", {
              state: {
                contractDetails,
                path: "edit",
                screen: "editAssignedContract",
                jobLineItems: jobLineItems,
                type: "editAssignedContract",
              },
            });
          } else {
            navigate("/create-contract", {
              state: {
                contractDetails,
                skills:
                  contractDetails?.status === "work_request_sent"
                    ? skills
                    : null,
                path: "edit",
                tradesmanID: !!contractDetails?.contractorId
                  ? contractDetails?.contractorId
                  : contractDetails?.tradesmanId,
                contractWithUser: !!contractDetails?.contractorId
                  ? STRINGS.CONTRACTOR_TYPE
                  : STRINGS.TRADESMAN_TYPE,
                type: contractDetails?.status,
              },
            });
          }
        }}
      >
        <img src={Edit} alt="Edit Contract" />
      </div>
    </div>
  );
};

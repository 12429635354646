import { setContractDetails } from "../../actions/ContractActions";
import { setGigmiCharges } from "../../actions/GigmiChargesActions";
import { SimpleCatchError } from "../../utils/Helper";
import { Axios } from "../../utils/axiosMiddleware";

export const GetContractDetails = async (payload, dispatch) => {
  try {
    const response = await Axios.get(
      `contracts/findOne/contractById/${payload}`
    );
    dispatch(setContractDetails(response?.data));
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const GenerateGigmiCharges = async (payload, dispatch) => {
  try {
    const response = await Axios.post("customer/contractor-cut", payload);
    dispatch(setGigmiCharges(response?.data?.contractor_cut));
  } catch (e) {
    SimpleCatchError(e);
  }
};

import { setContractListingType } from "../../actions/ContractActions";
import { SimpleCatchError } from "../../utils/Helper";
import { Axios } from "../../utils/axiosMiddleware";

export const CreateHamBurgContractRequest = async (
  finalData,
  contractImages,
  navigate,
  dispatch
) => {
  try {
    const response = await Axios.post("message-files/contract", finalData);
    let formData = new FormData();
    if (contractImages?.length > 0) {
      contractImages?.forEach((file) => {
        formData.append(`files`, file.originFileObj);
      });
    }
    formData.append(
      "contractIds",
      JSON.stringify([JSON.stringify(response?.data?.id)])
    );
    await Axios.post("/contract-files", formData);
    dispatch(setContractListingType("estimate"));
    navigate("/contract-lists");
  } catch (e) {
    SimpleCatchError(e);
  }
};

import React from "react";
import { bucket_base_url } from "../../constants";
import "./style.css";
import profileIcon from "../../assets/images/profileIcon.png";
export const ContractUserProfileCard = ({
  userImage,
  onPress,
  userName,
  userType,
  userTypeBackgroundColor,
  cardContainerstyle,
}) => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <div className="avatar-container">
        <img
          src={
            userImage === null || userImage === ""
              ? `${profileIcon}`
              : `${bucket_base_url}${userImage}`
          }
          onClick={onPress}
          className="avatar-image"
        />
      </div>
      <span>{userName}</span>
      <span
        style={{ backgroundColor: `${userTypeBackgroundColor}` }}
        className="userType-div mt-1"
      >
        {userType}
      </span>
    </div>
  );
};

import * as ActionTypes from "./ActionTypes";

export const setRedDot = (data) => {
  return {
    type: ActionTypes.SET_RED_DOT,
    data,
  };
};

export const setSocketConnection = (data) => {
  return {
    type: ActionTypes.IS_SOCKET_CONNECTED,
    data,
  };
};

import React, { useEffect, useState } from "react";
import "./style.css";
import Header from "../../components/Header/Header";
import { ContractCard } from "../../components/Contracts/ContractCard";
import { getContractsRequest } from "../../api/contractsApi/getContracts";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { isEmpty } from "lodash";
import { Select } from "antd";
import { IN_PROGRESS_CONTRACTS_TYPES } from "../../components/Contracts/helper";
import AddIconColored from "../../assets/images/addIconColored.svg";
import { useNavigate } from "react-router";
import backIcon from "../../assets/images/backButton.svg";
import Loader from "../../components/GeneralComponents";
import BankVerificationModal from "../../components/Contracts/BankVerificationModal";
import { socket } from "../../sockets/SocketConnection";
import ContractTabButton from "./contractTabButton";

const ContractListing = ({ intl }) => {
  const contactListingType = useSelector(
    (state) => state?.contracts?.contractListingType
  );
  const [activeMenu, setActiveMenu] = useState(contactListingType);
  const [verificationModal, setVerificationModal] = useState(false);
  const [filterValue, setFilterValue] = useState("all");

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const contracts = useSelector((state) => state?.contracts?.contracts);
  const userTypeData = useSelector((state) => state?.common?.fullUser);
  const userData = JSON.parse(localStorage.getItem("data"));
  const navigate = useNavigate();
  const isSocketConnected = useSelector(
    (state) => state?.RedDotReducer?.isSocketConnected
  );
  const redDot = useSelector(
    (state) => state?.RedDotReducer?.unSeenNotification
  );
  useEffect(() => {
    contractRequest();
  }, []);
  const contractRequest = async () => {
    setLoading(true);
    await getContractsRequest(dispatch);
    if (isSocketConnected) {
      socket.emit("red_dot", {
        user_id: userData?.id,
        user_type: userData?.user_type,
        seen: "contract",
      });
    }
    setLoading(false);
  };
  const getData = () => {
    switch (activeMenu) {
      case "estimate":
        return contracts?.contracts?.workRequestContracts;
      case "todo":
        return contracts?.contracts?.toDoContracts;
      case "inprogress":
        return contracts?.contracts?.inProgressContracts.filter(
          (item) => item?.status === filterValue || filterValue === "all"
        );
      case "archived":
        return contracts?.contracts?.archivedContracts;

      default:
        break;
    }
  };
  const currentSelectedTabData = getData();
  const onSelect = (e) => {
    setFilterValue(e);
  };
  const noContractText = () => {
    switch (activeMenu) {
      case "todo":
        return (
          contracts?.contracts?.toDoContracts?.length === 0 && (
            <span>
              <FormattedMessage id="NO_OPEN_CONTRACT" />
            </span>
          )
        );
      case "estimate":
        return (
          contracts?.contracts?.workRequestContracts?.length === 0 && (
            <span>
              <FormattedMessage id="NO_WORK_REQUESTS" />
            </span>
          )
        );
      case "inprogress":
        return (
          contracts?.contracts?.inProgressContracts?.length === 0 && (
            <span>
              <FormattedMessage id="NO_CONTRACTS_IN_PROGRESS" />
            </span>
          )
        );
      case "archived":
        return (
          contracts?.contracts?.archivedContracts?.length === 0 && (
            <span>
              <FormattedMessage id="NO_ARCHIVED_CONTRACTS" />
            </span>
          )
        );

      default:
        break;
    }
  };
  const RightIcon = () => {
    return userData?.user_type === "contractor" ? (
      <div
        onClick={() => {
          userTypeData?.sendingAccountVerify
            ? navigate("/create-contract", {
                state: {
                  path: "Open",
                },
              })
            : setVerificationModal(true);
        }}
        className="d-flex align-items-center"
        style={{ cursor: "pointer" }}
      >
        <span style={{ color: "#f77e0b" }}>
          <FormattedMessage id="contract" />
        </span>
        <img src={AddIconColored} />
      </div>
    ) : (
      <div></div>
    );
  };

  return (
    <>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}

      <div className="container">
        <Header />
        <div className="paymentscreen-inner mt-5">
          <div className="d-flex justify-content-between pb-3">
            <span className="d-flex align-items-center">
              <img
                src={backIcon}
                alt=""
                onClick={() => navigate("/")}
                style={{ cursor: "pointer" }}
                className="shadow-lg border rounded"
              />
              <span className="text-black fs-4 m-1">
                <FormattedMessage id="back" />
              </span>
            </span>
            <span className="fw-bold fs-5">
              <FormattedMessage id="contracts" />
            </span>
            <span>
              <div className="d-flex justify-content-end">
                {userData?.user_type === "homeowner" ? (
                  <div></div>
                ) : userData?.user_type === "contractor" ? (
                  activeMenu === "estimate" ? (
                    <div></div>
                  ) : (
                    <RightIcon />
                  )
                ) : userData?.user_type === "tradesman" ? (
                  <RightIcon />
                ) : null}
              </div>
            </span>
          </div>
          <div className="contract-heading">
            <div className="d-flex justify-content-between contract-heading-inner">
              <ContractTabButton
                title={`${intl?.formatMessage({ id: "estimate" })}`}
                setActiveMenu={setActiveMenu}
                setFilterValue={setFilterValue}
                socketSeen={"estimate"}
                selected={activeMenu === "estimate"}
                tab={"estimate"}
                showRedDot={redDot?.contract_status?.isEstimateSeen}
                userData={userData}
              />
              <ContractTabButton
                title={`${intl?.formatMessage({ id: "to_do" })}`}
                setActiveMenu={setActiveMenu}
                setFilterValue={setFilterValue}
                socketSeen={"todo"}
                selected={activeMenu === "todo"}
                tab={"todo"}
                showRedDot={redDot?.contract_status?.isToDoSeen}
                userData={userData}
              />
              <ContractTabButton
                title={`${intl?.formatMessage({ id: "in_progress" })}`}
                setActiveMenu={setActiveMenu}
                setFilterValue={setFilterValue}
                socketSeen={"running"}
                selected={activeMenu === "inprogress"}
                tab={"inprogress"}
                showRedDot={redDot?.contract_status?.isRunningSeen}
                userData={userData}
              />
              <ContractTabButton
                title={`${intl?.formatMessage({ id: "archived" })}`}
                setActiveMenu={setActiveMenu}
                setFilterValue={setFilterValue}
                socketSeen={"archived"}
                selected={activeMenu === "archived"}
                tab={"archived"}
                showRedDot={redDot?.contract_status?.isArchivedSeen}
                userData={userData}
              />
            </div>
          </div>
          {activeMenu === "inprogress" && (
            <Select
              options={IN_PROGRESS_CONTRACTS_TYPES}
              defaultValue="all"
              style={{
                width: "100%",
              }}
              onChange={(e) => onSelect(e)}
            />
          )}
          <div className="d-flex justify-content-center mt-5">
            {noContractText()}
          </div>
          <div className="pt-5 pb-5">
            {!isEmpty(currentSelectedTabData) &&
              currentSelectedTabData?.map((contract, index) => {
                return (
                  <div key={index}>
                    <ContractCard contractDetails={contract} />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <BankVerificationModal
        bankDetailsModal={verificationModal}
        setBankDetailsModal={setVerificationModal}
      />
    </>
  );
};
export default injectIntl(ContractListing);

import { Skeleton } from "antd";
import React from "react";

export const SkeltonCard = () => {
  return (
    <div className="video-card rounded border shadow p-4">
      <div className="h-100 d-flex flex-column justify-content-between">
        <div className="d-flex justify-content-between">
          <Skeleton.Button
            active={true}
            size="small"
            shape="round"
            style={{ width: "100px" }}
          />
          <Skeleton.Button active={true} size="small" shape="square" />
        </div>
        <div className="d-flex">
          <Skeleton active={true} />
          <div className="d-flex flex-column justify-content-between ps-4">
            <Skeleton.Avatar active={true} size="large" />
            <Skeleton.Button active={true} size="small" />
            <Skeleton.Button active={true} size="small" />
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import CreateContract from "../../components/Contracts/CreateContract";
import { useLocation } from "react-router";

export const CreateContractScreen = () => {
  const { state } = useLocation();

  return (
    <div>
      <CreateContract state={state} />
    </div>
  );
};

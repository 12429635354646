import {
  EnvironmentFilled,
  ShareAltOutlined,
  SoundOutlined,
} from "@ant-design/icons";
import { Typography } from "antd";
import badas from "../../assets/images/badas.svg";
import badasOrange from "../../assets/images/badasOrange.svg";
import badasReaction from "../../assets/images/badasReaction.svg";
import profileIcon from "../../assets/images/profileIcon.png";
import playIcon from "../../assets/images/playvideo.png";
import axios from "axios";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import muted from "../../assets/images/Mute.svg";
import { base_url } from "../../constants";
import ShareModal from "./ShareModal";
import { useDispatch, useSelector } from "react-redux";
import star from "../../assets/images/star.png";
import generateDynamicLink from "../../Firebase/generage_dynamic_link";
import {
  setSearchedProfilesList,
  setUnsubscribedUser,
} from "../../actions/CommonActions";
import contractIcon from "../../assets/images/contractIcon.svg";
import chat from "../../assets/images/chatwhite.svg";
import { leaveConversation } from "../../sockets/ChatSockets";
import { setChatDetailMobile } from "../../actions/ChatActions";
import { loadConversation } from "../../api/chatApi/loadConversation";
import { getContractValues } from "../../utils/Helper";

const { Paragraph } = Typography;

export const VideoCard = ({
  videoSrc,
  type,
  user,
  profile,
  adminSkills: skills,
}) => {
  const [showImage, setShowImage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dynamicLink, setDynamicLink] = useState("");
  const navigate = useNavigate();
  const focusRef = useRef();
  const dispatch = useDispatch();
  const [isPlaying, setPlaying] = useState(false);
  const [isMuted, setMuted] = useState(true);
  const [like, setLike] = useState(false);
  const [likesCount, setLikesCount] = useState(user?.likes);
  const [Clicked, setClicked] = useState(false);
  const [adminSkills, setAdminSkills] = useState([]);
  const LikedUsersArray = useSelector(
    (state) => state?.common?.likedUser?.data
  );
  const chatConversation = useSelector((state) => state?.chat?.conversation);
  const fullUser = useSelector((state) => state?.common?.fullUser);
  const isAuthenticated = JSON.parse(localStorage.getItem("data"));
  useEffect(() => {
    checkLikeStatus();
  }, [LikedUsersArray]);

  const getSkills = async () => {
    setAdminSkills(skills);
  };

  useEffect(() => {
    getSkills();
  }, []);

  const handleMouseEnter = () => {
    setPlaying(true);
    focusRef.current.focus();
  };

  const handleMouseLeave = () => {
    setPlaying(false);
  };

  function isVideoURL(url) {
    const videoExtensions = [".mp4", ".mov", ".avi", ".mkv", ".wmv"];
    const fileExtension = url.substring(url.lastIndexOf(".")).toLowerCase();
    return videoExtensions.includes(fileExtension);
  }

  const checkLikeStatus = () => {
    for (let i = 0; i < LikedUsersArray?.length; i++) {
      if (
        LikedUsersArray[i].savedByContractorId === accessToken.id &&
        LikedUsersArray[i].savedTradesmanId === user.id
      ) {
        setLike(true);
      }
      if (
        LikedUsersArray[i].savedByContractorId === accessToken.id &&
        LikedUsersArray[i].savedContractorId === user.id
      ) {
        setLike(true);
      }
      if (
        LikedUsersArray[i].savedByContractorId === accessToken.id &&
        LikedUsersArray[i].savedHomeownerId === user.id
      ) {
        setLike(true);
      }
      if (
        LikedUsersArray[i].savedByHomeownerId === accessToken.id &&
        LikedUsersArray[i].savedHomeownerId === user.id
      ) {
        setLike(true);
      }
      if (
        LikedUsersArray[i].savedByHomeownerId === accessToken.id &&
        LikedUsersArray[i].savedContractorId === user.id
      ) {
        setLike(true);
      }
      if (
        LikedUsersArray[i].savedByHomeownerId === accessToken.id &&
        LikedUsersArray[i].savedTradesmanId === user.id
      ) {
        setLike(true);
      }

      if (
        LikedUsersArray[i].savedByTradesmanId === accessToken.id &&
        LikedUsersArray[i].savedTradesmanId === user.id
      ) {
        setLike(true);
      }
      if (
        LikedUsersArray[i].savedByTradesmanId === accessToken.id &&
        LikedUsersArray[i].savedContractorId === user.id
      ) {
        setLike(true);
      }
      if (
        LikedUsersArray[i].savedByTradesmanId === accessToken.id &&
        LikedUsersArray[i].savedHomeownerId === user.id
      ) {
        setLike(true);
      }
    }
  };

  const isVideo = isVideoURL(videoSrc);

  const accessToken = JSON.parse(localStorage.getItem("data"));
  const userID =
    type === "tradesman" ? profile?.tradesman?.id : profile?.contractor?.id;
  const handleClick = (item) => {
    if (accessToken) {
      localStorage.setItem("selectedProfile", JSON.stringify(profile));
      window.scrollTo(0, 0);
      navigate(
        `/trads-profile?user-id=${profile?.id}&id=${userID}&type=${type}`
      );
    } else {
      dispatch(setSearchedProfilesList([]));
      navigate(`/welcome`);
    }
  };

  const handleLike = async () => {
    setClicked(true);

    if (accessToken && like === false) {
      try {
        setShowImage(true);
        await new Promise((resolve) => setTimeout(resolve, 0));
        setTimeout(() => {
          setShowImage(false);
        }, 1000);
        setLikesCount((prev) => prev + 1);
        setLike(true);

        await axios.post(
          `${base_url}message-files/like/profile`,
          {
            saved_id: user?.id,
            saved_by_id: accessToken?.id,
            saved_type: type,
            saved_by_type: accessToken?.user_type,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken?.accessToken}`,
              "ngrok-skip-browser-warning": true,
            },
          }
        );
        setClicked(false);
      } catch (error) {
        setClicked(false);
      }
    } else if (accessToken && like === true) {
      setShowImage(false);
      setLike(false);
      const saved_id = user.id;
      const saved_by_id = accessToken.id;
      const saved_type = type;
      const saved_by_type = accessToken.user_type;

      const finalData =
        saved_by_type === "contractor" && saved_type === "contractor"
          ? {
              savedByTradesmanId: null,
              savedByContractorId: saved_by_id,
              savedContractorId: saved_id,
              savedByHomeownerId: null,
              savedTradesmanId: null,
              savedHomeId: null,
              saved_type: saved_type,
            }
          : saved_by_type === "tradesman" && saved_type === "contractor"
          ? {
              savedByTradesmanId: saved_by_id,
              savedByContractorId: null,
              savedContractorId: saved_id,
              savedByHomeownerId: null,
              savedTradesmanId: null,
              savedHomeId: null,
              saved_type: saved_type,
            }
          : saved_by_type === "tradesman" && saved_type === "tradesman"
          ? {
              savedByTradesmanId: saved_by_id,
              savedByContractorId: null,
              savedContractorId: null,
              savedByHomeownerId: null,
              savedTradesmanId: saved_id,
              savedHomeId: null,
              saved_type: saved_type,
            }
          : saved_by_type === "contractor" && saved_type === "tradesman"
          ? {
              savedByTradesmanId: null,
              savedByContractorId: saved_by_id,
              savedContractorId: null,
              savedByHomeownerId: null,
              savedTradesmanId: saved_id,
              savedHomeId: null,
              saved_type: saved_type,
            }
          : saved_by_type === "homeowner" && saved_type === "tradesman"
          ? {
              savedByTradesmanId: null,
              savedByContractorId: null,
              savedByHomeownerId: saved_by_id,
              savedContractorId: null,
              savedTradesmanId: saved_id,
              savedHomeId: null,
              saved_type: saved_type,
            }
          : saved_by_type === "homeowner" && saved_type === "contractor"
          ? {
              savedByContractorId: null,
              savedByTradesmanId: null,
              savedByHomeownerId: saved_by_id,
              savedTradesmanId: null,
              savedContractorId: saved_id,
              savedHomeId: null,
              saved_type: saved_type,
            }
          : null;
      try {
        await axios.delete(`${base_url}saved-profiles`, {
          headers: {
            Authorization: `Bearer ${accessToken?.accessToken}`,
            "ngrok-skip-browser-warning": true,
          },
          data: finalData,
        });
        setLikesCount((prev) => {
          if (prev == 0) {
            return prev;
          } else {
            return prev - 1;
          }
        });
        setLike(false);
        setClicked(false);
      } catch (error) {
        setClicked(false);
      }
    } else {
      dispatch(setSearchedProfilesList([]));
      navigate("/welcome");
    }
  };

  const generateDynamicLinkClick = async () => {
    const Link = await generateDynamicLink(user.id, type);
    setDynamicLink(Link);
    setShowModal(true);
  };

  const handleShowContractIcon = () => {
    if (accessToken?.user_type === "homeowner") {
      return true;
    } else if (type !== accessToken?.user_type) {
      return true;
    }
    return false;
  };

  const handleContractClick = () => {
    getContractValues({
      navigate,
      verification: fullUser?.sendingAccountVerify,
      setVerificationModal: () => {},
      senderType: accessToken?.user_type,
      receiverType: type,
      receiverTypeId: profile?.[type]?.id,
      skills: adminSkills,
    });
  };

  const customRound = (number) => {
    if (Number.isInteger(number)) {
      return number;
    } else {
      const rounded = Math.round(number * 100) / 100;
      return rounded.toFixed(2).replace(/\.00$/, "");
    }
  };

  const getConversation = async (user) => {
    if (accessToken) {
      let item = {
        oponent: {
          first_name: user?.first_name,
          id: user?.id,
          last_name: user?.last_name,
          profile_picture: user?.profile_picture,
          userId: user?.userId,
        },
        oponentType: type === "tradesman" ? "Gigster" : "Contractor",
      };
      leaveConversation(chatConversation?.id);
      dispatch(setChatDetailMobile(true));
      const userData = JSON.parse(localStorage.getItem("data"));
      const payload = {
        senderID: userData?.id,
        receiverID: user?.id,
        senderType: userData?.user_type,
        receiverType: type,
        take: 10,
        skip: 0,
      };
      await loadConversation(payload, item, dispatch);
      navigate("/chat");
    } else {
      navigate("/welcome");
    }
  };

  const getUserSkills = () => {
    if (isEmpty(user?.skills)) {
      return null;
    }

    return user?.skills?.map((skill) => skill.user_skill)?.join(" , ");
  };

  return (
    <>
      <div className="video-card rounded bg-black">
        {isVideo ? (
          <div
            className="video"
            ref={focusRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <ReactPlayer
              config={{
                file: {
                  forceVideo: true,
                },
              }}
              width="100%"
              height="100%"
              muted={isMuted}
              url={videoSrc}
              playing={isPlaying}
              loop={true}
            />
          </div>
        ) : (
          <div className="video">
            <img src={videoSrc} className="video rounded" alt="" />
          </div>
        )}

        {isVideo && !isPlaying && (
          <div className="play-icon">
            <img src={playIcon} style={{ width: "50px" }} />
          </div>
        )}
        <div
          className="video-banner"
          style={
            type === "tradesman"
              ? { backgroundColor: "#8ABB2A" }
              : { backgroundColor: "#F77E0B" }
          }
        >
          {type === "tradesman" ? (
            <span className="">Gigster</span>
          ) : (
            <span>{type?.charAt(0)?.toUpperCase() + type?.slice(1)}</span>
          )}
        </div>

        <div
          className="d-flex flex-column align-items-center icon-div"
          style={{ width: "50px" }}
        >
          <ShareAltOutlined
            className="icon"
            style={{ fontSize: "30px", color: "white" }}
            onClick={generateDynamicLinkClick}
          />
          {isVideo && !isMuted && (
            <SoundOutlined
              style={{ fontSize: "30px", color: "white", marginTop: "10px" }}
              onClick={() => setMuted(true)}
            />
          )}
          {isVideo && isMuted && (
            <img
              src={muted}
              alt=""
              style={{ width: "30px", color: "white", marginTop: "10px" }}
              onClick={() => setMuted(false)}
            />
          )}
        </div>
        <div className="badas">
          {showImage && <img src={badasReaction} className="img-fluid w-100" />}
        </div>

        <div
          className="d-flex flex-column align-items-center icon-div1"
          style={{ width: "50px" }}
        >
          <div
            className="user-circle"
            onClick={() => {
              if (!isAuthenticated) {
                navigate("/welcome");
              } else if (isAuthenticated && fullUser?.subscribed) {
                handleClick();
              } else {
                dispatch(setUnsubscribedUser(true));
              }
            }}
            style={{ cursor: "pointer" }}
          >
            <img
              src={
                user?.profile_picture === null || user?.profile_picture === ""
                  ? profileIcon
                  : `${process.env.REACT_APP_S3_BUCKET_URL}${user?.profile_picture}`
              }
              // alt={profileIcon}
              className="gig-image img-fluid"
            />
          </div>
          <div className="d-flex flex-column justify-content-center">
            <div
              onClick={() => {
                if (!isAuthenticated) {
                  navigate("/welcome");
                } else if (fullUser?.subscribed) {
                  !Clicked && handleLike();
                } else {
                  dispatch(setUnsubscribedUser(true));
                }
              }}
              className="mt-2"
              style={{ cursor: "pointer" }}
            >
              {like ? (
                <img src={badasOrange} style={{ width: "32px" }} />
              ) : (
                <img src={badas} />
              )}
            </div>
            <span className="d-flex justify-content-center text-white">
              {likesCount}
            </span>
          </div>
          {handleShowContractIcon() && (
            <div className="d-flex flex-column justify-content-center mt-1">
              <img
                onClick={() => {
                  if (!isAuthenticated) {
                    navigate("/welcome");
                  } else if (fullUser?.subscribed) {
                    handleContractClick();
                  } else {
                    dispatch(setUnsubscribedUser(true));
                  }
                }}
                src={contractIcon}
                style={{ cursor: "pointer" }}
              />
            </div>
          )}
          <div className="d-flex flex-column justify-content-center mt-2">
            <img
              onClick={() => {
                if (!isAuthenticated) {
                  navigate("/welcome");
                } else if (fullUser?.subscribed) {
                  getConversation(user);
                } else {
                  dispatch(setUnsubscribedUser(true));
                }
              }}
              src={chat}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>

        <div className="profile-info d-flex flex-column">
          <div
            style={{ width: "220px" }}
            className="d-flex justify-content-start align-items-center profile-info-heading"
          >
            <span>
              {profile?.first_name} {profile?.last_name}
              <img src={star} className="mb-1 ms-1" />
              <span className="mt-1 ms-1 fw-normal">
                {customRound(user?.ratings)}
              </span>
            </span>
          </div>
          <Paragraph
            className="card-skills"
            ellipsis={{
              rows: 2,
              expandable: false,
            }}
          >
            {getUserSkills()}
          </Paragraph>

          <span>
            <EnvironmentFilled />
            <span>
              {profile.city},{profile.state}
            </span>
          </span>
        </div>
      </div>
      {showModal && (
        <ShareModal
          showModal={showModal}
          url={dynamicLink}
          title="check this profile"
          setShowModal={setShowModal}
        />
      )}
    </>
  );
};

import * as Actions from "../actions/ActionTypes";
const EarnSpendReducer = (
    state = { 
        total_amount:0,
        transactionsDetail:[]
    }, action) => {
  switch (action.type) {
    case Actions.SET_TOTAL_AMOUNT:
      return Object.assign({}, state, {
        total_amount: action?.data
      });
      case Actions.SET_TRANSACTIONS_DETAILS:
        return Object.assign({}, state, {
          transactionsDetail: action?.data
        });
  
 default:
    return state
    }}

export default EarnSpendReducer;
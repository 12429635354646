import { setRedDot } from "../../actions/RedDotActions";
import RedDot from "../General/RedDot";
import profileIcon from "../../assets/images/profileIcon.png";
import React, { useEffect } from "react";
import { Dropdown } from "antd";
import { BellOutlined } from "@ant-design/icons";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../GeneralComponents";
import {
  clearAllNotification,
  getNotification,
  readNotification,
} from "../../api/Notifications/notification";
import { bucket_base_url } from "../../constants";
import { truncateString } from "../../utils/Helper";
import { navigateOnNotification } from "./Helper";
import { useNavigate } from "react-router";
import AppLogo from "../../assets/images/AppLogo.png";
import { isEmpty } from "lodash";
import {
  setNotificationCount,
  setNotificationData,
} from "../../actions/NotificationActions";

export const NotificationDropDown = () => {
  const chatConversation = useSelector((state) => state?.chat?.conversation);
  const screenWidth = window.innerWidth;
  const redDot = useSelector(
    (state) => state?.RedDotReducer?.unSeenNotification
  );
  const { notifications, notificationCount } = useSelector(
    (state) => state?.notification
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchData = async () => {
    const body = {
      skip: notifications?.length,
      take: 10,
    };
    await getNotification(body, notifications, dispatch);
  };
  useEffect(() => {
    dispatch(setNotificationData([]));
    dispatch(setNotificationCount(0));
  }, []);
  const handleNavigation = (item) => {
    navigateOnNotification(
      item?.notificationType,
      navigate,
      item,
      chatConversation,
      dispatch
    );
  };
  const handleClearAll = async () => {
    await clearAllNotification(dispatch);
  };
  const notificationList = (
    <div className="notification-box shadow rounded">
      <div className="notification-header ">
        <FormattedMessage id="NOTIFICATIONS" />
        {notifications?.length > 0 && (
          <span className="clear-all" onClick={handleClearAll}>
            <FormattedMessage id="clear_all" />
          </span>
        )}
      </div>
      <div className="notification-message-div">
        <InfiniteScroll
          dataLength={notifications?.length}
          next={fetchData}
          hasMore={notificationCount > notifications?.length ? true : false}
          height={screenWidth > 767 ? 430 : 350}
          scrollThreshold={0.8}
          loader={
            <div>
              <Loader />
            </div>
          }
          endMessage={
            <div
              style={{
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "20px",
                color: "grey",
              }}
            >
              {isEmpty(notifications) ? (
                <p>
                  <FormattedMessage id="no_notifications" />
                </p>
              ) : (
                <p>
                  <FormattedMessage id="no_more_notifications" />
                </p>
              )}
            </div>
          }
        >
          {notifications?.map((item) => {
            return (
              <div
                key={item?.message}
                className="notification-message"
                onClick={() => handleNavigation(item)}
              >
                <div className="d-flex  flex-column">
                  <div
                    className="d-flex align-items-center notification-item"
                    style={{ cursor: "pointer", position: "relative" }}
                  >
                    {!item?.read && (
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          backgroundColor: "#f77e0b",
                          borderRadius: "50%",
                          position: "absolute",
                        }}
                      ></div>
                    )}
                    <div className="avatar-container">
                      {item?.sender && (
                        <img
                          src={
                            item?.sender?.profile_picture === null ||
                            item?.sender?.profile_picture === ""
                              ? `${profileIcon}`
                              : `${bucket_base_url}${item?.sender?.profile_picture}`
                          }
                          className="avatar-image"
                        />
                      )}
                      {!item?.sender && (
                        <img
                          src={AppLogo}
                          className="avatar-image"
                          style={{ objectFit: "contain" }}
                        />
                      )}
                    </div>
                    <div className="title">
                      <div className="d-flex">
                        <div className="title-name">
                          {item?.sender &&
                            truncateString(
                              `${item?.sender?.first_name} ${item?.sender?.last_name}`,
                              15
                            )}
                          {!item?.sender && "GigMi"}
                        </div>
                        <div
                          className="notification-type-tag"
                          style={
                            item?.notificationForm === "tradesman"
                              ? { backgroundColor: "rgb(138, 187, 42)" }
                              : item?.notificationForm === "contractor"
                              ? { backgroundColor: "#F77E0B" }
                              : { backgroundColor: "#5E7AA7" }
                          }
                        >
                          {item?.notificationForm === "tradesman"
                            ? "Gigster"
                            : item?.notificationForm === "homeowner"
                            ? "Homeowner"
                            : item?.notificationForm === "contractor"
                            ? "Contractor"
                            : "Admin"}
                        </div>
                      </div>
                      {item?.message && (
                        <span className="first-message">
                          {truncateString(`${item?.message}`, 35)}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
  const handleBellIconClick = async () => {
    await fetchData();
    readNotification();
    if (redDot?.isNotificationSent) {
      dispatch(
        setRedDot({
          isNotificationSent: false,
        })
      );
    }
  };
  return (
    <Dropdown
      dropdownRender={() => notificationList}
      placement="bottom"
      trigger={["click"]}
      destroyPopupOnHide={true}
      onOpenChange={() => {
        dispatch(setNotificationData([]));
        dispatch(setNotificationCount(0));
      }}
    >
      <div
        className="border rounded d-flex align-items-center justify-content-center"
        style={{ width: "58px", height: "48px", marginLeft: "10px" }}
        onClick={handleBellIconClick}
      >
        <BellOutlined />
        {redDot?.isNotificationSent && <RedDot />}
      </div>
    </Dropdown>
  );
};

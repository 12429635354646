import axios from "axios";
const currentLang = JSON.parse(localStorage.getItem("lang"));
let lang;
if (currentLang) {
  lang = "es";
} else {
  lang = "en";
}
async function translateText(text) {
  const url = `https://translate.googleapis.com/translate_a/single?client=gtx&sl=auto&tl=${lang}&dt=t&q=${text}`;
  const response = await axios.get(url);
  const data = response.data;

  const translation = data[0][0][0];
  return translation;
}

export { translateText };

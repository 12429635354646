import { Divider } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import shareImage from "../../assets/images/w9form.svg";
import AddBank from "../../assets/images/AddBank.svg";

export const Form = () => {
  const navigate = useNavigate();
  const userTypeData = useSelector((state) => state?.common?.fullUser);
  const userData = JSON.parse(localStorage.getItem("data"));
  const handleForm = () => {
    navigate(`/view-w9-form`);
  };
  return (
    <>
      {userData?.user_type === "tradesman" &&
        userTypeData?.receivingAccountVerify && (
          <div
            className="d-flex flex-column"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
            onClick={handleForm}
          >
            <div className="d-flex align-items-center p-2">
              <img
                src={shareImage}
                alt=""
                style={{ width: "20px", height: "20px" }}
              />
              <span className="dropdown-text ms-3">
                <FormattedMessage id="w9-form" />
              </span>
            </div>
            <Divider style={{ margin: "4px 0" }} />
          </div>
        )}
      {userData?.user_type === "contractor" &&
        userTypeData?.receivingAccountVerify && (
          <div
            className="d-flex flex-column"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
            onClick={handleForm}
          >
            <div className="d-flex align-items-center p-2">
              <img
                src={shareImage}
                alt=""
                style={{ width: "20px", height: "20px" }}
              />
              <span className="dropdown-text ms-3">
                <FormattedMessage id="w9-form" />
              </span>
            </div>
            <Divider style={{ margin: "4px 0" }} />
          </div>
        )}
      {/* <Modal
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        destroyOnClose={true}
      >
        <div className="d-flex flex-column">
          <span className="fs-3 fw-bold mb-3 text-center mt-3">
            <FormattedMessage id="Select" />
          </span>
          <div className="d-flex justify-content-around align-items-center">
            {((userData?.user_type === "contractor" &&
              userTypeData?.receivingAccountVerify) ||
              (userData?.user_type === "tradesman" &&
                userTypeData?.receivingAccountVerify)) && (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img
                  src={AddBank}
                  className="bank-modal-image"
                  onClick={() => {
                    setShowModal(false);
                    navigate("/view-w9-form?path=receive");
                  }}
                />
                <span className="bank-modal-text">
                  <FormattedMessage id="view_receiver" />
                </span>
              </div>
            )}
            {((userData?.user_type === "contractor" &&
              userTypeData?.sendingAccountVerify) ||
              (userData?.user_type === "tradesman" &&
                userTypeData?.sendingAccountVerify)) && (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img
                  src={AddBank}
                  className="bank-modal-image"
                  onClick={() => {
                    setShowModal(false);
                    navigate("/view-w9-form?path=sender");
                  }}
                />
                <span className="bank-modal-text">
                  <FormattedMessage id="view_sender" />{" "}
                </span>
              </div>
            )}
          </div>
        </div>
      </Modal> */}
    </>
  );
};

import { Axios } from "../../utils/axiosMiddleware";
import { SimpleCatchError } from "../../utils/Helper";
import { message } from "antd";

export const supportApi = async (payload, navigate, props) => {
  const token = JSON.parse(localStorage.getItem("data"));
  try {
    const body = {
      ...payload,
      userId: token?.user_id,
      type: token?.user_type,
    };
    await Axios.post("user-support", body);
    message.success(`${props.intl.formatMessage({ id: "message_sent" })}`);
    navigate("/");
  } catch (e) {
    console.log(e.toString());
    SimpleCatchError(e);
  }
};

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChatHeader } from "./ChatHeader";
import GetDate from "./GetDate";
import { SenderMessageBox } from "./SenderMessageBox";
import { ReceiverMessegeBox } from "./ReceiverMessegeBox";
import { ChatInput } from "./ChatInput";
import { isEmpty } from "lodash";
import "../../views/chat/style.css";
import PullToRefresh from "react-pull-to-refresh";
import { injectIntl, FormattedMessage } from "react-intl";

import {
  AllowChatRequest,
  ChatSeenRequest,
  loadConversation,
} from "../../api/chatApi/loadConversation";
import {
  joinConversation,
  sendChatMediaMsgReturn,
  sendChatMsg,
} from "../../sockets/ChatSockets";
import {
  FileImageOutlined,
  AudioOutlined,
  StopOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { getOpponentType } from "../service";
import { Axios } from "../../utils/axiosMiddleware";
import { ReactMic } from "react-mic";
import { setGetNewChatMsgs } from "../../actions/ChatActions";
import Loader from "../GeneralComponents";
import ShowContractIcon from "./ShowContractIcon";
import { FloatButton } from "antd";
import { useNavigate } from "react-router";
import { getModalTitle, getUserSkills } from "../../utils/Helper";
import Disclaimer from "../BankInfo/Disclaimer";
import { getCurrentUserDetails, getFullUser } from "../../views/Home/Service";
import BankModal from "../Header/BankModal";
import { setBankModal, setPaymentType } from "../../actions/bankActions";
import STRINGS from "../../utils/UserTypes";

const ChatBody = ({ intl }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [typedMessage, setTypedMessage] = useState("");
  const [disableChat, setDisableChat] = useState(false);
  const [loadingOnType, setLoadingOnType] = useState(null);
  const [VoiceNote, setVoiceNote] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const bottomRef = useRef(null);
  const userData = JSON.parse(localStorage.getItem("data"));
  const chatConversation = useSelector((state) => state?.chat?.conversation);
  const [elapsedTime, setElapsedTime] = useState(0);
  const intervalRef = useRef(null);

  const {
    conversationList,
    isMobile,
    newChatMessages,
    conversation,
    allowChatModal,
    allowChat,
  } = useSelector((state) => state?.chat);
  const userTypeData = useSelector((state) => state?.common?.newUser?.data);
  const fullUser = useSelector((state) => state?.common?.fullUser);
  const skills = useSelector((state) => state?.skills?.userSkills);
  let ChatUser = conversation?.opponentData?.oponent;
  const [profileCompleteModal, setProfileCompleteModal] = useState(false);
  const [showBankModal, setShowBankModal] = useState(false);
  const [userRequest, setUserRequest] = useState(true);

  useEffect(() => {
    (async () => {
      await getCurrentUserDetails(dispatch);
      await getFullUser(dispatch);
    })();
  }, []);

  useEffect(() => {
    if (VoiceNote) {
      (async () => {
        await sendMediaMsg(VoiceNote);
      })();
    }
  }, [VoiceNote]);

  useEffect(() => {
    if (newChatMessages) {
      (async () => {
        await getChatNewMsg();
      })();
    }
  }, [newChatMessages]);

  useEffect(() => {
    if (chatConversation?.id) scrolling();
  }, [chatConversation?.id]);

  useEffect(() => {
    if (chatConversation) {
      joinConversation(chatConversation);
      sendChatMediaMsgReturn(chatConversation, dispatch);
    }
  }, [chatConversation]);

  useEffect(() => {
    if (chatConversation?.opponentData?.oponent?.id) {
      (async () => {
        await getUserSkills(
          {
            receiverID: chatConversation?.opponentData?.oponent?.id,
            receiver_type: getOpponentType(chatConversation),
          },
          dispatch,
          intl
        );
      })();
    }
  }, [chatConversation]);

  useEffect(() => {
    if (conversationList?.length) {
      (async () => {
        const payload = {
          receiverID: chatConversation?.opponentData?.oponent?.id,
          roomID: chatConversation?.id,
          receiver_type: getOpponentType(chatConversation),
        };
        await ChatSeenRequest(payload);
      })();
    }
  }, [conversationList]);

  const scrolling = () => {
    setTimeout(() => {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const handleChange = async (e) => {
    setTypedMessage(e?.target?.value);
  };

  const onDropImage = async (event) => {
    setLoadingOnType("file");
    const selectedFile = event.target.files[0];
    await sendMediaMsg(selectedFile);
  };

  const startRecording = async () => {
    setIsRecording(true);
    setElapsedTime(0);
    intervalRef.current = setInterval(() => {
      setElapsedTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const stopRecording = async () => {
    setIsRecording(false);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  function blobToFile(blob, fileName) {
    const file = new File([blob], fileName, { type: blob.type });
    setVoiceNote(file);
  }

  const onAudioStop = async (data) => {
    setLoadingOnType("recording");
    if (data?.stopTime - data?.startTime > 1000) {
      blobToFile(data?.blob, "audio.mp4");
    } else {
      setLoadingOnType(null);
    }
  };

  const sendMediaMsg = async (acceptedFiles) => {
    const formData = new FormData();
    formData.append("files", acceptedFiles);
    formData.append("room", chatConversation?.id);
    formData.append("senderId", userData?.id);
    formData.append("receiverId", chatConversation?.opponentData?.oponent?.id);
    formData.append("receiverType", getOpponentType(chatConversation));
    formData.append("fileType", "document");
    formData.append("user_type", userData?.user_type);
    try {
      const response = await Axios.post("message-files", formData);
      if (response.status !== 201) {
        setLoadingOnType(null);
        alert("something went wrong please try again later");
      } else {
        scrolling();
        setLoadingOnType(null);
      }
    } catch (e) {
      setLoadingOnType(null);
      console.log(e);
    }
  };

  const SendChatMessage = async () => {
    if (typedMessage?.trim()?.length > 0) {
      let data = await sendChatMsg(
        userData,
        chatConversation,
        typedMessage,
        dispatch
      );
      if (data) {
        setTypedMessage("");
        scrolling();
      }
    }
  };

  const getChatNewMsg = async () => {
    if (conversationList?.length % 10 === 0) {
      const payload = {
        senderID: userData?.id,
        receiverID: chatConversation?.opponentData?.oponent?.id,
        senderType: userData?.user_type,
        receiverType: getOpponentType(chatConversation),
        take: 10,
        skip: conversationList?.length,
        chatOldMessages: conversationList,
      };
      await loadConversation(
        payload,
        chatConversation?.opponentData,
        dispatch,
        true
      );
    } else {
      dispatch(setGetNewChatMsgs(false));
    }
  };

  const handleRefresh = async () => {
    dispatch(setGetNewChatMsgs(true));
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };
  const getType = () => {
    if (
      userData?.user_type === "contractor" &&
      getOpponentType(chatConversation) === "tradesman"
    ) {
      return "send";
    } else if (
      userData?.user_type === "contractor" &&
      getOpponentType(chatConversation) === "homeowner"
    ) {
      return "receive";
    } else if (userData?.user_type === "tradesman") {
      return "receive";
    } else if (userData?.user_type === "homeowner") {
      return "send";
    }
  };
  const type = getType();

  const handleChatAllow = async (allowed) => {
    const senderUserId = userTypeData?.id;
    const senderUserRole = userData?.user_type;
    const receiverUserId = chatConversation?.opponentData?.oponent?.id;
    const receiverUserRole = getOpponentType(chatConversation);

    let messageBody = {
      senderID: senderUserId,
      receiverID: receiverUserId,
      senderType: senderUserRole,
      receiverType: receiverUserRole,
      take: 10,
      skip: 0,
    };
    let body = {
      id: conversation?.id,
      allowed,
      senderUserId,
      receiverUserId,
      senderUserRole,
      receiverUserRole,
    };

    if (senderUserId && senderUserRole && receiverUserId && receiverUserRole) {
      await AllowChatRequest({
        body,
        messageBody,
        chatConversation: chatConversation?.opponentData,
        dispatch,
      });
    }
    setUserRequest(false);
  };

  return (
    <div className="w-100">
      {chatConversation?.id ? (
        <div className="chat-body">
          <ChatHeader
            rightIcon={
              userData?.user_type === getOpponentType(chatConversation) ? (
                <div></div>
              ) : (
                <ShowContractIcon
                  senderType={userData?.user_type}
                  receiver_type={getOpponentType(chatConversation)}
                  tradesmanSkills={skills}
                  userTypeData={fullUser}
                  setShowProfileCompletionModal={() => {
                    setProfileCompleteModal(true);
                  }}
                  setVerificationModal={() => {
                    setShowBankModal(true);
                  }}
                  receiverTypeId={chatConversation?.opponentData?.oponent?.id}
                  allowChat={true}
                />
              )
            }
          />
          {userData?.user_type === STRINGS?.CONTRACTOR_TYPE &&
            getOpponentType(chatConversation) !== STRINGS?.CONTRACTOR_TYPE && (
              <FloatButton
                style={{
                  height: 75,
                  width: 75,
                  bottom: 110,
                  right: 24,
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                }}
                description={
                  getOpponentType(chatConversation) === STRINGS?.TRADESMAN_TYPE
                    ? "Send Receipt"
                    : "Send Invoice"
                }
                onClick={() => {
                  navigate(
                    `/generate-invoices-or-receipts?user_id=${
                      chatConversation?.opponentData?.oponent?.userId
                    }&type=${getOpponentType(chatConversation)}&id=${
                      chatConversation?.opponentData?.oponent?.id
                    }&path=chat`
                  );
                }}
              />
            )}
          {userData?.user_type === STRINGS?.CONTRACTOR_TYPE &&
            getOpponentType(chatConversation) === STRINGS?.HOMEOWNER_TYPE && (
              <FloatButton
                style={{
                  height: 75,
                  width: 75,
                  bottom: 110,
                  right: 110,
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                }}
                description={"Send Contract"}
                onClick={() => {
                  if (!skills || skills.length < 1) {
                    setShowProfileCompletionModal(true);
                    return;
                  }

                  navigate("/create-contract", {
                    state: {
                      path: "estimation",
                      skills: skills,
                      tradesmanID: chatConversation?.opponentData?.oponent?.id,
                      contractWithUser: STRINGS?.HOMEOWNER_TYPE,
                      type: "contract-reverse",
                    },
                  });
                }}
              />
            )}
          <div className="w-100 h-100 d-flex flex-column align-items-center">
            <PullToRefresh style={{ width: "100%" }} onRefresh={handleRefresh}>
              <div className="messages-div ">
                {newChatMessages && <Loader />}
                <div className="message-inner-div d-flex flex-column">
                  {!isEmpty(conversationList) &&
                    conversationList?.map((item, index) => {
                      const isSender =
                        !!item?.[`${userData?.user_type}SenderId`] &&
                        userData?.id ===
                          item?.[`${userData?.user_type}SenderId`];
                      return (
                        <React.Fragment key={index}>
                          {index === 0 && (
                            <GetDate
                              chatData={conversationList}
                              index={index}
                              firstMessage={true}
                            />
                          )}
                          <div className="w-100">
                            {isSender ? (
                              <SenderMessageBox item={item} />
                            ) : (
                              <ReceiverMessegeBox item={item} />
                            )}
                          </div>
                          <GetDate
                            chatData={conversationList}
                            index={index}
                            firstMessage={false}
                          />
                        </React.Fragment>
                      );
                    })}
                  <div style={{ height: 100 }} />
                </div>
                <div ref={bottomRef}></div>
              </div>
            </PullToRefresh>

            <div className="w-100 d-flex justify-content-center align-items-center input-div-area">
              {allowChat ? (
                <>
                  <p className="recording-text">
                    {isRecording && <>{formatTime(elapsedTime)}</>}
                  </p>
                  <ChatInput
                    value={typedMessage}
                    placeholder={`${intl?.formatMessage({
                      id: "type_message_here",
                    })}`}
                    onChange={handleChange}
                    disabled={disableChat || loadingOnType}
                    onPressEnter={async () => {
                      setDisableChat(true);
                      await SendChatMessage();
                      setDisableChat(false);
                    }}
                  />
                  {loadingOnType === "file" ? (
                    <div className="basic-loading loading-padding">
                      <Loader loadingFontSize={20} />
                    </div>
                  ) : (
                    <div>
                      <label for="browse">
                        <FileImageOutlined className="file-upload" />
                      </label>
                      <input
                        onChange={onDropImage}
                        accept="image/jpeg, image/jpg, application/pdf"
                        type="file"
                        id="browse"
                        name="browse"
                        style={{ display: "none" }}
                      />
                    </div>
                  )}
                  {loadingOnType === "recording" ? (
                    <div className="basic-loading">
                      <Loader loadingFontSize={20} />
                    </div>
                  ) : isRecording ? (
                    <StopOutlined
                      className="icon-style"
                      onMouseUp={stopRecording}
                    />
                  ) : (
                    <AudioOutlined
                      className="icon-style"
                      onMouseDown={startRecording}
                    />
                  )}
                  <ReactMic
                    className="w-0"
                    width="0"
                    height="0"
                    visualSetting="none"
                    record={isRecording}
                    onStop={onAudioStop}
                    mimeType="audio/m4a"
                    noiseSuppression={false} // defaults -> false
                  />
                  <SendOutlined
                    onClick={SendChatMessage}
                    className="icon-style"
                  />
                </>
              ) : (
                <p className="chat-declined-text">
                  <FormattedMessage id="CHAT DECLINED" />
                </p>
              )}
            </div>
          </div>
        </div>
      ) : !isMobile ? (
        <div className="no-chat-body">
          <FormattedMessage id="Please select a chat to continue" />
        </div>
      ) : null}
      <Disclaimer
        showModal={showBankModal}
        setShowModal={() => setShowBankModal(false)}
        body={getModalTitle(userData, type)}
        cancelText={<FormattedMessage id="no" />}
        confirmText={<FormattedMessage id="yes" />}
        handleClick={() => {
          dispatch(setBankModal(true));
          dispatch(setPaymentType(type));
        }}
        handleCancel={() => setShowBankModal(false)}
      />
      <BankModal />
      <Disclaimer
        showModal={profileCompleteModal}
        setShowModal={() => setProfileCompleteModal(false)}
        body={<FormattedMessage id="complete-profile" />}
        cancelText={<FormattedMessage id="cancel" />}
        confirmText={<FormattedMessage id="OK" />}
        handleClick={() => setProfileCompleteModal(false)}
        handleCancel={() => setProfileCompleteModal(false)}
      />
      {allowChatModal && (
        <Disclaimer
          showModal={userRequest}
          setShowModal={() => setProfileCompleteModal(false)}
          body={`${intl.formatMessage({ id: "ITS_A_FIRST_MESSAGE_FROM" })} "${
            ChatUser?.first_name + " " + ChatUser?.last_name
          }". ${intl.formatMessage({ id: "TO_CONNECT_PRESS_ALLOW" })}`}
          cancelText={<FormattedMessage id="cancel" />}
          confirmText={<FormattedMessage id="ALLOW" />}
          handleCancel={() => handleChatAllow(false)}
          handleClick={() => handleChatAllow(true)}
        />
      )}
    </div>
  );
};

export default injectIntl(ChatBody);

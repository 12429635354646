const firebaseConfig = {
  apiKey: "AIzaSyCc6Mkyk8KvFEW5UDMtmNDx-jZ2X9WGCTg",
  authDomain: "gigmi-342312.firebaseapp.com",
  projectId: "gigmi-342312",
  storageBucket: "gigmi-342312.appspot.com",
  messagingSenderId: "790797099459",
  appId: "1:790797099459:web:3aa210ea99988d369b1d76",
  measurementId: "G-T6BQV9DBR4",
};

export default firebaseConfig;

import { message } from "antd";
import { setWalletTransactionData } from "../../actions/WalletActions";
import { Axios } from "../../utils/axiosMiddleware";

export const WalletTransactionsDetails = async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("data"));
    const response = await Axios.get(`wallet-transaction/${token?.user_id}`);
    dispatch(setWalletTransactionData(response?.data));
  } catch (e) {
    if (e.message === "Network Error") {
      message.error(`${e?.message}`);
    } else {
      message.error(`${e?.response?.data?.message}`);
    }
  }
};

import * as ActionTypes from "./ActionTypes";


export const setTotalEarnSpendAmount = (data) => {
    return({
    type: ActionTypes.SET_TOTAL_AMOUNT,
    data
  })};
  export const setTransactionDetails = (data) => {
    return({
    type: ActionTypes.SET_TRANSACTIONS_DETAILS,
    data
  })};

import { setHowToUseAppList } from "../actions/HowToUseActions";
import { SimpleCatchError } from "../utils/Helper";
import { Axios } from "../utils/axiosMiddleware";

export const GetHowToUseApp = async (dispatch) => {
  try {
    const response = await Axios.get("banners/5/0/app");
    dispatch(setHowToUseAppList(response?.data));
  } catch (e) {
    SimpleCatchError(e);
  }
};

import React, { useEffect, useState } from "react";
import AppLogo from "../../assets/images/AppLogo.png";
import AppStore from "../../assets/images/AppStore.png";
import GooglePlay from "../../assets/images/GooglePlay.png";
import crown from "../../assets/images/crown.svg";
import tick from "../../assets/images/tick.svg";
import "./style.css";
import { FormattedMessage } from "react-intl";
import axios from "axios";
import { base_url } from "../../constants";
import { Button } from "antd";
import { getProfile } from "../../api/GetProfile";
import { useDispatch, useSelector } from "react-redux";
import { SimpleCatchError } from "../../utils/Helper";
import backIcon from "../../assets/images/backButton.svg";
import { useNavigate } from "react-router";
import Loader from "../../components/GeneralComponents";
export const Subscription = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const id = searchParams.get("typed_identifier");
  const user_id = searchParams.get("identifier");
  const user_type = searchParams.get("identifier_type");
  const userData = JSON.parse(localStorage.getItem("data"));
  const [amount, setAmount] = useState();
  const [loading, setLoading] = useState(false);
  const access_token = token ? token : userData?.accessToken;
  const user_type_id = id ? id : userData?.id;
  const main_user_id = user_id ? user_id : userData?.user_id;
  const userType = user_type ? user_type : userData?.user_type;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userTypeData = useSelector((state) => state?.common?.profile);
  useEffect(() => {
    getAmount();
    getProfile(dispatch, main_user_id, user_type_id, userType);
  }, []);
  const getAmount = async () => {
    setLoading(true);
    const response = await axios.get(`${base_url}subscriptions`, {
      headers: {
        Authorization: `Bearer ${token ? token : access_token}`,
        "ngrok-skip-browser-warning": true,
      },
    });
    setAmount(response?.data);
    setLoading(false);
  };
  const handleSubscribe = async () => {
    try {
      if (userTypeData?.nextSubscription) {
        const body = {
          userId: main_user_id,
        };
        setLoading(true);
        await axios.post(`${base_url}payment/unsubscribe`, body, {
          headers: {
            Authorization: `Bearer ${token ? token : access_token}`,
            "ngrok-skip-browser-warning": true,
          },
        });
        setLoading(false);
        navigate("/");
      } else {
        const body = {
          userId: main_user_id,
          homeownerId: userType === "homeowner" ? user_type_id : null,
          tradesmanId: userType === "tradesman" ? user_type_id : null,
          contractorId: userType === "contractor" ? user_type_id : null,
        };
        setLoading(true);
        await axios.post(`${base_url}payment/subscribe`, body, {
          headers: {
            Authorization: `Bearer ${token ? token : access_token}`,
            "ngrok-skip-browser-warning": true,
          },
        });
        setLoading(false);
        if (token) {
          navigate("/subscription?result=true");
        } else {
          navigate("/");
        }
      }
    } catch (error) {
      setLoading(false);
      SimpleCatchError(error);
    }
  };
  return (
    <>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}
      <div className="container">
        <div className="subscription-header d-flex justify-content-between align-items-center">
          <div className=" d-flex justify-content-start align-items-center">
            <img
              src={AppLogo}
              alt="logo"
              height={40}
              style={{ cursor: "pointer" }}
              onClick={() => handleReload(navigate)}
            />
          </div>
          <div className=" d-flex justify-content-center align-items-center">
            <div className="d-flex justify-content-center ms-3 store-icons">
              <a
                href="https://play.google.com/store/apps/details?id=com.gigmi"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={GooglePlay}
                  alt="Google Play"
                  style={{ width: "100px" }}
                />
              </a>
              <a
                href="https://apps.apple.com/us/app/gigmi-contractor-revolution/id1604625082"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={AppStore}
                  alt="App Store"
                  style={{ width: "100px" }}
                />
              </a>
            </div>
          </div>
        </div>
        <div>
          <span className="d-flex align-items-center">
            <img
              src={backIcon}
              alt=""
              onClick={() => navigate(-1)}
              style={{ cursor: "pointer" }}
              className="shadow-lg border rounded"
            />
            <span className="text-black fs-4 m-1">
              <FormattedMessage id="back" />
            </span>
          </span>
        </div>

        <div className="w-100 d-flex justify-content-center align-items-center mt-5">
          <span className="subscription-heading">
            <FormattedMessage id="subscription" />
          </span>
          <span className="subscription-heading2">
            <FormattedMessage id="plan" />
          </span>
        </div>
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <div className="subscription-card d-flex flex-column  align-items-center">
            <div>
              <img src={crown} />
            </div>
            <div
              className="fw-bold fs-4 mt-3"
              style={{ fontFamily: "Poppins" }}
            >
              ${amount?.amount}/ month
            </div>
            <div className="sub-divider"></div>
            <div>
              <div className="sub-item d-flex justify-content-center align-items-center">
                <img src={tick} className="me-2" />
                <span>
                  <FormattedMessage id="view_profile" />
                </span>
              </div>
              <div className="sub-item d-flex justify-content-center align-items-center">
                <img src={tick} className="me-2" />
                <span>
                  <FormattedMessage id="like" />
                </span>
              </div>
              <div className="sub-item d-flex justify-content-center align-items-center">
                <img src={tick} className="me-2" />
                <span>
                  <FormattedMessage id="share" />
                </span>
              </div>
              <div className="sub-item d-flex justify-content-center align-items-center">
                <img src={tick} className="me-2" />
                <span>
                  <FormattedMessage id="contract" />
                </span>
              </div>
              <div className="sub-item d-flex justify-content-center align-items-center">
                <img src={tick} className="me-2" />
                <span>
                  <FormattedMessage id="chat" />
                </span>
              </div>
            </div>
            <Button
              onClick={handleSubscribe}
              className="sub-button mt-5 fs-4 fw-bold"
              loading={loading}
            >
              {!userTypeData?.nextSubscription ? (
                <FormattedMessage id="subscribe" />
              ) : (
                <FormattedMessage id="unsubscribe" />
              )}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

import * as ActionTypes from "./ActionTypes";

export const setBankModal = (data) => {
  return {
    type: ActionTypes.SET_ADD_BANK_INFO_MODAL,
    data,
  };
};

export const setGigsterModal = (data) => ({
  type: ActionTypes.SET_GIGSTER_MODAL,
  data,
});
export const setContractorModal = (data) => ({
  type: ActionTypes.SET_CONTRACTOR_MODAL,
  data,
});

export const setHomeownerModal = (data) => ({
  type: ActionTypes.SET_HOMEOWNER_MODAL,
  data,
});
export const setPaymentType = (data) => ({
  type: ActionTypes.SET_PAYMENT_TYPE,
  data,
});
export const setBankAccountList = (data) => ({
  type: ActionTypes.SET_BANK_ACCOUNT_LIST,
  data,
});

import * as Actions from "../actions/ActionTypes";
const StoriesReducer = (
  state = {
    homeStoriesList: [],
  },
  action
) => {
  if (action.type == Actions.GET_ALL_STORIES) {
    return Object.assign({}, state, {
      homeStoriesList: action.storiesList,
    });
  } else {
    return state;
  }
};

export default StoriesReducer;

import React, { useState } from "react";
import { Button, Input, message } from "antd";
import { base_url } from "../../constants";
const { TextArea } = Input;
import axios from "axios";
import { useSelector } from "react-redux";
import lodash from "lodash";
import Loader from "../GeneralComponents";
import { injectIntl } from "react-intl";
function ThirdPage({ current, setCurrent, ...props }) {
  const data = useSelector((state) => state?.common?.fullUser);
  const [introText, setIntroText] = useState(
    data?.current_type === "contractor"
      ? data?.contractor?.intro_text
      : data?.current_type === "tradesman"
      ? data?.tradesman?.intro_text
      : data?.homeowner?.intro_text || ""
  );
  const accessToken = JSON.parse(localStorage.getItem("data"));
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    const validationErrors = {};
    if (!introText?.trim()) {
      validationErrors.introText = `${props.intl.formatMessage({
        id: "intro_is_required",
      })}`;
    }

    if (Object.keys(validationErrors).length > 0) {
      message.error(`${props.intl.formatMessage({ id: "intro_is_required" })}`);
      return;
    }
    try {
      setLoading(true);
      await axios
        .patch(
          `${base_url}user/${accessToken?.user_id}`,
          {
            intro_text: introText,
            user_type: accessToken?.user_type,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken?.accessToken}`,
              "ngrok-skip-browser-warning": true,
            },
          }
        )
        .then((response) => {
          setLoading(false);
          setCurrent((prevCurrent) => prevCurrent + 1);
        })
        .catch((error) => {
          const errorMessage = error.response.data.message;
          message.error(errorMessage);

          setLoading(false);
          throw error;
        });
    } catch (error) {
      message.error(
        `${props.intl.formatMessage({ id: "something_went_wrong" })}`
      );
    }
    setLoading(false);
  };

  return (
    <div className="h-50">
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <TextArea
          rows={15}
          placeholder={props.intl.formatMessage({ id: "tell_us" })}
          className="border rounded shadow p-3 h-50"
          onChange={(e) => setIntroText(e.target.value)}
          autoSize={true}
          defaultValue={introText}
        ></TextArea>

        <div className="d-flex justify-content-between mt-5">
          <div>
            {current > 0 && (
              <Button
                style={{
                  width: "120px",
                  height: "56px",
                  color: "#F77E0B",
                  border: "2px solid #F77E0B",
                }}
                onClick={() => setCurrent((prevCurrent) => prevCurrent - 1)}
              >
                {props.intl.formatMessage({ id: "previous" })}
              </Button>
            )}
          </div>
          <div>
            {current < 5 && (
              <Button
                style={{
                  width: "120px",
                  backgroundColor: "#F77E0B",
                  height: "56px",
                  color: "white",
                }}
                type="submit"
                htmlType="submit"
              >
                {props.intl.formatMessage({ id: "next" })}
              </Button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
export default injectIntl(ThirdPage);

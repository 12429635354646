import React, { useEffect, useState } from "react";
import { Input, Divider, Checkbox, Button, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { getAllSkills, getUserSkills } from "./service";
import axios from "axios";
import { base_url } from "../../constants";
import addIcon from "../../assets/images/add.svg";
import AddSkillModal from "./AddSkillModal";
import Loader from "../GeneralComponents";
import { injectIntl } from "react-intl";
function SecondPage(props) {
  const [loading, setLoading] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [skillArray, setSkillArray] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [modalShow, setModalShow] = useState(false);
  useEffect(() => {
    const setUserSkils = async () => {
      const userSkills = await getUserSkills();
      const allSkilsList = await getAllSkills();
      const customAddedSkills = userSkills?.data?.map((item) => {
        return {
          label: item?.user_skill,
          isChecked: true,
        };
      });

      const temp = allSkilsList?.map((data) => {
        return {
          label: data.label,
          isChecked: userSkills.data.some(
            (skill) => skill.user_skill === data.label
          ),
        };
      });

      const combinedArray = temp.concat(customAddedSkills);

      const uniqueObjectsMap = new Map();

      const filteredArray = [];

      for (const obj of combinedArray) {
        const label = obj.label;

        if (!uniqueObjectsMap.has(label)) {
          uniqueObjectsMap.set(label, true);
          filteredArray.push(obj);
        }
      }

      populateSelectedSkillsArray(filteredArray);
      setSkillArray([...filteredArray]);
    };
    setUserSkils();
  }, []);

  const populateSelectedSkillsArray = (payload) => {
    for (let i = 0; i < payload.length; i++) {
      if (payload[i].isChecked) {
        setSelectedSkills((prev) => [...prev, payload[i].label]);
      }
    }
  };

  const onSkillChange = (skillName, isChecked) => {
    let temp = [...skillArray];
    const skillIndexInAllSkilsArray = temp.findIndex(
      (item) => item.label == skillName
    );
    temp[skillIndexInAllSkilsArray].isChecked = isChecked;

    for (let i = 0; i < temp.length; i++) {
      if (isChecked && temp[i].label == skillName) {
        setSelectedSkills((prev) => [...prev, temp[i].label]);
      }
      if (!isChecked && temp[i].label == skillName) {
        const findElement = selectedSkills.filter((item) => item !== skillName);
        setSelectedSkills([...findElement]);
      }
    }
    setSkillArray([...temp]);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const filterSkillFunc = (value) => {
    return value?.label?.toLowerCase()?.includes(searchText?.toLowerCase());
  };
  const handleSkillSubmit = async () => {
    if (selectedSkills.length <= 0) {
      message.error(`${props.intl.formatMessage({ id: "please_select_one" })}`);
      return;
    }
    const accessToken = JSON.parse(localStorage.getItem("data"));
    const payload =
      accessToken.user_type === "contractor"
        ? {
            contractorId: accessToken.id,
            userId: accessToken.user_id,
            user_added_skills: selectedSkills,
          }
        : {
            tradesmanId: accessToken.id,
            userId: accessToken.user_id,
            user_added_skills: selectedSkills,
          };
    try {
      setLoading(true);
      await axios
        .post(`${base_url}tradesman-skills/add/skills`, payload, {
          headers: {
            Authorization: `Bearer ${accessToken?.accessToken}`,
            "ngrok-skip-browser-warning": true,
          },
        })
        .then((response) => {
          props.setCurrent((prevCurrent) => prevCurrent + 1);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          message.error(
            `${props.intl.formatMessage({ id: "unable_to_update" })}`
          );
        });
    } catch (error) {}
    setLoading(false);
  };

  return (
    <div style={{ width: "100%" }}>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          marginBottom: "8px",
        }}
      >
        <Input
          prefix={<SearchOutlined />}
          placeholder={props.intl.formatMessage({ id: "search_skills" })}
          value={searchText}
          onChange={handleSearchChange}
          style={{ width: "100%", height: "52px", borderRadius: "30px" }}
        />
      </div>
      <div className="border p-4 rounded shadow mt-4">
        {skillArray.length > 0 &&
          skillArray.filter(filterSkillFunc).map((skill, index) => (
            <div key={index} className="checkbox-c">
              <Checkbox
                checked={skill.isChecked}
                onChange={(e) => onSkillChange(skill.label, e.target.checked)}
              />
              <span style={{ marginLeft: "8px" }}>{skill.label}</span>
              <Divider />
            </div>
          ))}

        <div className="w-100 d-flex justify-content-center align-items-center">
          <div
            className="d-flex justify-content-center align-items-center "
            style={{ cursor: "pointer" }}
            onClick={() => setModalShow(true)}
          >
            <img src={addIcon} />
            <span
              style={{
                color: "#F77E0B",
                fontFamily: "Poppins",
                marginLeft: "5px",
              }}
            >
              {props.intl.formatMessage({ id: "addNewSkill" })}
            </span>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between mt-5">
        <div>
          {props.current > 0 && (
            <Button
              style={{
                width: "120px",
                height: "56px",
                color: "#F77E0B",
                border: "2px solid #F77E0B",
              }}
              onClick={() => props.setCurrent((prevCurrent) => prevCurrent - 1)}
            >
              {props.intl.formatMessage({ id: "previous" })}
            </Button>
          )}
        </div>
        <div>
          {props.current < 5 && (
            <Button
              style={{
                width: "120px",
                backgroundColor: "#F77E0B",
                height: "56px",
                color: "white",
              }}
              type="submit"
              htmlType="submit"
              onClick={handleSkillSubmit}
            >
              {props.intl.formatMessage({ id: "next" })}
            </Button>
          )}
        </div>
      </div>
      <AddSkillModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        skillArray={skillArray}
        selectedSkills={selectedSkills}
        setSkillArray={setSkillArray}
      />
    </div>
  );
}
export default injectIntl(SecondPage);

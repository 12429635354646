import { Input, message } from "antd";
import "./style.css";
import { useSelector } from "react-redux";
import Header from "../../components/Header/Header";
import { useRef, useState } from "react";
import { ShareApi } from "../../api/ShareApi/ShareApi";
import Loader from "../../components/GeneralComponents";
import { isEmpty } from "lodash";
import { FormattedMessage } from "react-intl";
const ShareApp = () => {
  const userData = useSelector((state) => state?.common?.fullUser);
  const userTypeData = useSelector((state) => state?.common?.newUser?.data);
  const textAreaRef = useRef(null);
  const [copySuccess, setCopySuccess] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  function copyToClipboard(e) {
    if (copySuccess === "") {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(textAreaRef.current);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("copy");
      selection.removeAllRanges();
      setCopySuccess("Copied!");
    }
  }

  const handleOnSubmit = async () => {
    setLoading(true);

    const referralCode =
      userData?.id +
      "-" +
      userData?.created_at?.split(".")[1].replace("Z", "") +
      "-" +
      userTypeData?.id +
      "-" +
      userTypeData?.created_at?.split(".")[1].replace("Z", "");

    if (inputValue.trim() === referralCode) {
      message.error("You cannot redeem yourself");
      setLoading(false);
      return;
    }

    const response = await ShareApi(inputValue, referralCode, userData?.id);
    if (response && response.status == 200) {
      message.success("Code Redeemed");
    } else {
      message.error("Refferal Code should be Like xxxx-xxxx-xxxxx-xxxxx");
    }
    setLoading(false);
  };

  return (
    <div className="container">
      <Header />
      <div className="screen-inner d-flex flex-column align-items-center">
        <div className="d-flex mt-2 fs-1 fw-bold ">
          <p>
            <FormattedMessage id="header.shareApp" />
          </p>
        </div>

        <div className="d-flex mt-2 circle">
          <span className="defaultColor ">{userData && userData?.refers}</span>
        </div>

        <div
          className="d-flex mt-3 fw-bold fs-4 "
          style={{ fontFamily: "Poppins" }}
        >
          <p>
            <FormattedMessage id="refferedUsers" />
          </p>
        </div>

        <div className="d-flex mt-3 " style={{ fontSize: 19 }}>
          <p>
            <FormattedMessage id="inviteUsers" />
          </p>
        </div>

        <div
          className="d-flex mt-3 text-center"
          style={{ fontSize: 18, fontFamily: "Poppins" }}
        >
          <p>
            <FormattedMessage id="more"></FormattedMessage>
            <br />
            <FormattedMessage id="views"></FormattedMessage>
          </p>
        </div>

        <button className="rectangle" onClick={copyToClipboard}>
          <span className="number" ref={textAreaRef}>
            {userData && copySuccess === ""
              ? userData?.id +
                "-" +
                userData?.created_at?.split(".")[1].replace("Z", "") +
                "-" +
                userTypeData?.id +
                "-" +
                userTypeData?.created_at?.split(".")[1].replace("Z", "")
              : copySuccess}
          </span>
        </button>

        <div className="d-flex mt-1 " style={{ fontSize: 18 }}>
          <p>Tap on code to copy to clipboard </p>
        </div>

        {loading && (
          <div className="search-loader">
            <Loader />
          </div>
        )}

        {!userData?.referred && (
          <Input
            type="text"
            className="d-flex mt-3 rectangular-input "
            placeholder="Redeem code here"
            style={{ fontFamily: "Poppins" }}
            onChange={(e) => setInputValue(e.target.value)}
            onPressEnter={handleOnSubmit}
          />
        )}
        <div className="d-flex mt-2" style={{ fontSize: 11 }}>
          <p>
            <FormattedMessage id="codeWithDashes"></FormattedMessage>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ShareApp;

import { isEmpty } from "lodash";
import { ContractCard } from "./ContractCard";

export const RenderSubContracts = ({ subContract }) => {
  return (
    <>
      {!isEmpty(subContract) &&
        subContract?.map((item) => {
          const contractStatuses =
            item?.homeownerId && !item?.tradesmanId
              ? [
                  "contractor_changed_requirements_homeowner",
                  "homeowner_accepted_changes",
                ]
              : [
                  "contractor_changed_requirements_gigster",
                  "gigster_changed_requirements_homeowner",
                  "gigster_accepted_changes",
                ];

          const showSecondaryStatus = contractStatuses?.includes(
            item?.secondary_status
          );

          return (
            <div>
              <div>
                <ContractCard
                  contractDetails={item}
                  showSecondaryStatus={showSecondaryStatus}
                />
              </div>
            </div>
          );
        })}
    </>
  );
};

import { Divider } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import shareImage from "../../assets/images/shareOrange.svg";
import { formatDate, getContractId } from "../Contracts/helper";
import { AppColors } from "../../assets/colors";
import { GenerateInvoiceRequest } from "../../api/contractsApi/GenerateInvoice";
import { GenerateReceiptRequest } from "../../api/contractsApi/generateReceipt";

export const ReceiptCard = ({ item, contractState = false, shareOnPress }) => {
  const isAuthenticated = localStorage.getItem("data");

  const contractStatuses =
    item?.homeownerId && !item?.tradesmanId
      ? [
          "contractor_changed_requirements_homeowner",
          "homeowner_accepted_changes",
        ]
      : [
          "contractor_changed_requirements_gigster",
          "gigster_changed_requirements_homeowner",
          "gigster_accepted_changes",
        ];

  const showSecondaryStatus =
    !contractState && contractStatuses?.includes(item?.secondary_status);

  const getFlatAmount = () => {
    if (showSecondaryStatus) {
      const flatAmount = parseInt(item?.changed_flat_amount);
      return flatAmount
        ? `$  ${contractDetails?.changed_flat_amount} ${(
            <FormattedMessage id="FIXED" />
          )}`
        : "---";
    } else if (item?.updated_flat_amount) {
      const flatAmount = parseInt(item?.updated_flat_amount);
      return flatAmount
        ? `$  ${item?.updated_flat_amount} ${(<FormattedMessage id="FIXED" />)}`
        : "---";
    } else {
      const flatAmount = parseInt(item?.flat_amount);
      return flatAmount ? `$  ${item?.flat_amount} Fixed` : "---";
    }
  };

  const UserImage = (item) => {
    return isAuthenticated?.user_type === "tradesman" ? (
      <img
        src={
          process.env.REACT_APP_S3_BUCKET_URL + item?.tradesman?.profile_picture
        }
        height={56}
        width={56}
      />
    ) : isAuthenticated?.user_type === "homeowner" ? (
      <img
        src={
          process.env.REACT_APP_S3_BUCKET_URL + item?.homeowner?.profile_picture
        }
        height={56}
        width={56}
      />
    ) : (
      <img
        src="https://www.gcsmc.org/assets/images/user.png"
        height={56}
        width={56}
      />
    );
  };

  const handleShare = async () => {
    const isHomeOwner =
      item?.invoice_user?.length > 0 &&
      item?.invoice_user[0]?.user_type === "homeowner";
    let fileURL;
    if (isHomeOwner) {
      fileURL = await GenerateInvoiceRequest(
        `contractor/homeowner/${item?.id}`,
        "invoicesAndReceiptScreen"
      );
    } else {
      fileURL = await GenerateReceiptRequest(
        item?.id,
        "invoicesAndReceiptScreen"
      );
    }
    shareOnPress(fileURL);
  };

  return (
    <div className="contract-card">
      <div className="row">
        <div className="col-1 d-flex justify-content-center align-items-center">
          <div
            style={{
              height: 16,
              width: 16,
              borderRadius: 8,
              backgroundColor: item?.isInvoice
                ? AppColors.RED
                : AppColors.PRIMARY_COLOR,
            }}
          />
        </div>
        <div className="col-3 d-flex justify-content-center align-items-center">
          <div className="contract-profile">
            <UserImage />
          </div>
        </div>
        <div className="col-3 d-flex justify-content-center align-items-center flex-column">
          <span className="fw-bold mt-2">
            <FormattedMessage id="CONTRACT_ID" />:
          </span>
          <span className="mt-2">{getContractId(item?.id)}</span>
        </div>
        <div className="col-3 d-flex justify-content-center align-items-center flex-column">
          <span className="fw-bold mt-2">
            <FormattedMessage id="flat_rate" />:
          </span>
          <span className="mt-2">{getFlatAmount()}</span>
        </div>
        <div className="col-2 d-flex justify-content-center align-items-center flex-column">
          <img
            src={shareImage}
            alt=""
            onClick={handleShare}
            style={{
              cursor: "pointer",
              width: "20px",
              height: "20px",
              color: "red",
            }}
          />
        </div>
      </div>
      <Divider />
      <div className="d-flex justify-content-center align-items-center">
        <span style={{ color: AppColors.PRIMARY_COLOR }}>
          Dated: {formatDate(item?.created_at)}
        </span>
      </div>
    </div>
  );
};

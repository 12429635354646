import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import Header from "../../components/Header/Header";
import Loader from "../../components/GeneralComponents";
import { useDispatch, useSelector } from "react-redux";
import { WalletTransactionsDetails } from "../../api/walletApi/WalletApi";
import "./style.css";
import { Divider } from "antd";
import IN from "../../assets/images/in.svg";
import OUT from "../../assets/images/out.svg";
const Wallet = (props) => {
  const [loading, setLoading] = useState(false);
  const userTypeData = useSelector((state) => state?.common?.fullUser);
  const walletDetails = useSelector((state) => state?.wallet?.wallet);
  const totalAmount = userTypeData?.wallet;
  const dispatch = useDispatch();
  useEffect(() => {
    getTransactions();
  }, []);
  const getTransactions = async () => {
    setLoading(true);
    await WalletTransactionsDetails(dispatch);
    setLoading(false);
  };

  return (
    <>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}

      <div className="container paymentscreen mb-5">
        <Header />
        <div className="paymentscreen-inner">
          <div className="amount-card">
            <span className="fw-bold text-white text-center fs-4">
              {props.intl.formatMessage({ id: "available_balance" })}
            </span>
            <span className="fw-normal text-white mt-4 text-center fs-2">
              ${totalAmount}
            </span>
          </div>
          <div className="w-100 p-4 shadow border in-out mt-5">
            <div className="d-flex justify-content-around">
              <span>
                IN
                <img src={IN} alt="" className="in-icon" />
              </span>
              <span>
                OUT
                <img src={OUT} alt="" className="in-icon mb-1" />
              </span>
            </div>
            <Divider />
            <div className="d-flex justify-content-around">
              <span>${walletDetails?.credit}</span>
              <span>${walletDetails?.debit}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default injectIntl(Wallet);

import React, { useState } from "react";
import { Header } from "../../components/Header";
import OTPInput from "react-otp-input";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Button } from "antd";
import "./style.css";
import { otpVerify } from "../../api/settingsApi/settings";
import { ChangeBankEmailModal } from "../../components/BankInfo/ChangeBankEmailModal";
export const OtpVerification = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const usernameType = state?.username?.includes("@")
    ? "email"
    : "Phone Number";
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const fullUser = useSelector((state) => state?.common?.fullUser);
  const handleSubmit = async () => {
    const body = {
      username: state?.username,
      otp: otp,
    };
    setLoading(true);
    await otpVerify(body, fullUser, navigate, setShowModal, usernameType);
    setLoading(false);
  };
  return (
    <div className="container">
      <Header />
      <div className="paymentscreen-inner">
        <div className="d-flex justify-content-center align-items-center mt-5 ">
          <span className="signIn-text margin-left-text fw-bolder">
            <FormattedMessage id="verification_code" />
          </span>
        </div>
        <div className="d-flex justify-content-center mt-3">
          <span className="login-sub-text text-center">
            <FormattedMessage id="we_have_sent" />
            {usernameType} {fullUser?.username}
          </span>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-5">
          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderInput={(props) => <input {...props} />}
            inputType="tel"
            shouldAutoFocus={true}
            inputStyle={{
              width: "50px",
              height: "50px",
              margin: "0 10px",
              fontSize: "24px",
              borderRadius: "4px",
              border: "1px solid rgba(0, 0, 0, 0.3)",
            }}
          />
        </div>
        <div className="d-flex justify-content-center">
          <Button
            className="button-submit mt-4"
            type="submit"
            htmlType="submit"
            onClick={handleSubmit}
            disabled={otp.length < 4 ? true : false}
            loading={loading}
          >
            <FormattedMessage id="verify" />
          </Button>
        </div>
        <div className="d-flex justify-content-center mt-3">
          <span>{"Didn’t receive OTP?"}</span>
          <span className="link">
            <a
              href="#"
              //   onClick={handleResendOtp}
            >
              <FormattedMessage id="resend_otp" />
            </a>
          </span>
        </div>
      </div>
      <ChangeBankEmailModal open={showModal} setOpen={setShowModal} />
    </div>
  );
};

import { combineReducers } from "@reduxjs/toolkit";
import AuthReducer from "./AuthReducer";
import CommonReducer from "./CommonReducer";
import StoriesReducer from "./StoriesReducer";
import BankReducer from "./BankReducer";
import EarnSpendReducer from "./EarnSpendReducer";
import WalletReducer from "./WalletReducer";
import ChatReducer from "./ChatReducer";
import W9Reducer from "./W9Reducer";
import ContractReducer from "./ContractReducer";
import ChargesReducer from "./GigmiChargesReducer";
import InvoicesReducer from "./InvoicesReducer";
import SkillsReducer from "./SkillsReducers";
import FeedsReducer from "./FeedsReducer";
import HowToReducer from "./HowToReducer";
import MapReducer from "./MapReducer";
import RedDotReducer from "./RedDotReducer";
import NotificationReducer from "./NotificationReducer";
const allReducers = combineReducers({
  auth: AuthReducer,
  skills: SkillsReducer,
  common: CommonReducer,
  stories: StoriesReducer,
  bankInfo: BankReducer,
  earnSpend: EarnSpendReducer,
  wallet: WalletReducer,
  chat: ChatReducer,
  formData: W9Reducer,
  contracts: ContractReducer,
  gigmiCharges: ChargesReducer,
  invoices: InvoicesReducer,
  feeds: FeedsReducer,
  howTo: HowToReducer,
  mapData: MapReducer,
  RedDotReducer: RedDotReducer,
  notification: NotificationReducer,
});

export default allReducers;

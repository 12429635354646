import { message } from "antd";
import { Axios } from "../../utils/axiosMiddleware";

export const sessionMaintain = async () => {
  const token = JSON.parse(localStorage.getItem("data"));
  try {
    await Axios.get(
      `user/stored/token/${token?.user_id}/${token?.accessToken}`
    );
  } catch (e) {
    if (e.message === "Network Error") {
      message.error(`${e?.message}`);
    } else {
      message.error(`${e?.response?.data?.message}`);
    }
  }
};

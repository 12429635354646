import { Button, Modal } from "antd";
import React from "react";
import { injectIntl } from "react-intl";
import { DownloadOutlined } from "@ant-design/icons";

import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  EmailIcon,
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";

function ShareModal({
  url,
  title,
  showModal,
  setShowModal,
  showDownloadOption = false,
  ...props
}) {
  return (
    <Modal
      open={showModal}
      className="p-5"
      onCancel={() => setShowModal(false)}
      maskClosable={true}
      closable={false}
      footer={false}
      style={{ height: "300px" }}
    >
      <div className="w-10 text-center p-3">
        <span
          style={{
            fontFamily: "Poppins",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          {props.intl.formatMessage({ id: "share_the_app" })}
        </span>
      </div>
      <TwitterShareButton
        url={url}
        title={"Checkout to GigMi App"}
        style={{ marginLeft: "10px" }}
      >
        <TwitterIcon size={50} round={true} />
      </TwitterShareButton>
      <EmailShareButton
        url={url}
        title={"Checkout to GigMi App"}
        style={{ marginLeft: "10px" }}
      >
        <EmailIcon size={50} round={true} />
      </EmailShareButton>
      <WhatsappShareButton
        url={url}
        title={"Checkout to GigMi App"}
        style={{ marginLeft: "10px" }}
      >
        <WhatsappIcon size={50} round={true} />
      </WhatsappShareButton>
      <FacebookShareButton
        url={url}
        title={"Checkout to GigMi App"}
        style={{ marginLeft: "10px" }}
      >
        <FacebookIcon size={50} round={true} />
      </FacebookShareButton>
      <TelegramShareButton
        url={url}
        title={"Checkout to GigMi App"}
        style={{ marginLeft: "10px" }}
      >
        <TelegramIcon size={50} round={true} />
      </TelegramShareButton>

      {showDownloadOption ? (
        <Button
          type="primary"
          shape="circle"
          icon={<DownloadOutlined />}
          style={{ marginLeft: "10px", height: 48, width: 48 }}
          onClick={() => {
            window.open(url);
            setShowModal(false);
          }}
        />
      ) : (
        <WorkplaceShareButton
          url={url}
          title={"Checkout to GigMi App"}
          style={{ marginLeft: "10px" }}
        >
          {<WorkplaceIcon size={50} round={true} />}
        </WorkplaceShareButton>
      )}
    </Modal>
  );
}

export default injectIntl(ShareModal);

import { SimpleCatchError } from "../../utils/Helper";
import { Axios } from "../../utils/axiosMiddleware";
import STRINGS from "../../utils/UserTypes";
import { sendChatMessageViaContract } from "../../sockets/ChatSockets";
import { setContractListingType } from "../../actions/ContractActions";

export const CreateContractRequest = async (
  finalBody,
  contractImages,
  contractImageIds,
  first_contract,
  navigate,
  intl,
  feedFileId
) => {
  try {
    const response = await Axios.post("message-files/contract", finalBody);
    if (contractImages?.length > 0 || contractImageIds?.length > 0) {
      let formData = new FormData();
      contractImages?.forEach((file) => {
        formData.append(`files`, file.originFileObj);
      });
      formData.append("contractIds", JSON.stringify(response?.data));
      formData.append("ids", JSON.stringify(contractImageIds || []));
      if (feedFileId) {
        formData.append("feedFileId", feedFileId);
      }
      await Axios.post("/contract-files", formData);
    }

    const filteredMessage =
      finalBody?.status === "work_request_sent"
        ? intl.formatMessage({ id: "WORKREQUEST" })
        : finalBody?.status === "contract_given"
        ? intl.formatMessage({ id: "CONTRACT_REQUEST" })
        : intl.formatMessage({ id: "ESTIMATION_REQUEST" });

    const conversation = await Axios.post(
      `/message-files/conversation/${finalBody?.user_id}/${finalBody?.tradesman_ids[0]}/${finalBody?.user_current_type}/${finalBody?.user_contracted_type}/0/0`,
      {}
    );

    if (conversation?.data?.id) {
      if (
        (finalBody?.user_contracted_type === STRINGS.TRADESMAN_TYPE &&
          finalBody?.user_current_type === STRINGS.CONTRACTOR_TYPE) ||
        (finalBody?.user_contracted_type === STRINGS.CONTRACTOR_TYPE &&
          finalBody?.user_current_type === STRINGS.TRADESMAN_TYPE)
      ) {
        sendChatMessageViaContract({
          message: `${intl.formatMessage({ id: "CONTRACT_HAS_BEEN_SENT" })} ${
            response?.data[0]
          }`,
          sender: finalBody?.user_id,
          receiver: finalBody?.tradesman_ids[0],
          room: conversation?.data?.id,
          type: "work_request",
          user_type: finalBody?.user_current_type,
          receiver_type: finalBody?.user_contracted_type,
        });
      } else {
        sendChatMessageViaContract({
          message: `${filteredMessage} ${intl.formatMessage({
            id: "HAS_BEEN_SENT",
          })} ${response?.data[0]}`,
          sender: finalBody?.user_id,
          receiver: finalBody?.tradesman_ids[0],
          room: conversation?.data?.id,
          type: "work_request",
          user_type: finalBody?.user_current_type,
          receiver_type: finalBody?.user_contracted_type,
        });
      }
    }
    navigate("/contract-lists");
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const UpdateContractRequest = async (
  finalBody,
  contractImages,
  messageData,
  navigate,
  intl
) => {
  try {
    const token = JSON.parse(localStorage.getItem("data"));
    await Axios.patch("contracts/updateContract", finalBody);

    const { contract_Id, receiverId, receiverType, currentStatus } =
      messageData;

    if (currentStatus === "work_request_sent") {
      const conversation = await Axios.post(
        `/message-files/conversation/${token.id}/${receiverId}/${token.user_type}/${receiverType}/0/0`,
        {}
      );

      if (conversation?.data?.id) {
        sendChatMessageViaContract({
          message: `${intl.formatMessage({
            id: "ESTIMATION_HAS_BEEN_SENT",
          })} ${contract_Id}`,
          sender: token.id,
          receiver: receiverId,
          room: conversation?.data?.id,
          type: "work_request",
          user_type: token.user_type,
          receiver_type: receiverType,
        });
      }
    }
    navigate("/contract-lists");
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const CreateQuickContract = async (
  finalData,
  contractImages,
  navigate,
  dispatch
) => {
  try {
    const response = await Axios.post(
      "message-files/contract/pay-direct",
      finalData
    );
    if (contractImages?.length > 0) {
      let formData = new FormData();
      contractImages?.forEach((file) => {
        formData.append(`files`, file.originFileObj);
      });
      formData.append(
        "contractIds",
        JSON.stringify([JSON.stringify(response?.data?.id)])
      );
      await Axios.post("/contract-files", formData);
    }
    dispatch(setContractListingType("estimate"));
    navigate("/contract-lists");
  } catch (e) {
    SimpleCatchError(e);
  }
};

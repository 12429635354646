import { setUsersListOnMap } from "../../actions/MapActions";
import { SimpleCatchError } from "../../utils/Helper";
import { Axios } from "../../utils/axiosMiddleware";

export const GetUsersLocations = async (dispatch) => {
  try {
    const response = await Axios.get(`/user/allUsersHavingLocation`);
    dispatch(setUsersListOnMap(response?.data?.users));
  } catch (e) {
    SimpleCatchError(e);
  }
};

import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Header from "../../components/Header/Header";
import dayjs from "dayjs";
import "./BankInfo.css";
import { useSelector } from "react-redux";
import Loader from "../../components/GeneralComponents";
import CardInput from "../../components/BankInfo/CreditCard";
import { useLocation, useNavigate } from "react-router";
import { BackButton } from "../../components/General/BackButton";
import usStates1 from "../../assets/UsStates";
import usCitiesWithCode from "../../assets/CitiesWithStateCodes";
import { getUserBankDetailsEveree } from "../../api/bankApi/AddBankAccount";

const PaymentScreenOne = (props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const path = queryParams.get("path");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [evreeBankDetails, setEvreeBankDetails] = useState(false);
  const [form] = Form.useForm();
  const fullUser = useSelector((state) => state?.common?.fullUser);
  const userData = JSON.parse(localStorage.getItem("data"));
  const navigate = useNavigate();

  const Cities = [];
  usCitiesWithCode.map((city) => {
    if (city.state === state) {
      Cities.push({
        label: city.city,
        value: city.city,
      });
    }
  });

  useEffect(() => {
    if (path === "Edit" && fullUser) {
      getUserBankDetailsEveree().then((res) => {
        if (res) {
          setEvreeBankDetails(res);
          let dob = res?.dateOfBirth ? dayjs(res.dateOfBirth) : null;
          let hire = res?.hireDate ? dayjs(res.hireDate) : null;
          const data = res;
          form.setFieldsValue({
            firstName: data?.firstName,
            lastName: data?.lastName,
            phoneNumber: data?.phoneNumber,
            email: data?.email,
            dob: dob,
            postalCode: data?.homeAddress?.current?.postalCode,
            state: data?.homeAddress?.current?.state,
            city: data?.homeAddress?.current?.city,
            address: data?.homeAddress?.current?.line1,
            hireDate: hire,
          });
        }
      });
    } else {
      form.setFieldsValue({
        firstName: fullUser?.first_name,
        lastName: fullUser?.last_name,
        phoneNumber: fullUser?.phone,
        email: fullUser?.email,
        state: fullUser?.state,
        city: fullUser?.city,
        address: fullUser?.location_name,
      });
    }
  }, [fullUser]);

  useEffect(() => {
    if (fullUser?.state) {
      const userState = usStates1.find(
        (item) => item?.label === fullUser?.state
      );
      if (userState) setState(userState?.value);
    }
  }, [fullUser]);

  const handleState = (e) => {
    setCity("");
    form.setFieldValue({ city: city });
    form.resetFields(["city"]);
    setState(e);
  };

  const disabledDate = (current) => {
    const minAllowedDate = new Date();
    minAllowedDate.setFullYear(minAllowedDate.getFullYear() - 18);
    return current && current >= minAllowedDate;
  };

  const pathname = location.pathname;
  const routePath = pathname.split('/')[1];

  const handleSubmit = async (values) => {
    const Dob = new Date(values.dob);
    const Hd = new Date(values.hireDate);
    const DOB = Dob.toISOString();
    const HD = Hd.toISOString();

    const sanitizedPhoneNumber = routePath === 'get-payments' ? values.phoneNumber.replace("+1", "") : values.phoneNumber.replace("+", "");

    const body = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.email,
      phoneNumber: sanitizedPhoneNumber,
      dateOfBirth: DOB,
      hireDate: HD,
      postalCode: values?.postalCode.replace(/\D/g, ""),
      line1: values?.address,
      city: values?.city,
      state: state,
    };
    navigate("/bank-info", {
      state: {
        bodyData: body,
        everee: path === "Edit" ? evreeBankDetails : null,
      },
    });
  };
  return (
    <>
      {loading ||
        (!fullUser?.id && (
          <div className="search-loader">
            <Loader />
          </div>
        ))}
      <BackButton />
      <div className="container paymentscreen">
        <Header />
        <div className="paymentscreen-inner">
          <Form
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            initialValues={{
              firstName: "",
              lastName: "",
              phoneNumber: "",
              email: "",
              socialSecurity: "",
              dob: "",
              postalCode: "",
              state: "",
              city: "",
              address: "",
            }}
          >
            <div className="row">
              <div className="col-6">
                <Form.Item
                  name="firstName"
                  label={props.intl.formatMessage({ id: "first_name" })}
                  rules={[
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "required",
                      })}`,
                    },
                  ]}
                >
                  <Input
                    placeholder={props.intl.formatMessage({
                      id: "first_name",
                    })}
                    className="input-field"
                  />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  name="lastName"
                  label={props.intl.formatMessage({
                    id: "last_name",
                  })}
                  rules={[
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "required",
                      })}`,
                    },
                  ]}
                >
                  <Input
                    placeholder={props.intl.formatMessage({
                      id: "last_name",
                    })}
                    className="input-field"
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item
              name="phoneNumber"
              label={props.intl.formatMessage({ id: "phone_number" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
                {
                  pattern: new RegExp(/^\+?[0-9]*(?:\.[0-9]*)?$/),
                  message: `${props.intl.formatMessage({
                    id: "valid_phone",
                  })}`,
                },
              ]}
            >
              <Input
                disabled={path === "Edit" ? true : false}
                placeholder="1234567890"
                maxLength={10}
                className="input-field"
              />
            </Form.Item>

            <Form.Item
              name="email"
              label={props.intl.formatMessage({ id: "email" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
              ]}
            >
              <Input
                disabled={path === "Edit" ? true : false}
                type="email"
                placeholder={props.intl.formatMessage({ id: "email" })}
                className="input-field"
              />
            </Form.Item>

            <div className="row">
              <div className="col-6">
                <Form.Item
                  name="dob"
                  label={props.intl.formatMessage({ id: "D_O_B" })}
                  rules={[
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "required",
                      })}`,
                    },
                  ]}
                >
                  <DatePicker
                    disabled={path === "Edit" ? true : false}
                    disabledDate={disabledDate}
                    placeholder={props.intl.formatMessage({
                      id: "select_your_dob",
                    })}
                    className="input-field w-100"
                  />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  name="postalCode"
                  label={props.intl.formatMessage({ id: "postal_code" })}
                  rules={[
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "required",
                      })}`,
                    },
                    {
                      pattern: /^[0-9 ]/,
                      message: `${props.intl.formatMessage({
                        id: "valid_post_code",
                      })}`,
                    },
                  ]}
                >
                  <Input
                    placeholder={props.intl.formatMessage({
                      id: "postal_code",
                    })}
                    className="input-field"
                  />
                </Form.Item>
              </div>
            </div>

            <Form.Item
              name="hireDate"
              label={props.intl.formatMessage({ id: "hire_date" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({
                    id: "required",
                  })}`,
                },
              ]}
            >
              <DatePicker
                placeholder={props.intl.formatMessage({
                  id: "select_your_dob",
                })}
                className="input-field w-100"
              />
            </Form.Item>

            <div className="row">
              <div className="col-6">
                <Form.Item
                  name="state"
                  label={props.intl.formatMessage({ id: "state" })}
                  rules={[
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "state_required",
                      })}`,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{
                      width: "100%",
                    }}
                    placeholder={props.intl.formatMessage({ id: "state" })}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={usStates1}
                    onSelect={(e) => handleState(e)}
                  />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  name="city"
                  label={props.intl.formatMessage({ id: "city" })}
                  rules={[
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "city_required",
                      })}`,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{
                      width: "100%",
                    }}
                    placeholder={props.intl.formatMessage({ id: "state" })}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={Cities}
                  />
                </Form.Item>
              </div>
            </div>

            <Form.Item
              name="address"
              label={props.intl.formatMessage({ id: "address" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
              ]}
            >
              <Input
                className="input-field"
                placeholder={props.intl.formatMessage({ id: "address" })}
              />
            </Form.Item>
            <div className="d-flex justify-content-end">
              <Form.Item>
                <Button
                  type="submit"
                  htmlType="submit"
                  className="submit-button"
                >
                  <FormattedMessage id="submit" />
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
        <Modal
          open={showModal}
          footer={null}
          onCancel={() => setShowModal(false)}
          destroyOnClose={true}
        >
          <div className="d-flex justify-content-center align-items-center getting-payment-modal-heading">
            <span>
              <FormattedMessage id="debit_card" />
            </span>
          </div>
          <CardInput
            tradesmanId={userData?.id}
            setLoading={setLoading}
            setShowModal={setShowModal}
          />
        </Modal>
      </div>
    </>
  );
};
export default injectIntl(PaymentScreenOne);

import React from "react";
import { Divider, Modal } from "antd";
import { FormattedMessage } from "react-intl";
import AddBank from "../../assets/images/AddBank.svg";
import { useNavigate } from "react-router";

const BankVerificationModal = ({
  bankDetailsModal,
  setBankDetailsModal,
  onCancelPress,
  cancelText,
  title,
}) => {
  const userData = JSON.parse(localStorage.getItem("data"));
  const navigate = useNavigate();
  return (
    <Modal
      open={bankDetailsModal}
      footer={null}
      onCancel={() => setBankDetailsModal(false)}
    >
      <div
        className="d-flex justify-content-center flex-column align-items-center mt-3"
        style={{ fontFamily: "Poppins" }}
      >
        <span className="fs-4">
          {title ? title : <FormattedMessage id="add_bank_info" />}
        </span>
        <img
          src={AddBank}
          className="bank-modal-image mt-3"
          onClick={() => {
            if (userData?.user_type === "contractor") {
              navigate("/payment-options");
              setBankDetailsModal(false);
            }
          }}
        />

        <span className="mt-3" style={{ fontSize: "18px" }}>
          <FormattedMessage id="add_bank_info" />
        </span>
        <Divider />
        <span
          onClick={() => {
            setBankDetailsModal(false);
            onCancelPress();
          }}
          style={{
            width: "50%",
            backgroundColor: "#f77e0b",
            height: "50px",
            color: "white",
            borderRadius: "10px",
            fontSize: "16px",
          }}
          className="d-flex justify-content-center align-items-center fw-bold"
        >
          {cancelText ? cancelText : <FormattedMessage id="CANCEL" />}
        </span>
      </div>
    </Modal>
  );
};

export default BankVerificationModal;

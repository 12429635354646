import {
  setChatListing,
  setSearchedChatListing,
} from "../../actions/ChatActions";
import { SimpleCatchError } from "../../utils/Helper";
import { Axios } from "../../utils/axiosMiddleware";

export const chatListing = async (take, skip, dispatch, searchText = "") => {
  try {
    const token = JSON.parse(localStorage.getItem("data"));
    if (token) {
      const response = await Axios.get(
        `conversation/${token?.id}/${token?.user_type}/${take}/${skip}?name=${searchText}`
      );
      const chatList = response?.data?.data?.map((data) => {
        if (
          (token?.user_type === "contractor" &&
            token?.id === data?.contractorReceiverId) ||
          (token?.user_type === "tradesman" &&
            token?.id === data?.tradesmanReceiverId) ||
          (token?.user_type === "homeowner" &&
            token?.id === data?.homeownerReceiverId)
        ) {
          if (data?.homeownerSenderId) {
            return {
              oponent: data?.homeowner_sender,
              time: data?.time_stamp,
              unread: data?.unReadCount,
              message: data?.messages,
              oponentType: "Homeowner",
            };
          } else if (data?.tradesmanSenderId) {
            return {
              oponent: data?.tradesman_sender,
              time: data?.time_stamp,
              unread: data?.unReadCount,
              message: data?.messages,
              oponentType: "Gigster",
            };
          } else {
            return {
              oponent: data?.contractor_sender,
              time: data?.time_stamp,
              unread: data?.unReadCount,
              message: data?.messages,
              oponentType: "Contractor",
            };
          }
        } else if (
          (token?.user_type === "contractor" &&
            token?.id === data?.contractorSenderId) ||
          (token?.user_type === "tradesman" &&
            token?.id === data?.tradesmanSenderId) ||
          (token?.user_type === "homeowner" &&
            token?.id === data?.homeownerSenderId)
        ) {
          if (data?.homeownerReceiverId) {
            return {
              oponent: data?.homeowner_receiver,
              time: data?.time_stamp,
              unread: data?.unReadCount,
              message: data?.messages,
              oponentType: "Homeowner",
            };
          } else if (data?.tradesmanReceiverId) {
            return {
              oponent: data?.tradesman_receiver,
              time: data?.time_stamp,
              unread: data?.unReadCount,
              message: data?.messages,
              oponentType: "Gigster",
            };
          } else {
            return {
              oponent: data?.contractor_receiver,
              time: data?.time_stamp,
              unread: data?.unReadCount,
              message: data?.messages,
              oponentType: "Contractor",
            };
          }
        }
      });
      if (!!searchText) {
        dispatch(setSearchedChatListing(chatList));
      } else {
        dispatch(setChatListing(chatList));
      }
    }
  } catch (e) {
    SimpleCatchError(e);
  }
};

import * as ActionTypes from "./ActionTypes";

export const setChatListing = (data) => {
  return {
    type: ActionTypes.SET_CHAT_ARRAY,
    data,
  };
};

export const setSearchedChatListing = (data) => {
  return {
    type: ActionTypes.SET_SEARCHED_CHAT_ARRAY,
    data,
  };
};

export const setConnectionList = (data) => {
  return {
    type: ActionTypes.SET_CONNECTIONS_ARRAY,
    data,
  };
};

export const setAllowChat = (data) => ({
  type: ActionTypes.SET_ALLOW_CHAT,
  data,
});
export const setChatDetailMobile = (data) => ({
  type: ActionTypes.SET_CHAT_DETAIL_MOBILE,
  data,
});
export const setAllowChatModal = (data) => ({
  type: ActionTypes.SET_ALLOW_CHAT_MODAL,
  data,
});
export const setIsMobile = (data) => ({
  type: ActionTypes.IS_MOBILE,
  data,
});
export const setGetNewChatMsgs = (data) => ({
  type: ActionTypes.GET_NEW_CHAT_MSGS,
  data,
});
export const setChatConversation = (data) => {
  return {
    type: ActionTypes.SET_CHAT_CONVERSATION,
    data,
  };
};

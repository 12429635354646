import { Modal } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import GalleryIcon from "../../assets/images/galleyWhite.svg";
import VideoWhiteIcon from "../../assets/images/VideoIcon.svg";
import axios from "axios";
import { base_url } from "../../constants";
import { SimpleCatchError } from "../../utils/Helper";
import Loader from "../../components/GeneralComponents";
import PropTypes from "prop-types";
import { TextOverImageModal } from "../../components/Stories/TextOverImage";

const GigSelectionModal = ({
  visible,
  onCancel,
  setData,
  handleVideoGalley,
}) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState();
  const [file, setFile] = useState();
  const [text, setText] = useState(null);
  const handleInput = (e) => {
    const file = e.target.files[0];
    setFile(file);
    const objectURL = URL.createObjectURL(file);
    setFileUrl(objectURL);
    setOpen(true);
  };
  const handleSubmit = async () => {
    const accessToken = JSON.parse(localStorage.getItem("data"));
    const formData = new FormData();
    formData.append("files", file);
    formData.append("story_duration", 4000);
    formData.append("type", "story/image");
    formData.append("userId", accessToken?.user_id);
    formData.append("overLayText", text);
    if (accessToken.user_type === "contractor") {
      formData.append("contractorId", accessToken.id);
    } else if (accessToken.user_type === "tradesman") {
      formData.append("tradesmanId", accessToken.id);
    } else if (accessToken.user_type === "homeowner") {
      formData.append("homeownerId", accessToken.id);
    }
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken?.accessToken}`,
          "ngrok-skip-browser-warning": true,
          "content-type": "multipart/form-data",
        },
      };
      const response = await axios.post(`${base_url}story`, formData, config);
      setLoading(false);
      setData(response?.data?.key);
      onCancel();
    } catch (error) {
      setLoading(false);
      SimpleCatchError(error);
    }
  };

  return (
    <div>
      <Modal
        open={visible}
        footer={null}
        destroyOnClose={true}
        onCancel={onCancel}
      >
        {loading && (
          <div className="search-loader">
            <Loader />
          </div>
        )}
        <div className="d-flex flex-column">
          <span className="fs-3 fw-bold mb-3 text-center mt-3">
            <FormattedMessage id={"upload_gigz"} />
          </span>

          <div className="d-flex justify-content-around align-items-center py-5">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <label
                htmlFor="imageInput"
                className="d-flex flex-column justify-content-center align-items-center file-upload-btn"
              >
                <img
                  src={GalleryIcon}
                  className="bank-modal-image gallery-icon"
                  alt={"GalleryIcon"}
                />
                <span className="bank-modal-text">
                  <FormattedMessage id={"select_image"} />
                </span>
              </label>
              <input
                type="file"
                id="imageInput"
                className="hide-input"
                accept="image/*"
                onChange={handleInput}
              />
            </div>
            <div
              className="d-flex flex-column justify-content-center align-items-center video-gallery-div"
              onClick={handleVideoGalley}
              onKeyDown={handleVideoGalley}
            >
              <img
                src={VideoWhiteIcon}
                className="bank-modal-image"
                alt={"Wallet"}
              />
              <span className="bank-modal-text">
                <FormattedMessage id={"select_video"} />
              </span>
            </div>
          </div>
        </div>
      </Modal>
      <TextOverImageModal
        open={open}
        setOpen={setOpen}
        fileUrl={fileUrl}
        text={text}
        setText={setText}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default GigSelectionModal;

GigSelectionModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  setData: PropTypes.array.isRequired,
  handleVideoGalley: PropTypes.func.isRequired,
};

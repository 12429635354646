import * as Actions from "../actions/ActionTypes";
const NotificationReducer = (
  state = {
    notifications: [],
    notificationCount: 0,
  },
  action
) => {
  switch (action.type) {
    case Actions.SET_NOTIFICATION_ARRAY:
      return Object.assign({}, state, {
        notifications: action?.data,
      });
    case Actions.SET_NOTIFICATION_COUNT:
      return Object.assign({}, state, {
        notificationCount: action?.data,
      });

    default:
      return state;
  }
};

export default NotificationReducer;

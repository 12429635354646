import React from "react";
import { Form, Input, Button, Select } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import { AddDebitCard } from "../../api/bankApi/AddBankAccount";
const CardInput = ({tradesmanId,setLoading,setShowModal,...props}) => {
  const [form] = Form.useForm();
  const formatCardNumber = (value) => {
    return value
      .replace(/\s/g, "")
      .replace(/(\d{4})/g, "$1 ")
      .trim();
  };
  const getYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const yearOptions = [];

    for (let i = 0; i < 10; i++) {
      yearOptions.push(currentYear + i);
    }

    return yearOptions;
  };
  const getMonthOptions = () => {
    return Array.from({ length: 12 }, (_, i) => {
      const month = i + 1 < 10 ? `0${i + 1}` : `${i + 1}`;
      return <Option key={month} value={month}>{month}</Option>;
    });
  };

  const onFinish = async(values) => {
    setShowModal(false);
    const cardNumber = values?.cardNumber?.replace(/[^\d]/g, "");
    const expiryDate=values?.expiryMonth +values?.expiryYear;
    const body= {
        tradesman_id: tradesmanId,
        name: values?.name,
        card_number: cardNumber.trim(),
        exp_date: expiryDate.trim(),
      }
      setLoading(true);
      await AddDebitCard(body);
      setLoading(false);
  };
  return (
    <Form
      form={form}
      name="cardInputForm"
      onFinish={onFinish}
      initialValues={{ cardNumber: "", expiryDate: "", cvv: "" }}
    >
    <Form.Item
        name="name"
        label={props.intl.formatMessage({id:"card_holder_name"})}
        rules={[
          { required: true, message: `${props.intl.formatMessage({id:"please_enter_name"})}` },
        ]}
      >
        <Input  className="input-field" placeholder={props.intl.formatMessage({id:"card_holder_name"})}/>
      </Form.Item>

      <Form.Item
        name="cardNumber"
        label={props.intl.formatMessage({id:"card_number"})}
        rules={[
          { required: true, message: `${props.intl.formatMessage({id:"please_card"})}` },
          {
            pattern: /^[0-9 ]{19}$/,
            message: `${props.intl.formatMessage({id:"valid_card"})}`,
          },
        ]}
      >
        <Input
          maxLength={19}
          onChange={(e) =>
            form.setFieldsValue({
              cardNumber: formatCardNumber(e.target.value),
            })
          }
          className="input-field"
          placeholder="XXXX-XXXX-XXXX-XXXX"
        />
      </Form.Item>

      <Form.Item label="Expiry Date" style={{marginLeft:"7px"}}>
        <Form.Item
          name="expiryMonth"
          style={{ display: "inline-block", width: "calc(50% - 8px)" }}
          rules={[{ required: true, message: `${props.intl.formatMessage({id:"enter_month"})}` }]}
        >
            <Select placeholder={props.intl.formatMessage({id:"month"})}>
              {getMonthOptions()}
            </Select>
        </Form.Item>

        <Form.Item
          name="expiryYear"
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "0 8px",
          }}
          rules={[{ required: true, message: `${props.intl.formatMessage({id:"enter_year"})}` }]}
        >
          <Select placeholder={props.intl.formatMessage({id:"year"})}>
            {getYearOptions().map((year) => (
              <Option key={year} value={`${year}`}>
                {year}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form.Item>

      <Form.Item
        name="cvv"
        label="CVV"
        rules={[
          { required: true, message: `${props.intl.formatMessage({id:"enter_cvv"})}` },
          {
            pattern: /^[0-9 ]/,
            message:`${props.intl.formatMessage({id:"valid_cvv"})}`,
          },
        ]}
      >
        <Input  maxLength={3} className="input-field"/>
      </Form.Item>

      <Form.Item className="d-flex justify-content-center">
        <Button className="submit-button" type="primary" htmlType="submit">
          <FormattedMessage id="submit" />
        </Button>
      </Form.Item>
    </Form>
  );
};
export default injectIntl(CardInput);

import { SimpleCatchError } from "../utils/Helper";
import { Axios } from "../utils/axiosMiddleware";

export const changeLanguage = async (id, body) => {
  try {
    await Axios.patch(`user/${id}`, body);
  } catch (e) {
    SimpleCatchError(e);
  }
};

import { setTransactionDetails } from "../../actions/EarningSpendingActions";
import { message } from "antd";
import { Axios } from "../../utils/axiosMiddleware";

export const TransactionsDetails = async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("data"));
    const response = await Axios.get(
      `payment/myPayments/${token?.id}/${token?.user_type}`
    );
    const transactions =
      response?.data.length > 0
        ? response?.data.reduce((open, val) => {
            const firstDate = val?.transaction_date;
            !open[firstDate]
              ? ((open[firstDate] = []), open[firstDate]?.push(val))
              : open[firstDate]?.push(val);
            return open;
          }, {})
        : [];

    const sortedTransactions = Object.keys(transactions).map((date) => {
      return {
        date,
        transactions: transactions[date],
      };
    });
    dispatch(setTransactionDetails(sortedTransactions));
  } catch (e) {
    if (e.message === "Network Error") {
      message.error(`${e?.message}`);
    } else {
      message.error(`${e?.response?.data?.message}`);
    }
  }
};

import { Button, Form, Input, Select, Upload } from "antd";
import React, { useEffect, useState } from "react";
import LocationSearch from "../../components/AutoCompleteLocation/AutoCompleteLocation";
import {
  CloseOutlined,
  EnvironmentOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { FormattedMessage, injectIntl } from "react-intl";
import Header from "../../components/Header/Header";
import axios from "axios";
import { GetAdminSkills } from "../../api/contractsApi/getSkillsApi";
import { useDispatch, useSelector } from "react-redux";
import { addPost, editPost } from "../../api/Feeds/GetAllPostRequest";
import { useLocation, useNavigate } from "react-router";
import { extractLatLng, extractString } from "../../utils/Helper";
import { bucket_base_url, google_map_api_key } from "../../constants";
import { isEmpty } from "lodash";
import AddSkillModal from "../../components/Contracts/AddSkillModal";
import Loader from "../../components/GeneralComponents";
import { BackButton } from "../../components/General/BackButton";

const CreatePost = ({ intl }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { path, data, onEdit, index } = state;
  const [locationLoading, setLocationLoading] = useState(true);
  const [editImage, setEditImage] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState(
    path === "edit" ? extractString(data?.latt_long_loc_name) : ""
  );
  const [coordinates, setCoordinates] = useState(
    path === "edit" ? extractLatLng(data?.latt_long_loc_name) : ""
  );
  const [fileList, setFileList] = useState(
    path === "edit" ? data?.postFile : []
  );
  const [skillOptions, setSkillOptions] = useState([]);
  const [city, setCity] = useState("");
  const getSkills = async () => {
    const res = await GetAdminSkills();
    setSkillOptions(res);
  };
  const userTypeData = useSelector((state) => state?.common?.newUser?.data);

  useEffect(() => {
    getSkills();
  }, []);
  const [form] = Form.useForm();
  const handleSubmit = async (values) => {
    if (path === "edit") {
      const payload = {
        body: {
          desc: values?.jobdesc,
          flat_rate: values?.flatrate,
          latt_long_loc_name: `${coordinates?.lat}_${coordinates?.lng}_${address}`,
          city: city,
          skill: values?.skill,
          contractorId: userTypeData?.id,
        },
        onEdit: onEdit,
        image:
          typeof fileList[0]?.key === "string" || fileList?.length === 0
            ? undefined
            : fileList[0],
        image_id:
          editImage && data?.postFile?.length > 0
            ? data?.postFile[0]?.id
            : null,
        post_id: data?.id,
        index: index,
        path: path,
      };
      setLoading(true);
      await editPost(payload, dispatch, navigate);
      setLoading(false);
    } else {
      const body = {
        desc: values?.jobdesc,
        flat_rate: values?.flatrate,
        latt_long_loc_name: `${coordinates?.lat}_${coordinates?.lng}_${address}`,
        city: city,
        skill: values?.skill,
        contractorId: userTypeData?.id,
      };
      setLoading(true);
      await addPost(body, fileList[0], dispatch, navigate);
      setLoading(false);
    }
  };
  const validateTotalAmount = (_, value) => {
    if (Number(value) > 5000) {
      return Promise.reject(
        `${intl.formatMessage({ id: "total_amount_should" })}`
      );
    }
    return Promise.resolve();
  };
  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          setCoordinates({
            lat,
            lng,
          });
          axios(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${google_map_api_key}`
          )
            .then((data) => {
              if (data.data.results && data.data.results.length > 0) {
                setAddress(data?.data?.results[0]?.formatted_address);
                let city = data?.data?.results[0].address_components.filter(
                  (x) => x.types.filter((t) => t == "locality").length > 0
                )[0];
                setCity(city?.long_name);
              }
              setLocationLoading(!locationLoading);
            })
            .catch((error) => {
              console.error(error);
            });
        },
        (error) => {
          message.error("Error getting location:", error);
        }
      );
    } else {
      message.error("Geolocation is not supported in this browser.");
    }
  };
  const onFileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const handleClearClick = () => {
    setFileList([]);
    setEditImage(true);
  };
  return (
    <>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}
      <div className="container">
        <Header />
        <div className="paymentscreen-inner">
          <div className="d-flex justify-content-start">
            {" "}
            <BackButton
              position="relative"
              top="0px"
              left="0px"
              navigateTo={-1}
            />
          </div>

          <div className="d-flex justify-content-center align-items-center getting-payment-heading border shadow text-center">
            <span>
              <FormattedMessage id="create_post" />
            </span>
          </div>

          <Form
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            initialValues={{
              skill: path === "edit" ? data?.skill : "",
              jobdesc: path === "edit" ? data?.desc : "",
              flatrate: path === "edit" ? data?.flat_rate : "",
              location: address ? address : "",
            }}
            style={{ fontFamily: "Poppins" }}
          >
            <Form.Item
              name="jobdesc"
              label={`${intl.formatMessage({ id: "job_desc" })}`}
              rules={[
                {
                  required: true,
                  message: `${intl.formatMessage({ id: "required" })}`,
                },
              ]}
            >
              <Input.TextArea
                placeholder={`${intl.formatMessage({ id: "job_desc" })}`}
                type="tdext"
                className="input-field"
                rows={4}
                style={{ padding: 20, resize: "none" }}
              />
            </Form.Item>
            <div className="row">
              <div className="col-6">
                <Form.Item
                  name="skill"
                  rules={[
                    {
                      required: true,
                      message: `${intl.formatMessage({ id: "select_skill" })}`,
                    },
                  ]}
                  style={{ marginTop: 1 }}
                >
                  <div className="row w-100">
                    <span className="col-6">
                      <FormattedMessage id="skill" />{" "}
                    </span>
                    <div className="col-6 d-flex justify-content-end">
                      <span
                        style={{
                          color: "#F77E0B",
                          fontFamily: "Poppins",
                          cursor: "pointer",
                        }}
                        onClick={() => setModalShow(true)}
                      >
                        {intl.formatMessage({ id: "addNewSkill" })}
                      </span>
                    </div>
                  </div>
                  <Select
                    style={{ paddingBottom: 0 }}
                    placeholder={`${intl.formatMessage({
                      id: "select_a_skill",
                    })}`}
                    onChange={(value) => form.setFieldsValue({ skill: value })}
                    defaultValue={path === "edit" ? data?.skill : ""}
                  >
                    {skillOptions?.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  name="flatrate"
                  label={`${intl.formatMessage({ id: "flat_rate" })}`}
                  rules={[
                    {
                      required: true,
                      message: `${intl.formatMessage({ id: "required" })}`,
                    },
                    { validator: validateTotalAmount },
                  ]}
                >
                  <Input
                    style={{ height: "55px" }}
                    type="number"
                    placeholder={`$ ${intl.formatMessage({ id: "flat_rate" })}`}
                    className="input-field"
                  />
                </Form.Item>
              </div>
            </div>

            <div className="row ">
              <div className="col-12">
                <div className="d-flex justify-content-between">
                  <div>
                    <span className="email-text">
                      {intl.formatMessage({ id: "location" })}
                    </span>
                  </div>
                  <div onClick={handleGetLocation}>
                    <span>
                      <EnvironmentOutlined style={{ color: "#F77E0B" }} />
                      <span
                        className="trad-location ms-1"
                        style={{ userSelect: "none" }}
                      >
                        {intl.formatMessage({ id: "current_location" })}
                      </span>
                    </span>
                  </div>
                </div>

                <Form.Item
                  name="location"
                  rules={[
                    {
                      required: false,
                      message: `${intl.formatMessage({
                        id: "location_required",
                      })}`,
                    },
                  ]}
                >
                  <LocationSearch
                    address={address}
                    setAddress={setAddress}
                    coordinates={coordinates}
                    setCoordinates={setCoordinates}
                    setCity={setCity}
                  />
                </Form.Item>
              </div>
            </div>
            <div style={{ height: "150px", width: "100%" }}>
              {!isEmpty(fileList) &&
                path === "edit" &&
                data?.postFile?.length > 0 &&
                !editImage &&
                fileList?.map((item, index) => (
                  <div className="m-2">
                    {(item?.type === "image/jpeg" ||
                      item?.key?.includes(".jpeg")) && (
                      <div style={{ position: "relative" }}>
                        <div className="contract-images" key={item?.id}>
                          <img
                            src={`${bucket_base_url}${item?.key}`}
                            className="w-100 h-100 rounded"
                            style={{
                              objectFit: "cover",
                              position: "relative",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            left: 105,
                            top: -5,
                          }}
                        >
                          <span
                            className="close-icon"
                            onClick={handleClearClick}
                            style={{ cursor: "pointer" }}
                          >
                            <CloseOutlined />
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                ))}

              <Upload
                action="/upload.do"
                listType="picture-card"
                maxCount={1}
                accept=".jpeg"
                onChange={onFileChange}
              >
                {fileList?.length >= 1 ? null : (
                  <div
                    style={{
                      width: "25px",
                      height: "25px",
                      backgroundColor: "#F77E0B",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <PlusOutlined style={{ color: "white" }} />
                  </div>
                )}
              </Upload>
            </div>

            <div className="d-flex justify-content-end">
              <Form.Item>
                <Button
                  type="submit"
                  htmlType="submit"
                  className="submit-button"
                >
                  <FormattedMessage id="submit" />
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
        <AddSkillModal
          modalShow={modalShow}
          setModalShow={setModalShow}
          skillArray={skillOptions}
          selectedSkills={selectedSkills}
          setSkillArray={setSkillOptions}
          setSelectedSkills={setSelectedSkills}
        />
      </div>
    </>
  );
};
export default injectIntl(CreatePost);

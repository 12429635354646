import React from "react";
import "./style.css";
import logo from "../../assets/images/gigmi-logo.svg";
import { useParams } from "react-router-dom";

const SoftwareLicense = () => {
  const param = useParams();
  const { lang } = param;

  return (
    <div style={{ backgroundColor: "#F77E0B" }}>
      <div
        style={{ backgroundColor: "#F77E0B", height: "100%" }}
        className="container pt-5"
      >
        <div className=" top-bar w-100">
          <div className="d-flex justify-content-center w-100">
            <img src={logo} alt="" />
          </div>
        </div>

        {/* <div classNameName="container-md"> */}
        <div classNameName="row ">
          <div classNameName="col-sm-12">
            <div
              className="et_pb_section et_pb_section_0 et_section_regular snipcss-R1jSL mt-3"
              style={{ borderRadius: "15px" }}
            >
              <div className="et_pb_row et_pb_row_0">
                <div className="et_pb_column et_pb_column_4_4 et_pb_column_0  et_pb_css_mix_blend_mode_passthrough et-last-child">
                  <div className="et_pb_module et_pb_text et_pb_text_0  et_pb_text_align_left et_pb_bg_layout_light">
                    <div className="et_pb_text_inner">
                      <p style={{ textAlign: "center" }}></p>
                      <p style={{ textAlign: "center" }}></p>
                      <p style={{ textAlign: "center" }}>
                        <span
                          style={{
                            fontSize: "24px",
                            fontFamily: "Poppins",
                            fontWeight: "bold",
                          }}
                        >
                          <strong>
                            {lang === "en"
                              ? "IMPORTANT INFORMATION"
                              : "INFORMACIÓN IMPORTANTE."}
                          </strong>
                        </span>
                      </p>
                      <p style={{ textAlign: "left" }}>
                        <span
                          style={{
                            fontSize: "medium",
                            fontFamily: "Poppins",
                            fontWeight: "normal",
                          }}
                        >
                          {lang === "en"
                            ? `USE OF THIS SOFTWARE IS SUBJECT TO LICENSE RESTRICTIONS. CAREFULLY
                        READ THIS LICENSE AGREEMENT BEFORE USING THE SOFTWARE. USE OF THIS
                        SOFTWARE IS INDICATES YOUR COMPLETE AND UNCONDITIONAL ACCEPTANCE
                        OF THE TERMS AND CONDITIONS SET FORTH IN THIS AGREEMENT ANY
                        ADDITIONAL OR DIFFERENT PURCHASE ORDER TERMS AND CONDITIONS SHALL
                        NOT APPLY.`
                            : `EL USO DE ESTE SOFTWARE ESTÁ SUJETO A RESTRICCIONES DE LICENCIA. LEA ATENTAMENTE ESTE CONTRATO DE LICENCIA ANTES DE UTILIZAR EL SOFTWARE. EL USO DE ESTE SOFTWARE INDICA SU ACEPTACIÓN COMPLETA E INCONDICIONAL DE LOS TÉRMINOS Y CONDICIONES ESTABLECIDOS EN ESTE ACUERDO. CUALQUIER TÉRMINO O CONDICIÓN DE PEDIDO DE COMPRA ADICIONAL O DIFERENTE NO SE APLICARÁ.`}
                        </span>
                      </p>
                      <p style={{ textAlign: "left" }}></p>
                      <p style={{ textAlign: "center" }}>
                        <span
                          style={{
                            fontSize: "medium",
                            fontFamily: "Poppins",
                            fontWeight: "normal",
                          }}
                        >
                          <strong>
                            {lang === "en"
                              ? `END-USER LICENSE AGREEMENT (“Agreement”)`
                              : `CONTRATO DE LICENCIA DE USUARIO FINAL ('Acuerdo')`}
                          </strong>
                        </span>
                      </p>
                      <p style={{ textAlign: "left" }}>
                        <span
                          style={{
                            fontSize: "medium",
                            fontFamily: "Poppins",
                            fontWeight: "normal",
                          }}
                        >
                          {lang === "en"
                            ? `This is a legal agreement concerning the use of software between
                        you, the end user, as an authorized representative of the company
                        acquiring the license and GigMi LLC acting directly or through
                        their subsidiaries or authorized distributors (collectively
                        “GigMi”). Except for license agreements related to the subject
                        matter of this license agreement which are physically signed by
                        you and an authorized representative of GigMi, this Agreement and
                        the applicable purchase agreement for the GigMi system contain the
                        parties’ entire understanding relating to the subject matter and
                        supersede all prior or contemporaneous agreements. If you do not
                        agree to these terms and conditions, promptly return or, if
                        received electronically, certify destruction of Software and all
                        accompanying items within five days after receipt of Software and
                        receive a full refund of any license fee paid.`
                            : `Este es un acuerdo legal relacionado con el uso de software entre usted, el usuario final, en calidad de representante autorizado de la empresa adquiriente de la licencia, y GigMi LLC, actuando directamente o a través de sus subsidiarias o distribuidores autorizados (en conjunto, "GigMi"). A excepción de los acuerdos de licencia relacionados con el objeto de este acuerdo de licencia que sean firmados físicamente por usted y un representante autorizado de GigMi, este Acuerdo y el acuerdo de compra correspondiente para el sistema de GigMi contienen el entendimiento completo de las partes relacionado con el objeto del acuerdo y reemplazan todos los acuerdos anteriores o contemporáneos. Si no está de acuerdo con estos términos y condiciones, le rogamos que devuelva de inmediato el Software, o, si lo recibió electrónicamente, certifique su destrucción en un plazo de cinco días después de recibir el Software, y recibirá un reembolso completo de cualquier tarifa de licencia pagada`}
                        </span>
                      </p>
                      <p style={{ textAlign: "left" }}></p>
                      <p style={{ textAlign: "left" }}>
                        <span
                          style={{
                            fontSize: "medium",
                            fontFamily: "Poppins",
                            fontWeight: "normal",
                          }}
                        >
                          <strong>
                            {" "}
                            {lang === "en" ? `1. GRANT OF LICENSE.` : ""}
                          </strong>
                          {lang === "en"
                            ? `This software programs, applications, widgets, or online services
                        you are installing, downloading, or have acquired with this
                        Agreement, including any updates, modifications, revisions,
                        copies, documentation and design data (“Software”) are
                        copyrighted, trade secret and confidential information of GigMi or
                        its licencors who maintain exclusive title to all Software and
                        retain all rights not expressly granted by this Agreement GigMi
                        grants to you, subject to payment of appropriate license fees, a
                        nontransferable, nonexclusive license to use Software solely:
                        (a)&nbsp;in machine-readable, object-code form; (b)&nbsp;for your
                        internal business purposes; (c) for the term; and (d) as otherwise
                        authorized by GigMi. GigMi’s standard policies and programs, which
                        vary depending on license fees paid or service purchased, apply to
                        the following: (a)&nbsp;relocation of Software; (b)&nbsp;use of
                        Software, which may be limited, for example, to execution on
                        authorized hardware or for a restricted period of time (such
                        limitations may be technically implemented through the use of
                        authorization codes or similar devices); and (c)&nbsp;support
                        services provided, including eligibility to receive telephone and
                        online support, updates, modifications, updates, and revisions.
                        You acknowledge and agree that you will not receive updates or
                        modifications of the Software if you do not participate in GigMi’s
                        Support and Upgrade Program.`
                            : `Los programas de software, aplicaciones, widgets o servicios en línea que está instalando, descargando o ha adquirido con este Acuerdo, incluyendo cualquier actualización, modificación, revisión, copia, documentación y datos de diseño (en adelante, "Software"), están protegidos por derechos de autor, secretos comerciales e información confidencial de GigMi o de sus licenciantes, quienes mantienen el título exclusivo sobre todo el Software y conservan todos los derechos que no se conceden expresamente mediante este Acuerdo. GigMi le otorga, previo pago de las tarifas de licencia correspondientes, una licencia no transferible y no exclusiva para utilizar el Software exclusivamente: (a) en forma de código objeto legible por máquina; (b) para sus propios fines comerciales internos; (c) durante el plazo; y (d) según lo autorizado por GigMi. Las políticas y programas estándar de GigMi, que varían en función de las tarifas de licencia pagadas o el servicio adquirido, se aplican a lo siguiente: (a) reubicación del Software; (b) uso del Software, que puede estar limitado, por ejemplo, a la ejecución en hardware autorizado o por un período de tiempo limitado (tales limitaciones pueden implementarse técnicamente mediante el uso de códigos de autorización u otros dispositivos similares); y (c) servicios de soporte proporcionados, incluida la elegibilidad para recibir soporte telefónico y en línea, actualizaciones, modificaciones, actualizaciones y revisiones. Usted reconoce y acepta que no recibirá actualizaciones ni modificaciones del Software si no participa en el Programa de Soporte y Actualización de GigMi.`}
                        </span>
                      </p>
                      <p style={{ textAlign: "left" }}></p>
                      <p style={{ textAlign: "left" }}>
                        <span
                          style={{
                            fontSize: "medium",
                            fontFamily: "Poppins",
                            fontWeight: "normal",
                          }}
                        >
                          <strong>
                            {lang === "en" ? `2. BETA CODE.` : ``}
                          </strong>
                          {lang === "en"
                            ? `Portions or all of certain Software may contain code for
                        experimental testing and evaluation (“Beta Code”), which may not
                        be used without GigMi’s explicit authorization and separate
                        agreement. Upon GigMi’s authorization, GigMi grants to you a
                        temporary, nontransferable, nonexclusive license for experimental
                        use to test and evaluate the Beta Code`
                            : `Ciertas porciones o la totalidad de cierto Software pueden contener código para pruebas y evaluación experimentales (en adelante, "Código Beta"), que no puede utilizarse sin la autorización explícita de GigMi y un acuerdo por separado. Con la autorización de GigMi, GigMi le otorga una licencia temporal, no transferible y no exclusiva para uso experimental con el fin de probar y evaluar el Código Beta`}
                          <b></b>
                          {lang === "en"
                            ? `without charge for a limited period of time specified by GigMi.
                        This grant and your use of the Beta Code shall not be construed as
                        marketing or offering to sell a license to the Beta Code, which
                        GigMi may choose not to release commercially in any form. If GigMi
                        authorizes you to use the Beta Code, you agree to evaluate and
                        test the Beta Code under normal conditions as directed by GigMi.
                        You will contact GigMi periodically during your use of the Beta
                        Code to discuss any malfunctions or suggested improvements. You
                        agree that any written evaluations and all inventions, product
                        improvements, modifications or developments that GigMi conceived
                        or made during or subsequent to this Agreement, including those
                        based partly or wholly on your feedback, will be the exclusive
                        property of GigMi. GigMi will have exclusive rights, title and
                        interest in all such property. The provisions of this section 2
                        shall survive the termination or expiration of this Agreement.`
                            : `sin cargo durante un período limitado de tiempo especificado por GigMi. Esta concesión y su uso del Código Beta no deben interpretarse como una oferta de comercialización o venta de una licencia para el Código Beta, que GigMi puede optar por no lanzar comercialmente en ninguna forma. Si GigMi le autoriza a utilizar el Código Beta, acepta evaluar y probar el Código Beta en condiciones normales según las indicaciones de GigMi. Se compromete a ponerse en contacto con GigMi periódicamente durante su uso del Código Beta para discutir cualquier mal funcionamiento o mejoras sugeridas. Usted acepta que cualquier evaluación por escrito y todas las invenciones, mejoras de productos, modificaciones o desarrollos que GigMi conciba o realice durante o después de este Contrato, incluidos aquellos basados parcial o totalmente en sus comentarios, serán propiedad exclusiva de GigMi. GigMi tendrá derechos exclusivos, título e interés en todos estos activos. Las disposiciones de esta sección 2 sobrevivirán a la terminación o expiración de este Contrato.`}
                        </span>
                      </p>
                      <p style={{ textAlign: "left" }}></p>
                      <p style={{ textAlign: "left" }}>
                        <span
                          style={{
                            fontSize: "medium",
                            fontFamily: "Poppins",
                            fontWeight: "normal",
                          }}
                        >
                          <strong>
                            {lang === "en"
                              ? `3. RESTRICTIONS ON USE.`
                              : `3. RESTRICCIONES DE USO.`}
                          </strong>
                          {lang === "en"
                            ? `You may copy Software only as reasonably necessary to support the
                        authorized use. Each copy must include all notices and legends
                        embedded in Software and affixed to its medium and container as
                        received from GigMi. All copies shall remain the property of GigMi
                        or its licensors. You shall maintain a record of the number and
                        primary location of all copies of Software and shall make those
                        records available to GigMi upon request. You shall not make
                        Software available in any form to any person other than employees
                        and on-site contractors, excluding GigMi’s competitors, whose job
                        performance requires access and who are under obligations of
                        confidentiality. You shall take appropriate action to protect the
                        confidentiality of Software and ensure that any person permitted
                        access to Software does not disclose it or use it except as
                        permitted by this Agreement. Except as otherwise permitted for
                        purposes of interoperability as specified by applicable and
                        mandatory local law, you shall not reverse-assemble,
                        reverse-compile, reverse-engineer or in any way derive from
                        Software any source code. You may not sublicense, assign or
                        otherwise transfer Software, this Agreement or the rights under
                        it, whether by operation of law or otherwise (“attempted
                        transfer”), without GigMi’s prior written consent and payment of
                        GigMi’s then-current applicable transfer charges. Any attempted
                        transfer without GigMi’s prior written consent shall be a material
                        breach of this Agreement and may, at GigMi’s option, result in the
                        immediate termination of the Agreement and licenses granted under
                        this Agreement. The terms of this Agreement, including without
                        limitation, the licensing and assignment provisions shall be
                        binding upon your successors in interest and assigns. The
                        provisions of this section 3 shall survive the termination or
                        expiration of this Agreement.`
                            : `Puede copiar el Software solo en la medida en que sea razonablemente necesario para respaldar el uso autorizado. Cada copia debe incluir todos los avisos y leyendas incrustados en el Software y adjuntos a su medio y contenedor tal como se recibió de GigMi. Todas las copias seguirán siendo propiedad de GigMi o de sus licenciatarios. Deberá mantener un registro del número y la ubicación principal de todas las copias del Software y poner esos registros a disposición de GigMi a pedido. No debe poner el Software a disposición de ninguna persona que no sean empleados y contratistas in situ, excluyendo a los competidores de GigMi, cuyo desempeño laboral requiere acceso y que están sujetos a obligaciones de confidencialidad. Deberá tomar medidas adecuadas para proteger la confidencialidad del Software y asegurarse de que cualquier persona a la que se le permita acceder al Software no lo divulgue ni lo utilice, excepto según lo permitido por este Acuerdo. A menos que la ley local aplicable y obligatoria lo permita de otro modo para fines de interoperabilidad, no deberá realizar ingeniería inversa, desmontar, descompilar ni derivar de ninguna manera el código fuente del Software. No puede sublicenciar, ceder ni transferir el Software, este Acuerdo ni los derechos en virtud del mismo, ya sea por operación de ley o de otro modo ("transferencia intentada"), sin el consentimiento previo por escrito de GigMi y el pago de las tarifas de transferencia aplicables en ese momento por GigMi. Cualquier intento de transferencia sin el consentimiento previo por escrito de GigMi constituirá una violación sustancial de este Acuerdo y, a elección de GigMi, podrá resultar en la terminación inmediata del Acuerdo y de las licencias otorgadas en virtud de este Acuerdo. Las disposiciones de esta sección 3 sobrevivirán a la terminación o expiración de este Acuerdo.`}
                        </span>
                      </p>
                      <p style={{ textAlign: "left" }}></p>
                      <p style={{ textAlign: "left" }}>
                        <span
                          style={{
                            fontSize: "medium",
                            fontFamily: "Poppins",
                            fontWeight: "normal",
                          }}
                        >
                          <strong>
                            4.{" "}
                            {lang === "en"
                              ? "LIMITED WARRANTY."
                              : "Garantía Limitada."}
                          </strong>
                        </span>
                      </p>
                      <p className="western">
                        <span
                          style={{
                            fontSize: "medium",
                            fontFamily: "Poppins",
                            fontWeight: "normal",
                          }}
                        >
                          {lang === "en"
                            ? `4.1 GigMi warrants that during the warranty period Software, when
                        properly installed, will substantially conform to the functional
                        specifications set forth in the applicable user manual. GigMi does
                        not warrant that Software will meet your requirements or that
                        operation of Software will be uninterrupted or error free. The
                        warranty period is one year starting on the 15th day after
                        delivery or upon installation, whichever first occurs. You must
                        notify GigMi in writing of any nonconformity within the warranty
                        period. This warranty shall not be valid if Software has been
                        subject to misuse, unauthorized modification, or improper
                        installation. GIGMI’ ENTIRE LIABILITY AND YOUR EXCLUSIVE REMEDY
                        SHALL BE MODIFICATION OR REPLACEMENT OF SOFTWARE THAT DOES NOT
                        MEET THIS LIMITED WARRANTY, PROVIDED YOU HAVE OTHERWISE COMPLIED
                        WITH THIS AGREEMENT AND ANY PURCHASE AGREEMENT. GIGMI MAKES NO
                        WARRANTIES WITH RESPECT TO: (A)&nbsp;SERVICES; (B)&nbsp;SOFTWARE
                        WHICH IS LICENSED TO YOU FOR A LIMITED TERM OR LICENSED AT NO
                        COST; OR (C)&nbsp;EXPERIMENTAL BETA CODE; ALL OF WHICH ARE
                        PROVIDED “AS IS.”`
                            : `4.1 GigMi garantiza que durante el período de garantía, el Software, cuando se instala correctamente, se ajustará sustancialmente a las especificaciones funcionales establecidas en el manual de usuario correspondiente. GigMi no garantiza que el Software cumplirá con sus requisitos o que su funcionamiento será ininterrumpido o libre de errores. El período de garantía es de un año a partir del decimoquinto día después de la entrega o después de la instalación, lo que ocurra primero. Debe notificar a GigMi por escrito cualquier falta de conformidad dentro del período de garantía. Esta garantía no será válida si el Software ha sido objeto de un uso incorrecto, modificación no autorizada o instalación incorrecta. LA RESPONSABILIDAD EXCLUSIVA DE GIGMI Y SU REMEDIO EXCLUSIVO SERÁN LA MODIFICACIÓN O REEMPLAZO DEL SOFTWARE QUE NO CUMPLA CON ESTA GARANTÍA LIMITADA, SIEMPRE Y CUANDO HAYA CUMPLIDO CON ESTE ACUERDO Y CUALQUIER ACUERDO DE COMPRA. GIGMI NO OFRECE GARANTÍAS CON RESPECTO A: (A) SERVICIOS; (B) SOFTWARE QUE SE LE CONCEDA LICENCIA POR UN PERÍODO LIMITADO O QUE SE LE CONCEDA LICENCIA SIN COSTO; O (C) CÓDIGO BETA EXPERIMENTAL; TODOS LOS CUALES SE PROPORCIONAN "TAL CUAL".`}
                        </span>
                      </p>
                      <p className="western" align="justify">
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "medium",
                            fontWeight: "normal",
                          }}
                        >
                          {lang === "en"
                            ? `4.2 THE WARRANTIES SET FORTH IN THIS SECTION 4 ARE EXCLUSIVE.
                        NEITHER GIGMI NOR ITS LICENSORS MAKE ANY OTHER WARRANTIES,
                        EXPRESS, IMPLIED OR STATUTORY, WITH RESPECT TO SOFTWARE OR OTHER
                        MATERIAL PROVIDED UNDER THIS AGREEMENT. GIGMI AND ITS LICENSORS
                        SPECIFICALLY DISCLAIM ALL IMPLIED WARRANTIES OF MERCHANTABILITY
                        AND FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT OF
                        INTELLECTUAL PROPERTY.`
                            : "4.2 LAS GARANTÍAS ESTABLECIDAS EN ESTA SECCIÓN 4 SON EXCLUSIVAS. NI GIGMI NI SUS LICENCIANTES OTORGAN OTRAS GARANTÍAS, YA SEAN EXPRESAS, IMPLÍCITAS O LEGALES, CON RESPECTO AL SOFTWARE U OTRO MATERIAL PROPORCIONADO BAJO ESTE ACUERDO. GIGMI Y SUS LICENCIANTES RENUNCIAN EXPRESAMENTE A TODAS LAS GARANTÍAS IMPLÍCITAS DE COMERCIABILIDAD, IDONEIDAD PARA UN PROPÓSITO PARTICULAR Y NO INFRACCIÓN DE LA PROPIEDAD INTELECTUAL."}
                        </span>
                      </p>
                      <p className="western" align="justify"></p>
                      <p className="western" align="justify">
                        <span
                          style={{
                            fontSize: "medium",
                            fontFamily: "Poppins",
                            fontWeight: "normal",
                          }}
                        >
                          <strong>
                            5.{" "}
                            {lang === "en"
                              ? `LIMITATION OF LIABILITY.`
                              : "LIMITACIÓN DE RESPONSABILIDAD."}
                          </strong>
                          {lang === "en"
                            ? `EXCEPT WHERE THIS EXCLUSION OR RESTRICTION OF LIABILITY WOULD BE
                        VOID OR INEFFECTIVE UNDER APPLICABLE LAW, IN NO EVENT SHALL GIGMI
                        OR ITS LICENSORS BE LIABLE FOR INDIRECT, SPECIAL, INCIDENTAL, OR
                        CONSEQUENTIAL DAMAGES (INCLUDING LOST PROFITS OR SAVINGS) WHETHER
                        BASED ON CONTRACT, TORT OR ANY OTHER LEGAL THEORY, EVEN IF GIGMI
                        OR ITS LICENSORS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                        DAMAGES. IN NO EVENT SHALL GIGMI’S OR ITS LICENSORS’ LIABILITY
                        UNDER THIS AGREEMENT EXCEED THE AMOUNT PAID BY YOU FOR THE
                        SOFTWARE OR SERVICE`
                            : "SALVO QUE ESTA EXCLUSIÓN O LIMITACIÓN DE RESPONSABILIDAD SEA NULA O INEFICAZ SEGÚN LA LEGISLACIÓN APLICABLE, EN NINGÚN CASO GIGMI O SUS LICENCIANTES SERÁN RESPONSABLES DE DAÑOS INDIRECTOS, ESPECIALES, INCIDENTALES O CONSECUENTES (INCLUYENDO PÉRDIDAS DE BENEFICIOS O AHORROS) YA SEA BASADOS EN CONTRATO, AGRAVIO U OTRA TEORÍA LEGAL, AUNQUE SE HAYA ADVERTIDO A GIGMI O SUS LICENCIANTES DE LA POSIBILIDAD DE TALES DAÑOS. EN NINGÚN CASO LA RESPONSABILIDAD DE GIGMI O SUS LICENCIANTES BAJO ESTE ACUERDO EXCEDERÁ LA CANTIDAD PAGADA POR USTED POR EL SOFTWARE O EL SERVICIO."}
                          <b></b>
                          {lang === "en"
                            ? `GIVING RISE TO THE CLAIM. IN THE CASE WHERE NO AMOUNT WAS PAID,
                        GIGMI AND ITS LICENSORS SHALL HAVE NO LIABILITY FOR ANY DAMAGES
                        WHATSOEVER. NEITHER GIGMI NOR ITS LICENSORS SHALL BE LIABLE FOR
                        ANY DAMAGES RESULTING FROM OR IN CONNECTION WITH THE USE OF
                        SOFTWARE IN ANY APPLICATION WHERE THE FAILURE OR INACCURACY OF THE
                        SOFTWARE MIGHT RESULT IN DEATH OR PERSONAL INJURY.`
                            : "QUE DA LUGAR A LA RECLAMACIÓN. EN EL CASO EN QUE NO SE HAYA REALIZADO NINGÚN PAGO, GIGMI Y SUS LICENCIANTES NO TENDRÁN RESPONSABILIDAD POR NINGÚN DAÑO EN ABSOLUTO. NI GIGMI NI SUS LICENCIANTES SERÁN RESPONSABLES DE NINGÚN DAÑO QUE SURJA O ESTÉ RELACIONADO CON EL USO DEL SOFTWARE EN CUALQUIER APLICACIÓN EN LA QUE EL FALLO O LA INEXACTITUD DEL SOFTWARE PUDIERAN RESULTAR EN LA MUERTE O LESIONES PERSONALES."}
                        </span>
                      </p>
                      <p className="western" align="justify"></p>
                      <p className="western" align="justify">
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "medium",
                            fontWeight: "normal",
                          }}
                        >
                          <b>
                            6.{" "}
                            {lang === "en"
                              ? `COMPLIANCE WITH LAWS.`
                              : `Cumplimiento de las leyes`}
                          </b>
                          {lang === "en"
                            ? `You agree to comply with all governmental laws, ordinances, rules,
                        and regulations applicable in the connection with your use of the
                        Software, your data usage, the operation of your business, the
                        operation of the GigMi, and in the fulfillment of your obligations
                        under this Agreement and any purchase agreement, including,
                        without limitation, the payment of all fees, duties or taxes
                        assets related to the GigMi system, or the Software, obtaining any
                        governmental permits or approvals, and full compliance with any
                        privacy laws related to customer data collected by you.`
                            : "Usted acepta cumplir con todas las leyes, ordenanzas, reglas y regulaciones gubernamentales aplicables en relación con su uso del Software, el uso de sus datos, la operación de su negocio, la operación del sistema GigMi y en el cumplimiento de sus obligaciones bajo este Acuerdo y cualquier acuerdo de compra, incluyendo, sin limitación, el pago de todas las tarifas, impuestos o gravámenes relacionados con el sistema GigMi o el Software, obtener cualquier permiso o aprobación gubernamental, y cumplir completamente con cualquier ley de privacidad relacionada con los datos de los clientes recopilados por usted."}
                        </span>
                      </p>
                      <p className="western" align="justify"></p>
                      <p className="western" align="justify">
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "medium",
                            fontWeight: "normal",
                          }}
                        >
                          <b>
                            7.{" "}
                            {lang === "en"
                              ? `INDEMNIFICATION.`
                              : "INDEMNIZACIÓN."}
                          </b>
                          {lang === "en"
                            ? `YOU AGREE TO INDEMNIFY AND HOLD HARMLESS GIGMI AND ITS LICENSORS
                        FROM ANY CLAIMS, LOSS, COST, DAMAGE, EXPENSE, OR LIABILITY,
                        INCLUDING ATTORNEYS’ FEES, ARISING OUT OF OR IN CONNECTION WITH
                        YOUR USE OF SOFTWARE, THE OPERATION OF YOUR BUSINESS, OR YOUR
                        BREACH OF THIS AGREEMENT.`
                            : `USTED ACEPTA INDEMNIZAR Y LIBERAR DE RESPONSABILIDAD A GIGMI Y A SUS LICENCIANTES DE CUALQUIER RECLAMO, PÉRDIDA, COSTO, DAÑO, GASTO O RESPONSABILIDAD, INCLUYENDO HONORARIOS DE ABOGADOS, DERIVADOS DE O EN RELACIÓN CON SU USO DEL SOFTWARE, LA OPERACIÓN DE SU NEGOCIO O SU INCUMPLIMIENTO DE ESTE ACUERDO.`}
                        </span>
                      </p>
                      <p className="western" align="justify"></p>
                      <p className="western" align="justify">
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "medium",
                            fontWeight: "normal",
                          }}
                        >
                          <b>8.{lang === "en" ? `TERM.` : "términos "}</b>
                          {lang === "en"
                            ? `This Agreement remains effective until expiration or termination.
                        All obligations and liabilities stated to or which by their nature
                        are intended to survive the termination of this Agreement will be
                        effective after termination. This Agreement will immediately
                        terminate upon notice if you exceed the scope of license granted
                        or otherwise fail to comply with the provisions of this Agreement
                        or any purchase agreement. For any other material breach under
                        this Agreement, GigMi may terminate this Agreement upon 30 days
                        written notice if you are in material breach and fail to cure such
                        breach within the 30 day notice period. The Software was provided
                        for limited term use, and this Agreement will automatically expire
                        at the end of the authorized term. Upon any termination or
                        expiration, you agree to cease all use of Software and return it
                        to GigMi or certify deletion and destruction of Software,
                        including all copies, to GigMi’ reasonable satisfaction.`
                            : `Este Acuerdo permanece en vigor hasta su vencimiento o terminación. Todas las obligaciones y responsabilidades establecidas o que por su naturaleza estén destinadas a sobrevivir a la terminación de este Acuerdo serán efectivas después de la terminación. Este Acuerdo se terminará de inmediato previa notificación si excede el alcance de la licencia otorgada o de lo contrario no cumple con las disposiciones de este Acuerdo o cualquier acuerdo de compra. Para cualquier otra infracción material bajo este Acuerdo, GigMi puede terminar este Acuerdo previo aviso por escrito de 30 días si está en infracción material y no corrige dicha infracción dentro del período de notificación de 30 días. El Software se proporcionó para uso por un período limitado, y este Acuerdo expirará automáticamente al final del período autorizado. Al finalizar o expirar, usted acepta cesar todo uso del Software y devolverlo a GigMi o certificar la eliminación y destrucción del Software, incluyendo todas las copias, a satisfacción razonable de GigMi.`}
                        </span>
                      </p>
                      <p className="western" align="justify"></p>
                      <p className="western" align="justify">
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "medium",
                            fontWeight: "normal",
                          }}
                        >
                          <b>9.{lang === "en" ? `EXPORT.` : "EXPORTACIÓN."}</b>
                          {lang === "en"
                            ? `Software is subject to regulation by local laws and United States
                        government agencies, which prohibit export or diversion of certain
                        products, information about the products, and direct products of
                        the products to certain countries and certain persons. You agree
                        that you will not export any Software or direct product of
                        Software in any manner without first obtaining all necessary
                        approval from appropriate local and United States government
                        agencies. The Software was developed entirely at private expense
                        and is commercial computer software provided with RESTRICTED
                        RIGHTS.`
                            : "El software está sujeto a la regulación de las leyes locales y las agencias gubernamentales de los Estados Unidos, que prohíben la exportación o desviación de ciertos productos, información sobre los productos y productos directos de los productos a ciertos países y ciertas personas. Usted acepta que no exportará ningún Software o producto directo del Software de ninguna manera sin obtener primero todas las aprobaciones necesarias de las agencias gubernamentales locales y de los Estados Unidos correspondientes. El Software fue desarrollado completamente con gastos privados y es software comercial proporcionado con DERECHOS RESTRINGIDOS."}
                        </span>
                      </p>
                      <p className="western" align="justify"></p>
                      <p className="western" align="justify">
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "medium",
                            fontWeight: "normal",
                          }}
                        >
                          <b>
                            10.
                            {lang === "en"
                              ? `AUDIT RIGHTS`
                              : `DERECHOS DE AUDITORÍA`}
                          </b>
                          <b>.</b>
                          {lang === "en"
                            ? `You will monitor access to, location and use of Software. With
                        reasonable prior notice and during your normal business hours,
                        GigMi shall have the right to review your software monitoring
                        system and reasonably relevant records to confirm your compliance
                        with the terms of this Agreement, an addendum to this Agreement,
                        any U.S. or other privacy laws, or any local export laws. Such
                        review may include report log files that you shall capture and
                        provide at GigMi’s request. GigMi shall treat as confidential
                        information all of your information gained as a result of any
                        request or review and shall only use or disclose such information
                        as required by law or to enforce its rights under this Agreement
                        or addendum to this Agreement. The provisions of this section 10
                        shall survive the expiration or termination of this Agreement.`
                            : "Usted supervisará el acceso, la ubicación y el uso del Software. Con un aviso previo razonable y durante su horario comercial normal, GigMi tendrá el derecho de revisar su sistema de monitoreo de software y los registros razonablemente relevantes para confirmar su cumplimiento de los términos de este Acuerdo, un anexo a este Acuerdo, las leyes de privacidad de EE. UU. u otras leyes de exportación locales. Esta revisión puede incluir archivos de registro de informes que deberá capturar y proporcionar a solicitud de GigMi. GigMi tratará como información confidencial toda su información obtenida como resultado de cualquier solicitud o revisión y solo utilizará o divulgará dicha información según lo requiera la ley o para hacer valer sus derechos en virtud de este Acuerdo o el anexo a este Acuerdo. Las disposiciones de esta sección 10 sobrevivirán a la expiración o terminación de este Acuerdo."}
                        </span>
                      </p>
                      <p className="western" align="justify"></p>
                      <p className="western" align="justify">
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "medium",
                            fontWeight: "normal",
                          }}
                        >
                          <b>
                            11.{" "}
                            {lang === "en"
                              ? `CONTROLLING LAW AND JURISDICTION.`
                              : ""}
                          </b>
                          {lang === "en"
                            ? `This Agreement shall be governed by and construed under the laws
                        of the State of COLORADO, USA. All disputes arising out of or in
                        relation to this Agreement shall be submitted to the exclusive
                        jurisdiction of Fort Collins, Colorado. This section shall not
                        restrict GigMi’s right to bring an action against you in the
                        jurisdiction where your place of business is located. The United
                        Nations Convention on Contracts for the International Sale of
                        Goods does not apply to this Agreement.`
                            : "Este Acuerdo estará regido y se interpretará de acuerdo con las leyes del Estado de COLORADO, EE. UU. Todas las disputas que surjan de este Acuerdo o en relación con él se someterán a la jurisdicción exclusiva de Fort Collins, Colorado. Esta sección no restringirá el derecho de GigMi de presentar una acción en su contra en la jurisdicción donde se encuentra su lugar de negocios. El Convenio de las Naciones Unidas sobre Contratos de Compraventa Internacional de Mercaderías no se aplica a este Acuerdo."}
                        </span>
                      </p>
                      <p className="western" align="justify"></p>
                      <p className="western" align="justify">
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "medium",
                            fontWeight: "normal",
                          }}
                        >
                          <b>12.{lang === "en" ? `SEVERABILITY.` : ``}</b>
                          {lang === "en"
                            ? `If any provision of this Agreement is held by a court of competent
                        jurisdiction to be void, invalid, unenforceable or illegal, such
                        provision shall be severed from this Agreement and the remaining
                        provisions will remain in full force and effect.`
                            : `Si alguna disposición de este Acuerdo es considerada nula, inválida, inaplicable o ilegal por un tribunal de jurisdicción competente, dicha disposición se separará de este Acuerdo y las disposiciones restantes seguirán siendo plenamente válidas y efectivas.`}
                        </span>
                      </p>
                      <p className="western" align="justify"></p>
                      <p className="western" align="justify">
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "medium",
                            fontWeight: "normal",
                          }}
                        >
                          <b>
                            13. {lang === "en" ? `MISCELLANEOUS.` : `VARIOS.`}
                          </b>
                          {lang === "en"
                            ? `You will pay amounts invoiced, in the currency specified on the
                        applicable invoice, within 30 days from the date of such invoice.
                        This Agreement may only be modified in writing by authorized
                        representatives of the parties. Waiver of terms or excuse of
                        breach must be in writing and shall not constitute subsequent
                        consent, waiver or excuse.`
                            : `Pagarás las cantidades facturadas en la moneda especificada en la factura correspondiente, dentro de los 30 días a partir de la fecha de dicha factura. Este Acuerdo solo puede ser modificado por escrito por representantes autorizados de las partes. La renuncia a los términos o la excusa de un incumplimiento deben hacerse por escrito y no constituirán un consentimiento, renuncia o excusa posterior.`}
                        </span>
                      </p>
                      <p style={{ textAlign: "center" }}></p>
                      <p style={{ textAlign: "center" }}></p>
                      <span
                        style={{
                          fontSize: "medium",
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                        }}
                      ></span>
                      <span
                        style={{
                          fontSize: "medium",
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                        }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoftwareLicense;

import * as Actions from "../actions/ActionTypes";
const RedDotReducer = (
  state = {
    isSocketConnected: false,
    unSeenNotification: {
      isMessageSeen: false,
      isContractSeen: true,
      isOpenContractSeen: true,
      isConnectionSeen: true,
      isRequestSeen: true,
      isPendingRequestSeen: true,
      isNotificationSent: false,
      contract_status: {
        isEstimateSeen: false,
        isToDoSeen: false,
        isRunningSeen: false,
        isArchivedSeen: false,
      },
    },
  },
  action
) => {
  switch (action.type) {
    case Actions.SET_RED_DOT:
      return Object.assign({}, state, {
        unSeenNotification: {
          ...state.unSeenNotification,
          ...action?.data,
          contract_status: {
            ...state.unSeenNotification.contract_status,
            ...action?.data?.contract_status,
          },
        },
      });

    case Actions.IS_SOCKET_CONNECTED:
      return Object.assign({}, state, {
        isSocketConnected: action?.data,
      });

    default:
      return state;
  }
};

export default RedDotReducer;

import { message } from "antd";
import { setFeedsListing, setTotalFeedCount } from "../../actions/FeedsActions";
import { SimpleCatchError } from "../../utils/Helper";
import { Axios } from "../../utils/axiosMiddleware";

export const GetAllPosts = async (payload, postList, dispatch) => {
  try {
    const { skip, take } = payload;

    const response = await Axios.get(
      `post/${take}/${skip}?tradesmanId=${payload?.tradsmanId}`
    );
    const newData = response?.data?.posts;
    const newArray = [...postList, ...newData];
    dispatch(setFeedsListing(newArray));
    dispatch(setTotalFeedCount(response?.data?.total));
  } catch (e) {
    SimpleCatchError(e);
  }
};
export const deletePost = async (id, index, list, dispatch) => {
  try {
    await Axios.delete(`post/${id}`);
    message.success("Post deleted successfully");
    const copy_arr = list;
    copy_arr.splice(index, 1);
    dispatch(setFeedsListing(copy_arr));
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const unlikePost = async (interested_id, postID, index) => {
  try {
    await Axios.delete(`interested/${interested_id}/${postID}`);
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const likePost = async (payload) => {
  try {
    const response = await Axios.post("interested", payload);
    return response?.data?.id;
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const addPost = async (body, image, dispatch, navigate) => {
  try {
    const response = await Axios.post("post", body);
    if (image) {
      const payload = {
        post_id: response?.data?.id,
        image: image?.originFileObj,
      };
      await uploadImage(payload);
    }
    dispatch(setFeedsListing([]));
    dispatch(setTotalFeedCount(0));
    navigate("/feeds");
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const uploadImage = async (payload) => {
  try {
    let formData = new FormData();
    const image = payload?.image;
    if (image !== null) {
      formData.append("file", image);
    }
    formData.append("postId", payload?.post_id);

    await Axios.post("post-file", formData);
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const editPost = async (payload, dispatch, navigate) => {
  try {
    const response = await Axios.patch(
      `post/${payload?.post_id}`,
      payload.body
    );
    if (payload?.image_id != null) {
      const pay = {
        post_id: response?.data?.id,
        image: payload?.image,
        imageId: payload?.image_id,
        index: payload?.index,
        onEdit: payload?.onEdit,
        path: payload?.path,
      };
      await deleteImage(pay);
    }
    if (payload?.image !== undefined) {
      const pay = {
        post_id: response?.data?.id,
        image: payload?.image?.originFileObj,
      };
      await uploadImage(pay);
    }
    dispatch(setFeedsListing([]));
    dispatch(setTotalFeedCount(0));
    navigate("/feeds");
  } catch (e) {
    SimpleCatchError(e);
  }
};

const deleteImage = async (payload) => {
  try {
    await Axios.delete(`post-file/${payload?.imageId}`);
  } catch (e) {
    SimpleCatchError(e);
  }
};

import React, { useState } from "react";
import { Header } from "../../components/Header";
import "./style.css";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Form, Input } from "antd";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import Loader from "../../components/GeneralComponents";
import { isEmpty } from "lodash";
import { changeUsername } from "../../api/settingsApi/settings";
import { useNavigate } from "react-router";
const ChangeUsername = (props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const fullUser = useSelector((state) => state?.common?.fullUser);
  const handleSubmit = async (values) => {
    setLoading(true);
    const body = {
      id: fullUser?.id,
      username: values?.username,
    };
    await changeUsername(body, navigate);
    setLoading(false);
  };
  return (
    <div className="container">
      <Header />
      <div className="paymentscreen-inner">
        <div className="d-flex justify-content-center align-items-center mt-5 change_heading">
          <FormattedMessage id="change_your_username" />
        </div>
        <div>
          <Form
            initialValues={{
              remember: true,
            }}
            form={form}
            onFinish={handleSubmit}
          >
            {isEmpty(fullUser) && (
              <div className="search-loader">
                <Loader />
              </div>
            )}
            {fullUser && fullUser?.username?.includes("@") && (
              <>
                <span className="email-text">
                  {props.intl.formatMessage({ id: "username" })}
                </span>
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: `${props.intl.formatMessage({
                        id: "email_required",
                      })}`,
                    },
                  ]}
                >
                  <Input
                    placeholder={props.intl.formatMessage({
                      id: "enter_email",
                    })}
                    type="email"
                    style={{
                      height: "56px",
                      backgroundColor: "#E6E7EB",
                      marginTop: "8px",
                    }}
                  />
                </Form.Item>
              </>
            )}
            {fullUser && !isNaN(fullUser?.username) && (
              <>
                <span className="email-text">
                  {props.intl.formatMessage({ id: "username" })}
                </span>
                <div style={{ marginTop: "8px" }}>
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: `${props.intl.formatMessage({
                          id: "phone_number_required",
                        })}`,
                      },
                    ]}
                  >
                    <PhoneInput
                      inputProps={{
                        name: "phone",
                        required: true,
                      }}
                      countryCodeEditable={false}
                      inputStyle={{
                        width: "100%",
                        height: "56px",
                        backgroundColor: "#E6E7EB",
                        marginTop: "8px",
                      }}
                      country={"us"}
                      className="w-100 phonenumber_field countries"
                      inputClass="contact_field"
                    />
                  </Form.Item>
                </div>
              </>
            )}

            <Form.Item>
              <Button
                style={{
                  width: "100%",
                  backgroundColor: "#F77E0B",
                  height: "56px",
                  color: "white",
                  fontWeight: "bold",
                }}
                type="submit"
                htmlType="submit"
                loading={loading}
              >
                {props.intl.formatMessage({ id: "request_otp" })}
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div>
          <span>
            <FormattedMessage id="note" />
          </span>
        </div>
      </div>
    </div>
  );
};
export default injectIntl(ChangeUsername);

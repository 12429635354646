import React from "react";
import AppLogo from "../../assets/images/AppLogo.png";
import FacebookIcon from "../../assets/images/FacebookIcon.png";
import InstagramIcon from "../../assets/images/InstagramIcon.png";
import TiktokIcon from "../../assets/images/TiktokIcon.png";
import WhatsappIcon from "../../assets/images/WhatsappIcon.png";
import { useNavigate } from "react-router-dom";
import { injectIntl } from "react-intl";
const Footer = (props) => {
  const navigate = useNavigate();
  const currentLang = JSON.parse(localStorage.getItem("lang"));
  let lang;
  if (currentLang) {
    lang = "es";
  } else {
    lang = "en";
  }
  return (
    <>
      <div className="w-100 py-4 mt-5" style={{ background: "#F5F5F5" }}>
        <div className="container-lg">
          <div className="row">
            <div className="d-flex flex-column col-md-3 col-sm-12 align-items-center align-items-sm-center align-items-md-start">
              <div className="pb-4">
                <img
                  src={AppLogo}
                  alt="logo"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/");
                  }}
                />
              </div>
              <span className="footer_headings  text-center text-md-start">
                {props.intl.formatMessage({ id: "footor.followGigmi" })}
              </span>
            </div>
            <div className="col-md-6 col-sm-12 ">
              <div className="d-flex flex-column justify-content-center align-items-center align-items-sm-center align-items-md-start">
                <span
                  onClick={() => navigate(`/privacy-policy/${lang}`)}
                  className="footer_headings"
                  style={{ cursor: "pointer" }}
                >
                  {props.intl.formatMessage({ id: "footor.privacyPolicy" })}
                </span>
                <span
                  onClick={() => navigate(`/terms-of-services/${lang}`)}
                  className="footer_headings"
                  style={{ cursor: "pointer" }}
                >
                  {props.intl.formatMessage({ id: "footor.terms&conditions" })}
                </span>
                <span
                  onClick={() => navigate(`/software-license/${lang}`)}
                  className="footer_headings"
                  style={{ cursor: "pointer" }}
                >
                  {props.intl.formatMessage({ id: "footor.softwarelicense" })}
                </span>
                <span
                  onClick={() => navigate(`/deposit-instructions/${lang}`)}
                  className="footer_headings cursor-pointer"
                >
                  {props.intl.formatMessage({
                    id: "header.depositInstructions",
                  })}
                </span>
                <span
                  onClick={() => navigate(`/fee-agreement/${lang}`)}
                  className="footer_headings cursor-pointer"
                >
                  {props.intl.formatMessage({ id: "feeAgreement" })}
                </span>

                <span
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate(`/support/${lang}`);
                  }}
                  className="footer_headings"
                  style={{ cursor: "pointer" }}
                >
                  {props.intl.formatMessage({ id: "footor.contactus" })}
                </span>
              </div>
              <div></div>
            </div>
            <div className="col-md-3 d-flex flex-column col-sm-12 align-items-sm-center align-items-center align-items-md-center">
              <p className="footer_headings">
                {props.intl.formatMessage({ id: "footor.followus" })}{" "}
              </p>
              <div className="d-flex ">
                <a
                  href="https://www.facebook.com/gigmiapp/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src={FacebookIcon}
                    alt="Facebook"
                    height={46}
                    width={46}
                    style={{ cursor: "pointer" }}
                  />
                </a>
                <a
                  href="https://www.instagram.com/gigmiapp/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src={InstagramIcon}
                    alt="Instagram"
                    height={46}
                    width={46}
                    style={{ cursor: "pointer", marginLeft: "10px" }}
                  />
                </a>
                <a
                  href="https://www.tiktok.com/@gigmi.app"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src={TiktokIcon}
                    alt="Twitter"
                    height={46}
                    width={46}
                    style={{ cursor: "pointer", marginLeft: "10px" }}
                  />
                </a>
                <a
                  href="https://api.whatsapp.com/send?text=https://gigmiapp.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src={WhatsappIcon}
                    alt="Linkedin"
                    height={46}
                    width={46}
                    style={{ cursor: "pointer", marginLeft: "10px" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 text-center pt-2 pb-1">
        <p style={{ color: "#F77E0B", fontWeight: "bold" }}>
          {props.intl.formatMessage({ id: "footor.copyright" })}
        </p>
      </div>
    </>
  );
};

export default injectIntl(Footer);

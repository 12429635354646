import * as ActionTypes from "./ActionTypes";

export const setNotificationData = (data) => {
  return {
    type: ActionTypes.SET_NOTIFICATION_ARRAY,
    data,
  };
};
export const setNotificationCount = (data) => {
  return {
    type: ActionTypes.SET_NOTIFICATION_COUNT,
    data,
  };
};

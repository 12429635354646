import { setBankAccountList } from "../../actions/bankActions";
import { Axios } from "../../utils/axiosMiddleware";
import { SimpleCatchError } from "../../utils/Helper";

export const getAccountList = async (id, dispatch) => {
  console.log(id);
  try {
    const response = await Axios.get(`aeropay/${id}`);
    dispatch(setBankAccountList(response?.data?.user?.bankAccounts));
    return response?.data?.user?.bankAccounts;
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const getSdkOtp = async (id) => {
  try {
    const response = await Axios.get(`aeropay/get-sdk-token/${id}`);
    return response?.data?.token;
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const deActivateAccount = async (id, body) => {
  try {
    await Axios.patch(`aeropay/${id}`, body);
  } catch (e) {
    SimpleCatchError(e);
  }
};

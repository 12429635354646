import { Button, Form, Input, message, Spin } from "antd";
import axios from "axios";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import apple from "../../assets/images/apple.png";
import { LoadingOutlined } from "@ant-design/icons";
import backIcon from "../../assets/images/backButton.svg";
import logo from "../../assets/images/gigmiLogo34.png";
import google from "../../assets/images/google.png";
import { base_url } from "../../constants";
import firebaseConfig from "../../Firebase/Firebase";
import { injectIntl } from "react-intl";
import { sessionMaintain } from "../../api/sessionsApi/session";
import { getFCMToken } from "../../utils/firebase";

function SignIn(props) {
  const [hideShowEmail, setHideShowEmail] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [EmailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const app = initializeApp(firebaseConfig);
  const recaptchaRef = useRef();
  const auth = getAuth(app);
  const url_string = window.location.href;
  const url2 = new URL(url_string);
  const type = url2.searchParams.get("type");
  const navigate = useNavigate();
  const lang = JSON.parse(localStorage.getItem("lang"));

  const handleSocialMediaLogin = async (data) => {
    await axios
      .post(`${base_url}auth/signInBySocial`, data)
      .then(async (response) => {
        localStorage.setItem("apiResponse", JSON.stringify(response?.data));
        localStorage.setItem("data", JSON.stringify(response.data));
        await sessionMaintain();
        window.location.href = "/";
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;
        message.error(errorMessage);
        navigate("/profile-selection");
      });
  };
  const handleGoogleSignIn = async () => {
    setButtonDisabled(true);
    const FCMToken = await getFCMToken();
    setButtonDisabled(false);

    setIsLoading(true);
    try {
      const token = await recaptchaRef.current.executeAsync();
      await axios.post(`${base_url}capcha`, {
        uuid: token,
        capcha: true,
      });
      setIsLoading(false);
      const provider = new GoogleAuthProvider();

      signInWithPopup(auth, provider)
        .then(async (result) => {
          const user = result._tokenResponse;
          const data = {
            username: user.email,
            type: type || "contractor",
            isSocial: true,
            notification_token: FCMToken ?? "string",
            preferences: lang ? "spanish" : "english",
          };
          await handleSocialMediaLogin(data);
        })
        .catch((error) => {});
    } catch (error) {}
  };

  const handleAppleSignUp = async () => {
    setButtonDisabled(true);
    const FCMToken = await getFCMToken();
    setButtonDisabled(false);

    setIsLoading(true);

    try {
      const token = await recaptchaRef.current.executeAsync();

      await axios
        .post(`${base_url}capcha`, {
          uuid: token,
          capcha: true,
        })
        .then((response) => {
          setIsLoading(false);
          const provider = new OAuthProvider("apple.com");
          signInWithPopup(auth, provider)
            .then(async (result) => {
              const user = result._tokenResponse;
              setEmailAddress(user.email);
              const data = {
                username: user.email,
                type: type || "contractor",
                isSocial: true,
                notification_token: FCMToken ?? "string",
                preferences: lang ? "spanish" : "english",
              };
              await handleSocialMediaLogin(data);
            })
            .catch((error) => {
              message.error("something went wrong");
            });
        })
        .catch((error) => {
          message.error("Something went wrong");
        });
    } catch (error) {
      message.error();
    }
  };

  const onSubmitWithReCAPTCHA = async () => {
    setButtonDisabled(true);
    const FCMToken = await getFCMToken();
    setButtonDisabled(false);
    setIsLoading(true);
    const token = await recaptchaRef.current.executeAsync();

    await axios
      .post(`${base_url}capcha`, {
        uuid: token,
        capcha: true,
      })
      .then(() => {
        handleSubmit(FCMToken);
      })
      .catch((error) => {
        message.error("something went wrong, please try again");
      });
  };

  const [form] = Form.useForm();
  function handleClick() {
    setHideShowEmail(!hideShowEmail);
  }
  const handleSubmit = async (FCMToken) => {
    const phone = "+" + phoneNumber;
    await axios
      .post(`${base_url}auth/signInBySocial`, {
        username: hideShowEmail ? EmailAddress?.toLowerCase() : phone,
        type: type || "contractor",
        isSocial: false,
        notification_token: FCMToken ?? "string",
        preferences: lang ? "spanish" : "english",
      })
      .then((response) => {
        localStorage.setItem("apiResponse", JSON.stringify(response?.data));
        localStorage.setItem(
          "userData",
          JSON.stringify({
            otpType: hideShowEmail ? "Email" : "Phone Number",
            username: hideShowEmail ? EmailAddress?.toLowerCase() : phone,
          })
        );
        setIsLoading(false);
        navigate(`/verifyOtp?flag=${false}`);
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;
        message.error(errorMessage);

        setIsLoading(false);
      });
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 64,
        color: "#F77E0B ",
      }}
      spin
    />
  );

  return (
    <div className=" d-flex justify-content-center vh-100">
      <div className="col-12 col-sm-12 col-md-6 welcome1-background d-flex flex-column justify-content-center align-items-center">
        <div
          style={{
            position: "absolute",
            top: "30px ",
            left: "40px",
            fontFamily: "Poppins",
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <img
            src={backIcon}
            alt=""
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
            className="shadow-lg border rounded"
          />
          <span className="text-black fs-4 m-1">
            {props.intl.formatMessage({ id: "back" })}
          </span>
        </div>
        <div
          className="w-100 d-flex d-sm-flex d-md-none d-lg-none justify-content-center"
          style={{ marginTop: "100px" }}
        >
          <img src={logo} className="w-25" />
        </div>

        <div className="bg-white shadow-lg signIn-box">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "32px",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span
              className="signIn-text margin-left-text"
              style={{ fontWeight: "bold" }}
            >
              {props.intl.formatMessage({ id: "login_account" })}
            </span>
          </div>
          <div className="d-flex justify-content-center">
            <div
              className="w-75"
              style={{
                display: "flex",
                marginTop: "22px",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <span
                className="login-sub-text text-center"
                style={{ fontFamily: "Poppins" }}
              >
                {props.intl.formatMessage({ id: "login_to_gigmi" })}
              </span>
            </div>
          </div>

          <div className="login-form" style={{ marginTop: "20px" }}>
            <Form
              initialValues={{
                remember: true,
              }}
              form={form}
              onFinish={onSubmitWithReCAPTCHA}
            >
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey="6LcG3L0mAAAAAA9M5sia-aqdCdf4mny7xVrrrM7L"
              />

              {hideShowEmail && (
                <>
                  <span className="email-text">
                    {props.intl.formatMessage({ id: "email" })}
                  </span>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: `${props.intl.formatMessage({
                          id: "email_required",
                        })}`,
                      },
                    ]}
                    initialValue={EmailAddress}
                  >
                    <Input
                      placeholder={props.intl.formatMessage({
                        id: "enter_email",
                      })}
                      type="email"
                      style={{
                        height: "56px",
                        backgroundColor: "#E6E7EB",
                        marginTop: "8px",
                      }}
                      onChange={(e) => setEmailAddress(e.target.value)}
                    />
                  </Form.Item>
                </>
              )}
              {!hideShowEmail && (
                <>
                  <span className="email-text">
                    {props.intl.formatMessage({ id: "phone_number" })}
                  </span>
                  <div style={{ marginTop: "8px" }}>
                    <Form.Item
                      name="Phonenumber"
                      rules={[
                        {
                          required: true,
                          message: `${props.intl.formatMessage({
                            id: "phone_number_required",
                          })}`,
                        },
                      ]}
                    >
                      <PhoneInput
                        inputProps={{
                          name: "phone",
                          required: true,
                        }}
                        value={phoneNumber}
                        onChange={(e) => {
                          setPhoneNumber(e);
                        }}
                        countryCodeEditable={false}
                        inputStyle={{
                          width: "100%",
                          height: "56px",
                          backgroundColor: "#E6E7EB",
                          marginTop: "8px",
                        }}
                        country={"us"}
                        className="w-100 phonenumber_field countries"
                        inputClass="contact_field"
                      />
                    </Form.Item>
                  </div>
                </>
              )}

              <Form.Item>
                <Button
                  style={{
                    width: "100%",
                    backgroundColor: isLoading ? "#cdd9df77" : "#F77E0B",
                    height: "56px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                  type="submit"
                  htmlType="submit"
                  loading={isLoading || buttonDisabled}
                >
                  {props.intl.formatMessage({ id: "request_otp" })}
                </Button>
              </Form.Item>
            </Form>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spin spinning={isLoading} size="larger" indicator={antIcon} />
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <span
                onClick={() => {
                  handleClick();
                }}
                className="phone-login text-center"
                style={{ cursor: "pointer" }}
              >
                {props.intl.formatMessage({ id: "login_with" })}{" "}
                {hideShowEmail
                  ? `${props.intl.formatMessage({ id: "phone_number" })}`
                  : `${props.intl.formatMessage({ id: "email" })}`}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: "0px",
                  width: "114px",
                  border: "1px solid #CFDAE6",
                }}
              ></div>
              <span>{props.intl.formatMessage({ id: "or" })}</span>
              <div
                style={{
                  height: "0px",
                  width: "114px",
                  border: "1px solid #CFDAE6",
                }}
              ></div>
            </div>
            {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <div onClick={handleGoogleSignIn}>
              <img
                src={google}
                alt=""
                className="social-logo"
                style={{ cursor: "pointer" }}
              />
            </div>
            <div style={{ marginLeft: "10px", marginRight: "10px" }}></div> */}
            {/* <div onClick={handleFacebookSignUp} style={{marginLeft:'10px',marginRight:"10px"}}>
            <img src={facebook} alt="" className="social-logo" style={{cursor:"pointer"}}/>
          </div> */}
            {/* <div onClick={handleAppleSignUp}>
              <img
                src={apple}
                alt=""
                className="social-logo"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>  */}
            <div className="w-100 d-flex flex-column">
              <div
                className="border w-100 shadow rounded d-flex justify-content-center align-items-center p-2 mt-4"
                style={{ height: "56px", cursor: "pointer" }}
                onClick={handleGoogleSignIn}
              >
                <img src={google} alt="" />
                <span className="social-text text-black ms-2">
                  {props.intl.formatMessage({ id: "continue_with_google" })}
                </span>
              </div>
              <div
                className="border w-100 shadow rounded d-flex justify-content-center align-items-center p-2 mt-3"
                style={{
                  height: "56px",
                  backgroundColor: "black",
                  cursor: "pointer",
                }}
                onClick={handleAppleSignUp}
              >
                <img src={apple} alt="" />
                <span className="social-text text-white ms-2">
                  {props.intl.formatMessage({ id: "continue_with_apple" })}
                </span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "12px",
              }}
              className="link"
            >
              <span>
                {props.intl.formatMessage({ id: "dont_have_account" })}{" "}
              </span>
              <a
                onClick={() => (window.location.href = "/profile-selection")}
                className="phone-login"
                style={{ cursor: "pointer", marginLeft: "5px", color: "black" }}
              >
                {props.intl.formatMessage({ id: "create_account" })}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-6 d-none d-sm-none d-md-flex justify-content-center align-items-center welcome-background">
        <img src={logo} style={{ width: "300px", height: "170px" }} />
      </div>
    </div>
  );
}
export default injectIntl(SignIn);

import * as Actions from "../actions/ActionTypes";
const MapReducer = (
  state = {
    usersLocations: [],
  },
  action
) => {
  if (action.type == Actions.SET_USERS_LIST_ON_MAP) {
    return Object.assign({}, state, {
      usersLocations: action?.data,
    });
  } else {
    return state;
  }
};

export default MapReducer;

import React from "react";
import { ContractUserProfileCard } from "./ContractUserProfileCard";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";

export const HomeownerProfileCard = ({
  contractDetail,
  userData,
  userTypeData,
}) => {
  const navigate = useNavigate();
  return contractDetail?.homeowner &&
    contractDetail?.tradesman &&
    contractDetail?.contractor &&
    userData.user_type === "homeowner" ? (
    <></>
  ) : contractDetail?.tradesman ? (
    <ContractUserProfileCard
      userImage={contractDetail?.tradesman?.profile_picture}
      onPress={() => {
        if (contractDetail?.tradesman?.id === userData?.id) {
          navigate("/user-profile");
        } else {
          navigate(
            `/trads-profile?user-id=${contractDetail?.tradesman?.userId}&id=${contractDetail?.tradesman?.id}&type=tradesman`
          );
        }
      }}
      userName={
        contractDetail?.tradesman?.user?.first_name +
        " " +
        contractDetail?.tradesman?.user?.last_name?.slice(0, 10)
      }
      userType={<FormattedMessage id="gigster" />}
      userTypeBackgroundColor="rgba(138, 187, 42, 1)"
    />
  ) : (
    <></>
  );
};

import React, { useEffect, useState } from "react";
import { convertTime } from "../../utils/Helper";
import { bucket_base_url } from "../../constants";
import { useNavigate } from "react-router";
import ImageViewModal from "../UserProfile/ImageViewModal";
import { translateText } from "../../utils/Translate";
import { FormattedMessage } from "react-intl";

export const ReceiverMessegeBox = ({ item }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [ImageUrl, setImageUrl] = useState(null);
  const [transactionLoading, setTranslationLoading] = useState(false);
  const [translatedText, setTranslatedText] = useState(
    item?.message ? item?.message : ""
  );
  const openModal = (url) => {
    setImageUrl({ key: url });
    setModalOpen(true);
  };
  useEffect(() => {
    (async () => {
      setTranslationLoading(true);
      const text = await translateText(translatedText);
      setTranslationLoading(false);
      setTranslatedText(text);
    })();
  }, []);

  const navigate = useNavigate();
  const contractId = item?.message?.split(" ").pop();

  return (
    <div>
      {modalOpen && (
        <ImageViewModal
          showModal={modalOpen}
          setShowModal={setModalOpen}
          images={[ImageUrl]}
          index={0}
        />
      )}

      <div className="chat-message receiver">
        {item?.type === "text" ? (
          <>
            <span className="long-text">
              {transactionLoading ? (
                <FormattedMessage id="Translating..." />
              ) : (
                translatedText
              )}
            </span>
          </>
        ) : item?.type === "image/jpeg" ? (
          <div onClick={() => openModal(item?.message_file_key)}>
            <img
              src={`${bucket_base_url}${
                item?.presence === "socket"
                  ? item?.message_file_key
                  : item?.message_file[0]?.key
              }`}
              className="w-100 h-100"
              alt={"chat-image"}
            />
          </div>
        ) : ["audio/aac", "audio/m4a", "audio/x-m4a"].includes(item?.type) ? (
          <>
            <audio
              controls
              src={`${bucket_base_url}${item?.message_file_key}`}
              style={{ width: "270px" }}
            />
          </>
        ) : item?.type === "application/pdf" ? (
          <a
            href={`${bucket_base_url}${item?.message_file_key}`}
            target="_blank"
          >
            <span style={{ color: "white", textDecoration: "underline" }}>
              {transactionLoading ? (
                <FormattedMessage id="Translating..." />
              ) : (
                translatedText
              )}
            </span>
          </a>
        ) : (
          item?.type === "work_request" && (
            <>
              {" "}
              <span>
                {transactionLoading ? (
                  <FormattedMessage id="Translating..." />
                ) : (
                  translatedText
                )}
              </span>
              <div
                style={{
                  width: "80%",
                  height: "30px",
                  backgroundColor: "white",
                  borderRadius: "15px",
                  margin: "5px",
                  color: "grey",
                  cursor: "pointer",
                }}
                className="d-flex justify-content-center align-items-center"
                onClick={() => navigate(`/contract-details?id=${contractId}`)}
              >
                <span>
                  <FormattedMessage id="tap_to_view" />
                </span>
              </div>
            </>
          )
        )}
        <span className="w-100 d-flex justify-content-end">
          {convertTime(item?.created_at)}
        </span>
      </div>
    </div>
  );
};

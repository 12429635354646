import io from "socket.io-client";
import { base_url } from "../constants";
export let socket = null;

export const SocketConnection = async (token) => {
  try {
    socket = io(base_url, {
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error("Error creating socket connection:", error.message);
  }
};

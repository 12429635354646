import { Button } from "antd";
import React, { useState } from "react";
import plus from "../../assets/images/BigPlus.svg";
import "./style.css";
import { FormattedMessage } from "react-intl";
import VideoThumbnail from "react-video-thumbnail";
import { isEmpty } from "lodash";
import { CloseOutlined } from "@ant-design/icons";
import { Header } from "../Header";
import { SimpleCatchError } from "../../utils/Helper";
import axios from "axios";
import { base_url } from "../../constants";
import { getStories } from "../../views/Home/Service";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Loader from "../GeneralComponents";
import { TextStoriesModal } from "./TextStoriesModal";

export const UploadStories = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [videoFile, setVideoFile] = useState([]);
  const [videoThumbnail, setVideoThumbnail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState("");
  const handleFileChange = async (e) => {
    const fileObject = e.target.files[0];
    setVideoFile((prev) => [...prev, { file: fileObject, text: null }]);
    const objectURL = URL.createObjectURL(fileObject);
    setFile(objectURL);
    setOpen(true);
    e.target.value = "";
  };

  const getVideoUrl = (index) => {
    if (videoFile[index]) {
      return URL.createObjectURL(videoFile[index].file);
    }
    return null;
  };

  async function dataUrlToFile(dataUrl, fileName) {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: "image/png" });
  }

  const handleSubmit = async (event) => {
    const accessToken = JSON.parse(localStorage.getItem("data"));
    event.target.value = "";
    const formData = new FormData();
    videoFile?.forEach((file, index) => {
      formData.append("files", file?.file);
      formData.append("name", file?.file?.name);
      formData.append("overLayText", file?.text ? file?.text : null);
    });
    formData.append("type", "story/video");
    formData.append(`${accessToken.user_type}Id`, accessToken.id);
    formData.append(`userId`, accessToken.user_id);
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken?.accessToken}`,
          "ngrok-skip-browser-warning": true,
          "content-type": "multipart/form-data",
        },
      };
      await axios.post(`${base_url}story`, formData, config);
      await getStories(dispatch);
      navigate(-1);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      SimpleCatchError(error);
    }
  };
  return (
    <div className="container">
      <TextStoriesModal />
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}
      <Header />
      <div className="mt-5 d-flex justify-content-center align-item-center">
        <span className="fw-bold fs-4" style={{ fontFamily: "Poppins" }}>
          <FormattedMessage id="upload_stories" />
        </span>
      </div>
      <div className="shadow w-100 upload-card">
        <div className="upload-div">
          {!isEmpty(videoFile) &&
            videoFile?.map((item, index) => {
              return (
                <div key={item?.id} className="video-thumbnail-setter">
                  <div style={{ display: "none" }}>
                    <VideoThumbnail
                      videoUrl={getVideoUrl(index)}
                      snapshotAtTime={1}
                      renderThumbnail={false}
                      thumbnailHandler={async (url) => {
                        const thumbnail = await dataUrlToFile(
                          url,
                          "thumbnail-" + index
                        );
                        setVideoThumbnail((prev) => [
                          ...prev,
                          { url, thumbnail },
                        ]);
                      }}
                    />
                  </div>

                  <div className="gig-preview border">
                    <img
                      src={videoThumbnail[index]?.url}
                      className="gig-preview-image"
                    />
                    {videoFile[index]?.text && (
                      <div className="typing-wrapper-div2">
                        <div className="glassInput">
                          <span>{videoFile[index]?.text}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div
                    className="cross-icon"
                    onClick={() => {
                      const videoList = [...videoFile];
                      const thumbnailList = [...videoThumbnail];
                      videoList.splice(index, 1);
                      thumbnailList.splice(index, 1);
                      setVideoFile((prev) => [...videoList]);
                      setVideoThumbnail((prev) => [...thumbnailList]);
                    }}
                    onKeyDown={() => {
                      const videoList = [...videoFile];
                      const thumbnailList = [...videoThumbnail];
                      videoList.splice(index, 1);
                      thumbnailList.splice(index, 1);
                      setVideoFile((prev) => [...videoList]);
                      setVideoThumbnail((prev) => [...thumbnailList]);
                    }}
                  >
                    <CloseOutlined className="close-icon-outlined" />
                  </div>
                </div>
              );
            })}
          {videoFile?.length < 6 && (
            <>
              {" "}
              <input
                type="file"
                id="story-input"
                style={{ display: "none" }}
                onChange={handleFileChange}
                accept="video/*"
              />
              <label htmlFor="story-input">
                <div className="d-flex flex-column justify-content-center align-items-center shadow  border upload-story-class">
                  <img src={plus} className="mb-1" alt={"plus"} />
                  <FormattedMessage id="upload-story" />
                </div>
              </label>
            </>
          )}
        </div>
        {!isEmpty(videoFile) && (
          <div className="shadow border py-2 video-thumbnail-div">
            {videoThumbnail?.map((item, index) => {
              return (
                <div
                  key={item?.id}
                  className=" mx-1 justify-content-center align-items-center thumbnail-preview"
                >
                  <img
                    src={item?.url}
                    className="video-thumbnail"
                    alt={"Image"}
                  />
                </div>
              );
            })}
          </div>
        )}
        <Button onClick={handleSubmit} className="video-upload-button">
          <FormattedMessage id="upload" />
        </Button>
      </div>
      <TextStoriesModal
        file={file}
        open={open}
        setOpen={setOpen}
        setVideoFile={setVideoFile}
        videoFile={videoFile}
      />
    </div>
  );
};

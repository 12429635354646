import React from "react";
import { FormattedMessage } from "react-intl";
import { formatDate, processSingleCondition, setDateFormat } from "./helper";

export const ContractDatesCard = ({ startDate, endDate }) => {
  return (
    <div className="date-div mt-5">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <span className="fw-bold">
          <FormattedMessage id="START_DATE" />
        </span>
        <span style={{ color: "#F77e0b" }} className="text-center">
          {processSingleCondition(
            startDate,
            formatDate(setDateFormat(startDate, "YYYY-MM-DD")),
            <FormattedMessage id="PLEASE_ADD_START_DATE" />
          )}
        </span>
      </div>
      <div className="date-divider"></div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <span className="fw-bold">
          <FormattedMessage id="END_DATE" />
        </span>
        <span style={{ color: "#F77e0b" }} className="text-center">
          {processSingleCondition(
            endDate,
            formatDate(setDateFormat(endDate, "YYYY-MM-DD")),
            <FormattedMessage id="PLEASE_ADD_END_DATE" />
          )}
        </span>
      </div>
    </div>
  );
};

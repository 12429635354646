import React, { useEffect, useState } from "react";
import "./style.css";
import { bucket_base_url } from "../../constants";
import { Divider, Input } from "antd";
import { truncateString } from "../../utils/Helper";
import profileIcon from "../../assets/images/profileIcon.png";
import { loadConversation } from "../../api/chatApi/loadConversation";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import {
  setChatDetailMobile,
  setSearchedChatListing,
} from "../../actions/ChatActions";
import BackArrow from "../BackArrow";
import { leaveConversation } from "../../sockets/ChatSockets";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { chatListing } from "../../api/chatApi/getChatListing";
import useDebounce from "../../hooks/useDebounce";
import Loader from "../GeneralComponents";

export const UserChatList = ({ chatList, searchedChatList, intl }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const chatConversation = useSelector((state) => state?.chat?.conversation);

  const debouncedSearch = useDebounce(searchText);

  const handleClick = async (item) => {
    leaveConversation(chatConversation?.id);
    dispatch(setChatDetailMobile(true));
    const userData = JSON.parse(localStorage.getItem("data"));
    const receiverType =
      item?.oponentType === "Gigster"
        ? "tradesman"
        : item?.oponentType === "Contractor"
        ? "contractor"
        : "homeowner";
    const payload = {
      senderID: userData?.id,
      receiverID: item?.oponent?.id,
      senderType: userData?.user_type,
      receiverType: receiverType,
      take: 10,
      skip: 0,
    };
    await loadConversation(payload, item, dispatch);
  };

  const handleSearch = async (skip) => {
    setLoading(true);
    await chatListing(20, skip, dispatch, debouncedSearch);
    setLoading(false);
  };

  const handleChat = async (skip) => {
    setLoading(true);
    await chatListing(20, skip, dispatch);
    setLoading(false);
  };

  useEffect(() => {
    if (debouncedSearch) {
      (async () => await handleSearch(0))();
    } else {
      dispatch(setSearchedChatListing([]));
      (async () => await handleChat(0))();
    }
  }, [debouncedSearch]);

  const sourceData = debouncedSearch?.length > 0 ? searchedChatList : chatList;
  return (
    <div>
      <div className="chat-header shadow border d-flex justify-content-between">
        <div
          style={{
            fontFamily: "Poppins",
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <div
            onClick={() => {
              leaveConversation(chatConversation?.id);
              navigate("/");
            }}
            className="w-100"
          >
            <BackArrow />
          </div>
        </div>
        <div className="fw-bold fs-4">
          <FormattedMessage id="chat" />
        </div>
        <div
          className="d-flex justify-content-center px-3"
          role="button"
          onClick={() => {
            setSearchVisible(!searchVisible);
          }}
        >
          <SearchOutlined style={{ fontSize: 20 }} />
        </div>
      </div>
      {searchVisible && (
        <Input
          className="chat-search-input"
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder={intl.formatMessage({ id: "header.search" })}
          prefix={<SearchOutlined />}
          suffix={
            searchText ? (
              <CloseOutlined
                onClick={() => {
                  setSearchText("");
                }}
              />
            ) : (
              <div style={{ width: "14px" }}></div>
            )
          }
        />
      )}
      <div className="chat-listing-scroll" style={{ maxHeight: "89vh" }}>
        {loading ? (
          <div className=" justify-content-center align-items-center h-100 mt-5">
            <Loader />
          </div>
        ) : (
          sourceData?.map((item) => {
            return (
              <div key={item?.oponent?.id} className="d-flex  flex-column">
                <div
                  className="d-flex align-items-center"
                  onClick={() => handleClick(item)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="avatar-container">
                    <img
                      src={
                        item?.oponent?.profile_picture === null ||
                        item?.oponent?.profile_picture === ""
                          ? `${profileIcon}`
                          : `${bucket_base_url}${item?.oponent?.profile_picture}`
                      }
                      className="avatar-image"
                      alt="Avatar"
                    />
                  </div>
                  <div className="title">
                    <div className="d-flex">
                      <div className="title-name">
                        {truncateString(
                          `${item?.oponent?.first_name} ${item?.oponent?.last_name}`,
                          15
                        )}
                      </div>
                      <div
                        className="type-tag"
                        style={
                          item?.oponentType === "Gigster"
                            ? { backgroundColor: "rgb(138, 187, 42)" }
                            : item?.oponentType === "Homeowner"
                            ? { backgroundColor: "#5E7AA7" }
                            : { backgroundColor: "#F77E0B" }
                        }
                      >
                        {item?.oponentType}
                      </div>
                    </div>
                    {item?.message && item?.message?.length > 0 && (
                      <span className="first-message">
                        {truncateString(`${item?.message[0]?.message}`, 15)}
                      </span>
                    )}
                  </div>
                  {item?.unread > 0 && (
                    <div className="unread">
                      <span style={{ fontSize: 12 }}>{item?.unread}</span>
                    </div>
                  )}
                </div>
                <Divider className="chat-divider" />
              </div>
            );
          })
        )}
        {sourceData?.length === 0 && !loading && (
          <div className="fs-5 mt-5 pt-5 text-center">
            <FormattedMessage id="no_chat_history" />
          </div>
        )}
      </div>
    </div>
  );
};

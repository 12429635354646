import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { UserChatList } from "../../components/Chat/ChatList";
import { chatListing } from "../../api/chatApi/getChatListing";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import "./style.css";
import Loader from "../../components/GeneralComponents";
import { BackButton } from "../../components/General/BackButton";
import ChatBody from "../../components/Chat/ChatBody";
import { setChatConversation, setIsMobile } from "../../actions/ChatActions";
import { leaveConversation } from "../../sockets/ChatSockets";
import { socket } from "../../sockets/SocketConnection";

const Chat = ({ intl }) => {
  const dispatch = useDispatch();
  const chatConversation = useSelector((state) => state?.chat?.conversation);
  const [loading, setLoading] = useState(false);
  const { chatList, searchedChatList } = useSelector((state) => state?.chat);
  const { chatDetailsMobile, isMobile } = useSelector((state) => state?.chat);
  const userData = JSON.parse(localStorage.getItem("data"));
  const isSocketConnected = useSelector(
    (state) => state?.RedDotReducer?.isSocketConnected
  );

  useEffect(() => {
    return () => {
      leaveConversation(chatConversation?.id);
      dispatch(setChatConversation({}));
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      dispatch(setIsMobile(window.innerWidth <= 768));
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [chatConversation]);

  const fetchData = async () => {
    const take = 20;
    const skip = 0;
    setLoading(true);
    await chatListing(take, skip, dispatch);
    if (isSocketConnected) {
      socket.emit("red_dot", {
        user_id: userData?.id,
        user_type: userData?.user_type,
        seen: "message",
      });
    }
    setLoading(false);
  };

  return (
    <>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}
      {isEmpty(chatList) ? (
        <div className="w-100 h-100vh d-flex justify-content-center align-items-center">
          <span className="fs-4 fw-bold mt-5 pt-5">
            <FormattedMessage id="no_chat_history" />
          </span>
          <BackButton />
        </div>
      ) : (
        <>
          {isMobile ? (
            <div className="d-flex ">
              {!chatDetailsMobile ? (
                <div className="w-100">
                  {!isEmpty(chatList) && (
                    <UserChatList
                      chatList={chatList}
                      searchedChatList={searchedChatList}
                      intl={intl}
                    />
                  )}
                </div>
              ) : (
                <ChatBody intl={intl} />
              )}
            </div>
          ) : (
            <div className="d-flex  ">
              <div className="chat-listing">
                {!isEmpty(chatList) && (
                  <UserChatList
                    chatList={chatList}
                    searchedChatList={searchedChatList}
                    intl={intl}
                  />
                )}
              </div>
              <div className="divide-line"></div>
              <ChatBody intl={intl} />
            </div>
          )}
        </>
      )}
    </>
  );
};
export default injectIntl(Chat);

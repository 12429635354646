import React from "react";

export const FooterTextButton = ({ text, icon }) => {
  return (
    <div className="help_section">
      <div className="help_section_icon">{icon}</div>
      <p
        style={{
          fontSize: "15px",
          color: "#000000",
          margin: "0px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {text}
      </p>
    </div>
  );
};

import { Button, Modal } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";

export const ChangeBankEmailModal = ({ open, setOpen }) => {
  const navigate = useNavigate();
  return (
    <Modal open={open} onClose={() => setOpen(false)} footer={null}>
      <div className="d-flex justify-content-center mt-5">
        <span className="modal-text">
          <FormattedMessage id="WOULD_YOU_LIKE_TO_CHANGE_YOUR_BANK_ACCOUNT_EMAIL" />
        </span>
      </div>
      <div className="d-flex justify-content-around mt-3">
        <Button className="button-modal" onClick={() => navigate("/")}>
          <FormattedMessage id="no" />{" "}
        </Button>
        <Button
          className="button-modal"
          onClick={() => navigate("/payment-options")}
        >
          <FormattedMessage id="yes" />{" "}
        </Button>
      </div>
    </Modal>
  );
};

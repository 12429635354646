import contractor from '../../assets/images/conractor.png';
import homeowner from '../../assets/images/home.png';
import Tradesman from '../../assets/images/tradesman.png';
import "./Selection.css"
import logo from '../../assets/images/gigmiLogo34.png';
import backIcon from '../../assets/images/backButton.svg';
import { useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
function ProfileSelection(props) {
  const navigate=useNavigate();
  return (
    <div className='view'>
    <div className='row vh-100 d-flex justify-content-center'>
              <div className='col-12 col-sm-12 col-md-6 welcome1-background d-flex justify-content-center align-items-center'>
              <div
          style={{
            position: 'absolute',
            top: '30px ',
            left: '40px',
            fontFamily: 'Poppins',
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <img
            src={backIcon}
            alt=""
            onClick={() => navigate(-1)}
            style={{ cursor: 'pointer' }}
            className="shadow-lg border rounded"
          />
          <span className="text-black fs-4 m-1">{props.intl.formatMessage({id:"back"})}</span>
        </div>


    <div className="d-flex  align-items-center justify-content-center w-100" >

      <div className='bg-white  shadow-lg  d-flex flex-column justify-content-center align-items-center welcome-box' style={{borderRadius:"15px",fontFamily:"Poppins"}} > 
          <div
          className='d-flex justify-content-center w-100'
          >
            <span className="signIn-text" style={{fontWeight:"bold"}}>{props.intl.formatMessage({id:"select_profile"})}</span>
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: '12px',
              justifyContent: 'center',
            }}
          >
            <span className="login-sub-text text-center">{props.intl.formatMessage({id:"chose_who"})}</span>
          </div>
          <div
            className="row w-100 d-flex justify-content-center align-items-center"
            style={{ marginTop:"20px" ,marginBottom:"20px"}}
          >
            <div
              className="col-md-5 col-sm-5 col-lg-4 col-xl-4 col-xxl-3 col-xs-5 col-5 "
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                onClick={() => {
                 window.location.href=`/signup?type=${"contractor"}`;
                }}
                style={{cursor:"pointer"}}
              >
                <img src={contractor} 
                alt=""
                />
              </div>
              <div>
                <p className="email-text">{props.intl.formatMessage({id:"contractor"})}</p>
              </div>
            </div>
            <div
              className="col-md-5 col-sm-5 col-lg-4 col-xl-4 col-xxl-3 col-xs-5 col-5"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                onClick={() => {
                  window.location.href=`/signup?type=${"tradesman"}`;

                }}
                style={{cursor:"pointer"}}
              >
                <img src={Tradesman}
                    alt=""
                />
              </div>
              <div>
                <p className="email-text">{props.intl.formatMessage({id:"gigster"})}</p>
              </div>
            </div>
            <div
            className='col-md-5 col-sm-5 col-lg-4 col-xl-4 col-xxl-3 col-xs-5 col-5 '
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              onClick={() => {
                window.location.href=`/signup?type=${"homeowner"}`;

              }}
              style={{cursor:"pointer"}}
            >
              <img src={homeowner}
                alt=""
              />
            </div>
            <div>
              <p className="email-text">{props.intl.formatMessage({id:"homeowner"})}</p>
            </div>
          </div>

          </div>
          </div>
          </div>
          </div>
          <div className="col-6 d-none d-sm-none d-md-flex justify-content-center align-items-center welcome-background">
        <img src={logo} style={{ width: '300px', height: '170px' }} />
      </div>

          </div>
          </div>
  );
}
export default injectIntl(ProfileSelection);
import { FormattedMessage } from "react-intl";
import invoice from "../../assets/images/invoice.svg";
import { GenerateInvoiceRequest } from "../../api/contractsApi/GenerateInvoice";
import { Button } from "antd";
import dayjs from "dayjs";

const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getContractId = (inputValue) => {
  if (inputValue < 100) {
    return `#000000${inputValue}`;
  } else if (inputValue > 100) {
    return `#00000${inputValue}`;
  } else if (inputValue > 1000) {
    return `#0000${inputValue}`;
  }
};
export const Capitalize = (str) => {
  return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
};

export const getSkill = (inputValue) => {
  let skill;
  if (inputValue?.custom_skill) {
    skill = inputValue?.custom_skill;
  } else if (inputValue?.tradesmanSkill) {
    if (inputValue?.tradesmanSkill?.skill) {
      skill = inputValue?.tradesmanSkill?.skill?.skill;
    } else {
      skill = inputValue?.tradesmanSkill?.user_skill;
    }
  } else {
    skill = inputValue?.skill?.skill;
  }

  return Capitalize(skill?.length > 8 ? skill?.substring(0, 8) + "..." : skill);
};

export const getContractStatus = (inputValue) => {
  let contractStatus;
  if (inputValue === "send") {
    contractStatus = <FormattedMessage id="PRIVATE" />;
  } else if (inputValue === "work_request_sent") {
    contractStatus = <FormattedMessage id="WORK_REQUEST" />;
  } else if (inputValue === "estimation_given") {
    contractStatus = <FormattedMessage id="ESTIMATION_GIVEN" />;
  } else if (inputValue === "contract_given") {
    contractStatus = <FormattedMessage id="CONTRACT_GIVEN" />;
  } else if (
    inputValue === "contractor_changed_requirements_homeowner" ||
    inputValue === "gigster_changed_requirements_homeowner"
  ) {
    contractStatus = <FormattedMessage id="ESTIMATION_REQUESTED" />;
  } else if (
    [
      "direct_pay_to_contractor",
      "direct_pay_to_tradesman",
      "direct_pay_to_homeowner",
    ].includes(inputValue)
  ) {
    contractStatus = <FormattedMessage id="QUICK_PAY" />;
  } else if (inputValue === "not_assigned") {
    contractStatus = <FormattedMessage id="ESTIMATION_REJECTED" />;
  } else if (inputValue === "partially_accepted") {
    contractStatus = <FormattedMessage id="PARTIALLY_ACCEPTED" />;
  } else if (inputValue === "contractor_changed_requirements_gigster") {
    contractStatus = <FormattedMessage id="CONTRACT_CHANGES" />;
  } else if (inputValue === "assigned") {
    contractStatus = <FormattedMessage id="ASSIGNED" />;
  } else if (inputValue === "open") {
    contractStatus = <FormattedMessage id="OPEN" />;
  } else if (inputValue === "receive") {
    contractStatus = <FormattedMessage id="ACCEPTED" />;
  } else if (inputValue === "homeowner_accepted_changes") {
    contractStatus = <FormattedMessage id="ESTIMATION_ACCEPTED" />;
  } else if (inputValue === "running") {
    contractStatus = <FormattedMessage id="IN_PROGRESS" />;
  } else if (inputValue === "pending_review") {
    contractStatus = <FormattedMessage id="PENDING_REVIEW" />;
  } else if (inputValue === "submit_to_homeowner") {
    contractStatus = <FormattedMessage id="IN_PROGRESS" />;
  } else if (inputValue === "pending_approval") {
    contractStatus = <FormattedMessage id="PENDING_APPROVAL" />;
  } else if (inputValue === "archived") {
    contractStatus = <FormattedMessage id="CLOSED" />;
  } else if (inputValue === "cancel") {
    contractStatus = <FormattedMessage id="CANCELLED" />;
  } else if (inputValue === "reject") {
    contractStatus = <FormattedMessage id="REJECTED" />;
  } else if (inputValue === "disputed") {
    contractStatus = <FormattedMessage id="DISPUTED" />;
  } else if (inputValue === "waiting") {
    contractStatus = <FormattedMessage id="IN_WAITING" />;
  } else {
    contractStatus = Capitalize(
      inputValue?.length > 10
        ? inputValue?.substring(0, 10) + "..."
        : inputValue
    );
  }
  return contractStatus;
};

export const getIDStatus = (status) => {
  if (status === "estimation_given" || status === "work_request_sent") {
    return <FormattedMessage id="ESTIMATION_ID" />;
  } else {
    return <FormattedMessage id="CONTRACT_ID" />;
  }
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();
  return `${month} ${day}, ${year}`;
};
export const IN_PROGRESS_CONTRACTS_TYPES = [
  { label: <FormattedMessage id="ALL" />, value: "all" },
  { label: <FormattedMessage id="RECEIVE" />, value: "receive" },
  { label: <FormattedMessage id="RUNNING" />, value: "running" },
  { label: <FormattedMessage id="PENDING_REVIEW" />, value: "pending_review" },
];

export const processSingleCondition = (
  condition,
  firstValue,
  secondValue = null
) => {
  if (condition) {
    return firstValue;
  } else {
    return secondValue;
  }
};

export const getCheckButtonTitle = (contractLogs) => {
  return contractLogs[0]?.check_in_time !== null &&
    contractLogs[0]?.check_out_time !== null ? (
    <FormattedMessage id="CHECK_IN" />
  ) : contractLogs[0]?.check_out_time === null ? (
    <FormattedMessage id="CHECK_OUT" />
  ) : null;
};
export const logTime = (checkInTime) => {
  let formattedTime = " - - : - -";

  if (checkInTime) {
    const checkInDate = new Date(checkInTime);
    const hours = checkInDate.getHours();
    const minutes = checkInDate.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    formattedTime = `${formattedHours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;
  }
  return formattedTime;
};

export const formatContractLogTime = (checkInTime) => {
  let formattedTime = " - - : - -";

  if (checkInTime) {
    const checkInDate = new Date(checkInTime);
    const year = checkInDate.getFullYear();
    const month = ("0" + (checkInDate.getMonth() + 1)).slice(-2);
    const day = ("0" + checkInDate.getDate()).slice(-2);
    const hours = ("0" + checkInDate.getHours()).slice(-2);
    const minutes = ("0" + checkInDate.getMinutes()).slice(-2);
    formattedTime = `${year}-${month}-${day} ${hours}:${minutes}`;
  }
  return formattedTime;
};

export const generateInvoiceLink = (contractDetail, userData) => {
  let link = null;
  if (
    !!contractDetail?.homeownerId &&
    !!contractDetail?.contractorId &&
    userData?.user_type === "contractor"
  ) {
    link = `contractor/homeowner/${contractDetail?.id}`;
  } else if (
    !!contractDetail?.homeownerId &&
    !!contractDetail?.contractorId &&
    userData?.user_type === "tradesman"
  ) {
    link = `tradesman/contractor/${contractDetail?.id}`;
  } else if (
    !!contractDetail?.homeownerId &&
    !contractDetail?.contractorId &&
    userData?.user_type === "tradesman"
  ) {
    link = `tradesman/homeowner/${contractDetail?.id}`;
  } else if (
    !contractDetail?.homeownerId &&
    !!contractDetail?.contractorId &&
    !!contractDetail?.tradesmanId &&
    userData?.user_type === "tradesman"
  ) {
    link = `tradesman/contractor/${contractDetail?.id}`;
  } else console.log("Nothing");

  return link;
};

export const GenerateInvoice = (contractDetail, userData) => {
  const link = generateInvoiceLink(contractDetail, userData);

  if (link) {
    return (
      <div
        style={{ width: "30px" }}
        onClick={() => GenerateInvoiceRequest(link)}
      >
        <img
          src={invoice}
          className="w-100"
          style={{ cursor: "pointer" }}
          alt={"invoice generation"}
        />
      </div>
    );
  } else {
    return true;
  }
};

export const ContractDetailButton = ({ title, onClick }) => {
  return (
    <div className="w-100">
      <Button
        className="w-100 fw-bold"
        style={{
          backgroundColor: "#f77e0b",
          height: "56px",
          fontSize: "18px",
          color: "white",
        }}
        onClick={onClick}
      >
        {title}
      </Button>
    </div>
  );
};

export const getHeaderTitle = (path, type = "") => {
  if (type === "contract-reverse") {
    return <FormattedMessage id="SEND_CONTRACT" />;
  } else if (type === "homeowner") {
    return <FormattedMessage id="WORKREQUEST" />;
  } else if (path === "estimation") {
    return <FormattedMessage id="CREATE_ESTIMATE" />;
  } else if (path === "contract") {
    return <FormattedMessage id="SEND_CONTRACT" />;
  } else if (path === "work_request_sent") {
    return <FormattedMessage id="UPDATE_WORKREQUEST" />;
  } else if (path === "pricing") {
    return <FormattedMessage id="Add Pricing" />;
  } else {
    return <FormattedMessage id="CREATE_CONTRACT" />;
  }
};

export const getContractInitialValues = (path, data) => {
  const skill = simplifiedCondition(path, data?.custom_skill);
  const jobDesc = simplifiedCondition(path, data?.job_desc);
  const flatRate = simplifiedCondition(
    path,
    data?.flat_rate?.toString(),
    data?.flat_amount?.toString()
  );
  const sDate = simplifiedCondition(
    path,
    data?.dates_record?.[data?.dates_record?.length - 1].start_date
  );
  const eDate = simplifiedCondition(
    path,
    data?.dates_record?.[data?.dates_record?.length - 1]?.end_date
  );
  const location = simplifiedCondition(
    path,
    data?.contract_location?.[0]?.location_name
  );
  const lineItems = simplifiedCondition(path, data?.lineItems);

  return {
    skill,
    jobDesc,
    totalAmount: "",
    flatRate,
    sDate: sDate ? dayjs(sDate, "YYYY-MM-DD") : "",
    eDate: eDate ? dayjs(eDate, "YYYY-MM-DD") : "",
    upload: "",
    lineItems,
    location,
  };
};

const simplifiedCondition = (path, value) => {
  if (["edit", "assigned"].includes(path)) {
    return value;
  } else {
    return "";
  }
};

export const setDateFormat = (date) => {
  const formattedDateString = dayjs(date).format("YYYY-MM-DD");
  const currentTime = dayjs().tz(browserTimeZone);
  return dayjs
    .tz(
      `${formattedDateString} ${currentTime.format("HH:mm:ss")}`,
      browserTimeZone
    )
    .toDate();
};

export const getContractLineItems = (
  contractDetails,
  path,
  screen,
  jobLineItems
) => {
  const lineItems = contractDetails?.parentContractId
    ? [...jobLineItems]
    : contractDetails?.lineItem;

  return lineItems?.filter((obj) => {
    if (
      (!obj?.subContractId && !["edit", "assigned"].includes(path)) ||
      screen === "editAssignedContract"
    ) {
      return {
        ...obj,
        isSelected: false,
      };
    }
  });
};

import { useNavigate } from "react-router";
import AddBank from "../../assets/images/AddBank.svg";
import Wallet from "../../assets/images/editBank.svg";
import { Modal } from "antd";
import { FormattedMessage } from "react-intl";

const VerificationModal = ({ visible, onClose, userTypeData, userData }) => {
  const navigate = useNavigate();
  const handleBankInfoClick = () => {
    if (userData?.user_type === "tradesman") {
      navigate("/get-payments");
    } else {
      navigate("/payment-options");
    }
  };

  const handleDebitCardClick = () => {
    navigate("/get-payments?path=debitcard");
  };

  return (
    <Modal
      open={visible}
      footer={null}
      destroyOnClose={true}
      onCancel={onClose}
    >
      <div className="d-flex flex-column">
        <span className="fs-3 fw-bold mb-3 text-center mt-3">
          <FormattedMessage id="Select" />
        </span>
        <div className="d-flex justify-content-around align-items-center">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img
              src={AddBank}
              className="bank-modal-image"
              onClick={handleBankInfoClick}
            />
            <span className="bank-modal-text">
              <FormattedMessage id="add_bank_info" />
            </span>
          </div>
          {userData?.user_type === "tradesman" && (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                src={Wallet}
                className="bank-modal-image"
                onClick={handleDebitCardClick}
              />
              <span className="bank-modal-text">
                <FormattedMessage id="add_debit_card" />
              </span>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default VerificationModal;

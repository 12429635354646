import axios from "axios";
import { base_url } from "../../constants";

export const getAllSkills = async () => {
  const accessToken = JSON.parse(localStorage.getItem("data"));

  try {
    const getAll = await axios.get(`${base_url}skills`, {
      headers: {
        Authorization: `Bearer ${accessToken?.accessToken}`,
        "ngrok-skip-browser-warning": true,
      },
    });
    return skillsList(getAll?.data);
  } catch (error) {}
};

const skillsList = (data) => {
  let finalData = data.map((skill) => {
    return {
      label: skill.skill.toLowerCase(),
      isChecked: false,
    };
  });
  return finalData;
};

export const getUserSkills = async () => {
  const accessToken = JSON.parse(localStorage.getItem("data"));

  try {
    const getUserData = await axios.get(
      `${base_url}tradesman-skills/users/${accessToken.id}/${accessToken.user_type}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken?.accessToken}`,
          "ngrok-skip-browser-warning": true,
        },
      }
    );
    return getUserData;
  } catch (e) {
    message.error(e);
  }
};

export const UploadImagFunction = async (file, setData) => {
  const accessToken = JSON.parse(localStorage.getItem("data"));

  const formData = new FormData();
  formData.append("file", file);
  formData.append("type", "story/image");
  formData.append("name", file.name);
  if (accessToken.user_type === "contractor") {
    formData.append("contractorId", accessToken.id);
  } else if (accessToken.user_type === "tradesman") {
    formData.append("tradesmanId", accessToken.id);
  } else if (accessToken.user_type === "homeowner") {
    formData.append("homeownerId", accessToken.id);
  }

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken?.accessToken}`,
        "ngrok-skip-browser-warning": true,
        "content-type": "multipart/form-data",
      },
    };
    const response = await axios.post(
      `${base_url}files/uploadPicture`,
      formData,
      config
    );
    return response?.data;
  } catch (error) {
    message.error(error);
  }
};

import React from "react";
import { ContractUserProfileCard } from "./ContractUserProfileCard";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";

export const TradesmanProfileCard = ({
  contractDetail,
  userData,
  userTypeData,
}) => {
  const navigate = useNavigate();
  return contractDetail?.homeowner &&
    contractDetail?.tradesman &&
    contractDetail?.contractor &&
    userData.user_type === "tradesman" ? (
    <></>
  ) : contractDetail?.homeowner ? (
    <ContractUserProfileCard
      userImage={contractDetail?.homeowner?.profile_picture}
      onPress={() => {
        if (contractDetail?.homeowner?.id === userTypeData?.id) {
          navigate("/user-profile");
        } else {
          navigate(
            `/trads-profile?user-id=${contractDetail?.homeowner?.userId}&id=${contractDetail?.homeowner?.id}&type=homeowner`
          );
        }
      }}
      userName={
        contractDetail?.homeowner?.user?.first_name +
        " " +
        contractDetail?.homeowner?.user?.last_name?.slice(0, 10)
      }
      userType={<FormattedMessage id="homeowner" />}
      userTypeBackgroundColor="rgba(94, 122, 167, 1)"
    />
  ) : (
    <></>
  );
};

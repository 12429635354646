import React, { useState } from "react";
import Header from "../../components/Header/Header";
import { FormattedMessage, injectIntl } from "react-intl";
import { Select, Switch } from "antd";
import "./style.css";
import { deleteUser, disableUser } from "../../api/settingsApi/settings";
import Loader from "../../components/GeneralComponents";
import {
  HandleEnglishClick,
  HandleSpanishClick,
} from "../../components/Header/Helper";
import { changeLanguage } from "../../api/ChangeLanguage";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
const Settings = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const userData = JSON.parse(localStorage?.getItem("data"));
  const currentLang = JSON.parse(localStorage.getItem("lang"));
  const fullUser = useSelector((state) => state?.common?.fullUser);
  let currentLanguage;
  if (currentLang) {
    currentLanguage = "spanish";
  } else {
    currentLanguage = "english";
  }

  const handleDelete = async () => {
    setLoading(true);
    await deleteUser();
    setLoading(false);
  };
  const handleSignOut = () => {
    localStorage.clear();
    window.location.href = "/login";
  };
  const handleDisableAccount = async (e) => {
    setChecked(true);
    setLoading(true);
    await disableUser();
    setLoading(false);
    setChecked(false);
  };
  const handleLangChange = async (e) => {
    if (e === "english") {
      const body = {
        user_type: userData?.user_type,
        preferences: "english",
      };
      const id = userData?.user_id;
      await changeLanguage(id, body);
      HandleEnglishClick();
    } else {
      const body = {
        user_type: userData?.user_type,
        preferences: "spanish",
      };
      const id = userData?.user_id;
      await changeLanguage(id, body);
      HandleSpanishClick();
    }
  };
  const handleChangeUsername = () => {
    navigate("/change-username");
  };
  return (
    <>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}

      <div className="container">
        <Header />
        <div className="paymentscreen-inner">
          <div className="d-flex justify-content-center align-items-center getting-payment-heading shadow border">
            <span className="fw-bold">
              {" "}
              {props.intl.formatMessage({ id: "settings" })}
            </span>
          </div>

          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center">
              <span className="settings-text">
                <FormattedMessage id="disable_accounts" />
              </span>
              <Switch
                checked={checked}
                onClick={(e) => handleDisableAccount(e)}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <span className="settings-text">
                <FormattedMessage id="change_language" />
              </span>
              <Select
                options={[
                  { value: "english", label: "English" },
                  { value: "spanish", label: "Español" },
                ]}
                style={{ width: "120px" }}
                defaultValue={currentLanguage}
                onSelect={(e) => handleLangChange(e)}
              />
            </div>
            {!fullUser?.isSocial && (
              <div className="d-flex justify-content-center mt-5">
                <div className="signout-button" onClick={handleChangeUsername}>
                  <FormattedMessage id="change_username" />
                </div>
              </div>
            )}

            <div className="d-flex justify-content-center mt-2">
              <div className="activate-deactivate" onClick={handleDelete}>
                <FormattedMessage id="DELETE/DEACTIVATE ACCOUNT" />
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <div className="signout-button" onClick={handleSignOut}>
                <FormattedMessage id="header.signout" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default injectIntl(Settings);

import * as Actions from "../actions/ActionTypes";
const BankReducer = (
  state = {
    bankModal: false,
    homeownerModal: false,
    gigsterModal: false,
    paymentType: "",
    alreadyAvailableAccounts: false,
    bankAccountList: [],
    contractorModal: false,
  },
  action
) => {
  switch (action.type) {
    case Actions.SET_ADD_BANK_INFO_MODAL:
      return Object.assign({}, state, {
        bankModal: action?.data,
      });
    case Actions.SET_HOMEOWNER_MODAL:
      return Object.assign({}, state, {
        homeownerModal: action?.data,
      });
    case Actions.SET_CONTRACTOR_MODAL:
      return Object.assign({}, state, {
        contractorModal: action?.data,
      });

    case Actions.SET_GIGSTER_MODAL:
      return Object.assign({}, state, {
        gigsterModal: action?.data,
      });
    case Actions.SET_PAYMENT_TYPE:
      return Object.assign({}, state, {
        paymentType: action?.data,
      });
    case Actions.SET_ALREADY_AVAILIABLE_ACCOUNTS:
      return Object.assign({}, state, {
        alreadyAvailableAccounts: action?.data,
      });

    case Actions.SET_BANK_ACCOUNT_LIST:
      return Object.assign({}, state, {
        bankAccountList: action?.data,
      });

    default:
      return state;
  }
};

export default BankReducer;

import {
  setCommentsListing,
  setLikesListing,
} from "../../actions/FeedsActions";
import { SimpleCatchError } from "../../utils/Helper";
import { Axios } from "../../utils/axiosMiddleware";

export const getPostLikes = async (payload, dispatch) => {
  try {
    const response = await Axios.get(`interested/${payload}`);
    dispatch(setLikesListing(response?.data));
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const getPostComments = async (payload, dispatch) => {
  try {
    const response = await Axios.get(`comments/${payload}`);
    dispatch(setCommentsListing(response?.data));
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const commentPost = async (payload, comments, dispatch) => {
  try {
    const response = await Axios.post("comments", payload);
    comments.push(response?.data);
    dispatch(setCommentsListing(comments));
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const commentDelete = async (payload) => {
  try {
    await Axios.delete(`comments/${payload?.id}/${payload?.post_id}`);
  } catch (e) {
    SimpleCatchError(e);
  }
};

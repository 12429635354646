import React from "react";
import profileIcon from "../../assets/images/profileIcon.png";
import { bucket_base_url } from "../../constants";
import { Divider } from "antd";

export const CommentSection = ({
  ProfilePic,
  comment,
  firstName,
  lastName,
}) => {
  return (
    <div className="w-100 d-flex flex-column">
      <Divider style={{ margin: "15px 0" }} />

      <div className="w-100 d-flex justify-content-start align-items-center">
        <div style={{ width: "60px" }}>
          <div className="review-profile rounded-circle border ">
            <img
              src={
                ProfilePic === null || ProfilePic === ""
                  ? profileIcon
                  : `${bucket_base_url}${ProfilePic}`
              }
              className="img-fluid w-100 h-100 rounded-circle"
            />
          </div>
        </div>
        <div className="d-flex flex-column justify-content-between ms-3">
          <div>
            <span className="review-profile-name">
              {firstName} {lastName}
            </span>
          </div>
          <div style={{ width: "100%" }}>
            <span className="review-content">{comment}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";

export default function BackArrow() {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ cursor: "pointer" }}
    >
      <ArrowLeftOutlined style={{ paddingLeft: 20, paddingRight: 10 }} />
    </div>
  );
}

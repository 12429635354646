import { message } from "antd";
import axios from "axios";
import { setSearchedProfilesList } from "../../actions/CommonActions";
import { base_url } from "../../constants";

const lang = JSON.parse(localStorage.getItem("lang"));

export const SwitchUser = async (type) => {
  const accessToken = JSON.parse(localStorage.getItem("data"));
  try {
    await axios
      .post(
        `${base_url}auth/switch-profile/${type}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken?.accessToken}`,
            "ngrok-skip-browser-warning": true,
          },
        }
      )
      .then((response) => {
        localStorage.setItem("data", JSON.stringify(response.data));
        window.location.href = "/";
        if (type === "tradesman") {
          if (lang) {
            message.success(`Cambió con éxito a Gigster`);
          } else {
            message.success(`Successfully Switched to Gigster`);
          }
        } else {
          if (lang) {
            message.success(`Cambió con éxito a ${type}`);
          } else {
            message.success(`Successfully Switched to ${type}`);
          }
        }
      })
      .catch((error) => {
        if (lang) {
          message.error(
            `No es posible cambiar a  ${
              type === "tradesman" ? "Gigster" : type
            }`
          );
        } else {
          message.error(
            `unable to switch to  ${type === "tradesman" ? "Gigster" : type}`
          );
        }
      });
  } catch (error) {
    if (lang) {
      message.error(
        `No es posible cambiar a  ${type === "tradesman" ? "Gigster" : type}`
      );
    } else {
      message.error(
        `unable to switch to  ${type === "tradesman" ? "Gigster" : type}`
      );
    }
  }
};

export const SwitchNewUser = async (type) => {
  const accessToken = JSON.parse(localStorage.getItem("data"));
  try {
    await axios
      .post(
        `${base_url}auth/switch_user`,
        {
          type: type,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken?.accessToken}`,
            "ngrok-skip-browser-warning": true,
          },
        }
      )
      .then((response) => {
        localStorage.setItem("data", JSON.stringify(response.data));
        window.location.href = "/";
        if (type === "tradesman") {
          if (lang) {
            message.success(`Cambió con éxito a Gigster`);
          } else {
            message.success(`Successfully Switched to Gigster`);
          }
        } else {
          if (lang) {
            message.success(`Cambió con éxito a ${type}`);
          } else {
            message.success(`Successfully Switched to ${type}`);
          }
        }
      })
      .catch((error) => {
        if (lang) {
          message.error(
            `No es posible cambiar a  ${
              type === "tradesman" ? "Gigster" : type
            }`
          );
        } else {
          message.error(
            `unable to switch to  ${type === "tradesman" ? "Gigster" : type}`
          );
        }
      });
  } catch (error) {
    if (lang) {
      message.error(
        `No es posible cambiar a  ${type === "tradesman" ? "Gigster" : type}`
      );
    } else {
      message.error(
        `unable to switch to  ${type === "tradesman" ? "Gigster" : type}`
      );
    }
  }
};

export const handleFilter = async (
  city,
  state,
  skill,
  profilesOf,
  dispatch
) => {
  const accessToken = JSON.parse(localStorage.getItem("data"));

  try {
    await axios
      .get(
        `${base_url}user/search/api/name/data/value?city=${city}&state=${state}&skill=${skill}&current_type=${profilesOf}&userId=${
          accessToken ? accessToken?.user_id : ""
        }`,

        {
          headers: {
            "ngrok-skip-browser-warning": true,
          },
        }
      )
      .then((response) => {
        if (response?.data?.length == 0) {
          if (lang) {
            message.error(`No se ha encontrado ningún perfil.`);
          } else {
            message.error(`No Profile Found`);
          }
        }
        dispatch(setSearchedProfilesList(response?.data));
      })
      .catch((error) => {
        if (lang) {
          message.error(`No se ha encontrado ningún perfil.`);
        } else {
          message.error(`No Profile Found`);
        }
      });
  } catch (e) {
    if (lang) {
      message.error(`No se ha encontrado ningún perfil.`);
    } else {
      message.error(`No Profile Found`);
    }
  }
};

export const getAllFromSkills = async () => {
  const accessToken = JSON.parse(localStorage.getItem("data"));

  try {
    const getAll = await axios.get(`${base_url}skills`, {
      headers: {
        Authorization: `Bearer ${accessToken?.accessToken}`,
        "ngrok-skip-browser-warning": true,
      },
    });
    return skillsList(getAll?.data);
  } catch (error) {}
};

const skillsList = (data) => {
  let finalData = data?.map((skill) => {
    return {
      label: skill.skill.toLowerCase(),
      id: skill.id,
    };
  });
  return finalData;
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConnections } from "../../api/contractsApi/getConnections";
import { Button, Checkbox, Divider, Modal } from "antd";
import { bucket_base_url } from "../../constants";
import { truncateString } from "../../utils/Helper";
import profileIcon from "../../assets/images/profileIcon.png";
import { FormattedMessage } from "react-intl";
import { isEmpty } from "lodash";
import { CreateContractRequest } from "../../api/contractsApi/createContract";
import { useNavigate } from "react-router";

const UserCard = ({
  SortedUsers,
  Profile,
  name,
  item,
  index,
  selectedTradesman,
  handleSelectUser,
  path,
}) => {
  return (
    <>
      <div className="d-flex align-items-center">
        <div className="avatar-container">
          <img
            src={
              Profile === null || Profile === ""
                ? `${profileIcon}`
                : `${bucket_base_url}${Profile}`
            }
            className="avatar-image"
          />
        </div>
        <div className="title">
          <div className="d-flex">
            <div className="title-name">{truncateString(`${name}`, 15)}</div>
          </div>
        </div>
        <div>
          <span>
            <Checkbox
              checked={
                path === "post"
                  ? selectedTradesman?.includes(item?.tradesmanId)
                  : selectedTradesman?.includes(item?.viewed_user_type_id)
              }
              onChange={() => handleSelectUser(item)}
            />
          </span>
        </div>
      </div>
      {SortedUsers.length - 1 !== index && <Divider />}
    </>
  );
};

export const UserList = ({
  SortedUsers,
  selectedTradesman,
  path,
  handleSelectUser,
}) => {
  const [search, setSearch] = useState("");

  const filterByValue = (array, string) => {
    return array.filter((o) =>
      ["name"].some((k) => o[k]?.toLowerCase()?.includes(string?.toLowerCase()))
    );
  };
  return (
    <>
      <div className="d-flex justify-content-center">
        <input
          style={{
            border: "2px solid #f77e0b",
            width: "90%",
            height: "46px",
            borderRadius: "25px",
            marginTop: "20px",
            padding: "20px",
          }}
          placeholder="Search Gigster"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div
        style={{
          overflowY: "scroll",
          height: "500px",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        {SortedUsers?.length === 0 && (
          <span>
            <FormattedMessage id="NO_TRADESMAN_IN_CONNECTION_PLACEHOLDER_TEXT" />
          </span>
        )}
        {!isEmpty(SortedUsers) &&
          path !== "post" &&
          filterByValue(SortedUsers, search)?.map((item, index) => {
            return (
              <UserCard
                SortedUsers={SortedUsers}
                Profile={item?.profile_picture}
                name={item?.name}
                item={item}
                index={index}
                selectedTradesman={selectedTradesman}
                handleSelectUser={handleSelectUser}
                path={path}
              />
            );
          })}
        {!isEmpty(SortedUsers) &&
          path === "post" &&
          filterByValue(SortedUsers, search)?.map((item, index) => {
            return (
              <UserCard
                SortedUsers={SortedUsers}
                Profile={item?.tradesman?.profile_picture}
                name={`${item?.tradesman?.user?.first_name}${item?.tradesman?.user?.last_name}`}
                item={item}
                index={index}
                selectedTradesman={selectedTradesman}
                handleSelectUser={handleSelectUser}
                path={path}
              />
            );
          })}
      </div>
    </>
  );
};

const GigsterListingModal = ({
  visible,
  finalData,
  contractImages,
  intl,
  handleCancel,
  setLoading,
  path,
  connectionList,
  feedFileId,
}) => {
  const [selectedTradesman, setSelectedTradesman] = useState([]);
  const dispatch = useDispatch();
  const AddTradesman = (payload) => {
    setSelectedTradesman((prev) => [...prev, payload]);
  };
  const DeleteTradesman = (payload) => {
    const index = selectedTradesman?.indexOf(payload);
    setSelectedTradesman((prev) =>
      prev.filter((_, currentIndex) => currentIndex !== index)
    );
  };
  const SortedUsers =
    path === "post"
      ? connectionList
      : useSelector((state) => state?.chat?.connections);
  const connections = async () => {
    await getConnections(dispatch);
  };
  useEffect(() => {
    if (path !== "post") {
      (async () => {
        await connections();
      })();
    }
  }, []);
  const navigate = useNavigate();
  const handleSelectUser = (item) => {
    if (path === "post") {
      if (selectedTradesman?.includes(item?.tradesmanId)) {
        DeleteTradesman(item?.tradesmanId);
      } else if (finalData?.isSubContract) {
        setSelectedTradesman([item?.tradesmanId]);
      } else {
        AddTradesman(item?.tradesmanId);
      }
    } else {
      if (selectedTradesman?.includes(item?.viewed_user_type_id)) {
        DeleteTradesman(item?.viewed_user_type_id);
      } else if (finalData?.isSubContract) {
        setSelectedTradesman([item?.viewed_user_type_id]);
      } else {
        AddTradesman(item?.viewed_user_type_id);
      }
    }
  };
  const handleSubmit = async () => {
    const finalBody = {
      ...finalData,
      status: "send",
      tradesman_ids: selectedTradesman,
      user_contracted_type: "tradesman",
    };
    setLoading(true);
    handleCancel();
    await CreateContractRequest(
      finalBody,
      contractImages,
      null,
      false,
      navigate,
      intl,
      feedFileId
    );
    setLoading(false);
  };

  return (
    <>
      <Modal
        open={visible}
        onCancel={handleCancel}
        footer={null}
        className="p-3"
      >
        <div className="mt-3">
          <div className="date-div fw-bold fs-5">
            <FormattedMessage id="SELECT_GIGSTER" />
          </div>
          <UserList
            SortedUsers={SortedUsers}
            selectedTradesman={selectedTradesman}
            path={path}
            handleSelectUser={handleSelectUser}
          />
          <div>
            <Button
              onClick={handleSubmit}
              style={{
                width: "100%",
                color: "white",
                backgroundColor: "#f77e0b",
                height: "46px",
                fontFamily: "Poppins",
                fontWeight: "600",
              }}
              disabled={selectedTradesman?.length === 0 ? true : false}
            >
              <FormattedMessage id="CREATE_CONTRACT" />
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GigsterListingModal;

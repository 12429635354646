import { Divider } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import estimate from "../../assets/images/create-estimate.svg";
import contract from "../../assets/images/create-contract.svg";
import howtouse from "../../assets/images/howto.svg";
import { useDispatch } from "react-redux";
import { setUnsubscribedUser } from "../../actions/CommonActions";
export const EstimateContract = ({ fullUser }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("data"));
  return (
    <>
      <div
        className="d-flex flex-column"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
        onClick={() => {
          if (fullUser?.subscribed) {
            navigate(`/send-estimations?path=quick`);
          } else {
            dispatch(setUnsubscribedUser(true));
          }
        }}
      >
        <div className="d-flex align-items-center p-2">
          <img
            src={estimate}
            alt=""
            style={{ width: "20px", height: "20px" }}
          />
          <span className="dropdown-text ms-3">
            <FormattedMessage id="quick_contract" />
          </span>
        </div>
        <Divider style={{ margin: "4px 0" }} />
      </div>

      {userData?.user_type === "contractor" && (
        <div
          className="d-flex flex-column"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
          onClick={() => {
            if (fullUser?.subscribed) {
              navigate(`/send-estimations?path=contract`);
            } else {
              dispatch(setUnsubscribedUser(true));
            }
          }}
        >
          <div className="d-flex align-items-center p-2">
            <img
              src={contract}
              alt=""
              style={{ width: "20px", height: "20px" }}
            />
            <span className="dropdown-text ms-3">
              <FormattedMessage id="create-contract" />
            </span>
          </div>
          <Divider style={{ margin: "4px 0" }} />
        </div>
      )}
      {userData?.user_type === "contractor" && (
        <div
          className="d-flex flex-column"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
          onClick={() => {
            if (fullUser?.subscribed) {
              navigate(`/send-estimations?path=estimate`);
            } else {
              dispatch(setUnsubscribedUser(true));
            }
          }}
        >
          <div className="d-flex align-items-center p-2">
            <img
              src={estimate}
              alt=""
              style={{ width: "20px", height: "20px" }}
            />
            <span className="dropdown-text ms-3">
              <FormattedMessage id="create_estimate" />
            </span>
          </div>
          <Divider style={{ margin: "4px 0" }} />
        </div>
      )}
      <div
        className="d-flex flex-column"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
        onClick={() => navigate(`/how-use-app`)}
      >
        <div className="d-flex align-items-center p-2">
          <img
            src={howtouse}
            alt=""
            style={{ width: "20px", height: "20px" }}
          />
          <span className="dropdown-text ms-3">
            <FormattedMessage id="How_to" />
          </span>
        </div>
        <Divider style={{ margin: "4px 0" }} />
      </div>
    </>
  );
};

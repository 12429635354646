import { Button, Modal, Tooltip } from "antd";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import "./style.css";
import { FontSizeOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
export const TextStoriesModal = ({
  file,
  open,
  setOpen,
  setVideoFile,
  videoFile,
}) => {
  const [text, setText] = useState(null);
  const [typing, setTyping] = useState(false);
  const [lineCount, setLineCount] = useState(1);
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && lineCount > 3) {
      event.preventDefault();
    }
  };
  const autoResize = (textarea) => {
    textarea.style.height = textarea.scrollHeight + "px";
  };
  const handleChange = (e) => {
    setText(e.target.value);
    autoResize(e.target);
    const textarea = e.target;
    const lineHeight = parseInt(getComputedStyle(textarea).lineHeight);
    const lines = Math.floor(textarea.scrollHeight / lineHeight);
    setLineCount(lines);
  };
  const onConfirm = () => {
    const newArray = [...videoFile];
    newArray[newArray.length - 1].text = text;
    setVideoFile(newArray);
    setText(null);
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
      width={450}
      destroyOnClose={true}
    >
      <div>
        <div className="main-wrapper-div">
          <div
            className="absolute-text-icon"
            onClick={() => setTyping(!typing)}
          >
            <Tooltip title="Add Text">
              <FontSizeOutlined />
            </Tooltip>
          </div>

          {typing && (
            <div className="typing-wrapper-div">
              <textarea
                className="glassInput"
                onChange={(e) => handleChange(e)}
                onKeyDown={handleKeyDown}
                autoFocus
                rows={1}
                maxLength={90}
              />
            </div>
          )}
          <ReactPlayer
            config={{
              file: {
                forceVideo: true,
              },
            }}
            width="100%"
            height="100%"
            muted={false}
            url={file}
            playing={true}
            loop={true}
            style={{
              borderRadius: "20px",
              backgroundColor: "black",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          />
        </div>
        <div className="w-100">
          <Button onClick={onConfirm} className="button-continue">
            <FormattedMessage id="confirm" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

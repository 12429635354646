import * as ActionTypes from "./ActionTypes";

export const setContractsList = (data) => {
  return {
    type: ActionTypes.SET_CONTRACT_LISTING,
    data,
  };
};
export const setContractDetails = (data) => {
  return {
    type: ActionTypes.SET_CONTRACT_DETAILS,
    data,
  };
};

export const setContractListingType = (data) => {
  return {
    type: ActionTypes.SET_CONTRACT_LISTING_TYPE,
    data,
  };
};

export const setUserListData = (data) => {
  return {
    type: ActionTypes.SET_USER_LIST_DATA,
    data,
  };
};

export const setSelectedUser = (data) => {
  return {
    type: ActionTypes.SET_SELECTED_USER,
    data,
  };
};

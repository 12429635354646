import React from "react";
import { ContractUserProfileCard } from "./ContractUserProfileCard";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";

export const ContractorProfileCard = ({ contractDetail, userTypeData }) => {
  const navigate = useNavigate();
  return contractDetail?.contractor ? (
    <ContractUserProfileCard
      userImage={contractDetail?.contractor?.profile_picture}
      onPress={() => {
        if (contractDetail?.contractor?.id === userTypeData?.id) {
          navigate("/user-profile");
        } else {
          navigate(
            `/trads-profile?user-id=${contractDetail?.contractor?.userId}&id=${contractDetail?.contractor?.id}&type=contractor`
          );
        }
      }}
      userName={
        contractDetail?.contractor?.user?.first_name +
        " " +
        contractDetail?.contractor?.user?.last_name?.slice(0, 10)
      }
      userType={<FormattedMessage id="contractor" />}
      userTypeBackgroundColor="#f77e0b"
    />
  ) : (
    <></>
  );
};

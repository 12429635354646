import React from "react";
import StarFilled from "../../assets/images/star-filled.svg";
import StarOutline from "../../assets/images/star-outline.svg";

const SelectStar = ({ starCount, setStarCount }) => {
  return (
    <div className="d-flex justify-content-center">
      {[1, 2, 3, 4, 5].map((item) => {
        return (
          <img
            alt={"StarOutline"}
            src={starCount >= item ? StarFilled : StarOutline}
            className="bank-modal-image mx-2 my-5"
            onClick={() => {
              setStarCount(item);
            }}
          />
        );
      })}
    </div>
  );
};

export default SelectStar;

import {
  setNotificationCount,
  setNotificationData,
} from "../../actions/NotificationActions";
import { SimpleCatchError } from "../../utils/Helper";
import { Axios } from "../../utils/axiosMiddleware";
const token = JSON.parse(localStorage.getItem("data"));
export const getNotification = async (payload, notifications, dispatch) => {
  try {
    const { take, skip } = payload;
    const response = await Axios.post(`notification/find-all`, {
      stakeholderId: token?.id,
      userId: token?.user_id,
      userRole: token?.user_type,
      take,
      skip,
    });
    const newData = response?.data?.notifications?.map((item) => {
      return {
        message: item?.message,
        sender: item?.homeownerSender
          ? item?.homeownerSender
          : item?.contractorSender
          ? item?.contractorSender
          : item?.tradesmanSender,
        created_at: item?.created_at,
        notificationFor: item?.notificationFor,
        notificationForm: item?.notificationFrom,
        notificationType: item?.notificationType,
        read: item?.read,
        RequiredData: item,
      };
    });
    const newArray = [...notifications, ...newData];
    dispatch(setNotificationData(newArray));
    dispatch(setNotificationCount(response?.data?.totalCount));
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const readNotification = async () => {
  try {
    await Axios.patch(`notification/read/mark`, {
      userId: token?.user_id,
      userRole: token?.user_type,
      stakeholderId: token?.id,
    });
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const clearAllNotification = async (dispatch) => {
  try {
    await Axios.patch(`notification/delete`, {
      userId: token?.user_id,
      stakeholderId: token?.id,
      userRole: token?.user_type,
    });
    dispatch(setNotificationData([]));
    dispatch(setNotificationCount(0));
  } catch (e) {
    SimpleCatchError(e);
  }
};

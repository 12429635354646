import React, { useState } from "react";
import profileIcon from "../../assets/images/profileIcon.png";
import Stories from "react-insta-stories";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import deleteIcon from "../../assets/images/delete.png";
import playLeft from "../../assets/images/playLeft.png";
import playRight from "../../assets/images/playRight.png";
import { getStories, handleDeleteStory } from "../../views/Home/Service";
import { setStoryIndex, setStoryModal } from "../../actions/CommonActions";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";

export default function Gig({ storyList }) {
  const { singleStoryModal, storyIndex } = useSelector(
    (state) => state?.common
  );

  const isAuthenticated = JSON.parse(localStorage.getItem("data"));

  const dispatch = useDispatch();

  const [gigIndex, setGigIndex] = useState(0);

  const stories2 = storyList?.map(({ stories: item, user }, index) => {
    return item?.map((story) => {
      return {
        url: story?.stories[0],
        type: story?.storyType,
        header: {
          heading: `${user?.first_name} ${user?.last_name}`,
          subheading:
            user?.type === "tradesman"
              ? "Gigster"
              : user?.type.charAt(0).toUpperCase() + user?.type.slice(1),
          profileImage:
            user?.profile_picture === null || user?.profile_picture === ""
              ? profileIcon
              : `${process.env.REACT_APP_S3_BUCKET_URL}${user?.profile_picture}`,
        },
      };
    });
  });

  const handleOnClickDelete = async (value) => {
    dispatch(setStoryModal(false));
    await handleDeleteStory(value);
    await getStories(dispatch);
  };

  const handleStoriesEnd = () => {
    if (storyIndex < storyList?.length - 1) {
      dispatch(setStoryIndex(storyIndex + 1));
    } else {
      dispatch(setStoryModal(!singleStoryModal));
    }
  };

  const handleLeftClick = () => {
    if (storyIndex > 0) {
      dispatch(setStoryIndex(storyIndex - 1));
    }
  };

  const handleRightClick = () => {
    if (storyIndex < storyList?.length - 1) {
      dispatch(setStoryIndex(storyIndex + 1));
    } else {
      dispatch(setStoryModal(!singleStoryModal));
    }
  };

  const antIcon = <LoadingOutlined className="loading-outline" spin />;

  return (
    <>
      {singleStoryModal && (
        <div className="mainModalDiv">
          <div className="d-flex align-items-center ">
            <div
              className="closeIcon"
              onClick={() => dispatch(setStoryModal(false))}
            >
              X
            </div>
            {storyList?.length > 1 && (
              <div className="d-none d-sm-none d-md-flex ">
                <img
                  src={playLeft}
                  className="storyChangeIcons"
                  onClick={handleLeftClick}
                  alt={"Left"}
                />
              </div>
            )}
            <>
              <div className="delete-icon-div">
                {storyList[storyIndex]?.user?.type ===
                  isAuthenticated?.user_type &&
                  storyList[storyIndex]?.user?.id === isAuthenticated?.id && (
                    <div
                      className="deleteIcon"
                      onClick={() =>
                        handleOnClickDelete(
                          storyList[storyIndex]?.stories[gigIndex]?.storyId
                        )
                      }
                    >
                      <img
                        src={deleteIcon}
                        className="deleteStoryIcon"
                        alt={"Delete Icon"}
                      />
                    </div>
                  )}
              </div>
              <div>
                <Stories
                  key={storyIndex}
                  loader={
                    <div className="storyLoading">
                      <Spin spinning={true} size="larger" indicator={antIcon} />
                    </div>
                  }
                  currentIndex={0}
                  keyboardNavigation={true}
                  defaultInterval={4000}
                  stories={stories2?.[storyIndex]}
                  onNext={() => {
                    console.log("onNext");
                  }}
                  onPrevious={() => {
                    console.log("onPrevious");
                  }}
                  onStoryStart={(currentId) => {
                    setGigIndex(currentId);
                  }}
                  onAllStoriesEnd={handleStoriesEnd}
                  storyContainerStyles={{
                    zIndex: "100",
                    height: 650,
                    width: 350,
                    resizeMode: "cover",
                  }}
                />
              </div>
            </>
            {storyList?.length > 1 && (
              <img
                src={playRight}
                className="d-none d-sm-none d-md-flex storyChangeIcons play-right-icon"
                onClick={handleRightClick}
                alt={"Right"}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}

import axios from "axios";
import { base_url } from "../../constants";
import { message } from "antd";
import { getContractedUser } from "../../utils/Helper";

export const uploadReview = async (payload, setLoading, navigate, intl) => {
  const { data, review, rating, contract } = payload;
  const token = JSON.parse(localStorage.getItem("data"));
  const { user_contracted_type, id } = getContractedUser(
    token?.user_type,
    contract
  );
  const parentContractId =
    typeof contract?.parentContractId !== "string"
      ? contract?.parentContractId
      : null;

  const formData = new FormData();
  data.map((item) => formData.append("images", item));
  formData.append("comment", review);
  formData.append("stars", rating);
  formData.append("type", token?.user_type);
  formData.append("user_contracted_type", user_contracted_type);
  formData.append("contract_id", contract?.id);
  formData.append("parentContractId", parentContractId);
  formData.append(`${token?.user_type}Id`, token?.id);
  formData.append(`${user_contracted_type}Id`, id);

  try {
    setLoading(true);
    await axios
      .post(`${base_url}message-files/reviews`, formData, {
        headers: {
          Authorization: `Bearer ${token?.accessToken}`,
          "ngrok-skip-browser-warning": true,
        },
      })
      .then((response) => {
        message.success(`${intl.formatMessage({ id: "review_submitted" })}`);
        setLoading(false);
        navigate("/");
      })
      .catch((error) => {
        setLoading(false);
        const errorMessage = error.response.data.message;
        message.error(errorMessage);
      });
  } catch (error) {
    message.error(`${intl.formatMessage({ id: "something_went_wrong" })}`);
  }
};

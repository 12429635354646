import React, { useState } from "react";
import DocumentIcon from "../../assets/images/documentColored.svg";
import star from "../../assets/images/star.png";

import { FormattedMessage } from "react-intl";
import { bucket_base_url } from "../../constants";
import ImageViewModal from "../UserProfile/ImageViewModal";

export const ProofOfWorkCard = ({ contractDetail }) => {
  const [showModal, setShowModal] = useState(false);
  const [index, setIndex] = useState();
  const [images, setImages] = useState();

  const reviews = [];

  if (contractDetail?.review?.length > 0) {
    contractDetail?.review?.forEach((element) => {
      if (element?.proof.length > 0) {
        reviews?.push(element);
      }
    });
  } else {
    null;
  }

  return (
    <div className="date-div d-flex justify-content-center flex-column align-items-center mb-5">
      <span className="fw-bold">{<FormattedMessage id="PROOF_OF_WORK" />}</span>
      <div className="d-flex justify-content-center align-items-center mt-3 w-100 h-100">
        <img src={star} />
        <span style={{ marginLeft: "5px", paddingTop: "4px" }}>
          {reviews[0]?.stars}
        </span>
      </div>
      <span className="mt-3">{reviews[0]?.comment}</span>
      {reviews &&
        reviews[0]?.proof?.map((item, index) => {
          if (item?.key?.includes(".pdf")) {
            return (
              <div key={index}>
                <img src={DocumentIcon} height={48} width={48} />
              </div>
            );
          } else {
            return (
              <div className="contract-images mt-3">
                <img
                  src={`${bucket_base_url}${item?.key}`}
                  className="w-100 h-100 rounded"
                  style={{ objectFit: "cover" }}
                  onClick={() => {
                    setShowModal(true);
                    setIndex(index);
                    setImages(
                      reviews[0]?.proof?.filter(
                        (element) => !element?.type?.includes(".pdf")
                      )
                    );
                  }}
                />
              </div>
            );
          }
        })}
      {showModal && (
        <ImageViewModal
          showModal={showModal}
          setShowModal={setShowModal}
          images={images}
          index={index}
        />
      )}
    </div>
  );
};

import { SimpleCatchError } from "../../utils/Helper";
import { Axios } from "../../utils/axiosMiddleware";

export const createInvoiceOrReceipt = async (finalBody, navigate) => {
  try {
    const response = await Axios.post("/contracts/create/invoice", finalBody);
    return response?.data;
  } catch (e) {
    SimpleCatchError(e);
  }
};

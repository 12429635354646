import React, { useState } from "react";
import { Button, Input } from "antd";
import Loader from "../../components/GeneralComponents";
import { injectIntl } from "react-intl";
import SelectStar from "../../components/SelectStar";
import { uploadReview } from "./service";
import { useNavigate } from "react-router";

const WorkReview = ({ intl, current, data, contract, ...props }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [starCount, setStarCount] = useState(1);
  const [review, setReview] = useState("");

  const handleSubmitForm = async () => {
    await uploadReview(
      { data, review, rating: starCount, contract },
      setLoading,
      navigate,
      intl
    );
    setLoading(false);
  };

  return (
    <div>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}

      <div className="m-1 row shadow p-0  p-sm-1 p-md-3 p-lg-2 rounded">
        <div className="col-12 div-proof-image-col justify-content-center align-items-center">
          <p className="my-4 text-center">
            {intl.formatMessage({ id: "body.give_rating" })}
          </p>
          <SelectStar starCount={starCount} setStarCount={setStarCount} />
          <p className="mx-2 my-2">
            {intl.formatMessage({ id: "body.review" })}
          </p>
          <Input.TextArea
            rows={7}
            placeholder={intl.formatMessage({ id: "body.please_add_review" })}
            onChange={(e) => {
              setReview(e?.target?.value);
            }}
          />
        </div>
        <div className="col-md-12 text-center mb-4">
          <Button
            className=""
            style={{
              width: "99%",
              height: "56px",
              color: "#fff",
              backgroundColor: "#F77E0B",
              border: "2px solid #F77E0B",
            }}
            onClick={handleSubmitForm}
          >
            {intl.formatMessage({ id: "submit" })}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(WorkReview);

import * as ActionTypes from "./ActionTypes";

export const setFeedsListing = (data) => {
  return {
    type: ActionTypes.SET_FEEDS_LISTING,
    data,
  };
};
export const setTotalFeedCount = (data) => {
  return {
    type: ActionTypes.TOTAL_FEEDS_LENGTH,
    data,
  };
};
export const setCommentsListing = (data) => {
  return {
    type: ActionTypes.SET_COMMENTS_LISTING,
    data,
  };
};
export const setLikesListing = (data) => {
  return {
    type: ActionTypes.SET_LIKES_LISTING,
    data,
  };
};

import Bag from "../../assets/images/Bag.svg";
import React from "react";
import { Divider } from "antd";
import { FormattedMessage } from "react-intl";

export const CompanyProfileCard = ({ contractDetail }) => {
  if (contractDetail?.contractor && contractDetail?.company) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center mt-3 mb-3">
        <div className="d-flex align-items-center">
          <img src={Bag} />
          <span
            className="fw-bold fs-5"
            style={{ fontFamily: "Poppins", color: "#f77e0b" }}
          >
            {contractDetail?.contractor?.company}
          </span>
          <span className="ms-2 mt-2">
            <FormattedMessage id="COMPANY_WITH_BRACKETS" />
          </span>
        </div>
        <Divider />
      </div>
    );
  } else {
    return <></>;
  }
};

import { Modal } from "antd";
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";

const Disclaimer = ({
  showModal,
  body,
  confirmText,
  cancelText,
  handleClick,
  handleCancel,
  title,
}) => {
  return (
    <Modal open={showModal} closable={false} footer={null}>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div
          className="d-flex justify-content-center fs-5 text-center fw-bold mb-1 "
          style={{ color: "#f77e0b", fontFamily: "Poppins" }}
        >
          {title ?? <FormattedMessage id="disclaimer" />}
        </div>

        <div
          className="mt-2 text-center fs-5  mb-3"
          style={{ fontFamily: "Poppins" }}
        >
          {body}
        </div>
        <div
          style={{ borderTop: "2px solid #e6e7eb", width: "100%" }}
          className="mt-4 mb-4"
        ></div>
        <div className="d-flex justify-content-around fw-bold w-100">
          {cancelText && (
            <span
              style={{ color: "#f77e0b", cursor: "pointer" }}
              onClick={handleCancel}
            >
              {cancelText}
            </span>
          )}
          {confirmText && cancelText && (
            <div style={{ borderLeft: "2px solid #e6e7eb" }}></div>
          )}
          {confirmText && (
            <span
              style={{ color: "#f77e0b", cursor: "pointer" }}
              onClick={handleClick}
            >
              {confirmText}
            </span>
          )}
        </div>

        {/* <div className="d-flex w-100 justify-content-around">
          <button
            className="p-2"
            style={{
              backgroundColor: "#F77E0B",
              color: "white",
              fontSize: "17px",
              fontFamily: "Poppins",
              border: "none",
              borderRadius: "3px",
            }}
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            className="p-2"
            style={{
              backgroundColor: "#F77E0B",
              color: "white",
              fontSize: "17px",
              fontFamily: "Poppins",
              border: "none",
              borderRadius: "3px",
            }}
            onClick={handleClick}
          >
            Confirm
          </button>
        </div> */}
      </div>
    </Modal>
  );
};

export default injectIntl(Disclaimer);

import { Divider, Input, Modal } from "antd";
import React from "react";
import disputeIcon from "../../assets/images/disputeIcon.svg";
import { injectIntl } from "react-intl";

const DisputeModal = ({
  intl,
  visible,
  title,
  description,
  setReason,
  cancelAction,
  confirmAction,
  cancelText,
  confirmText,
}) => {
  return (
    <Modal open={visible} footer={null} onCancel={cancelAction}>
      <div className="d-flex flex-column" style={{ fontFamily: "Poppins" }}>
        <div className="d-flex justify-content-center align-items-center">
          <img
            src={disputeIcon}
            width={25}
            height={25}
            alt=""
            className="mt-3 mx-2"
          />
          <span
            className="d-flex justify-content-center mt-3 mb-1 fs-5 fw-bold "
            style={{ color: "#f77e0b" }}
          >
            {title}
          </span>
        </div>
        <p className="text-center mb-4 fs-6">{description}</p>

        <Input.TextArea
          rows={7}
          placeholder={intl.formatMessage({ id: "ENTER_DISPUTE_REASON" })}
          onChange={(e) => {
            setReason(e?.target?.value);
          }}
        />

        <Divider />

        <div className="d-flex justify-content-around fw-bold">
          {cancelText ? (
            <>
              <div
                onClick={cancelAction}
                style={{ cursor: "pointer", color: "#f77e0b" }}
              >
                <span>{cancelText}</span>
              </div>
            </>
          ) : null}
          <Divider type={"vertical"} style={{ height: 25 }} />
          {confirmText ? (
            <div
              onClick={confirmAction}
              style={{ cursor: "pointer", color: "#f77e0b" }}
            >
              <span>{confirmText}</span>
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default injectIntl(DisputeModal);

import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  message,
  Select,
  Switch,
  Upload,
} from "antd";
import {
  CloseOutlined,
  EnvironmentOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Header from "../../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/GeneralComponents";
import { BackButton } from "../General/BackButton";
import axios from "axios";
import { GetAdminSkills } from "../../api/contractsApi/getSkillsApi";
import AddSkillModal from "../Contracts/AddSkillModal";
import LocationSearch from "../AutoCompleteLocation/AutoCompleteLocation";
import { GenerateGigmiCharges } from "../../api/contractsApi/contractDetails";
import ContractAmountDetailsModal from "./ContractAmountDetailModal";
import GigsterListingModal from "./GigsterListingModal";
import Disclaimer from "../BankInfo/Disclaimer";
import STRINGS from "../../utils/UserTypes";
import {
  CreateContractRequest,
  UpdateContractRequest,
} from "../../api/contractsApi/createContract";
import { UpdateContractRequest as UpdateSecondaryStatusContractRequest } from "../../api/contractsApi/acceptRejectApi";
import { useNavigate } from "react-router";
import { bucket_base_url, google_map_api_key } from "../../constants";
import {
  getContractInitialValues,
  getContractLineItems,
  getHeaderTitle,
  setDateFormat,
} from "./helper";

const CreateContract = ({ intl, state }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    path,
    screen,
    skills,
    tradesmanID,
    contractWithUser,
    type,
    contractDetails = {},
    jobLineItems,
  } = state;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [lineItemsRate, setLineItemsRate] = useState([]);
  const userData = JSON.parse(localStorage.getItem("data"));
  const userTypeData = useSelector((state) => state?.common?.newUser?.data);
  const fullUser = useSelector((state) => state?.common?.fullUser);
  const gigmiCharges = useSelector((state) => state?.gigmiCharges?.charges);
  const [confirmChargesModal, setConfirmChargesModal] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [locationLoading, setLocationLoading] = useState(true);
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState(null);
  const [finalData, setFinalData] = useState({});
  const [formValues, setFormValues] = useState({});
  const [gigsterModal, setGigsterModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [amountDeduction, setAmountDeduction] = useState(false);
  const [isBalanceAvailable, setIsBalanceAvailable] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [skillOptions, setSkillOptions] = useState([]);
  const [startDate, setStartDate] = useState("");
  let ImagesArray = contractDetails?.contractFiles?.map((obj) => ({
    ...obj,
    isSelected: false,
  }));
  const [contractImages, setContractImages] = useState(ImagesArray || []);
  const [contractImageIds, setContractImageIds] = useState([]);
  let lineItemsArray = getContractLineItems(
    contractDetails,
    path,
    screen,
    jobLineItems
  );
  const [ContractPricing, setContractPricing] = useState(lineItemsArray);
  const [lineItems, setLineItems] = useState([]);

  const getSkills = async () => {
    const res = await GetAdminSkills();
    setSkillOptions(res);
  };

  useEffect(() => {
    if (["Open", "edit"].includes(path) && !skills) {
      (async () => {
        await getSkills();
      })();
    } else {
      setSkillOptions(skills);
    }
    if (userData?.user_type !== "tradesman") {
      const body = {
        homeOwnerId:
          userData?.user_type === "contractor" ? null : userTypeData?.id,
        contractorId:
          userData?.user_type === "contractor" ? userTypeData?.id : null,
      };
      (async () => {
        await GenerateGigmiCharges(body, dispatch);
      })();
    }
    if (["edit", "assigned"].includes(path)) {
      setAddress(contractDetails?.contract_location?.[0]?.location_name || "");
      setCoordinates({
        lat: contractDetails?.contract_location?.[0]?.latitude,
        lng: contractDetails?.contract_location?.[0]?.longitude,
      });
      setLineItems(jobLineItems);
      const flatRates = jobLineItems?.map((element) =>
        parseInt(element?.flatRate)
      );
      setLineItemsRate(flatRates);
    }
  }, []);

  useEffect(() => {
    if (path === "pricing") {
      setLineItemsRate(
        ContractPricing?.map(() => {
          return 0;
        })
      );
    }
  }, []);

  const handleGetLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          setCoordinates({
            lat,
            lng,
          });
          axios(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${google_map_api_key}`
          )
            .then((data) => {
              if (data.data.results && data.data.results.length > 0) {
                setAddress(data?.data?.results[0]?.formatted_address);
              }
              setLocationLoading(!locationLoading);
            })
            .catch((error) => {
              console.error(error);
            });
        },
        (error) => {
          message.error("Error getting location:", error);
        }
      );
    } else {
      message.error("Geolocation is not supported in this browser.");
    }
  };

  const createContract = async (values) => {
    if (fullUser?.sendingAccountVerify) {
      if (path === "Chat") {
        setLoading(true);
        await CreateContractRequest(
          {
            user_contracted_type: contractWithUser,
            skill: values.skill,
            user_skill: values.skill,
            user_id: userTypeData?.id,
            user_current_type: userData?.user_type,
            job_desc: values.jobDesc,
            flat_amount: values.flatRate,
            start_date: setDateFormat(values.sDate),
            end_date: setDateFormat(values.eDate),
            latitude: coordinates?.lat,
            longitude: coordinates?.lng,
            location_name: address,
            status: "send",
            tradesman_ids: [tradesmanID],
            isTemplate: false,
            gigmi_charges: gigmiCharges,
            is_contractor_wallet: amountDeduction,
          },
          fileList,
          null,
          false,
          navigate,
          intl
        );
        setLoading(false);
      }
    } else {
      message.error(
        "No Bank Account Available. Please add bank account first."
      );
    }
  };

  const CreateContractHomeToGig = async (values) => {
    if (fullUser?.sendingAccountVerify) {
      if (["Chat", "edit"].includes(path)) {
        setLoading(true);
        await CreateContractRequest(
          {
            user_contracted_type: contractWithUser,
            skill: values.skill,
            user_skill: values.skill,
            user_id: userTypeData?.id,
            user_current_type: userData?.user_type,
            job_desc: values.jobDesc,
            latitude: coordinates?.lat,
            longitude: coordinates?.lng,
            location_name: address,
            status: "work_request_sent",
            tradesman_ids: [tradesmanID],
            isTemplate: false,
            gigmi_charges: gigmiCharges,
            contract_id: contractDetails?.id || null,
            is_homeowner_wallet: amountDeduction,
          },
          fileList,
          contractImageIds,
          false,
          navigate,
          intl
        );
        setLoading(false);
      }
    } else {
      message.error(
        "No Bank Account Available. Please add bank account first."
      );
    }
  };

  const directEstimationContractor = async (values) => {
    if (fullUser?.receivingAccountVerify) {
      setLoading(true);
      await CreateContractRequest(
        {
          user_contracted_type: "homeowner",
          user_contracted_id:
            type === "homeowner-reverse"
              ? tradesmanID
              : contractDetails?.homeownerId,
          first_name: type === "homeowner-reverse" ? null : "firstName",
          last_name: type === "homeowner-reverse" ? null : "lastName",
          skill: null,
          user_skill: values?.skill,
          user_id: userData?.id,
          user_current_type: userData?.user_type,
          job_desc: null,
          latitude: coordinates?.lat,
          longitude: coordinates?.lng,
          location_name: address,
          status:
            type === "homeowner-reverse"
              ? "estimation_given"
              : "contract_given",
          tradesman_ids: [tradesmanID],
          isTemplate: false,
          gigmi_charges: gigmiCharges,
          flat_amount: handleFlatAmount(),
          line_item: values?.lineItems?.map((item) => {
            return { job_desc: item?.lineItem, flat_rate: item?.flatRate };
          }),
          start_date: setDateFormat(values.sDate),
          end_date: setDateFormat(values.eDate),
        },
        fileList,
        null,
        false,
        navigate,
        intl
      );
      setLoading(false);
    } else {
      message.error(
        "No Bank Account Available. Please add bank account first."
      );
    }
  };

  const handleTradesmanEstimation = async (values) => {
    if (
      (fullUser?.sendingAccountVerify &&
        userData.user_type === STRINGS.TRADESMAN_TYPE) ||
      (fullUser?.receivingAccountVerify &&
        userData.user_type === STRINGS.CONTRACTOR_TYPE)
    ) {
      setLoading(true);
      await UpdateContractRequest(
        {
          contract_id: contractDetails?.id,
          tradesmanId: contractDetails?.tradesmanId,
          homeownerId: contractDetails?.homeownerId,
          user_skill: contractDetails?.custom_skill,
          job_desc: contractDetails?.job_desc,
          latitude: contractDetails?.contract_location?.[0]?.latitude,
          longitude: contractDetails?.contract_location?.[0]?.longitude,
          location_name: contractDetails?.contract_location?.[0]?.location_name,
          status: "estimation_given",
          flat_amount: contractDetails?.contractorId
            ? null
            : handleFlatAmount(),
          line_item: values?.lineItems?.map((item) => {
            return { job_desc: item?.lineItem, flat_rate: item?.flatRate };
          }),
          start_date: setDateFormat(values.sDate),
          end_date: setDateFormat(values.eDate),
        },
        fileList,
        {
          contract_Id: contractDetails?.id,
          receiverId: contractDetails?.homeownerId,
          receiverType: STRINGS.HOMEOWNER_TYPE,
          currentStatus: contractDetails?.status,
        },
        navigate,
        intl
      );
      setLoading(false);
    } else {
      message.error(
        "No Bank Account Available. Please add bank account first."
      );
    }
  };

  const updateEstimation = async (values, lineItemsOriginal) => {
    const isContract = !contractDetails?.homeownerId;

    const deletedLineItems = jobLineItems.filter((lineElement, index) => {
      if (
        lineItems[index]?.id === lineElement?.id &&
        lineItems[index]?.isDeleted
      ) {
        return {
          ...lineElement,
          isChanged: true,
          isDeleted: true,
          job_desc: lineElement?.lineItem,
          flat_rate: lineElement.flatRate,
        };
      }
    });

    const lineItemsChanged = lineItemsOriginal.map((lineItem) => {
      const conditionMetLineItem = jobLineItems.find((lineElement) => {
        return (
          lineItem?.id === lineElement.id &&
          JSON.stringify(lineItem) !== JSON.stringify(lineElement)
        );
      });

      if (!!conditionMetLineItem) {
        if (screen === "editAssignedContract") {
          return {
            ...lineItem,
            isChanged: true,
          };
        }
        return {
          ...lineItem,
          isChanged: true,
          job_desc: lineItem?.lineItem,
          flat_rate: lineItem.flatRate,
        };
      } else {
        if (screen === "editAssignedContract") {
          return {
            ...lineItem,
            isChanged: true,
          };
        }
        return {
          ...lineItem,
          job_desc: lineItem?.lineItem,
          flat_rate: lineItem.flatRate,
        };
      }
    });

    let filteredLineItems = [...deletedLineItems, ...lineItemsChanged];

    if (fullUser?.sendingAccountVerify) {
      setLoading(true);
      await UpdateSecondaryStatusContractRequest(
        {
          contract_Id: isContract
            ? contractDetails?.parentContractId
            : contractDetails?.id,
          isSubContract: isContract,
          subContractId: isContract ? contractDetails?.id : null,
          secondary_status:
            (contractDetails?.parentContractId || contractDetails?.tradesman) &&
            !isContract
              ? "gigster_changed_requirements_homeowner"
              : isContract
              ? "contractor_changed_requirements_gigster"
              : "contractor_changed_requirements_homeowner",
          line_items: filteredLineItems,
          start_date: setDateFormat(values.sDate) || null,
          end_date: setDateFormat(values.eDate) || null,
        },
        navigate
      );
      setLoading(false);
    } else {
      message.error(
        "No Bank Account Available. Please add bank account first."
      );
    }
  };

  const onChange = (key, item = 0) => {
    let value = [...lineItemsRate];
    value[key] = parseInt(item);
    setLineItemsRate(value);
  };

  const handleSubmit = async (values) => {
    const finalData = {
      contract_id: contractDetails?.id,
      skill: values?.skill,
      user_skill: null,
      user_id: userTypeData?.id,
      user_current_type: userData?.user_type,
      job_desc: values?.jobDesc ? values?.jobDesc : undefined,
      flat_amount:
        ["estimation", "pricing"].includes(path) ||
        screen === "editAssignedContract"
          ? handleFlatAmount()
          : values.flatRate,
      start_date: setDateFormat(values.sDate),
      end_date: setDateFormat(values.eDate),
      latitude: coordinates?.lat,
      longitude: coordinates?.lng,
      location_name: address,
      isTemplate: false,
      gigmi_charges:
        contractDetails?.subContracts?.length === 0 ? gigmiCharges : 0,
    };
    setFinalData(finalData);
    setFormValues(values);

    const lineItemsFields = values?.lineItems?.map((element) => {
      if (element.id) {
        return element;
      }
      return {
        lineItem: element?.lineItem,
        flatRate: element?.flatRate,
        isChanged: true,
        isChecked: true,
        isDeleted: false,
        isNew: true,
      };
    });
    let subContractLineItems;
    if (path !== "pricing") {
      subContractLineItems = ContractPricing?.map((element) => {
        delete element.isSelected;
        return element;
      });
    }
    if (
      path === "estimation" ||
      (path === "edit" &&
        screen === "editEstimation" &&
        !["receive", "running"].includes(contractDetails?.status))
    ) {
      if (["homeowner-reverse", "contract-reverse"].includes(type)) {
        await directEstimationContractor(values);
      } else {
        await handleTradesmanEstimation(values);
      }
    } else if (path === "pricing") {
      let checkLineItems = ContractPricing?.find(
        (data) => data?.isSelected && !data.flat_rate
      );

      if (!checkLineItems) {
        finalData.line_item = ContractPricing?.filter((item) => {
          if (item?.isSelected) {
            return { job_desc: item?.lineItem, flat_rate: item?.flatRate };
          }
        });
        finalData.isSubContract = true;
        finalData.skill = contractDetails?.custom_skill;
        finalData.latitude = contractDetails?.contract_location?.[0]?.latitude;
        finalData.longitude =
          contractDetails?.contract_location?.[0]?.longitude;
        setFinalData(finalData);
        finalData.location_name =
          contractDetails?.contract_location?.[0]?.location_name;
        finalData.is_contractor_wallet = amountDeduction;
        setFinalData(finalData);
        setConfirmChargesModal(true);
      }
    } else if (userData?.user_type === STRINGS.CONTRACTOR_TYPE) {
      if (
        contractDetails?.status === "send" &&
        contractDetails?.parentContractId
      ) {
        const finalBody = {
          ...finalData,
          contract_id: contractDetails?.parentContractId,
          status: "send",
          line_item: ContractPricing,
          tradesman_ids: [contractDetails?.tradesmanId],
          user_contracted_type: "tradesman",
          isSubContract: true,
          sub_contract_id: contractDetails?.id,
        };
        setLoading(true);
        await CreateContractRequest(finalBody, [], null, false, navigate, intl);
        setLoading(false);
      } else if (path === "assigned" || screen === "editAssignedContract") {
        const lineItemsDecision =
          screen === "editAssignedContract"
            ? subContractLineItems
            : lineItemsFields;
        await updateEstimation(values, lineItemsDecision);
      } else {
        setConfirmChargesModal(true);
      }
    } else if (userData?.user_type === STRINGS.HOMEOWNER_TYPE) {
      await CreateContractHomeToGig(values);
    } else {
      if (
        ["receive", "running"].includes(contractDetails?.status) &&
        screen === "editEstimation"
      ) {
        await updateEstimation(values, lineItemsFields);
      } else {
        await createContract(values);
      }
    }
  };

  const disabledDate = (current) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return current && current < currentDate;
  };
  const disabledEndDate = (current) => {
    return current && current < startDate;
  };

  const onFileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleClearClick = (item, index) => {
    let pics = [...contractImages];
    setContractImageIds([...contractImageIds, item?.id]);
    pics.splice(index, 1);
    setContractImages(pics);
  };

  const validateTotalAmount = (_, value) => {
    if (path === "estimation" || path === "pricing") {
      if (handleFlatAmount() > 5000) {
        return Promise.reject(
          `${intl.formatMessage({ id: "total_amount_should" })}`
        );
      }
    }
    if (Number(value) > 5000) {
      return Promise.reject(
        `${intl.formatMessage({ id: "total_amount_should" })}`
      );
    }
    return Promise.resolve();
  };

  const AddLineItemToPricing = (indexOf) => {
    let updatedValue = ContractPricing?.map((data, index) => ({
      ...data,
      isSelected: index === indexOf ? !data?.isSelected : data?.isSelected,
    }));
    const flatRate = updatedValue?.[indexOf]?.isSelected
      ? updatedValue?.[indexOf]?.flat_rate
      : 0;
    onChange(flatRate || 0, indexOf);
    setContractPricing(updatedValue);
  };

  const AddImages = (indexOf) => {
    let updatedValue = contractImages?.map((data, index) => ({
      ...data,
      isSelected: index === indexOf ? !data?.isSelected : data?.isSelected,
    }));
    setContractImages(updatedValue);
  };

  const updateFlatRate = (indexOf, value) => {
    let updatedValue = ContractPricing?.map((data, index) => ({
      ...data,
      flat_rate: index === indexOf ? value : data.flat_rate,
    }));
    onChange(value || 0, indexOf);
    setContractPricing(updatedValue);
  };

  const handleFlatAmount = useCallback(() => {
    return lineItemsRate?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
  }, [lineItemsRate]);

  const getInitialValues = () => {
    return getContractInitialValues(path, contractDetails);
  };

  return (
    <>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}
      <BackButton />
      <div className="container paymentscreen">
        <Header />
        <div className="paymentscreen-inner">
          <div className="d-flex justify-content-center align-items-center getting-payment-heading border shadow text-center">
            <span>{getHeaderTitle(path, userData?.user_type)}</span>
          </div>
          <Form
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            initialValues={getInitialValues()}
            style={{ fontFamily: "Poppins" }}
          >
            <div>
              <Form.Item
                name="skill"
                rules={[
                  {
                    required: !(
                      (path === "estimation" && !type) ||
                      path === "pricing"
                    ),
                    message: `${intl.formatMessage({ id: "select_skill" })}`,
                  },
                ]}
              >
                <div className="row w-100">
                  <span className="col-6">
                    <FormattedMessage id="skill" />{" "}
                  </span>
                  {!(
                    (path === "estimation" && !type) ||
                    path === "pricing" ||
                    screen
                  ) && (
                    <div className="col-6 d-flex justify-content-end">
                      <span
                        style={{
                          color: "#F77E0B",
                          fontFamily: "Poppins",
                          cursor: "pointer",
                        }}
                        onClick={() => setModalShow(true)}
                      >
                        {intl.formatMessage({ id: "addNewSkill" })}
                      </span>
                    </div>
                  )}
                </div>
                <Select
                  defaultValue={contractDetails?.custom_skill || null}
                  disabled={
                    (path === "estimation" && !type) ||
                    path === "pricing" ||
                    screen
                  }
                  style={{ paddingBottom: 0 }}
                  placeholder={
                    contractDetails?.custom_skill
                      ? contractDetails?.custom_skill
                      : `${intl.formatMessage({ id: "select_a_skill" })}`
                  }
                  onChange={(value) => form.setFieldsValue({ skill: value })}
                >
                  {skillOptions?.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            {ContractPricing?.map((data, index) => {
              return (
                <div
                  className="row d-flex mt-4 justify-content-center align-items-center"
                  key={index}
                >
                  {screen !== "editAssignedContract" && (
                    <div className="col-1">
                      <Checkbox
                        onChange={() => AddLineItemToPricing(index)}
                        checked={data?.isSelected}
                        height="100"
                      ></Checkbox>
                    </div>
                  )}
                  <div
                    className={`${
                      screen === "editAssignedContract" ? "col-9" : "col-8"
                    }`}
                  >
                    {index === 0 && "Line Item"}
                    <Input
                      type="number"
                      disabled={true}
                      placeholder={data?.job_desc}
                      className="input-field"
                    />
                  </div>
                  <div className="col-3">
                    {index === 0 && "  Flat Rate"}
                    <Input
                      onChange={(event) =>
                        updateFlatRate(index, event?.target?.value || 0)
                      }
                      disabled={!data?.isSelected}
                      status={!data?.flat_rate && data?.isSelected && "error"}
                      defaultValue={parseFloat(data?.flat_rate)}
                      type="number"
                      placeholder={`${intl.formatMessage({ id: "flat_rate" })}`}
                      className="input-field"
                    />
                    {!data?.flat_rate && data?.isSelected && (
                      <div
                        style={{
                          position: "absolute",
                          color: "red",
                        }}
                      >
                        `${intl.formatMessage({ id: "required" })}`
                      </div>
                    )}
                  </div>
                </div>
              );
            })}

            {((!type && !["pricing", "assigned"].includes(path)) ||
              (path === "edit" && screen !== "editAssignedContract")) && (
              <Form.Item
                name="jobDesc"
                label={`${intl.formatMessage({ id: "job_desc" })}`}
                rules={[
                  {
                    required: !(path === "estimation" && !type),
                    message: `${intl.formatMessage({ id: "required" })}`,
                  },
                ]}
              >
                <Input.TextArea
                  disabled={path === "estimation" || screen}
                  placeholder={
                    contractDetails?.job_desc
                      ? contractDetails?.job_desc
                      : `${intl.formatMessage({ id: "job_desc" })}`
                  }
                  type="tdext"
                  className="input-field"
                  rows={4}
                  style={{ padding: 20, resize: "none" }}
                />
              </Form.Item>
            )}

            {(path === "estimation" ||
              (["edit", "assigned"].includes(path) &&
                screen &&
                screen !== "editAssignedContract")) && (
              <Form.List name="lineItems" initialValue={jobLineItems}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...field }) => (
                      <div className="row" key={key}>
                        <div className="col-8">
                          <Form.Item
                            {...field}
                            name={[name, "lineItem"]}
                            label={`${intl.formatMessage({ id: "line_item" })}`}
                            key={[key + "lineItem"]}
                            rules={[
                              {
                                required: true,
                                message: `${intl.formatMessage({
                                  id: "missing_line_item",
                                })}`,
                              },
                            ]}
                          >
                            <Input
                              placeholder={`${intl.formatMessage({
                                id: "add_new_line_item",
                              })}`}
                              className="input-field"
                            />
                          </Form.Item>
                        </div>
                        <div className="col-3">
                          <Form.Item
                            {...field}
                            name={[name, "flatRate"]}
                            key={[key + "flatRate"]}
                            label={`${intl.formatMessage({ id: "flat_rate" })}`}
                            rules={[
                              {
                                required: true,
                                message: `${intl.formatMessage({
                                  id: "missing_flat_rate",
                                })}`,
                              },
                            ]}
                          >
                            <Input
                              type="number"
                              placeholder={`${intl.formatMessage({
                                id: "flat_rate",
                              })}`}
                              className="input-field"
                              onChange={(e) => onChange(e?.target?.value, key)}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-1 d-flex justify-content-center align-items-center">
                          <MinusCircleOutlined
                            style={{ height: 110, fontSize: 25 }}
                            onClick={() => {
                              let items = [...lineItems];
                              if (items?.[key]?.notRemovable) {
                                message.error(
                                  intl.formatMessage({
                                    id: "Line Item cannot be deleted",
                                  })
                                );
                                return;
                              }
                              if (items?.[key]?.id) {
                                items[key].isDeleted = true;
                                setLineItems(items);
                              }
                              remove(name);
                              onChange(0, key);
                            }}
                          />
                        </div>
                      </div>
                    ))}

                    <Button className="input-field mb-3" onClick={add} block>
                      <span style={{ color: "black" }}>
                        <PlusOutlined style={{ paddingRight: "10px" }} />
                        {intl.formatMessage({ id: "add_line_items" })}
                      </span>
                    </Button>
                  </>
                )}
              </Form.List>
            )}

            {userData?.user_type !== STRINGS.HOMEOWNER_TYPE && (
              <Form.Item
                name="flatRate"
                label={`${intl.formatMessage({ id: "flat_rate" })}`}
                rules={[
                  {
                    required: !(
                      ["estimation", "pricing", "assigned"].includes(path) ||
                      ["editEstimation", "editAssignedContract"].includes(
                        screen
                      )
                    ),
                    message: `${intl.formatMessage({ id: "required" })}`,
                  },
                  { validator: validateTotalAmount },
                ]}
              >
                <Input
                  type="number"
                  disabled={
                    path === "estimation" ||
                    path === "pricing" ||
                    path === "assigned" ||
                    screen === "editEstimation" ||
                    screen === "editAssignedContract"
                  }
                  placeholder={
                    "$" + handleFlatAmount() ||
                    `${intl.formatMessage({ id: "flat_rate" })}`
                  }
                  className="input-field"
                />
              </Form.Item>
            )}

            {!(
              ["estimation", "edit"].includes(path) ||
              screen === "editAssignedEstimation"
            ) && (
              <>
                {userData?.user_type !== "homeowner" && (
                  <Form.Item>
                    <div
                      className="d-flex p-2"
                      style={{
                        border: "2px solid grey",
                        borderRadius: "3px",
                        fontFamily: "Poppins",
                      }}
                    >
                      <span className="d-flex flex-column w-75">
                        <span style={{ fontSize: "17px" }}>
                          <FormattedMessage id="DEDUCT_AMOUNT" />
                        </span>
                        <span>
                          <FormattedMessage id="Available_balance" />= $
                          {userTypeData?.wallet}
                        </span>
                      </span>
                      <div className="d-flex w-25 justify-content-end">
                        <Switch
                          checked={amountDeduction}
                          onChange={() => {
                            if (parseFloat(userTypeData?.wallet) > 0)
                              setAmountDeduction(!amountDeduction);
                            else setIsBalanceAvailable(true);
                          }}
                        />
                      </div>
                    </div>
                  </Form.Item>
                )}
              </>
            )}
            {userData?.user_type !== "homeowner" && (
              <div className="row">
                <div className="col-6">
                  <Form.Item
                    name="sDate"
                    label={`${intl.formatMessage({ id: "start_date" })}`}
                    rules={[
                      {
                        required: true,
                        message: `${intl.formatMessage({ id: "required" })}`,
                      },
                    ]}
                  >
                    <DatePicker
                      disabledDate={disabledDate}
                      className="input-field"
                      placeholder={`${intl.formatMessage({
                        id: "start_date",
                      })}`}
                      onChange={(e) => setStartDate(e)}
                    />
                  </Form.Item>
                </div>
                <div className="col-6">
                  <Form.Item
                    name="eDate"
                    label={`${intl.formatMessage({ id: "end_date" })}`}
                    rules={[
                      {
                        required: true,
                        message: `${intl.formatMessage({ id: "required" })}`,
                      },
                    ]}
                  >
                    <DatePicker
                      disabledDate={disabledEndDate}
                      disabled={startDate ? false : true}
                      className="input-field"
                      placeholder={`${intl.formatMessage({ id: "end_date" })}`}
                    />
                  </Form.Item>
                </div>
              </div>
            )}
            <div className="row ">
              <div className="col-12">
                <div className="d-flex justify-content-between">
                  <div>
                    <span className="email-text">
                      {intl.formatMessage({ id: "location" })}
                    </span>
                  </div>
                  {!(
                    (path === "estimation" && !type) ||
                    path === "pricing" ||
                    screen
                  ) && (
                    <div onClick={handleGetLocation}>
                      <span>
                        <EnvironmentOutlined style={{ color: "#F77E0B" }} />
                        <span
                          className="trad-location ms-1"
                          style={{ userSelect: "none" }}
                        >
                          {intl.formatMessage({ id: "current_location" })}
                        </span>
                      </span>
                    </div>
                  )}
                </div>

                <Form.Item
                  name="location"
                  rules={[
                    {
                      required: false,
                      message: `${intl.formatMessage({
                        id: "location_required",
                      })}`,
                    },
                  ]}
                >
                  {(path === "estimation" && !type) ||
                  path === "pricing" ||
                  screen ? (
                    <Input
                      disabled
                      placeholder={
                        contractDetails?.contract_location?.[0]
                          ?.location_name || ""
                      }
                      type="text"
                      className="input-field"
                    />
                  ) : (
                    <LocationSearch
                      address={address}
                      setAddress={setAddress}
                      coordinates={coordinates}
                      setCoordinates={setCoordinates}
                    />
                  )}
                </Form.Item>
              </div>
            </div>

            {(path === "estimation" && !type) ||
            ["editEstimation", "editAssignedContract"].includes(screen) ? (
              <div className="d-flex ">
                {contractDetails?.contractFiles?.map((item) => (
                  <div>
                    {(item?.type === "image/jpeg" ||
                      item?.key?.includes(".jpeg")) && (
                      <div className="contract-images mx-1" key={item?.id}>
                        <img
                          src={`${bucket_base_url}${item?.key}`}
                          className="w-100 h-100 rounded"
                          style={{ objectFit: "cover", backgroundColor: "red" }}
                          alt="Contract Images"
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : ["pricing", "edit"].includes(path) ||
              screen === "editAssignedEstimation" ? (
              <Form.Item
                label={intl.formatMessage({ id: "upload" })}
                valuePropName="fileList"
              >
                <div className="d-flex">
                  {contractImages?.map((item, index) => (
                    <div className="m-2">
                      {(item?.type === "image/jpeg" ||
                        item?.key?.includes(".jpeg")) && (
                        <div>
                          <div className="contract-images" key={item?.id}>
                            <img
                              src={`${bucket_base_url}${item?.key}`}
                              className="w-100 h-100 rounded"
                              style={{
                                objectFit: "cover",
                                position: "relative",
                              }}
                              alt="Contract Images"
                            />
                            {path === "edit" && (
                              <span
                                className="crossIcon"
                                onClick={() => handleClearClick(item, index)}
                              >
                                <CloseOutlined />
                              </span>
                            )}
                          </div>
                          {path === "pricing" && (
                            <div
                              style={{
                                position: "absolute",
                                bottom: 30,
                                paddingLeft: 10,
                              }}
                            >
                              <Checkbox
                                onChange={() => AddImages(index)}
                                checked={item?.isSelected}
                                height="100"
                              ></Checkbox>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                  <div className="m-2">
                    <Upload
                      accept=".jpeg,.pdf"
                      listType="picture-card"
                      maxCount={4}
                      onChange={onFileChange}
                    >
                      <div
                        style={{
                          width: "25px",
                          height: "25px",
                          backgroundColor: "#F77E0B",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <PlusOutlined style={{ color: "white" }} />
                      </div>
                    </Upload>
                  </div>
                </div>
              </Form.Item>
            ) : (
              <Form.Item
                label={intl.formatMessage({ id: "upload" })}
                valuePropName="fileList"
              >
                <Upload
                  accept=".jpeg,.pdf"
                  listType="picture-card"
                  maxCount={4}
                  onChange={onFileChange}
                >
                  <div
                    style={{
                      width: "25px",
                      height: "25px",
                      backgroundColor: "#F77E0B",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <PlusOutlined style={{ color: "white" }} />
                  </div>
                </Upload>
              </Form.Item>
            )}

            <div className="d-flex justify-content-end">
              <Form.Item>
                <Button
                  type="submit"
                  htmlType="submit"
                  className="submit-button"
                >
                  {intl.formatMessage({ id: "submit" })}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
        <div>
          <AddSkillModal
            modalShow={modalShow}
            setModalShow={setModalShow}
            skillArray={skillOptions}
            selectedSkills={selectedSkills}
            setSkillArray={setSkillOptions}
            setSelectedSkills={setSelectedSkills}
          />
        </div>
        <ContractAmountDetailsModal
          visible={confirmChargesModal}
          title={<FormattedMessage id="CONTRACT_AMOUNT_DETAIL" />}
          contractId={null}
          gigmiCharges={
            contractDetails?.subContracts?.length === 0 ? gigmiCharges : 0
          }
          flatAmount={finalData?.flat_amount}
          cancelText={<FormattedMessage id="CANCEL" />}
          cancelAction={() => setConfirmChargesModal(false)}
          confirmText={<FormattedMessage id="CONFIRM" />}
          confirmAction={() => {
            setConfirmChargesModal(false);
            if (["Open", "pricing"].includes(path)) {
              setGigsterModal(true);
            } else {
              (async () => await createContract(formValues))();
            }
          }}
        />
        {gigsterModal && (
          <GigsterListingModal
            visible={gigsterModal}
            finalData={finalData}
            contractImages={fileList}
            handleCancel={() => setGigsterModal(false)}
            setLoading={setLoading}
            intl={intl}
          />
        )}
        <Disclaimer
          showModal={isBalanceAvailable}
          setShowModal={() => setIsBalanceAvailable(false)}
          body={intl.formatMessage({
            id: "WALLET_DOES_NOT_HAVE_SUFFICIENT_BALANCE",
          })}
          handleCancel={() => {
            setIsBalanceAvailable(false);
          }}
          handleClick={() => setIsBalanceAvailable(false)}
          confirmText={<FormattedMessage id="ok" />}
          cancelText={<FormattedMessage id="cancel" />}
        />
      </div>
    </>
  );
};
export default injectIntl(CreateContract);

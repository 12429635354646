import * as Actions from "../actions/ActionTypes";
const HowToReducer = (
  state = {
    videoList: [],
  },
  action
) => {
  if (action.type == Actions.SET_HOW_TO_USE_LIST) {
    return Object.assign({}, state, {
      videoList: action?.data,
    });
  } else {
    return state;
  }
};

export default HowToReducer;

import React, { useState } from "react";
import "./style.css";
import { bucket_base_url } from "../../constants";
import { FormattedMessage } from "react-intl";
import { Divider } from "antd";
import {
  formatDate,
  getContractId,
  getContractStatus,
  getIDStatus,
  getSkill,
} from "./helper";
import { RenderSubContracts } from "./RenderSubcontracts";
import showdown from "../../assets/images/caretDown.svg";
import showup from "../../assets/images/caretUp.svg";
import profileIcon from "../../assets/images/profileIcon.png";
import { useNavigate } from "react-router";
import { convertTime } from "../../utils/Helper";
import Bag from "../../assets/images/Bag.svg";
import STRINGS from "../../utils/UserTypes";

export const ContractCard = ({ contractDetails, contractState = false }) => {
  const userData = JSON.parse(localStorage.getItem("data"));
  const [showSubContracts, setShowSubContracts] = useState(false);
  const navigate = useNavigate();
  const contractStatuses =
    contractDetails?.homeownerId && !contractDetails?.tradesmanId
      ? [
          "contractor_changed_requirements_homeowner",
          "homeowner_accepted_changes",
        ]
      : [
          "contractor_changed_requirements_gigster",
          "gigster_changed_requirements_homeowner",
          "gigster_accepted_changes",
        ];
  const showSecondaryStatus =
    !contractState &&
    contractStatuses?.includes(contractDetails?.secondary_status);

  const getFlatAmount = () => {
    let flatAmount = 0;
    if (showSecondaryStatus) {
      flatAmount = parseInt(contractDetails?.changed_flat_amount);
    } else if (contractDetails?.updated_flat_amount) {
      flatAmount = parseInt(contractDetails?.updated_flat_amount);
    } else {
      flatAmount = parseInt(contractDetails?.flat_amount);
    }
    return flatAmount ? flatAmount?.toFixed(2) : "---";
  };

  const getContractedUserDetails = () => {
    if (
      (contractDetails?.homeownerId &&
        contractDetails?.tradesmanId &&
        !contractDetails?.contractorId &&
        userData?.user_type === STRINGS.TRADESMAN_TYPE) ||
      (contractDetails?.homeownerId &&
        !contractDetails?.tradesmanId &&
        contractDetails?.contractorId &&
        userData?.user_type === STRINGS.CONTRACTOR_TYPE)
    ) {
      return STRINGS.HOMEOWNER_TYPE;
    } else if (
      (contractDetails?.homeownerId &&
        !contractDetails?.tradesmanId &&
        contractDetails?.contractorId &&
        userData?.user_type === STRINGS.HOMEOWNER_TYPE) ||
      (!contractDetails?.homeownerId &&
        contractDetails?.tradesmanId &&
        contractDetails?.contractorId &&
        userData?.user_type === STRINGS.TRADESMAN_TYPE)
    ) {
      return STRINGS.CONTRACTOR_TYPE;
    } else if (
      (contractDetails?.homeownerId &&
        contractDetails?.tradesmanId &&
        !contractDetails?.contractorId &&
        userData?.user_type === STRINGS.HOMEOWNER_TYPE) ||
      (!contractDetails?.homeownerId &&
        contractDetails?.tradesmanId &&
        contractDetails?.contractorId &&
        userData?.user_type === STRINGS.CONTRACTOR_TYPE)
    ) {
      return STRINGS.TRADESMAN_TYPE;
    } else {
      return null;
    }
  };

  const userType = getContractedUserDetails();

  const showUserImages = () => {
    return (
      <img
        src={
          contractDetails?.[userType]?.profile_picture === null ||
          contractDetails?.[userType]?.profile_picture === ""
            ? `${profileIcon}`
            : `${bucket_base_url}${contractDetails?.[userType]?.profile_picture}`
        }
        className="avatar-image"
        alt="avatar-image"
      />
    );
  };

  return (
    <div className="contract-card">
      <div
        onClick={() => navigate(`/contract-details?id=${contractDetails?.id}`)}
        style={{ cursor: "pointer" }}
      >
        {contractDetails?.contractor?.company || userType ? (
          <div className="d-flex flex-column justify-content-center align-items-center mt-3 mb-3">
            <div className="d-flex align-items-center">
              <img src={Bag} />
              <span
                className="fw-bold fs-5"
                style={{ fontFamily: "Poppins", color: "#f77e0b" }}
              >
                {contractDetails?.contractor?.company ||
                  contractDetails?.[userType]?.first_name +
                    " " +
                    contractDetails?.[userType]?.last_name}
              </span>
              {contractDetails?.contractor?.company && (
                <span className="ms-2 mt-2">
                  <FormattedMessage id="COMPANY_WITH_BRACKETS" />
                </span>
              )}
            </div>
            <Divider />
          </div>
        ) : null}
        <div className="row">
          <div className="col-3 d-flex justify-content-center align-items-center">
            <div className="contract-profile">{showUserImages()}</div>
          </div>
          <div className="col-4 d-flex justify-content-center align-items-center flex-column">
            <span className="fw-bold mt-3">
              {getIDStatus(contractDetails?.status)}:
            </span>
            <span className="mt-2">{getContractId(contractDetails?.id)}</span>
            <span className="fw-bold mt-3">
              <FormattedMessage id="skill" />:
            </span>
            <span className="mt-2 skill-div">{getSkill(contractDetails)}</span>
          </div>
          <div className="col-5 d-flex justify-content-center align-items-center flex-column">
            <span className="fw-bold mt-3">
              <FormattedMessage id="flat_rate" />:
            </span>
            <span className="mt-2">
              {getFlatAmount()}{" "}
              {getFlatAmount() !== "---" ? (
                <FormattedMessage id="FIXED" />
              ) : null}
            </span>
            <span className="fw-bold mt-3">
              <FormattedMessage id="status" />:
            </span>
            <span className="mt-2 status-div text-center">
              {" "}
              {getContractStatus(
                showSecondaryStatus
                  ? contractDetails?.secondary_status
                  : contractDetails?.primary_status
                  ? contractDetails?.primary_status
                  : contractDetails?.status
              )}
            </span>
          </div>
        </div>
      </div>
      <Divider />
      <div className="d-flex justify-content-center align-items-center">
        <span style={{ color: "#f77e0b" }}>
          Dated:{formatDate(contractDetails?.created_at)}{" "}
          {convertTime(contractDetails?.created_at)}
        </span>
      </div>
      {userData?.user_type === "contractor" && showSubContracts && (
        <RenderSubContracts
          subContract={contractDetails?.subContracts}
          userData={userData}
        />
      )}
      {userData?.user_type === "contractor" &&
        contractDetails?.subContracts?.length > 0 && (
          <span
            style={{ backgroundColor: "red" }}
            onClick={() => setShowSubContracts(!showSubContracts)}
          >
            {showSubContracts ? (
              <div className="d-flex w-100 justify-content-center mt-2">
                <span style={{ color: "#f77e0b", cursor: "pointer" }}>
                  <FormattedMessage id="hide_details" />
                </span>
                <img src={showup} />
              </div>
            ) : (
              <div className="d-flex w-100 justify-content-center mt-2">
                <span
                  onClick={() => setShowSubContracts(!showSubContracts)}
                  style={{ color: "#f77e0b", cursor: "pointer" }}
                >
                  <FormattedMessage id="show_details" />
                </span>
                <img src={showdown} />
              </div>
            )}
          </span>
        )}
    </div>
  );
};

import { Button, Checkbox, Form, Input, message, Radio, Spin } from "antd";
import axios from "axios";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import apple from "../../assets/images/apple.png";
import { LoadingOutlined } from "@ant-design/icons";
import backIcon from "../../assets/images/backButton.svg";
import logo from "../../assets/images/gigmiLogo34.png";
import google from "../../assets/images/google.png";
import { base_url } from "../../constants";
import firebaseConfig from "../../Firebase/Firebase";
import "./Signup.css";
import { injectIntl } from "react-intl";
import { sessionMaintain } from "../../api/sessionsApi/session";
import { getFCMToken } from "../../utils/firebase";

function SignUpModal(props) {
  const [hideshowemail, setHideshowEmail] = useState(true);
  const [hideshowphone, setHideshowPhone] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [refCode, setRefCode] = useState("");
  const [termState, setTermState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [registerAs, setRegisterAs] = useState("email");
  const [EmailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const app = initializeApp(firebaseConfig);
  const recaptchaRef = useRef();
  const auth = getAuth(app);
  const url_string = window.location.href;
  const url2 = new URL(url_string);
  const type = url2.searchParams.get("type");
  let gigster;
  if (type === "tradesman") {
    gigster = "gigster";
  }
  const navigate = useNavigate();
  const lang = JSON.parse(localStorage.getItem("lang"));

  const handleSocialMediaLogin = async (data) => {
    await axios
      .post(`${base_url}auth/signupBySocial`, data)
      .then(async (response) => {
        localStorage.setItem("data", JSON.stringify(response.data));
        await sessionMaintain();
        message.success("SignUp successful");
        window.location.href = "/";
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;
        message.error(errorMessage);
      });
  };
  const handleGoogleSignIn = async () => {
    setIsLoading(true);
    try {
      const token = await recaptchaRef.current.executeAsync();

      await axios.post(`${base_url}capcha`, {
        uuid: token,
        capcha: true,
      });
      setIsLoading(false);
      const provider = new GoogleAuthProvider();
      const FCMToken = await getFCMToken();

      signInWithPopup(auth, provider)
        .then(async (result) => {
          const user = result._tokenResponse;
          const data = {
            first_name: user.firstName,
            last_name: user.lastName,
            username: user.email,
            type: type,
            isSocial: true,
            firebase_token: FCMToken ?? user.idToken,
            verification_code: token,
            referredById: null,
            preferences: lang ? "spanish" : "english",
          };
          await handleSocialMediaLogin(data);
        })
        .catch((error) => {});
    } catch (error) {}
  };

  const handleAppleSignUp = async () => {
    setIsLoading(true);
    try {
      const token = await recaptchaRef.current.executeAsync();

      await axios
        .post(`${base_url}capcha`, {
          uuid: token,
          capcha: true,
        })
        .then((response) => {
          setIsLoading(false);
          const provider = new OAuthProvider("apple.com");
          const FCMToken = getFCMToken();
          signInWithPopup(auth, provider)
            .then(async (result) => {
              const user = result._tokenResponse;
              const data = {
                first_name: user.firstName,
                last_name: user.lastName,
                username: user.email,
                type: type,
                isSocial: true,
                firebase_token: FCMToken ?? user.idToken,
                verification_code: token,
                referredById: null,
                preferences: lang ? "spanish" : "english",
              };
              await handleSocialMediaLogin(data);
            })
            .catch((error) => {
              message.error("something went wrong");
            });
        })
        .catch((error) => {
          message.error("Something went wrong");
        });
    } catch (error) {
      message.error();
    }
  };

  const onSubmitWithReCAPTCHA = async () => {
    setIsLoading(true);
    try {
      const token = await recaptchaRef.current.executeAsync();
      await axios
        .post(`${base_url}capcha`, {
          uuid: token,
          capcha: true,
        })
        .then(async () => {
          const FCMToken = await getFCMToken();
          handleSubmit(token, FCMToken);
        })
        .catch((error) => {
          message.error("something went wrong, please try again");
        });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [form] = Form.useForm();
  function handleClick() {
    setHideshowEmail(!hideshowemail);
  }
  function handleOtherClick() {
    setHideshowPhone(!hideshowphone);
  }

  const handleSubmit = async (token, FCMToken) => {
    await axios
      .post(`${base_url}auth/signupBySocial`, {
        first_name: firstName,
        last_name: lastName,
        username:
          registerAs === "email"
            ? EmailAddress?.toLowerCase()
            : "+" + phoneNumber,
        type: type,
        isSocial: false,
        firebase_token: FCMToken ?? null,
        verification_code: token,
        referredById: refCode.includes("-") ? refCode.split("-")[0] : null,
        preferences: lang ? "spanish" : "english",
      })
      .then((response) => {
        localStorage.setItem("apiResponse", JSON.stringify(response?.data));
        localStorage.setItem(
          "userData",
          JSON.stringify({
            otpType: registerAs,
            username:
              registerAs === "email"
                ? EmailAddress?.toLowerCase()
                : "+" + phoneNumber,
          })
        );

        setIsLoading(false);
        navigate(`/verifyOtp?flag=${true}`);
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;
        message.error(errorMessage);

        setIsLoading(false);
      });
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 64,
        color: "#F77E0B ",
      }}
      spin
    />
  );
  const currentLang = JSON.parse(localStorage.getItem("lang"));
  let language;
  if (currentLang) {
    language = "es";
  } else {
    language = "en";
  }

  return (
    <div className=" d-flex justify-content-center">
      <div className="col-12 col-sm-12 col-md-6 welcome1-background d-flex flex-column justify-content-center align-items-center">
        <div
          style={{
            position: "absolute",
            top: "30px ",
            left: "40px",
            fontFamily: "Poppins",
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <img
            src={backIcon}
            alt=""
            onClick={() => navigate(-1)}
            style={{ cursor: "pointer" }}
            className="shadow-lg border rounded"
          />
          <span className="text-black fs-4 m-1">
            {props.intl.formatMessage({ id: "back" })}
          </span>
        </div>
        <div
          className="w-100 d-flex d-sm-flex d-md-none d-lg-none justify-content-center "
          style={{ marginTop: "100px" }}
        >
          <img src={logo} className="w-25" />
        </div>

        <div className="bg-white  shadow-lg signIn-box mb-5">
          <div
            style={{
              display: "flex",
              marginTop: "22px",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <span className="login-sub-text" style={{ fontFamily: "Poppins" }}>
              {props.intl.formatMessage({ id: "create_profile_as" })}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span
              className="signIn-text"
              style={{ fontFamily: "Poppins", fontWeight: "bold" }}
            >
              {type === "tradesman"
                ? gigster.toUpperCase()
                : type?.toUpperCase()}
            </span>
          </div>

          <div className="form-padding-div">
            <Form
              initialValues={{
                remember: true,
              }}
              form={form}
              onFinish={onSubmitWithReCAPTCHA}
            >
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey="6LcG3L0mAAAAAA9M5sia-aqdCdf4mny7xVrrrM7L"
              />
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <span className="email-text">
                    {props.intl.formatMessage({ id: "first_name" })}
                  </span>

                  <Form.Item
                    name="firstname"
                    rules={[
                      {
                        required: true,
                        message: `${props.intl.formatMessage({
                          id: "first_name_required",
                        })}`,
                      },
                      {
                        pattern: new RegExp(/^[A-Za-z\s]+$/),
                        message: `${props.intl.formatMessage({
                          id: "not_accept_number",
                        })}`,
                      },
                    ]}
                  >
                    <Input
                      placeholder={props.intl.formatMessage({
                        id: "first_name",
                      })}
                      maxLength={30}
                      style={{
                        height: "56px",
                        backgroundColor: "#E6E7EB",
                        marginTop: "8px",
                      }}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Form.Item>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <span className="email-text">
                    {props.intl.formatMessage({ id: "last_name" })}
                  </span>

                  <Form.Item
                    name="lastname"
                    rules={[
                      {
                        required: true,
                        message: `${props.intl.formatMessage({
                          id: "last_name_required",
                        })}`,
                      },
                      {
                        pattern: new RegExp(/^[A-Za-z\s]+$/),
                        message: `${props.intl.formatMessage({
                          id: "not_accept_number",
                        })}`,
                      },
                    ]}
                  >
                    <Input
                      placeholder={props.intl.formatMessage({
                        id: "last_name",
                      })}
                      maxLength={30}
                      style={{
                        height: "56px",
                        backgroundColor: "#E6E7EB",
                        marginTop: "8px",
                      }}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Form.Item>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <span className="email-text">
                  {props.intl.formatMessage({ id: "register_by" })}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "8px",
                  marginBottom: "16px",
                }}
              >
                <Radio.Group
                  name="radiogroup"
                  onChange={() => {
                    handleClick();
                    handleOtherClick();
                  }}
                  defaultValue={1}
                >
                  <Radio
                    value={1}
                    className="email-text"
                    onClick={() => setRegisterAs("email")}
                  >
                    {props.intl.formatMessage({ id: "email" })}
                  </Radio>
                  <Radio
                    value={2}
                    className="email-text"
                    onClick={() => setRegisterAs("phone")}
                  >
                    {props.intl.formatMessage({ id: "phone_no" })}
                  </Radio>
                </Radio.Group>
              </div>
              {hideshowemail && (
                <>
                  <span className="email-text">
                    {props.intl.formatMessage({ id: "email" })}
                  </span>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        pattern: new RegExp(
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|io|org|net|edu|gov|mil|co|ac|biz|info|me|us|ca|uk|co\.uk)$/
                        ),

                        message: `${props.intl.formatMessage({
                          id: "valid_email",
                        })}`,
                      },
                      {
                        required: true,
                        message: `${props.intl.formatMessage({
                          id: "email_required",
                        })}`,
                      },
                    ]}
                  >
                    <Input
                      placeholder={props.intl.formatMessage({ id: "email" })}
                      type="email"
                      maxLength={50}
                      style={{
                        height: "56px",
                        backgroundColor: "#E6E7EB",
                        marginTop: "8px",
                      }}
                      onChange={(e) => setEmailAddress(e.target.value)}
                    />
                  </Form.Item>
                </>
              )}
              {hideshowphone && (
                <>
                  <span className="email-text">
                    {props.intl.formatMessage({ id: "phone_no" })}
                  </span>
                  <div style={{ marginTop: "8px" }}>
                    <Form.Item
                      name="Phonenumber"
                      rules={[
                        {
                          required: true,
                          message: `${props.intl.formatMessage({
                            id: "phone_required",
                          })}`,
                        },
                      ]}
                    >
                      <PhoneInput
                        inputProps={{
                          name: "phone",
                          required: true,
                          //  autoFocus: true,
                        }}
                        value={phoneNumber}
                        onlyCountries={["us"]}
                        onChange={(e) => {
                          setPhoneNumber(e);
                        }}
                        countryCodeEditable={false}
                        inputStyle={{
                          width: "100%",
                          height: "56px",
                          backgroundColor: "#E6E7EB",
                          marginTop: "8px",
                        }}
                        country={"us"}
                        className="w-100 phonenumber_field countries"
                        inputClass="contact_field"
                      />
                    </Form.Item>
                  </div>
                </>
              )}
              <span className="email-text">
                {props.intl.formatMessage({ id: "refferal_code" })}
              </span>

              <Form.Item
                name="referalCode"
                rules={[
                  {
                    required: false,
                    message: "",
                  },
                  {
                    pattern: new RegExp(/^\d*-?\d*-?\d*$/),
                    message: `${props.intl.formatMessage({
                      id: "wrong_format",
                    })}`,
                  },
                ]}
              >
                <Input
                  placeholder="Enter Referral Code"
                  style={{
                    height: "56px",
                    backgroundColor: "#E6E7EB",
                    marginTop: "8px",
                  }}
                  onChange={(e) => setRefCode(e.target.value)}
                />
              </Form.Item>

              <div className="view">
                <Form.Item
                  name="mycheckbox"
                  rules={[
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "accept_terms",
                      })}`,
                      validator: () => {
                        if (!termState) {
                          return Promise.reject();
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <div style={{ display: "flex" }}>
                    <Checkbox onChange={() => setTermState(!termState)} />
                    <p style={{ marginTop: "13px", marginLeft: "10px" }}>
                      {props.intl.formatMessage({ id: "agree_to" })}{" "}
                      <span
                        style={{ color: "#F77E0B", cursor: "pointer" }}
                        onClick={() =>
                          (window.location.href = `/terms-of-services/${language}`)
                        }
                      >
                        {props.intl.formatMessage({ id: "terms" })}{" "}
                      </span>{" "}
                      {props.intl.formatMessage({ id: "and" })}{" "}
                      <span
                        style={{ color: "#F77E0B", cursor: "pointer" }}
                        onClick={() =>
                          (window.location.href = `/privacy-policy/${language}`)
                        }
                      >
                        {props.intl.formatMessage({
                          id: "footor.privacyPolicy",
                        })}{" "}
                      </span>{" "}
                    </p>
                  </div>
                </Form.Item>
              </div>
              <Form.Item>
                <Button
                  style={{
                    width: "100%",
                    backgroundColor: isLoading ? "#cdd9df77" : "#F77E0B",
                    height: "56px",
                    color: "white",
                    marginTop: "10px",
                    fontWeight: "bold",
                  }}
                  type="submit"
                  htmlType="submit"
                  disabled={isLoading}
                >
                  {props.intl.formatMessage({ id: "continue" })}
                </Button>
              </Form.Item>
            </Form>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spin spinning={isLoading} size="larger" indicator={antIcon} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: "0px",
                  width: "114px",
                  border: "1px solid #CFDAE6",
                }}
              ></div>
              <span>{props.intl.formatMessage({ id: "or" })}</span>
              <div
                style={{
                  height: "0px",
                  width: "114px",
                  border: "1px solid #CFDAE6",
                }}
              ></div>
            </div>

            <div className="w-100 d-flex flex-column">
              <div
                className="border w-100 shadow rounded d-flex justify-content-center align-items-center p-2 mt-4"
                style={{ height: "56px", cursor: "pointer" }}
                onClick={handleGoogleSignIn}
              >
                <img src={google} alt="" />
                <span className="social-text text-black ms-2">
                  {props.intl.formatMessage({ id: "continue_with_google" })}
                </span>
              </div>
              <div
                className="border w-100 shadow rounded d-flex justify-content-center align-items-center p-2 mt-3"
                style={{
                  height: "56px",
                  backgroundColor: "black",
                  cursor: "pointer",
                }}
                onClick={handleAppleSignUp}
              >
                <img src={apple} alt="" />
                <span className="social-text text-white ms-2">
                  {props.intl.formatMessage({ id: "continue_with_apple" })}
                </span>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "12px",
              }}
              className="link"
            >
              <span>
                {props.intl.formatMessage({ id: "already_have_account" })}{" "}
              </span>
              <a
                className="phone-login"
                style={{ cursor: "pointer", marginLeft: "5px" }}
                onClick={() => navigate("/login")}
              >
                {props.intl.formatMessage({ id: "login" })}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-6 d-none d-sm-none d-md-flex justify-content-center align-items-center welcome-background">
        <img src={logo} style={{ width: "300px", height: "170px" }} />
      </div>
    </div>
  );
}
export default injectIntl(SignUpModal);

import { Button, DatePicker, Form, Input, Select, Switch, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { GetAdminSkills } from "../../api/contractsApi/getSkillsApi";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header/Header";
import LocationSearch from "../../components/AutoCompleteLocation/AutoCompleteLocation";
import { EnvironmentOutlined, PlusOutlined } from "@ant-design/icons";
import { bucket_base_url, google_map_api_key } from "../../constants";
import { useLocation, useNavigate } from "react-router";
import { extractLatLng, extractString } from "../../utils/Helper";
import AddSkillModal from "../../components/UpdateProfile/AddSkillModal";
import ContractAmountDetailsModal from "../../components/Contracts/ContractAmountDetailModal";
import GigsterListingModal from "../../components/Contracts/GigsterListingModal";
import Disclaimer from "../../components/BankInfo/Disclaimer";
import { GenerateGigmiCharges } from "../../api/contractsApi/contractDetails";
import axios from "axios";
import Loader from "../../components/GeneralComponents";

const SendOffer = ({ intl, ...props }) => {
  const { state } = useLocation();
  const { data, likeListing } = state;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [skillOptions, setSkillOptions] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const gigmiCharges = useSelector((state) => state?.gigmiCharges?.charges);
  const [confirmChargesModal, setConfirmChargesModal] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [finalData, setFinalData] = useState({});
  const [gigsterModal, setGigstersModal] = useState(false);
  const userTypeData = useSelector((state) => state?.common?.newUser?.data);
  const fullUser = useSelector((state) => state?.common?.fullUser);
  const userData = JSON.parse(localStorage.getItem("data"));
  const [amountDeduction, setAmountDeduction] = useState(false);
  const [isBalanceAvailable, setIsBalanceAvailable] = useState(false);
  const [locationLoading, setLocationLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [senderVerification, setSenderVerification] = useState(
    !fullUser?.sendingAccountVerify
  );
  const [address, setAddress] = useState(
    extractString(data?.latt_long_loc_name)
  );
  const [coordinates, setCoordinates] = useState(
    extractLatLng(data?.latt_long_loc_name)
  );

  const [fileList, setFileList] = useState(
    data?.postFile?.length > 0
      ? [
          {
            url: `${bucket_base_url}${data?.postFile[0]?.key}`,
            originFileObj: data?.postFile[0],
          },
        ]
      : []
  );
  const feedFileId = data?.postFile?.length > 0 ? data?.postFile[0]?.id : null;
  const getSkills = async () => {
    const res = await GetAdminSkills();
    setSkillOptions(res);
  };

  useEffect(() => {
    getSkills();
    if (userData?.user_type !== "tradesman") {
      const body = {
        homeOwnerId:
          userData?.user_type === "contractor" ? null : userTypeData?.id,
        contractorId:
          userData?.user_type === "contractor" ? userTypeData?.id : null,
      };
      GenerateGigmiCharges(body, dispatch);
    }
  }, []);
  const validateTotalAmount = (_, value) => {
    if (Number(value) > 5000) {
      return Promise.reject("Total amount should not be greater than 5000");
    }
    if (Number(value) < 0) {
      return Promise.reject("Total amount should not be less than 0");
    }

    return Promise.resolve();
  };
  const disabledDate = (current) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return current && current < currentDate;
  };
  useEffect(() => {
    form.setFieldsValue({ location: address });
  }, [locationLoading]);
  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          setCoordinates({
            lat,
            lng,
          });
          axios(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${google_map_api_key}`
          )
            .then((data) => {
              if (data.data.results && data.data.results.length > 0) {
                setAddress(data?.data?.results[0]?.formatted_address);
              }
              setLocationLoading(!locationLoading);
            })
            .catch((error) => {
              console.error(error);
            });
        },
        (error) => {
          message.error("Error getting location:", error);
        }
      );
    } else {
      message.error("Geolocation is not supported in this browser.");
    }
  };
  const onFileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const handleSubmit = (values) => {
    const finalData = {
      skill: null,
      user_skill: values.skill,
      user_id: userTypeData?.id,
      user_current_type: userData?.user_type,
      job_desc: values.jobdesc,
      flat_amount: values.flatrate,
      start_date: values.sDate,
      end_date: values.eDate,
      latitude: coordinates?.lat,
      longitude: coordinates?.lng,
      location_name: address,
      isTemplate: false,
      gigmi_charges: gigmiCharges,
    };
    setFinalData(finalData);

    if (userData?.user_type === "contractor") {
      setConfirmChargesModal(true);
    }
  };
  return (
    <>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}
      <div className="container">
        <Header />
        <Form
          onFinish={handleSubmit}
          form={form}
          layout="vertical"
          initialValues={{
            skill: data?.skill,
            jobdesc: data?.desc,
            totalAmount: "",
            sDate: "",
            flatrate: data?.flat_rate,
            eDate: "",
            upload: "",
            flatRate: "",
            lineItems: "",
          }}
          style={{ fontFamily: "Poppins" }}
        >
          <div>
            <Form.Item
              name="skill"
              rules={[
                {
                  required: true,
                  message: `${intl.formatMessage({ id: "required" })}`,
                },
              ]}
            >
              <div className="row w-100">
                <span className="col-6">
                  <FormattedMessage id="skill" />{" "}
                </span>
                <div className="col-6 d-flex justify-content-end">
                  <span
                    style={{
                      color: "#F77E0B",
                      fontFamily: "Poppins",
                      cursor: "pointer",
                    }}
                    onClick={() => setModalShow(true)}
                  >
                    {intl.formatMessage({ id: "addNewSkill" })}
                  </span>
                </div>
              </div>
              <Select
                style={{ paddingBottom: 0 }}
                placeholder={`${intl.formatMessage({ id: "select_skill" })}`}
                onChange={(value) => form.setFieldsValue({ skill: value })}
                defaultValue={data?.skill}
              >
                {skillOptions?.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Form.Item
            name="jobdesc"
            label={`${intl.formatMessage({ id: "job_desc" })}`}
            rules={[
              {
                required: true,
                message: `${intl.formatMessage({ id: "required" })}`,
              },
            ]}
          >
            <Input.TextArea
              placeholder={`${intl.formatMessage({ id: "job_desc" })}`}
              type="tdext"
              className="input-field"
              rows={4}
              style={{ padding: 20, resize: "none" }}
            />
          </Form.Item>
          <Form.Item
            name="flatrate"
            label={`${intl.formatMessage({ id: "flat_rate" })}`}
            rules={[
              {
                required: true,
                message: `${intl.formatMessage({ id: "required" })}`,
              },
              { validator: validateTotalAmount },
            ]}
          >
            <Input
              type="number"
              placeholder={`$ ${intl.formatMessage({ id: "flat_rate" })}`}
              className="input-field"
            />
          </Form.Item>
          <Form.Item>
            <div
              className="d-flex p-2"
              style={{
                border: "2px solid grey",
                borderRadius: "3px",
                fontFamily: "Poppins",
              }}
            >
              <span className="d-flex flex-column w-75">
                <span style={{ fontSize: "17px" }}>
                  <FormattedMessage id="DEDUCT_AMOUNT" />
                </span>
                <span>
                  <FormattedMessage id="Available_balance" />= $
                  {userTypeData?.wallet}
                </span>
              </span>
              <div className="d-flex w-25 justify-content-end">
                <Switch
                  checked={amountDeduction}
                  onChange={() => {
                    if (parseFloat(userTypeData?.wallet) > 0)
                      setAmountDeduction(!amountDeduction);
                    else setIsBalanceAvailable(true);
                  }}
                />
              </div>
            </div>
          </Form.Item>
          <div className="row">
            <div className="col-6">
              <Form.Item
                name="sDate"
                label={`${intl.formatMessage({ id: "start_date" })}`}
                rules={[
                  {
                    required: true,
                    message: `${intl.formatMessage({ id: "required" })}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={`${intl.formatMessage({ id: "start_date" })}`}
                  disabledDate={disabledDate}
                  className="input-field"
                />
              </Form.Item>
            </div>
            <div className="col-6">
              <Form.Item
                name="eDate"
                label={`${intl.formatMessage({ id: "end_date" })}`}
                rules={[
                  {
                    required: true,
                    message: `${intl.formatMessage({ id: "required" })}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={`${intl.formatMessage({ id: "end_date" })}`}
                  disabledDate={disabledDate}
                  className="input-field"
                />
              </Form.Item>
            </div>
          </div>
          <div className="row ">
            <div className="col-12">
              <div className="d-flex justify-content-between">
                <div>
                  <span className="email-text">
                    {intl.formatMessage({ id: "location" })}
                  </span>
                </div>
                <div onClick={handleGetLocation}>
                  <span>
                    <EnvironmentOutlined style={{ color: "#F77E0B" }} />
                    <span
                      className="trad-location ms-1"
                      style={{ userSelect: "none" }}
                    >
                      {intl.formatMessage({ id: "current_location" })}
                    </span>
                  </span>
                </div>
              </div>

              <Form.Item
                name="location"
                rules={[
                  {
                    required: false,
                    message: `${intl.formatMessage({
                      id: "location_required",
                    })}`,
                  },
                ]}
              >
                <LocationSearch
                  address={address}
                  setAddress={setAddress}
                  coordinates={coordinates}
                  setCoordinates={setCoordinates}
                />
              </Form.Item>
            </div>
          </div>
          <Upload
            action="/upload.do"
            listType="picture-card"
            maxCount={4}
            accept=".jpeg"
            fileList={fileList}
            onChange={onFileChange}
          >
            {fileList?.length >= 4 ? null : (
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  backgroundColor: "#F77E0B",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PlusOutlined style={{ color: "white" }} />
              </div>
            )}
          </Upload>
          <div className="d-flex justify-content-end">
            <Form.Item>
              <Button type="submit" htmlType="submit" className="submit-button">
                <FormattedMessage id="submit" />
              </Button>
            </Form.Item>
          </div>
        </Form>
        <AddSkillModal
          modalShow={modalShow}
          setModalShow={setModalShow}
          skillArray={skillOptions}
          selectedSkills={selectedSkills}
          setSkillArray={setSkillOptions}
          setSelectedSkills={setSelectedSkills}
        />
        <ContractAmountDetailsModal
          visible={confirmChargesModal}
          title={<FormattedMessage id="CONTRACT_AMOUNT_DETAIL" />}
          contractId={null}
          gigmiCharges={gigmiCharges}
          flatAmount={finalData?.flat_amount}
          cancelText={<FormattedMessage id="CANCEL" />}
          cancelAction={() => setConfirmChargesModal(false)}
          confirmText={<FormattedMessage id="CONFIRM" />}
          confirmAction={() => {
            setConfirmChargesModal(false);
            setGigstersModal(true);
          }}
        />
        {gigsterModal && (
          <GigsterListingModal
            visible={gigsterModal}
            finalData={finalData}
            contractImages={fileList}
            handleCancel={() => setGigstersModal(false)}
            setLoading={setLoading}
            path="post"
            connectionList={likeListing}
            intl={intl}
            feedFileId={feedFileId}
          />
        )}
        <Disclaimer
          showModal={isBalanceAvailable}
          setShowModal={() => setIsBalanceAvailable(false)}
          body={intl.formatMessage({
            id: "WALLET_DOES_NOT_HAVE_SUFFICIENT_BALANCE",
          })}
          handleCancel={() => {
            setIsBalanceAvailable(false);
          }}
          handleClick={() => setIsBalanceAvailable(false)}
          confirmText={<FormattedMessage id="ok" />}
          cancelText={<FormattedMessage id="cancel" />}
        />
      </div>
      <Disclaimer
        showModal={senderVerification}
        setShowModal={() => setSenderVerification(false)}
        body={<FormattedMessage id="receiver_account" />}
        cancelText={<FormattedMessage id="no" />}
        confirmText={<FormattedMessage id="yes" />}
        handleClick={() => navigate("/payment-options")}
        handleCancel={() => navigate("/")}
      />
    </>
  );
};
export default injectIntl(SendOffer);

import React, { useState } from "react";
import { injectIntl } from "react-intl";
import showdown from "../../assets/images/caretDown.svg";
import showup from "../../assets/images/caretUp.svg";
import "./style.css";
import { formatDate, formatTime } from "../../utils/Helper";
const TransactionDetailsCard = ({
  transactionID,
  amount,
  date,
  accountName,
  accountNumber,
  contractID,
  type,
  typeName,
  status,
  ...props
}) => {
  const [selected, setSelected] = useState("Collapse");

  return (
    <div className="transaction-card">
      <div className="d-flex justify-content-between">
        <span>{props.intl.formatMessage({ id: "trans_id" })}</span>
        <span>#{transactionID}</span>
      </div>
      <div className="d-flex justify-content-between">
        <span>{props.intl.formatMessage({ id: "amount" })}</span>
        <span>$ {amount}</span>
      </div>
      {selected === "View" ? null : (
        <div
          onClick={() => setSelected("View")}
          className="d-flex justify-content-center"
        >
          <span style={{ color: "#F77E0B", cursor: "pointer" }}>
            {props.intl.formatMessage({ id: "show_details" })}
          </span>
          <img src={showdown} />
        </div>
      )}

      {selected === "View" && (
        <>
          <div className="d-flex justify-content-between">
            <span>{props.intl.formatMessage({ id: "date_&_time" })}</span>
            <span>
              {formatDate(date)} at {formatTime(date)}
            </span>
          </div>
          <div className="divider"></div>
          <div className="d-flex justify-content-between">
            <span>{props.intl.formatMessage({ id: "contract_id" })}</span>
            <span>#{contractID}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span>
              {type === "contractor" && "Contractor"}
              {type === "tradesman" && "Gigster"}
              {type === "homeowner" && "Homeowner"}:
            </span>
            <span>{typeName}</span>
          </div>
        </>
      )}

      {selected === "Collapse" ? null : (
        <div
          onClick={() => setSelected("Collapse")}
          className="d-flex justify-content-center"
        >
          <span
            style={{ color: "#F77E0B", paddingLeft: "5px", cursor: "pointer" }}
          >
            {props.intl.formatMessage({ id: "hide_details" })}
          </span>
          <img src={showup} />
        </div>
      )}
    </div>
  );
};
export default injectIntl(TransactionDetailsCard);

import * as Actions from "../actions/ActionTypes";
const InvoicesReducer = (
  state = {
    invoicesData: [],
  },
  action
) => {
  if (action.type == Actions.SET_INVOICES_LIST_REQUEST) {
    return Object.assign({}, state, {
      invoicesData: action?.data,
    });
  } else {
    return state;
  }
};

export default InvoicesReducer;

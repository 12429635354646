import React, { useEffect, useState } from "react";
import { getCheckButtonTitle } from "./helper";
import { Button, message } from "antd";
import { CheckInCheckOutRequest } from "../../api/contractsApi/checkincheckout";
import { useDispatch } from "react-redux";

export const CheckInButton = ({
  userData,
  status,
  contractLogs,
  contractDetail,
  setLoading,
}) => {
  const [userLocation, setUserLocation] = useState("");
  useEffect(() => {
    getUserLocation();
  }, []);
  const dispatch = useDispatch();
  const checkButtonOnPress = async () => {
    setLoading(true);

    if (
      contractDetail?.contract_logs[0]?.check_in_time !== null &&
      contractDetail?.contract_logs[0]?.check_out_time !== null
    ) {
      await checkIn("checkIn");
      setLoading(false);
    } else if (contractDetail?.contract_logs[0]?.check_out_time === null) {
      await checkIn("checkOut");
    }
    setLoading(false);
  };

  const checkIn = async (type) => {
    if (!userLocation) {
      return;
    }
    const contractLocation = {
      contractLatitude: contractDetail?.contract_location[0]?.latitude,
      contractLongitude: contractDetail?.contract_location[0]?.longitude,
    };
    const distance = calculateDistance(userLocation, contractLocation);

    if (distance <= 500) {
      const finalData = {
        time: new Date(),
        contract_id: contractDetail?.id,
        contractor_id: contractDetail?.contractorId || null,
        homeownerId: contractDetail?.contractorId
          ? null
          : contractDetail?.homeownerId,
      };
      const msg = type;
      await CheckInCheckOutRequest(finalData, msg, dispatch);
    } else {
      message.error("Outside the  Radius");
    }
  };
  const calculateDistance = (location1, location2) => {
    const R = 6371000;
    const lat1 = (Math.PI / 180) * location1.latitude;
    const lat2 = (Math.PI / 180) * location2.contractLatitude;
    const lon1 = (Math.PI / 180) * location1.longitude;
    const lon2 = (Math.PI / 180) * location2.contractLongitude;

    const dlat = lat2 - lat1;
    const dlon = lon2 - lon1;

    const a =
      Math.sin(dlat / 2) * Math.sin(dlat / 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) * Math.sin(dlon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;
    return distance;
  };

  const getUserLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting user location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };
  return userData?.user_type === "tradesman" &&
    status === "running" &&
    !["waiting", "blocked"].includes(contractDetail?.primary_status) ? (
    <div className="mt-5">
      <Button
        onClick={checkButtonOnPress}
        className="w-100 fw-bold shadow"
        style={{
          height: "56px",
          color: "#f77e0b",
          border: "2px dashed #f77e0b",
        }}
      >
        {getCheckButtonTitle(contractLogs)}
      </Button>
    </div>
  ) : (
    <></>
  );
};

import React, { useEffect, useState } from "react";
import Gig from "../Gig/Gig";
import { getStories } from "../../views/Home/Service";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import plus from "../../assets/images/Plus.svg";
import logo from "../../assets/images/gigmiLogo34.png";
import profileIcon from "../../assets/images/profileIcon.png";

import "./style.css";
import { FormattedMessage } from "react-intl";
import {
  setStoryIndex,
  setStoryModal,
  setUnsubscribedUser,
} from "../../actions/CommonActions";
import GigSelectionModal from "../../views/GigsViewScreen/GigzSelectionModal";
import { isEmpty } from "lodash";

export const Stories = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = JSON.parse(localStorage.getItem("data"));
  const { stories } = useSelector((state) => state?.common?.storiesList);
  const { singleStoryModal } = useSelector((state) => state?.common);
  const [data, setData] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const fullUser = useSelector((state) => state?.common?.fullUser);
  useEffect(() => {
    (async () => await getStories(dispatch))();
  }, [data]);

  const handleVideoGalley = () => {
    setModalVisible(false);
    navigate("/upload-gigs");
  };

  return (
    <div>
      <div className="d-flex container pt-3 stories-container-div">
        <div className="stories-container-inner-div">
          <div className="d-flex flex-column justify-content-around align-items-center story-card">
            <img src={logo} className="story-logo" alt={"logo"} />
            <div className="gig-circle d-flex justify-content-center align-items-center">
              <div
                onClick={() => {
                  if (!isAuthenticated) {
                    return navigate("/welcome");
                  } else if (fullUser?.subscribed === false) {
                    dispatch(setUnsubscribedUser(true));
                  } else {
                    setModalVisible(true);
                  }
                }}
                onKeyDown={() => {
                  if (!isAuthenticated) {
                    return navigate("/welcome");
                  }
                  setModalVisible(true);
                }}
              >
                <label
                  htmlFor="gig"
                  className="d-flex flex-column align-items-center justify-content-center"
                >
                  <div className="d-flex justify-content-center align-items-center plus-icon">
                    <img src={plus} alt={"plus"} />
                  </div>
                </label>
              </div>
            </div>
            <div>
              <span className="create-story">
                <FormattedMessage id="create_story" />
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex story-card-div">
          {stories?.length > 0 &&
            stories?.map(({ user, stories: story }, index) => {
              const image =
                process.env.REACT_APP_S3_BUCKET_URL + story[0]?.storyThumbnail;

              return (
                <>
                  <div className="d-flex flex-column align-items-center me-3">
                    <div
                      className="story-card story-card-bg gig-image-alt"
                      style={{
                        backgroundImage: `url(${image})`,
                      }}
                      onClick={() => {
                        dispatch(setStoryModal(!singleStoryModal));
                        dispatch(setStoryIndex(index));
                      }}
                      onKeyDown={() => {
                        dispatch(setStoryModal(!singleStoryModal));
                        dispatch(setStoryIndex(index));
                      }}
                    >
                      <div className="gig-circle mt-1 ms-1">
                        <img
                          src={
                            user?.profile_picture === null ||
                            user?.profile_picture === ""
                              ? profileIcon
                              : `${process.env.REACT_APP_S3_BUCKET_URL}${user?.profile_picture}`
                          }
                          alt="Profile Picture"
                          className="gig-image gig-image-alt"
                        />
                      </div>
                      <div className="story-name">
                        <span className="story-name-text">
                          {user?.type === isAuthenticated?.user_type &&
                          user?.id === isAuthenticated?.id
                            ? "My Stories"
                            : user?.first_name}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Gig storyList={stories} index={index} />
                </>
              );
            })}
        </div>
        {modalVisible && (
          <GigSelectionModal
            visible={modalVisible}
            onCancel={() => {
              setModalVisible(false);
            }}
            setData={setData}
            handleVideoGalley={handleVideoGalley}
          />
        )}
      </div>
    </div>
  );
};

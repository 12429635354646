import { Modal } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

export const GigmiUserModal = ({ showModal, setShowModal, setGigmiUser }) => {
  return (
    <Modal footer={null} open={showModal} closable={false}>
      <div
        className="d-flex  flex-column p-4 "
        style={{ fontFamily: "Poppins" }}
      >
        <div
          className="d-flex justify-content-center fs-5 text-center fw-bold mb-1 "
          style={{ color: "#f77e0b" }}
        >
          <FormattedMessage id="disclaimer" />
        </div>
        <div className="d-flex justify-content-center fs-5 text-center">
          <FormattedMessage id="existing_user_text" />
        </div>
        <div
          style={{ borderTop: "2px solid #e6e7eb", width: "100%" }}
          className="mt-4 mb-4"
        ></div>
        <div className="d-flex justify-content-around fw-bold">
          <span
            style={{ color: "#f77e0b", cursor: "pointer" }}
            onClick={() => {
              setGigmiUser(false);
              setShowModal();
            }}
          >
            <FormattedMessage id="no" />
          </span>
          <div style={{ borderLeft: "2px solid #e6e7eb" }}></div>
          <span
            style={{ color: "#f77e0b", cursor: "pointer" }}
            onClick={() => {
              setGigmiUser(true);
              setShowModal();
            }}
          >
            <FormattedMessage id="yes" />
          </span>
        </div>
      </div>
    </Modal>
  );
};

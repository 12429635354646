import * as Actions from "../actions/ActionTypes";
const ChargesReducer = (
  state = {
    charges: null,
  },
  action
) => {
  if (action.type == Actions.SET_GIGMI_CHARGES) {
    return Object.assign({}, state, {
      charges: action?.data,
    });
  } else {
    return state;
  }
};

export default ChargesReducer;

import React, { useState } from "react";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { message } from "antd";
import Loader from "../../components/GeneralComponents";
import DocumentIcon from "../../assets/images/documentColored.svg";
import { injectIntl } from "react-intl";

const ProofofWork = ({ current, setCurrent, data, setData, ...props }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event, index) => {
    const file = event.target.files[0];
    event.target.value = "";
    const pics = [...data, file];
    setData(pics);
  };

  const handleClearClick = (index) => {
    let pics = [...data];
    pics.splice(index, 1);
    setData(pics);
  };

  const handleSubmitForm = async () => {
    if (data.length === 0) {
      message.error(`${props.intl.formatMessage({ id: "upload_atleast" })}`);
      return;
    }
    setCurrent(current + 1);
    setLoading(false);
  };

  const handlePreview = (item) => {
    if (typeof item === "string") {
      return `${process.env.REACT_APP_S3_BUCKET_URL}${item}`;
    } else {
      if (item?.type === "application/pdf") {
        return DocumentIcon;
      } else {
        return URL.createObjectURL(item);
      }
    }
  };

  return (
    <div>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}

      <div className="m-1 row shadow p-0  p-sm-1 p-md-3 p-lg-2 rounded">
        <div className="d-flex justify-content-between">
          <div className="col-8 div-proof-image-col ">
            <div className="imageUpload">
              <input
                id={`media`}
                type="file"
                accept="image/*, application/pdf"
                onChange={handleSubmit}
                className="imageUpload-input"
              />

              <div className="proofPreview">
                {!data[0] && (
                  <label htmlFor={`media`}>
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        backgroundColor: "#F77E0B",
                        fontSize: "30px",
                        color: "white",
                        cursor: "pointer",
                      }}
                      className="d-flex justify-content-center align-items-center rounded-circle"
                    >
                      <span className="mb-1">+</span>
                    </div>
                  </label>
                )}
              </div>
              {data[0] && (
                <div className="proofPreview">
                  <img
                    src={handlePreview(data[0])}
                    className={`${
                      data[0].type === "application/pdf"
                        ? "w-50 h-50"
                        : "w-100 h-100"
                    } rounded`}
                    style={{
                      objectFit:
                        data[0].type === "application/pdf" ? "fill" : "cover",
                    }}
                  />
                  <span
                    className="close-icon"
                    onClick={() => handleClearClick(0)}
                  >
                    <CloseOutlined />
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="col-4 mx-4">
            <div className="col-10 div-proof-col">
              <div className="imageUpload">
                <input
                  id="media1"
                  type="file"
                  accept="image/*, application/pdf"
                  onChange={handleSubmit}
                  className="imageUpload-input"
                />

                <div className="proofPreview">
                  {!data[1] && (
                    <label htmlFor={`media1`}>
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          backgroundColor: "#F77E0B",
                          fontSize: "30px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        className="d-flex justify-content-center align-items-center rounded-circle"
                      >
                        <span className="mb-1">+</span>
                      </div>
                    </label>
                  )}
                </div>
                {data[1] && (
                  <div className="proofPreview">
                    <img
                      src={handlePreview(data[1])}
                      className={`${
                        data[1].type === "application/pdf"
                          ? "w-50 h-50"
                          : "w-100 h-100"
                      } rounded`}
                      style={{
                        objectFit:
                          data[1].type === "application/pdf" ? "fill" : "cover",
                      }}
                    />
                    <span
                      className="close-icon"
                      onClick={() => handleClearClick(1)}
                    >
                      <CloseOutlined />
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="col-10 div-proof-col">
              <div className="imageUpload">
                <input
                  id="media1"
                  type="file"
                  accept="image/*, application/pdf"
                  onChange={handleSubmit}
                  className="imageUpload-input"
                />

                <div className="proofPreview">
                  {!data[2] && (
                    <label htmlFor={`media1`}>
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          backgroundColor: "#F77E0B",
                          fontSize: "30px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        className="d-flex justify-content-center align-items-center rounded-circle"
                      >
                        <span className="mb-1">+</span>
                      </div>
                    </label>
                  )}
                </div>
                {data[2] && (
                  <div className="proofPreview">
                    <img
                      src={handlePreview(data[2])}
                      className={`${
                        data[2].type === "application/pdf"
                          ? "w-50 h-50"
                          : "w-100 h-100"
                      } rounded`}
                      style={{
                        objectFit:
                          data[2].type === "application/pdf" ? "fill" : "cover",
                      }}
                    />
                    <span
                      className="close-icon"
                      onClick={() => handleClearClick(2)}
                    >
                      <CloseOutlined />
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="col-10 div-proof-col">
              <div className="imageUpload">
                <input
                  id="media1"
                  type="file"
                  accept="image/*, application/pdf"
                  onChange={handleSubmit}
                  className="imageUpload-input"
                />

                <div className="proofPreview">
                  {!data[3] && (
                    <label htmlFor={`media1`}>
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          backgroundColor: "#F77E0B",
                          fontSize: "30px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        className="d-flex justify-content-center align-items-center rounded-circle"
                      >
                        <span className="mb-1">+</span>
                      </div>
                    </label>
                  )}
                </div>
                {data[3] && (
                  <div className="proofPreview">
                    <img
                      src={handlePreview(data[3])}
                      className={`${
                        data[3].type === "application/pdf"
                          ? "w-50 h-50"
                          : "w-100 h-100"
                      } rounded`}
                      style={{
                        objectFit:
                          data[3].type === "application/pdf" ? "fill" : "cover",
                      }}
                    />
                    <span
                      className="close-icon"
                      onClick={() => handleClearClick(3)}
                    >
                      <CloseOutlined />
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 text-center">
          <Button
            className=""
            style={{
              width: "99%",
              height: "56px",
              color: "#fff",
              backgroundColor: "#F77E0B",
              border: "2px solid #F77E0B",
            }}
            onClick={handleSubmitForm}
          >
            {props.intl.formatMessage({ id: "continue" })}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ProofofWork);

import { Button } from 'antd';

import logo from '../../assets/images/gigmiLogo34.png';
import logoPic from '../../assets/images/gigmipic.png';
import { injectIntl } from 'react-intl';

 function Welcome(props) {
  return (
    <div className='view'>
    <div className='row vh-100'>
      <div className='col-12 col-sm-12 col-md-6 welcome1-background d-flex justify-content-center align-items-center'>
      <div className=' shadow-lg bg-white welcome-box' >
        <div className="d-flex justify-content-center w-100">
          <img src={logoPic} alt="" style={{ width: '60%' }} />
        </div>

        <div
          className="shadow-lg  p-4 w-100"
          style={{ marginTop: '0px ! important' ,backgroundColor:"#F77E0B",borderRadius:"15px"}}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <span className="welcome-text margin-left-text ">
            {props.intl.formatMessage({id:"welcome_to"})}
            </span>
          </div>
          <div
            style={{
              marginTop: '12px',
            }}
          ></div>

          <div className="container" style={{ marginTop: '24px' }}>
            <Button
              style={{
                width: '100%',
                height: '56px',
              }}
              type="submit"
              htmlType="submit"
              className="signIn-Button"
              onClick={() => (window.location.href = '/login')}
            >
              {props.intl.formatMessage({id:"login"})}
            </Button>
          </div>
          <div className="container" style={{ marginTop: '24px' }}>
            <Button
              style={{
                width: '100%',
                height: '56px',
              }}
              className="signUp-Button"
              type="submit"
              htmlType="submit"
              onClick={() => (window.location.href = '/profile-selection')}
            >
              {props.intl.formatMessage({id:"header.register"})}
            </Button>
          </div>
        </div>
        </div>
        </div>
        <div className='col-6 d-none d-sm-none d-md-flex justify-content-center align-items-center welcome-background'
        >
            <img
            src={logo}
            style={{width:"300px",height:"170px"}}
            />
        </div>
    </div>
    </div>
  );
}
export default injectIntl(Welcome);